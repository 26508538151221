import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { stopReloadPage } from '../../../actions/reload-page-manager';
import { setSelectedNode } from '../../../actions/selected-node-manager';
import {
  setNode,
  removeLeafProp,
  setTree,
} from '../../../actions/tree-manager';
import Nodes from './Nodes';

const mapStateToProps = (state) => ({
  globalSettings: state.globalSettingsManager.globalSettings,
  selectedNode: state.selectedNodeManager.node,
  reloadPage: state.reloadPageManager.reloadPage,
  roles: state.userManager.user.roles,
  mainColor: state.userManager.mainColor,
  rootNode: state.userManager.user.accountInfo
    ? state.userManager.user.accountInfo.nodeData
    : {},
});

const mapDispatchToProps = (dispatch) => ({
  stopReloadPage: () => dispatch(stopReloadPage()),
  setNode: (newNode) => dispatch(setNode(newNode)),
  setTree: (tree) => dispatch(setTree(tree)),
  setSelectedNode: (tree) => dispatch(setSelectedNode(tree)),
  removeLeafProp: (nodeId) => dispatch(removeLeafProp(nodeId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Nodes));
