const cleanUser = {
  userInfo: {
    firstname: '',
    lastname: '',
    fiscalCode: '',
    email: '',
    mobileNumber: '',
  },
  accountInfo: undefined,
  roles: [],
};

const userManager = (
  state = {
    isValidUser: false,
    userError: false,
    user: cleanUser,
    mainColor: '#02a8f3',
    logo: null,
    isLogoLoading: true,
  },
  action
) => {
  let nextState = state;

  switch (action.type) {
    case 'SET_USER':
      nextState = {
        ...state,
        isValidUser: true,
        userError: false,
        user: {
          userInfo: action.newUserInfo,
          accountInfo: action.newAccountInfo,
          roles: action.newRoleList,
        },
      };
      return nextState;
    case 'CLEAN_USER':
      nextState = {
        ...state,
        isValidUser: true,
        userError: false,
        user: cleanUser,
      };
      return nextState;
    case 'GET_USER_ERROR':
      nextState = {
        ...state,
        isValidUser: false,
        userError: true,
        user: cleanUser,
      };
      return nextState;
    case 'SET_MAIN_COLOR':
      nextState = {
        ...state,
        mainColor: action.mainColor,
      };
      return nextState;
    case 'SET_LOGO':
      nextState = {
        ...state,
        logo: action.logo,
      };
      return nextState;
    case 'SET_IS_LOGO_LOADING':
      nextState = {
        ...state,
        isLogoLoading: action.isLogoLoading,
      };
      return nextState;
    default:
      return nextState;
  }
};

export default userManager;
