const clearNode = {};

const selectedNodeManager = (state = { node: clearNode }, action) => {
  let nextState = state;

  switch (action.type) {
    case 'SET_SELECTED_NODE':
      nextState = {
        node: action.node,
      };
      return nextState;
    default:
      return nextState;
  }
};

export default selectedNodeManager;
