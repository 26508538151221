const defaultUrlManager = (
  state = {
    url: '/',
    currentPage: 'DASHBOARD',
  },
  action
) => {
  let nextState = state;

  switch (action.type) {
    case 'SET_DEFAULT_URL':
      nextState = {
        ...state,
        url: action.newState,
      };
      return nextState;

    case 'SET_CURRENT_PAGE':
      nextState = {
        ...state,
        currentPage: action.newState,
      };
      return nextState;

    default:
      return nextState;
  }
};

export default defaultUrlManager;
