const defaultTree = [];

const treeManager = (state = { tree: defaultTree }, action) => {
  let nextState = state;

  switch (action.type) {
    case 'SET_TREE':
      nextState = {
        tree: action.newTree,
      };
      return nextState;
    case 'SET_NODE':
      nextState = {
        tree: [...state.tree, action.newNode],
      };
      return nextState;
    case 'REMOVE_LEAF_PROP':
      nextState = {
        tree: [
          ...state.tree.map((el) =>
            el.id === action.nodeId ? { ...el, isLeaf: false } : el
          ),
        ],
      };
      return nextState;
    default:
      return nextState;
  }
};

export default treeManager;
