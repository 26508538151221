import {
  Alert,
  Button,
  Checkbox,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Modal,
  notification,
  Popconfirm,
  Row,
  Select,
  Spin,
  Switch,
  Tag,
  Tooltip,
} from 'antd';
import { ReactComponent as ItalyIco } from '../../../assets/icons/ico-flag-it.svg';
import { ReactComponent as UkIco } from '../../../assets/icons/ico-flag-uk.svg';
import { ReactComponent as EsIco } from '../../../assets/icons/ico-flag-es.svg';
import { ReactComponent as FrIco } from '../../../assets/icons/ico-flag-fr.svg';
import {
  QuestionCircleOutlined,
  DeleteOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import { useState } from 'react';
import './styles.less';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import PhoneInputWrapper from '../../PhoneInputWrapper';
import { getStatus, isNilOrEmpty } from '../../../utility/utils';

const { Option } = Select;

function ValidSignAccountDetailDrawer({
  data = {},
  nodeName,
  title,
  accountServices,
  canUpdate,
  canDelete,
  canChangeStatus,
  canRemoveRoles,
  isVisible,
  onRemoveAccountRole,
  onClose,
  accountRoles,
  t,
  assignableRole,
  onUpdate,
  onDelete,
  onSendActivationEmail,
  onChangeStatus,
}) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [editableAccountRoles, setEditableAccountRoles] = useState(
    accountRoles.sort((x, y) =>
      x.name === 'basic_user' ? -1 : y.name === 'basic_user' ? 1 : 0
    )
  );
  const langOptionStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  };
  const extTooltip = (title) => (
    <Tooltip title={title}>
      <QuestionCircleOutlined />
    </Tooltip>
  );
  const onOperationClick = (option, extraParam) => {
    switch (option) {
      case 'update':
        setIsLoading(true);

        form
          .validateFields()
          .then((values) => {
            onUpdate(values);
          })
          .catch((_) => {
            setIsLoading(false);
          });
        break;

      case 'change_status':
        Modal.confirm({
          title: t(`Modal.ChangeStatus.Account.${extraParam}.Title`, {
            login: data?.login,
          }),
          okText: t(`Modal.ChangeStatus.Account.${extraParam}.Confirm`),
          okButtonProps: {
            type: extraParam === 'REVOKE' ? 'primary' : 'default',
            danger: extraParam === 'REVOKE' || extraParam === 'SUSPEND',
          },
          cancelText: 'Cancel',
          autoFocusButton: null,
          closable: true,
          content: (
            <>
              <Alert
                style={{ marginBottom: 16 }}
                message={t(
                  `Modal.ChangeStatus.Account.${extraParam}.Description`,
                  { login: data?.login }
                )}
                type={extraParam === 'RESUME' ? 'info' : 'warning'}
              />
              {extraParam === 'REVOKE' ? (
                <span>
                  {t(`Modal.ChangeStatus.Account.${extraParam}.Extra`, {
                    login: data?.login,
                  })}
                </span>
              ) : null}
            </>
          ),
          onOk: () => {
            setIsLoading(true);
            changeStatus(extraParam);
          },
        });
        break;

      case 'resend_email':
        Modal.confirm({
          title: 'Re-send activation email',
          okText: 'Confirm',
          closable: true,
          content: (
            <>
              <Alert
                style={{ marginBottom: 16 }}
                message="Pressing the send button will be send the activation e-mail"
                type="warning"
              />
              <span>
                Are you sure to re-send the activation email to{' '}
                <strong>{data.username}</strong>?
              </span>
            </>
          ),
          onOk: () => {
            setIsLoading(true);
            onSendActivationEmail();
          },
        });
        break;

      case 'delete':
        Modal.confirm({
          title: 'Delete IG SIGN account',
          okText: 'Delete',
          okType: 'danger',
          okButtonProps: {
            type: 'primary',
          },
          cancelText: 'Cancel',
          autoFocusButton: null,
          closable: true,
          content: (
            <>
              <Alert
                style={{ marginBottom: 16 }}
                message="Non-reversible operation"
                description="Pressing delete will lose all account information"
                type="warning"
              />
              <span>
                Are you sure to delete <strong>{data.username}</strong> from{' '}
                <strong>{nodeName}</strong>?
              </span>
            </>
          ),
          onOk: () => {
            setIsLoading(true);
            onDelete();
          },
        });
        break;

      default:
        break;
    }
  };

  const changeStatus = (newStatus) => {
    accountServices.updateAccountStatus(data?.login, newStatus).then(
      (response) => {
        onChangeStatus('error');

        setIsLoading(false);

        if (response?.data) {
          if (accountServices.checkError(response)) {
            const error = accountServices.getError(response);

            Modal.error({
              title: t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          } else {
            onChangeStatus('success');
          }
        }
      },
      (error) => {
        onChangeStatus('error');
        setIsLoading(false);

        if (axios.isCancel(error)) {
        } else {
          Modal.error({
            title: this.props.t('App.Message.General.ServerError.Title'),
            content: error.message,
          });
        }
      }
    );
  };

  const checkRole = (roleName) => {
    return data.roles.filter((role) => role.name === roleName)?.length > 0;
  };

  const getUserType = () => {
    // const isVsAdm =
    //   data.roles.filter((role) => role.name === 'validsign_administrator')
    //     ?.length > 0;

    // const isVsAdmExt =
    //   data.roles.filter((role) => role.name === 'validsign_administrator_ext')
    //     ?.length > 0;

    const isVsAdm =
      data.roles.filter((role) => role.name === 'vs_admin')
        ?.length > 0;

    const isVsAdmExt =
      data.roles.filter((role) => role.name === 'vs_admin_ext')
        ?.length > 0;

    const isVsAdmAdv =
      data.roles.filter((role) => role.name === 'vs_admin_adv')
        ?.length > 0;

    const isVsAdmGrp =
      data.roles.filter((role) => role.name === 'vs_group_admin')
        ?.length > 0;

    let userType = 'validsign_user';

    // if (isVsAdm) {
    //   userType = 'validsign_administrator';
    // }

    // if (isVsAdmExt) {
    //   userType = 'validsign_administrator_ext';
    // }

    if (isVsAdm) {
      userType = 'vs_admin';
    }

    if (isVsAdmExt) {
      userType = 'vs_admin_ext';
    }

    if (isVsAdmAdv) {
      userType = 'vs_admin_adv';
    }

    if (isVsAdmGrp) {
      userType = 'vs_group_admin';
    }

    return userType;
  };
  const [userType, setUserType] = useState(getUserType());

  const removeRoleFromAccount = (roleName) => {
    accountServices.deleteAuthorizedRole(data.login, roleName).then(
      (response) => {
        if (response?.data) {
          if (accountServices.checkError(response)) {
            const error = accountServices.getError(response);

            Modal.error({
              title: t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          } else {
            notification.success({
              description: t(
                'Modal.ChangeItemLinkStatus.Role_account.Message',
                { action: 'removed' }
              ),
            });
            setEditableAccountRoles(
              editableAccountRoles.filter(function (obj) {
                return obj.name !== roleName;
              })
            );
            onClose();
            onRemoveAccountRole();
          }
        }
      },
      (error) => {
        Modal.error({
          title: t('App.Message.General.ServerError.Title'),
          content: error.message,
        });
      }
    );
  };

  return (
    <Drawer
      title={title}
      className="validSignAccountDetailDrawer"
      placement="right"
      width={480}
      onClose={onClose}
      visible={isVisible}
      footer={
        <Row align="middle" justify="space-between">
          <Col>
            <Button onClick={onClose}>{t('Drawer.Buttons.Close')}</Button>
          </Col>
          {canDelete && (
            <Col>
              <Button
                danger
                onClick={() => onOperationClick('delete')}
                icon={<DeleteOutlined />}
                type="primary"
              >
                {t('Modal.Modify.Validsign.Buttons.Delete')}
              </Button>
            </Col>
          )}
          {canUpdate && (
            <Col>
              <Button onClick={() => onOperationClick('update')} type="primary">
                {t('Modal.Modify.Validsign.Buttons.Modify')}
              </Button>
            </Col>
          )}
        </Row>
      }
    >
      <Spin spinning={isLoading}>
        {canChangeStatus && (
          <>
            <Row justify="space-between" align="middle">
              <Row align="middle">
                <Row>{t('Modal.ChangeStatus.Account.Status')}:</Row>
                <Row>
                  <Tag color={getStatus(data?.accountStatus, t).color}>
                    {getStatus(data?.accountStatus, t).value}
                  </Tag>
                </Row>
              </Row>
              <Row className="btnStatusContainer">
                {data?.accountStatus > 0 && data?.accountStatus !== 8 && (
                  <>
                    <Row>
                      <Button
                        type="primary"
                        danger
                        size="small"
                        onClick={() =>
                          onOperationClick('change_status', 'REVOKE')
                        }
                      >
                        {t('Modal.ChangeStatus.Account.REVOKE.Confirm')}
                      </Button>
                    </Row>
                  </>
                )}
                {data?.accountStatus === 1 && (
                  <Row>
                    <Button
                      danger
                      size="small"
                      onClick={() =>
                        onOperationClick('change_status', 'SUSPEND')
                      }
                    >
                      {t('Modal.ChangeStatus.Account.SUSPEND.Confirm')}
                    </Button>
                  </Row>
                )}
                {data?.accountStatus === 6 && (
                  <Row>
                    <Button
                      size="small"
                      onClick={() =>
                        onOperationClick('change_status', 'RESUME')
                      }
                    >
                      {t('Modal.ChangeStatus.Account.RESUME.Confirm')}
                    </Button>
                  </Row>
                )}
                {data.accountStatus === 9 ? (
                  <Row>
                    <Button
                      size="small"
                      onClick={() => onOperationClick('resend_email')}
                    >
                      {t('Modal.Modify.Validsign.Buttons.ResendEmail')}
                    </Button>
                  </Row>
                ) : null}
              </Row>
            </Row>
            <Divider />
          </>
        )}
        <Form
          layout="vertical"
          form={form}
          requiredMark="optional"
          initialValues={{
            language: data.language,
            email: data.userData?.email,
            firstName: data.userData?.firstname,
            lastName: data.userData?.lastname,
            phone: data.userData?.mobileNumber,
            userType: getUserType(),
            sendActivationEmail: data.accountStatus === 9,
            validsign_user: checkRole('validsign_user'),
            validsign_basic_cloud: checkRole('validsign_basic_cloud'),
            validsign_user_cloud: checkRole('validsign_user_cloud'),
            validsign_administrator_ext: checkRole(
              'validsign_administrator_ext'
            ),
            vs_admin_adv: checkRole('vs_admin_adv'),
            vs_admin_ext: checkRole('vs_admin_ext'),
            validsign_administrator_cloud: checkRole(
              'validsign_administrator_cloud'
            ),
          }}
        >
          <Row gutter={16}>
            <Col xs={16}>
              <Form.Item
                label={t('Modal.Modify.Validsign.Form.Email.Label')}
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'Please input a valid e-mail!',
                  },
                  {
                    required: true,
                    message: t('General.MandatoryField.Text'),
                  },
                ]}
              >
                <Input
                  placeholder={t(
                    'Modal.Modify.Validsign.Form.Email.Placeholder'
                  )}
                  disabled={!canUpdate}
                />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item
                label={t('Modal.Modify.Validsign.Form.Language.Label')}
                name="language"
                rules={[
                  {
                    required: true,
                    message: t('General.MandatoryField.Text'),
                  },
                ]}
              >
                <Select disabled={!canUpdate}>
                  <Option value="EN">
                    <div style={langOptionStyle}>
                      <UkIco
                        style={{ width: 20, height: 20, marginRight: 8 }}
                      />
                      <span>{t('App.Languages.ENGLISH')}</span>
                    </div>
                  </Option>
                  <Option value="IT">
                    <div style={langOptionStyle}>
                      <ItalyIco
                        style={{ width: 20, height: 20, marginRight: 8 }}
                      />
                      <span>{t('App.Languages.ITALIAN')}</span>
                    </div>
                  </Option>
                  <Option value="ES">
                    <div style={langOptionStyle}>
                      <EsIco
                        style={{ width: 20, height: 20, marginRight: 8 }}
                      />
                      <span>{t('App.Languages.SPANISH')}</span>
                    </div>
                  </Option>
                  <Option value="FR">
                    <div style={langOptionStyle}>
                      <FrIco
                        style={{ width: 20, height: 20, marginRight: 8 }}
                      />
                      <span>{t('App.Languages.FRENCH')}</span>
                    </div>
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={12}>
              <Form.Item
                label={t('Modal.Modify.Validsign.Form.Name.Label')}
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: t('General.MandatoryField.Text'),
                  },
                ]}
              >
                <Input
                  placeholder={t(
                    'Modal.Modify.Validsign.Form.Name.Placeholder'
                  )}
                  disabled={!canUpdate}
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label={t('Modal.Modify.Validsign.Form.Surname.Label')}
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: t('General.MandatoryField.Text'),
                  },
                ]}
              >
                <Input
                  placeholder={t(
                    'Modal.Modify.Validsign.Form.Surname.Placeholder'
                  )}
                  disabled={!canUpdate}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: t('General.MandatoryField.Text'),
                  },
                ]}
              >
                <PhoneInputWrapper
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  disabled={!canUpdate}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <div className="userType-section">
            <Form.Item
              name="userType"
              label={t('Modal.Modify.Validsign.Form.UserType.Label')}
              rules={[
                {
                  required: true,
                  message: t('General.MandatoryField.Text'),
                },
              ]}
              className="userType-section-inputSelect"
            >
              <Select
                disabled={!canUpdate}
                placeholder={t('App.AssignableRole.Label')}
                onChange={(value) => setUserType(value)}
              >
                {assignableRole.map((role) => {
                  if (
                    role === 'validsign_user' ||
                    role === 'vs_admin' ||
                    role === 'vs_admin_ext' ||
                    role === 'vs_admin_adv' ||
                    role === 'vs_group_admin'
                  ) {
                    return (
                      <Option value={role}>
                        {t(`App.AssignableRole.Role.${role}`)}
                      </Option>
                    );
                  } else return null;
                })}
              </Select>
            </Form.Item>
            {userType === 'validsign_user' ? (
              <>
                <Row className="roles-switch" gutter={[8, 8]}>
                  <Col>
                    <Form.Item name="validsign_basic">
                      <Switch defaultChecked disabled />
                    </Form.Item>
                  </Col>
                  <Col>
                    {t('Modal.Add.Validsign.UserTypes.OnlyQuickSign.Label')}{' '}
                    {extTooltip(
                      t('Modal.Add.Validsign.UserTypes.OnlyQuickSign.Tooltip')
                    )}
                  </Col>
                </Row>
                {assignableRole.indexOf('validsign_user') > -1 && (
                  <Row className="roles-switch" gutter={[8, 8]}>
                    <Col>
                      <Form.Item name="validsign_user" valuePropName="checked">
                        <Switch disabled={!canUpdate} />
                      </Form.Item>
                    </Col>
                    <Col>
                      {t('Modal.Add.Validsign.UserTypes.FolderCreator.Label')}{' '}
                      {extTooltip(
                        t('Modal.Add.Validsign.UserTypes.FolderCreator.Tooltip')
                      )}
                    </Col>
                  </Row>
                )}
                {assignableRole.indexOf('validsign_basic_cloud') > -1 && (
                  <Row className="roles-switch" gutter={[8, 8]}>
                    <Col>
                      <Form.Item
                        name="validsign_basic_cloud"
                        valuePropName="checked"
                      >
                        <Switch disabled={!canUpdate} />
                      </Form.Item>
                    </Col>
                    <Col>
                      {t(
                        'Modal.Add.Validsign.UserTypes.OnlyQuickSignCloud.Label'
                      )}{' '}
                      {extTooltip(
                        t(
                          'Modal.Add.Validsign.UserTypes.OnlyQuickSignCloud.Tooltip'
                        )
                      )}
                    </Col>
                  </Row>
                )}
                {assignableRole.indexOf('validsign_user_cloud') > -1 && (
                  <Row className="roles-switch" gutter={[8, 8]}>
                    <Col>
                      <Form.Item
                        name="validsign_user_cloud"
                        valuePropName="checked"
                      >
                        <Switch disabled={!canUpdate} />
                      </Form.Item>
                    </Col>
                    <Col>
                      {t(
                        'Modal.Add.Validsign.UserTypes.FolderCreatorCloud.Label'
                      )}{' '}
                      {extTooltip(
                        t(
                          'Modal.Add.Validsign.UserTypes.FolderCreatorCloud.Tooltip'
                        )
                      )}
                    </Col>
                  </Row>
                )}
              </>
            ) : userType === 'vs_admin' ||
            userType === 'vs_admin_ext' ||
            userType === 'vs_admin_adv' ||
            userType === 'vs_group_admin' ? (
              <>
                {assignableRole.indexOf('validsign_user') > -1 && (
                  <Row className="roles-switch" gutter={[8, 8]}>
                    <Col>
                      <Form.Item name="validsign_user" valuePropName="checked">
                        <Switch disabled={!canUpdate} />
                      </Form.Item>
                    </Col>
                    <Col>
                      {t('Modal.Add.Validsign.UserTypes.FolderCreator.Label')}{' '}
                      {extTooltip(
                        t('Modal.Add.Validsign.UserTypes.FolderCreator.Tooltip')
                      )}
                    </Col>
                  </Row>
                )}
                {assignableRole.indexOf('validsign_user_cloud') > -1 && (
                  <Row className="roles-switch" gutter={[8, 8]}>
                    <Col>
                      <Form.Item
                        name="validsign_user_cloud"
                        valuePropName="checked"
                      >
                        <Switch disabled={!canUpdate} />
                      </Form.Item>
                    </Col>
                    <Col>
                      {t(
                        'Modal.Add.Validsign.UserTypes.FolderCreatorCloud.Label'
                      )}{' '}
                      {extTooltip(
                        t(
                          'Modal.Add.Validsign.UserTypes.FolderCreatorCloud.Tooltip'
                        )
                      )}
                    </Col>
                  </Row>
                )}
                {/* {assignableRole.indexOf('vs_admin_adv') > -1 && (
                  <Row className="roles-switch" gutter={[8, 8]}>
                    <Col>
                      <Form.Item name="vs_admin_adv" valuePropName="checked">
                        <Switch disabled={!canUpdate} />
                      </Form.Item>
                    </Col>
                    <Col>
                      {t('Modal.Add.Validsign.UserTypes.WithSettings.Label')}{' '}
                      {extTooltip(
                        t('Modal.Add.Validsign.UserTypes.WithSettings.Tooltip')
                      )}
                    </Col>
                  </Row>
                )} */}
                {/* {assignableRole.indexOf('vs_admin_ext') > -1 && (
                  <Row className="roles-switch" gutter={[8, 8]}>
                    <Col>
                      <Form.Item name="vs_admin_ext" valuePropName="checked">
                        <Switch disabled={!canUpdate} />
                      </Form.Item>
                    </Col>
                    <Col>
                      {t('Modal.Add.Validsign.UserTypes.WithSubc.Label')}{' '}
                      {extTooltip(
                        t('Modal.Add.Validsign.UserTypes.WithSubc.Tooltip')
                      )}
                    </Col>
                  </Row>
                )} */}
                {/* {assignableRole.indexOf('validsign_administrator_cloud') >
                  -1 && (
                  <Row className="roles-switch" gutter={[8, 8]}>
                    <Col>
                      <Form.Item
                        name="validsign_administrator_cloud"
                        valuePropName="checked"
                      >
                        <Switch disabled={!canUpdate} />
                      </Form.Item>
                    </Col>
                    <Col>
                      {t('Modal.Add.Validsign.UserTypes.WithIGCloud.Label')}{' '}
                      {extTooltip(
                        t('Modal.Add.Validsign.UserTypes.WithIGCloud.Tooltip')
                      )}
                    </Col>
                  </Row>
                )} */}
              </>
            ) : null}
          </div>
          <Divider />
          <Row align="middle" justify="space-between">
            <Col>
              <Form.Item
                name="sendActivationEmail"
                valuePropName="checked"
                style={{ marginBottom: 0 }}
              >
                <Checkbox disabled={!canUpdate}>
                  {t('Modal.Modify.Validsign.Form.ConfirmationEmail.Label')}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {!isNilOrEmpty(editableAccountRoles) && (
          <>
            <Divider style={{ marginBottom: 8 }} orientation="left">
              {t('PageContainer.Roles.Title')}
            </Divider>
            {editableAccountRoles.map((role, i) => (
              <div
                className="customTag"
                style={{
                  display: 'inline-flex',
                  background: '#fafafa',
                  border: '1px solid #d9d9d9',
                  padding: '8px 12px',
                  boxSizing: 'border-box',
                  alignItems: 'center',
                  margin: '8px 8px 0 0',
                }}
              >
                <span>{role.name}</span>
                {canRemoveRoles && role.name !== 'basic_user' && (
                  <Popconfirm
                    title="Are you sure to remove this role from this account?"
                    onConfirm={() => removeRoleFromAccount(role.name)}
                    okText="Yes"
                    cancelText="No"
                    key={i}
                  >
                    <LinkOutlined
                      style={{
                        marginLeft: 8,
                        cursor: 'pointer',
                        color: '#ff4d4f',
                      }}
                    />
                  </Popconfirm>
                )}
              </div>
            ))}
          </>
        )}
      </Spin>
    </Drawer>
  );
}

export default withTranslation()(ValidSignAccountDetailDrawer);
