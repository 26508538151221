import { withTranslation } from 'react-i18next';
import { Col, Layout, Row, Typography } from 'antd';

const { Title } = Typography;

function SoftwareInfo({ t }) {
  const release =
    process.env.REACT_APP_VERSION ||
    t('PageContainer.SoftwareInfo.ReleaseNotAvailable');
  const environment =
    process.env.REACT_APP_ENV ||
    t('PageContainer.SoftwareInfo.EnvironmentNotAvailable');

  return (
    <div className="softwareInfo">
      <Title level={3}>{t('PageContainer.SoftwareInfo.Title')}</Title>
      <Layout className="softwareInfo-main">
        <Row>
          <Col xs={3}>
            {t('PageContainer.SoftwareInfo.ReleaseDetail.Release')}
          </Col>
          <Col xs={21}>{release}</Col>
        </Row>
        <Row>
          <Col xs={3}>
            {t('PageContainer.SoftwareInfo.ReleaseDetail.Environment')}
          </Col>
          <Col xs={21}>{environment}</Col>
        </Row>
      </Layout>
    </div>
  );
}

export default withTranslation()(SoftwareInfo);
