import HttpServices from './http.services';

class UserServices {
  constructor(envSettings) {
    this.envSettings = envSettings;
    this.httpService = new HttpServices(this.envSettings);
  }

  checkError = (res) => {
    if (res.data.error) {
      return true;
    } else {
      return false;
    }
  };

  getError = (res) => {
    return {
      code: res.data.error.code,
      message: res.data.error.message,
    };
  };

  getResult = (res) => {
    return res.data.result;
  };

  getAccountInfoEx = () => {
    const param = {};
    return this.httpService.httpServicesPost(
      'getAccountInfoEx',
      this.envSettings,
      param
    );
  };

  updateUserData = (
    language,
    userData,
    confirmationEmail = this.envSettings.envConstants.confirmationEmail
  ) => {
    const param = {
      language: language,
      confirmationEmail: confirmationEmail,
      userData: {
        ...userData,
        mobileNumber: userData.mobileNumber.replaceAll('+', '').replace(/^/, '+')
      },
    };
    return this.httpService.httpServicesPost(
      'updateUserData',
      this.envSettings,
      param
    );
  };
}

export default UserServices;
