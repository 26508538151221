import {
  Alert,
  Col,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Select,
  Skeleton,
  Space,
  Switch,
  Table,
  Tabs,
  Tooltip,
  Typography,
} from 'antd';
import { LinkOutlined, GlobalOutlined } from '@ant-design/icons';
import { Component } from 'react';
import './styles.less';
import AppUtil from '../../../utility/admin.util';
import AccountServices from '../../../services/account.services';
import LogsFirer from '../../../services/logs-firer.services';
import axios from 'axios';
import _ from 'lodash';
import RoleServices from '../../../services/role.services';
import { withTranslation } from 'react-i18next';
import Text from 'antd/lib/typography/Text';

const { Title } = Typography;
const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

class Context {
  name;
  description;
  rightInfo;
  assignableRole;
  key;

  constructor(name, description = '') {
    this.description = description;
    this.name = name;
    this.rightInfo = [];
    this.assignableRole = [];
    this.key = Math.random();
  }

  setRightInfo = (rightInfo) => {
    rightInfo.forEach((item) => {
      this.rightInfo.push(item);
    });
  };

  setAssignableRole = (assignableRole) => {
    assignableRole.forEach((item) => {
      this.assignableRole.push(item);
    });
  };
}

class Roles extends Component {
  isTreeViewAllowed;

  constructor(props) {
    super(props);
    let filteredRoles;
    let rightInfoList;

    let permissionList;
    let userRolesRightInfo;

    this.isTreeViewAllowed = this.props.roles.some((r) => {
      return Object.entries(r.attributes).some(([_, value]) => {
        return value.rightInfo && value.rightInfo.indexOf('treeView') !== -1
          ? true
          : false;
      });
    });

    filteredRoles = this.props.roles.filter((r) => {
      return !_.isUndefined(r.attributes['role_manager']);
    });

    permissionList =
      this.props.globalSettings.PagePermissionManager['role_manager'] || {};
    rightInfoList = filteredRoles[0]
      ? filteredRoles[0].attributes['role_manager'].rightInfo || []
      : [];

    this.assignableRole = filteredRoles[0]
      ? filteredRoles[0].attributes['role_manager'].assignableRole || []
      : [];

    if (_.isEmpty(permissionList)) {
      this.props.history.push('/app/fallback');
      userRolesRightInfo = {
        inquiryRole: false,
        createRole: false,
        updateRole: false,
        deleteRole: false,
        addProfile: false,
        deleteProfile: false,
        updateProfile: false,
        addAuthorizedRole: false,
        deleteAuthorizedRole: false,
      };
    } else {
      userRolesRightInfo = {
        inquiryRole: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'inquiryRole'
        ),
        createRole: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'createRole'
        ),
        updateRole: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'updateRole'
        ),
        deleteRole: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'deleteRole'
        ),
        addProfile: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'addProfile'
        ),
        deleteProfile: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'deleteProfile'
        ),
        updateProfile: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'updateProfile'
        ),
        addAuthorizedRole: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'addAuthorizedRole'
        ),
        deleteAuthorizedRole: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'deleteAuthorizedRole'
        ),
      };
    }

    this.state = {
      selectedNode: this.props.selectedNode,
      activeTab: 1,
      roleList: [],
      isRoleOpen: true,
      isRolesLoading: false,
      selectedRole: {},

      accountList: [],
      accountRows: 1,
      loadedAccountList: false,
      accountFilterManager: {
        allAccountVisible: false,
        page: 1,
        searchString: '',
        order: 'ASC',
        recursion: this.isTreeViewAllowed,
        activeFuzzy: false,
      },

      contextList: [],
      contextRows: 1,
      loadedContextList: false,
      contextFilterManager: {
        page: 1,
        searchString: '',
        order: 'ASC',
        recursion: this.isTreeViewAllowed,
        activeFuzzy: false,
      },

      userRolesRightInfo: userRolesRightInfo,
    };

    if (
      _.isString(process.env.REACT_APP_OFFLINE) &&
      _.isBoolean(JSON.parse(process.env.REACT_APP_OFFLINE))
    ) {
      this.offline = JSON.parse(process.env.REACT_APP_OFFLINE);
    } else {
      this.offline = false;
    }

    if (_.isString(process.env.REACT_APP_ENV)) {
      this.env = process.env.REACT_APP_ENV;
    } else {
      this.env = this.props.globalSettings.DefaultEnvironment;
    }

    this.accountServices = new AccountServices(
      this.props.globalSettings.Environment[this.env]
    );
    this.roleServices = new RoleServices(
      this.props.globalSettings.Environment[this.env]
    );
    this.logFirer = new LogsFirer(
      this.props.globalSettings.Environment[this.env]
    );
    this.pageSize = this.props.globalSettings.Environment[
      this.env
    ].envConstants.pageSize;
    this.stopStatePropagation = true;
  }

  submit = (e) => {
    e.preventDefault();
  };

  changeSelectedRole = (option) => {
    let newSelectedRole = this.state.roleList.filter((role) => {
      return role.roleId === parseInt(option, 10);
    })[0];

    this.setState(
      {
        selectedRole: _.cloneDeep(newSelectedRole),
      },
      () => {
        this.refreshAccountList(
          this.state.accountFilterManager.page,
          this.state.accountFilterManager.searchString,
          this.state.accountFilterManager.order,
          this.state.accountFilterManager.recursion,
          this.state.accountFilterManager.activeFuzzy
        );
        this.refreshContextList(
          1,
          '',
          'ASC',
          this.state.contextFilterManager.recursion
        );
      }
    );
  };

  handleAccountChange = (event) => {
    clearTimeout(this.timer);

    this.setState({
      searchString: event.target.value,
    });

    this.timer = setTimeout(
      event.target.value.length > 0 && event.target.value.length < 3 ?
        null
        : () => this.refreshAccountList(
          1,
          event.target.value,
          this.state.accountFilterManager.order,
          this.state.accountFilterManager.recursion,
          this.state.accountFilterManager.activeFuzzy
        ), 1500);
  };

  handleContextChange = (event) => {
    if(event.target.value.length >= 1 && event.target.value.length < 3) return

    clearTimeout(this.timer);

    this.timer = setTimeout(() => this.refreshContextList(
      1,
      event.target.value,
      this.state.contextFilterManager.order,
      this.state.contextFilterManager.recursion
    ), 1500)
  };

  changeAccountPagination = (pagination) => {
    this.refreshAccountList(
      pagination.current,
      this.state.accountFilterManager.searchString,
      this.state.accountFilterManager.order,
      this.state.accountFilterManager.recursion,
      this.state.accountFilterManager.activeFuzzy
    );
  };

  changeContextPagination = (pagination) => {
    this.refreshContextList(
      pagination.current,
      this.state.contextFilterManager.searchString,
      this.state.contextFilterManager.order,
      this.state.contextFilterManager.recursion
    );
  };

  seeAllAccount = (newState) => {
    this.setState(
      (prevState) => ({
        accountFilterManager: {
          ...prevState.accountFilterManager,
          allAccountVisible: newState,
          page: 1,
        },
        loadedAccountList: false,
      }),
      () => {
        if (this.props.selectedNode.nodeId && this.state.selectedRole) {
          this.getAccountList(
            this.props.selectedNode.nodeId,
            this.state.selectedRole.name,
            this.state.accountFilterManager.recursion
          );
        }
      }
    );
  };

  refreshAccountList = (
    page,
    searchStr,
    order,
    recursion,
    activeFuzzy = false
  ) => {
    if (this.props.selectedNode.nodeId && this.state.selectedRole) {
      let nodeId = this.props.selectedNode.nodeId;

      this.stopStatePropagation &&
        this.setState(
          (prevState) => ({
            loadedAccountList: false,
            accountFilterManager: {
              ...prevState.accountFilterManager,
              page,
              searchString: searchStr,
              order: order,
              recursion: this.isTreeViewAllowed ? recursion : false,
            },
          }),
          () =>
            this.getAccountList(
              nodeId,
              this.state.selectedRole?.name || '',
              this.isTreeViewAllowed ? recursion : false
            )
        );
    }
  };

  refreshContextList = (page, searchStr, order, activeFuzzy = false) => {
    if (this.props.selectedNode.nodeId && this.state.selectedRole) {
      let nodeId = this.props.selectedNode.nodeId;

      this.stopStatePropagation &&
        this.setState(
          (prevState) => ({
            loadedContextList: false,
            contextFilterManager: {
              ...prevState.contextFilterManager,
              page: page,
              searchString: searchStr,
              order: order,
            },
          }),
          () => {
            return this.state.selectedRole
              ? this.getContextList(nodeId, this.state.selectedRole.roleId)
              : null;
          }
        );
    }
  };

  getAccountList = (nodeId, roleName, hereditariness = true) => {
    let str = !this.state.accountFilterManager.activeFuzzy
      ? this.state.accountFilterManager.searchString
      : this.state.accountFilterManager.searchString.split('').join('%');

    this.accountServices
      .listAccounts(
        nodeId,
        {
          login: str,
          roles: this.state.accountFilterManager.allAccountVisible
            ? ''
            : roleName,
        },
        {
          login: this.state.accountFilterManager.order,
        },
        this.state.accountFilterManager.page,
        this.isTreeViewAllowed ? hereditariness : false
      )
      .then(
        (response) => {
          if (response?.data) {
            if (this.accountServices.checkError(response)) {
              const error = this.accountServices.getError(response);

              Modal.error({
                title: this.props.t('App.Message.General.ServerError.Title'),
                content: error.message,
              });

              this.stopStatePropagation &&
                this.setState({
                  accountList: [],
                  loadedAccountList: true,
                });
            } else {
              const result = this.accountServices.getResult(response);

              if (result.accountsRows) {
                this.stopStatePropagation &&
                  this.setState({
                    accountList: result.accountsInfoEx || [],
                    accountRows: result.accountsRows,
                    loadedAccountList: true,
                  });
              } else {
                if (result.accountsInfoEx && result.accountsInfoEx.length > 0) {
                  this.stopStatePropagation &&
                    this.setState({
                      accountList: result.accountsInfoEx || [],
                      loadedAccountList: true,
                    });
                } else {
                  this.stopStatePropagation &&
                    this.setState({
                      accountList: result.accountsInfoEx || [],
                      accountsRows: 1,
                      loadedAccountList: true,
                    });
                }
              }
            }
          }
        },
        (error) => {
          if (axios.isCancel(error)) {
            this.logFirer.fireLog('LOG', error);
          } else {
            this.stopStatePropagation &&
              this.setState({
                accountList: [],
                loadedAccountList: true,
              });
            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };

  getContextList = (nodeId, roleId) => {
    let str = !this.state.contextFilterManager.activeFuzzy
      ? this.state.contextFilterManager.searchString
      : this.state.contextFilterManager.searchString.split('').join('%');

    this.roleServices
      .getRoleContexts(
        nodeId,
        roleId,
        this.state.contextFilterManager.page,
        str
      )
      .then(
        (response) => {
          if (response?.data) {
            if (this.roleServices.checkError(response)) {
              const error = this.accountServices.getError(response);

              Modal.error({
                title: this.props.t('App.Message.General.ServerError.Title'),
                content: error.message,
              });

              this.stopStatePropagation &&
                this.setState({
                  contextList: [],
                  loadedContextList: true,
                });
            } else {
              const result = this.roleServices.getResult(response);
              if (result) {
                let contexts = [];
                for (const key in result.contexts) {
                  if (result.contexts.hasOwnProperty(key)) {
                    const element = result.contexts[key];
                    let context = new Context(key);
                    context.setRightInfo(element.rightInfo || []);
                    context.setAssignableRole(element.assignableRole || []);
                    contexts.push(context);
                  }
                }
                this.stopStatePropagation &&
                  this.setState({
                    contextList: contexts,
                    contextRows: result.countRows,
                    loadedContextList: true,
                  });
              }
            }
          }
        },
        (error) => {
          if (axios.isCancel(error)) {
            this.logFirer.fireLog('LOG', error);
          } else {
            this.stopStatePropagation &&
              this.setState({
                contextList: [],
                loadedContextList: true,
              });
            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };

  getRoleList = (nodeId) => {
    this.setState({ isRolesLoading: true });

    this.roleServices.getRoles(nodeId).then(
      (response) => {
        this.setState({ isRolesLoading: false });

        if (response?.data) {
          if (this.roleServices.checkError(response)) {
            const error = this.roleServices.getError(response);

            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });

            this.stopStatePropagation &&
              this.setState({
                accountList: [],
                loadedAccountList: true,
              });
          } else {
            let result = this.roleServices.getResult(response);

            result.roles = result.roles.filter(
              (role) => role.name !== 'basic_user'
            );

            if (result) {
              let roleList;

              roleList = [];
              result.roles.forEach((r) => {
                if (this.assignableRole.indexOf(r.name) !== -1) {
                  let newRole = {
                    roleId: r.roleId,
                    entityId: r.entityId,
                    name: r.name,
                    description: r.description,
                    nodeId: r.nodeId !== null ? r.nodeId : undefined,
                    nodeName: r.nodeName !== null ? r.nodeName : undefined,
                  };
                  roleList.push(newRole);
                }
              });

              this.setState(
                {
                  roleList: _.cloneDeep(result.roles),
                  selectedRole:
                    result.roles.length > 0
                      ? _.isEmpty(this.state.selectedRole)
                        ? _.cloneDeep(result.roles[0])
                        : this.state.selectedRole
                      : undefined,
                },
                () => {
                  if (this.state.selectedRole) {
                    this.getAccountList(nodeId, this.state.selectedRole.name);
                    this.getContextList(nodeId, this.state.selectedRole.roleId);
                  }
                }
              );
            }
          }
        }
      },
      (error) => {
        this.setState({ isRolesLoading: false });

        if (axios.isCancel(error)) {
          this.logFirer.fireLog('LOG', error);
        } else {
          this.stopStatePropagation &&
            this.setState({
              accountList: [],
              loadedAccountList: true,
            });
          Modal.error({
            title: this.props.t('App.Message.General.ServerError.Title'),
            content: error.message,
          });
        }
      }
    );
  };

  componentWillMount = () => {
    if (this.props.selectedNode.nodeId) {
      this.getRoleList(this.props.selectedNode.nodeId);
    }
  };

  componentDidUpdate = () => {
    if (
      this.props.selectedNode.nodeId &&
      this.props.selectedNode.nodeId !== this.state.selectedNode.nodeId
    ) {
      this.stopStatePropagation &&
        this.setState((prevState) => ({
          selectedNode: this.props.selectedNode,
          loadedAccountList: false,
          accountFilterManager: {
            ...prevState.accountFilterManager,
            page: 1,
            searchString: '',
            order: 'ASC',
            recursion: this.isTreeViewAllowed,
          },
          loadedContextList: false,
          contextFilterManager: {
            ...prevState.accountFilterManager,
            page: 1,
            searchString: '',
            order: 'ASC',
            recursion: this.isTreeViewAllowed,
          },
        }));
      this.getRoleList(this.props.selectedNode.nodeId);
    }
    if (this.props.reloadPage && this.props.selectedNode.nodeId) {
      this.props.stopReloadPage();
      this.stopStatePropagation &&
        this.setState((prevState) => ({
          selectedNode: this.props.selectedNode,
          loadedAccountList: false,
          accountFilterManager: {
            ...prevState.accountFilterManager,
            searchString: '',
          },
          loadedContextList: false,
          contextFilterManager: {
            ...prevState.accountFilterManager,
            searchString: '',
          },
        }));
      this.getRoleList(this.props.selectedNode.nodeId);
    }
  };

  componentWillUnmount = () => {
    this.stopStatePropagation = false;
  };

  changeAccountStatus = (account, isSuscribed) => {
    const that = this;

    Modal.confirm({
      title: this.props.t('Modal.ChangeItemLinkStatus.Role_account.Title', {
        action: isSuscribed ? 'Removing' : 'Adding',
        nodeName: that.state.selectedNode.nodeName,
      }),
      okText: isSuscribed ? 'Remove' : 'Add',
      okType: isSuscribed ? 'danger' : '',
      okButtonProps: {
        type: 'primary',
      },
      cancelText: 'Cancel',
      autoFocusButton: null,
      closable: true,
      content: (
        <>
          <Alert
            style={{ marginBottom: 16 }}
            message={this.props.t(
              'Modal.ChangeItemLinkStatus.Role_account.ActionMessage.Warning',
              { action: isSuscribed ? 'Removing' : 'Adding' }
            )}
            type="warning"
          />
          <span>
            {this.props.t(
              'Modal.ChangeItemLinkStatus.Role_account.ActionMessage.Question',
              {
                action: isSuscribed ? 'remove' : 'add',
                role: this.state.selectedRole.name,
                login: account.login,
              }
            )}
          </span>
        </>
      ),
      onOk: () => {
        if (isSuscribed) {
          this.accountServices
            .deleteAuthorizedRole(account.login, this.state.selectedRole.name)
            .then(
              (response) => {
                if (response?.data) {
                  if (this.accountServices.checkError(response)) {
                    const error = this.accountServices.getError(response);

                    Modal.error({
                      title: this.props.t(
                        'App.Message.General.ServerError.Title'
                      ),
                      content: error.message,
                    });
                  } else {
                    message.success(
                      this.props.t(
                        'Modal.ChangeItemLinkStatus.Role_account.Message',
                        { action: 'removed' }
                      )
                    );
                    this.getAccountList(
                      this.props.selectedNode.nodeId,
                      this.state.selectedRole.name,
                      this.state.accountFilterManager.recursion
                    );
                  }
                }
              },
              (error) => {
                if (axios.isCancel(error)) {
                  this.logFirer.fireLog('LOG', error);
                } else {
                  Modal.error({
                    title: this.props.t(
                      'App.Message.General.ServerError.Title'
                    ),
                    content: error.message,
                  });
                }
              }
            );
        } else {
          this.accountServices
            .addAuthorizedRole(account.login, this.state.selectedRole.name)
            .then(
              (response) => {
                if (response?.data) {
                  if (this.accountServices.checkError(response)) {
                    const error = this.accountServices.getError(response);

                    Modal.error({
                      title: this.props.t(
                        'App.Message.General.ServerError.Title'
                      ),
                      content: error.message,
                    });
                  } else {
                    message.success(
                      this.props.t(
                        'Modal.ChangeItemLinkStatus.Role_account.Message',
                        { action: 'added' }
                      )
                    );
                    this.getAccountList(
                      this.props.selectedNode.nodeId,
                      this.state.selectedRole.name,
                      this.state.accountFilterManager.recursion
                    );
                  }
                }
              },
              (error) => {
                if (axios.isCancel(error)) {
                  this.logFirer.fireLog('LOG', error);
                } else {
                  Modal.error({
                    title: this.props.t(
                      'App.Message.General.ServerError.Title'
                    ),
                    content: error.message,
                  });
                }
              }
            );
        }
      },
    });
  };

  render() {
    const suffix = (
      <GlobalOutlined
        onClick={() =>
          this.setState(
            {
              accountFilterManager: {
                ...this.state.accountFilterManager,
                activeFuzzy: !this.state.accountFilterManager.activeFuzzy,
              },
            },
            () =>
              this.refreshAccountList(
                this.state.accountFilterManager.page,
                this.state.accountFilterManager.searchString,
                this.state.accountFilterManager.order,
                this.state.accountFilterManager.recursion,
                this.state.accountFilterManager.activeFuzzy
              )
          )
        }
        style={{
          fontSize: 16,
          color: this.state.accountFilterManager.activeFuzzy
            ? this.props.mainColor
            : '#e6e6e6',
        }}
      />
    );

    const columns = {
      accounts: [
        {
          title: this.props.t(
            'PageContainer.Roles.Account.Table.Headers.Username'
          ),
          dataIndex: 'login',
          key: 'login',
          ellipsis: true,
          width: '25%',
          sorter: (a, b) => a.login.length - b.login.length,
        },
        {
          title: this.props.t('PageContainer.Roles.Account.Table.Headers.Name'),
          dataIndex: ['userData', 'firstname'],
          key: 'firstname',
          width: '25%',
        },
        {
          title: this.props.t(
            'PageContainer.Roles.Account.Table.Headers.Surname'
          ),
          dataIndex: ['userData', 'lastname'],
          key: 'lastname',
          width: '25%',
        },
        {
          title: this.props.t('PageContainer.Roles.Account.Table.Headers.Node'),
          dataIndex: ['nodeData', 'nodeName'],
          key: 'nodeName',
          width: '15%',
        },
        {
          key: 'action',
          width: '10%',
          render: (_, record) => {
            const isSuscribed = record.roles.find((role) => {
              return role.name === this.state.selectedRole.name;
            })
              ? true
              : false;

            return (
              <Tooltip
                title={this.props.t(
                  `PageContainer.Roles.Account.Table.Action.${
                    isSuscribed ? 'UnlinkUser' : 'LinkUsert'
                  }`
                )}
              >
                <LinkOutlined
                  onClick={() => this.changeAccountStatus(record, isSuscribed)}
                  style={{
                    color: isSuscribed ? '#ff4d4f' : 'limegreen',
                    fontSize: 18,
                    cursor: 'pointer',
                  }}
                />
              </Tooltip>
            );
          },
        },
      ],
      profiles: [
        {
          key: 'name',
          title: this.props.t(
            'PageContainer.Roles.Profiles.Table.Headers.Title'
          ),
          dataIndex: 'name',
          sorter: {
            compare: (a, b) => a.name.length - b.name.length,
          },
        },
      ],
    };

    return (
      <div className="roles">
        <Title level={3}>{this.props.t('PageContainer.Roles.Title')}</Title>
        <Layout className="roles-main">
          {this.state.isRolesLoading ? (
            <Skeleton active />
          ) : (
            <Row justify="space-between" align="middle">
              <Col>
                <div>
                  <Select
                    showSearch
                    className="roles-select"
                    style={{ width: 500 }}
                    placeholder="Select role"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    defaultActiveFirstOption
                    value={this.state.selectedRole.roleId}
                    onSelect={this.changeSelectedRole}
                  >
                    {this.state.roleList
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      ?.map((role, i) => (
                        <Option value={role.roleId} key={i}>
                          {role.name}
                        </Option>
                      ))}
                  </Select>
                </div>
                <Text>{this.state.selectedRole.description}</Text>
              </Col>
            </Row>
          )}
        </Layout>
        <Layout className="roles-submain">
          <Tabs defaultActiveKey="ACCOUNTS">
            <TabPane
              tab={this.props.t('PageContainer.Roles.Account.Title')}
              key="ACCOUNTS"
            >
              <Space direction="horizontal" size={16}>
                <Search
                  placeholder={this.props.t(
                    'PageContainer.Roles.Account.Search.Placeholder'
                  )}
                  disabled={!this.state.loadedAccountList}
                  onChange={this.handleAccountChange}
                  style={{ width: 300 }}
                  suffix={suffix}
                />
                {this.isTreeViewAllowed && (
                  <Row gutter={8}>
                    <Col>
                      {this.props.t(
                        'PageContainer.Roles.Account.Recursion.Label'
                      )}
                    </Col>
                    <Col>
                      <Switch
                        defaultChecked
                        onChange={(e) =>
                          this.refreshAccountList(
                            this.state.accountFilterManager.page,
                            this.state.accountFilterManager.searchString,
                            this.state.accountFilterManager.order,
                            e,
                            this.state.accountFilterManager.activeFuzzy
                          )
                        }
                      />
                    </Col>
                  </Row>
                )}
                <Row gutter={8}>
                  <Col>
                    {this.props.t(
                      'PageContainer.Roles.Account.AllAccounts.Label'
                    )}
                  </Col>
                  <Col>
                    <Switch
                      onChange={() =>
                        this.seeAllAccount(
                          !this.state.accountFilterManager.allAccountVisible
                        )
                      }
                    />
                  </Col>
                </Row>
              </Space>
              <Table
                className="roles-accounts-table"
                columns={columns.accounts}
                dataSource={this.state.accountList}
                loading={!this.state.loadedAccountList}
                pagination={{
                  current: this.state.accountFilterManager.page,
                  total: this.state.accountRows,
                  showSizeChanger: false,
                  pageSize: this.pageSize,
                  position: ['topLeft', 'bottomRight'],
                }}
                onChange={this.changeAccountPagination}
              />
            </TabPane>
            <TabPane
              tab={this.props.t('PageContainer.Roles.Profiles.Title')}
              key="PROFILES"
            >
              <Search
                placeholder={this.props.t(
                  'PageContainer.Roles.Profiles.Search.Placeholder'
                )}
                onChange={this.handleContextChange}
                style={{ width: 300 }}
              />
              <Table
                className="roles-profiles-table"
                columns={columns.profiles}
                dataSource={this.state.contextList}
                loading={!this.state.loadedContextList}
                pagination={{
                  current: this.state.contextFilterManager.page,
                  total: this.state.contextRows,
                  showSizeChanger: false,
                  pageSize: this.pageSize,
                  position: ['topLeft', 'bottomRight'],
                }}
                onChange={this.changeContextPagination}
                expandable={{
                  expandedRowRender: (record) => (
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '24px',
                      }}
                    >
                      {record.rightInfo.map((elem) => (
                        <span key={Math.random()}>{elem}</span>
                      ))}
                    </div>
                  ),
                  expandRowByClick: true,
                }}
              />
            </TabPane>
          </Tabs>
        </Layout>
      </div>
    );
  }
}

export default withTranslation()(Roles);
