const modalManager = (
  state = {
    isOpenInfo: false,
    isOpenAdd: false,
    isOpenModify: false,
    isOpenChangeItemLinkStatus: false,
    isOpenDelete: false,
    isOpenDisable: false,
    isOpenShiftNode: false,
    isOpenActivationEmail: false,
    isOpenError: false,
    title: '',
  },
  action
) => {
  let nextState = state;

  switch (action.type) {
    case 'OPEN_MODAL_INFO':
      nextState = {
        isOpenInfo: action.isOpen,
        isOpenAdd: false,
        isOpenModify: false,
        isOpenChangeItemLinkStatus: false,
        isOpenDelete: false,
        isOpenDisable: false,
        isOpenShiftNode: false,
        isOpenActivationEmail: false,
        isOpenError: false,
        title: action.title,
        message: action.message,
      };
      return nextState;
    case 'OPEN_MODAL_ADD':
      nextState = {
        isOpenInfo: false,
        isOpenAdd: action.isOpen,
        isOpenModify: false,
        isOpenChangeItemLinkStatus: false,
        isOpenDelete: false,
        isOpenDisable: false,
        isOpenShiftNode: false,
        isOpenActivationEmail: false,
        isOpenError: false,
        title: action.title,
        nodeId: action.nodeId,
        nodeName: action.nodeName,
        section: action.section,
        options: action.options,
        message: action.message,
      };
      return nextState;
    case 'OPEN_MODAL_MODIFY':
      nextState = {
        isOpenInfo: false,
        isOpenAdd: false,
        isOpenModify: action.isOpen,
        isOpenChangeItemLinkStatus: false,
        isOpenDelete: false,
        isOpenDisable: false,
        isOpenShiftNode: false,
        isOpenActivationEmail: false,
        isOpenError: false,
        title: action.title,
        nodeId: action.nodeId,
        nodeName: action.nodeName,
        section: action.section,
        payload: action.payload,
        message: action.message,
      };
      return nextState;
    case 'OPEN_MODAL_CHANGE_ITEM_LINK_STATUS':
      nextState = {
        isOpenInfo: false,
        isOpenAdd: false,
        isOpenModify: false,
        isOpenChangeItemLinkStatus: action.isOpen,
        isOpenDelete: false,
        isOpenDisable: false,
        isOpenShiftNode: false,
        isOpenActivationEmail: false,
        isOpenError: false,
        title: action.title,
        nodeId: action.nodeId,
        nodeName: action.nodeName,
        section: action.section,
        payload: action.payload,
        message: action.message,
      };
      return nextState;
    case 'OPEN_MODAL_DELETE':
      nextState = {
        isOpenInfo: false,
        isOpenAdd: false,
        isOpenModify: false,
        isOpenChangeItemLinkStatus: false,
        isOpenDelete: action.isOpen,
        isOpenDisable: false,
        isOpenShiftNode: false,
        isOpenActivationEmail: false,
        isOpenError: false,
        title: action.title,
        nodeId: action.nodeId,
        nodeName: action.nodeName,
        section: action.section,
        payload: action.payload,
        message: action.message,
      };
      return nextState;
    case 'OPEN_MODAL_DISABLE':
      nextState = {
        isOpenInfo: false,
        isOpenAdd: false,
        isOpenModify: false,
        isOpenChangeItemLinkStatus: false,
        isOpenDelete: false,
        isOpenDisable: action.isOpen,
        isOpenShiftNode: false,
        isOpenActivationEmail: false,
        isOpenError: false,
        title: action.title,
        nodeId: action.nodeId,
        nodeName: action.nodeName,
        section: action.section,
        payload: action.payload,
        message: action.message,
      };
      return nextState;
    case 'OPEN_MODAL_SHIFT_NODE':
      nextState = {
        isOpenInfo: false,
        isOpenAdd: false,
        isOpenModify: false,
        isOpenChangeItemLinkStatus: false,
        isOpenDelete: false,
        isOpenDisable: false,
        isOpenShiftNode: action.isOpen,
        isOpenActivationEmail: false,
        isOpenError: false,
        title: action.title,
        nodeId: action.nodeId,
        nodeName: action.nodeName,
        section: action.section,
        payload: action.payload,
        message: action.message,
      };
      return nextState;
    case 'OPEN_MODAL_ACTIVTION_EMAIL':
      nextState = {
        isOpenInfo: false,
        isOpenAdd: false,
        isOpenModify: false,
        isOpenChangeItemLinkStatus: false,
        isOpenDelete: false,
        isOpenDisable: false,
        isOpenShiftNode: false,
        isOpenActivationEmail: action.isOpen,
        isOpenError: false,
        title: action.title,
        section: action.section,
        payload: action.payload,
        message: action.message,
      };
      return nextState;
    case 'OPEN_MODAL_ERROR':
      nextState = {
        isOpenInfo: false,
        isOpenAdd: false,
        isOpenModify: false,
        isOpenChangeItemLinkStatus: false,
        isOpenDelete: false,
        isOpenDisable: false,
        isOpenShiftNode: false,
        isOpenActivationEmail: false,
        isOpenError: action.isOpen,
        title: action.title,
        message: action.message,
      };
      return nextState;
    case 'CLOSE_MODAL':
      nextState = {
        isOpenInfo: false,
        isOpenAdd: false,
        isOpenModify: false,
        isOpenChangeItemLinkStatus: false,
        isOpenDelete: false,
        isOpenDisable: false,
        isOpenShiftNode: false,
        isOpenActivationEmail: false,
        isOpenError: false,
        title: '',
      };
      return nextState;
    default:
      return nextState;
  }
};

export default modalManager;
