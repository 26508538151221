import HttpServices from './http.services';

class OAuthServices {
  envSettings;
  httpService;
  constructor(envSettings) {
    this.envSettings = envSettings;
    this.httpService = new HttpServices(this.envSettings);
  }

  /************** START Response utilities*********************************************/

  checkError = (res) => {
    if (res.data.error) {
      return true;
    } else {
      return false;
    }
  };

  getError = (res) => {
    return {
      code: res.data.error.code,
      message: res.data.error.message,
    };
  };

  getResult = (res) => {
    return res.data.result;
  };

  /************** END Response utilities*********************************************/

  /************** START API services*********************************************/

  addCredential = (
    image,
    name,
    app_name,
    description,
    app_logo,
    allowed_URIs,
    profile,
    nodeId,
    showAuthorizationPage,
    extraOptions
  ) => {
    const param = {
      image,
      name,
      app_name,
      description,
      app_logo,
      allowed_URIs,
      profile,
      ...(nodeId && {
        node_id: nodeId,
      }),
      show_authorization_page: showAuthorizationPage,
      ...(extraOptions && {
        extra_options: extraOptions,
      }),
    };

    return this.httpService.httpServicesPost(
      'addCredential',
      this.envSettings,
      param
    );
  };

  removeCredential = (clientId, nodeId) => {
    const param = {
      client_id: clientId,
      node_id: nodeId,
    };
    return this.httpService.httpServicesPost(
      'removeCredential',
      this.envSettings,
      param
    );
  };

  listCredentials = () => {
    return this.httpService.httpServicesPost(
      'listCredentials',
      this.envSettings,
      {}
    );
  };

  updateCredential = (
    image,
    name,
    app_name,
    description,
    app_logo,
    allowed_URIs,
    profile,
    client_id,
    nodeId,
    showAuthorizationPage,
    extraOptions
  ) => {
    const param = {
      image,
      name,
      app_name,
      description,
      app_logo,
      allowed_URIs,
      profile,
      client_id,
      node_id: nodeId,
      show_authorization_page: showAuthorizationPage,
      extra_options: extraOptions,
    };

    return this.httpService.httpServicesPost(
      'updateCredential',
      this.envSettings,
      param
    );
  };

  /************** END API services*********************************************/
}

export default OAuthServices;
