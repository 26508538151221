import ValidDriver from './ValidDriver';
import { stopReloadPage } from '../../../actions/reload-page-manager';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  globalSettings: state.globalSettingsManager.globalSettings,
  selectedNode: state.selectedNodeManager.node,
  reloadPage: state.reloadPageManager.reloadPage,
  roles: state.userManager.user.roles,
  mainColor: state.userManager.mainColor,
});

const mapDispatchToProps = (dispatch) => ({
  stopReloadPage: () => dispatch(stopReloadPage()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ValidDriver)
);
