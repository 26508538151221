import { connect } from 'react-redux';
import Dashboard from './Dashboard';

const mapStateToProps = (state) => ({
  globalSettings: state.globalSettingsManager.globalSettings,
  selectedNode: state.selectedNodeManager.node,
  roles: state.userManager.user.roles,
  mainColor: state.userManager.mainColor,
});

export default connect(mapStateToProps, null)(Dashboard);
