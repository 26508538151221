import { Card, Layout, Typography, Row, Col, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import './styles.less';
import { withTranslation } from 'react-i18next';
import AccountChart from './components/AccountChart';
import ValidSignChart from './components/ValidSignChart';
import ValidDriverChart from './components/ValidDriverChart';
import RaoChart from './components/RaoChart';
import NodeCard from './components/NodeCard';
import _ from 'lodash';
import ValidSignFoldersCounter from '../ValidSign/Overview/components/ValidSignFoldersCounter';
import NodeServices from '../../services/node.services';
import Modal from 'antd/lib/modal/Modal';
import ServiceServices from '../../services/service.services';
import { useEffect, useState } from 'react';
import axios from 'axios';
import ValidSignServices from '../../services/validsign.services';

const { Title } = Typography;

function Dashboard({ globalSettings, selectedNode, roles, t, mainColor }) {
  const [maxFolders, setMaxFolders] = useState(0);
  const [foldersStarted, setFoldersStarted] = useState(0);
  const [overviewIsLoaded, setOverviewIsLoaded] = useState(false);
  const percentage = foldersStarted / maxFolders;

  let env;
  if (_.isString(process.env.REACT_APP_ENV)) {
    env = process.env.REACT_APP_ENV;
  } else {
    env = globalSettings.DefaultEnvironment;
  }
  const serviceServices = new ServiceServices(globalSettings.Environment[env]);

  const checkPermission = (roleName) =>
    roles.filter((r) => {
      return !_.isUndefined(r.attributes[roleName]);
    }).length > 0;

  useEffect(() => {
    let env;

    if (!selectedNode.nodeId) {
      return;
    }

    if (_.isString(process.env.REACT_APP_ENV)) {
      env = process.env.REACT_APP_ENV;
    } else {
      env = globalSettings.DefaultEnvironment;
    }

    setMaxFolders(0);
    setFoldersStarted(0);
    setOverviewIsLoaded(false);

    getNode(env);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode]);

  const getNode = (env) => {
    const nodeServices = new NodeServices(globalSettings.Environment[env]);

    nodeServices.getNode(selectedNode?.nodeId).then(
      (response) => {
        if (response?.data) {
          if (nodeServices.checkError(response)) {
            const error = nodeServices.getError(response);

            Modal.error({
              title: t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          } else {
            const result = nodeServices.getResult(response);

            if (result) {
              const serviceServices = new ServiceServices(
                globalSettings.Environment[env]
              );

              serviceServices.getNodeServices(selectedNode?.nodeId).then(
                (response) => {
                  if (response?.data) {
                    if (serviceServices.checkError(response)) {
                      const error = serviceServices.getError(response);

                      Modal.error({
                        title: t('App.Message.General.ServerError.Title'),
                        content: error.message,
                      });
                    } else {
                      const result = serviceServices.getResult(response);

                      if (result) {
                        if (result.services.length > 0) {
                          const validSignServiceID = result.services.find(
                            (serv) => serv.name === 'ValidSign'
                          );

                          if (validSignServiceID) {
                            getServiceAttributes(validSignServiceID.sid);
                          } else {
                            setOverviewIsLoaded(true);
                          }
                        } else {
                          setOverviewIsLoaded(true);
                        }
                      } else {
                        setOverviewIsLoaded(true);
                      }
                    }
                  }
                },
                (error) => {
                  setOverviewIsLoaded(true);
                  if (axios.isCancel(error)) {
                  } else {
                    Modal.error({
                      title: t('App.Message.General.ServerError.Title'),
                      content: error.message,
                    });
                  }
                }
              );
            }
          }
        } else {
          setOverviewIsLoaded(true);
        }
      },
      (error) => {
        setOverviewIsLoaded(true);

        if (axios.isCancel(error)) {
          //this.logFirer.fireLog('LOG', error);
        } else {
          Modal.error({
            title: t('App.Message.General.ServerError.Title'),
            content: error.message,
          });
        }
      }
    );
  };

  const getServiceAttributes = (validSignServiceID) => {
    serviceServices
      .getServiceAttributes(selectedNode?.nodeId, validSignServiceID, true)
      .then(
        (response) => {
          if (response?.data) {
            if (serviceServices.checkError(response)) {
              const error = serviceServices.getError(response);

              Modal.error({
                title: t('App.Message.General.ServerError.Title'),
                content: error.message,
              });

              setOverviewIsLoaded(true);
            } else {
              const result = serviceServices.getResult(response);
              const attributes = result.attributes;

              if (result?.countRows) {
                const maxFolders = attributes.find(
                  (attr) => attr.attributeKey === 'MAX_FOLDER_AVAILABLE'
                )?.attributeValue;
                const baseUrl = attributes.find(
                  (attr) => attr.attributeKey === 'BASE_URL'
                )?.attributeValue;
                const jwtToken = attributes.find(
                  (attr) => attr.attributeKey === 'JWT_TOKEN'
                )?.attributeValue;

                if (baseUrl && jwtToken) {
                  const validSignServices = new ValidSignServices();

                  validSignServices
                    .getFoldersStarted(
                      `${baseUrl}/be/counter?type=folder&node_context=${selectedNode?.nodeName}`,
                      {
                        headers: {
                          authorization: `Bearer ${jwtToken}`,
                        },
                      }
                    )
                    .then((response) => {
                      if (response?.data) {
                        const counter = response.data[0];

                        setFoldersStarted(counter.counter);
                      }

                      setOverviewIsLoaded(true);
                    })
                    .catch((err) => {
                      setOverviewIsLoaded(true);
                    });
                } else {
                  setOverviewIsLoaded(true);
                }

                if (maxFolders) {
                  setMaxFolders(parseInt(maxFolders, 10));
                }
              } else {
                setOverviewIsLoaded(true);
              }
            }
          }
        },
        (error) => {
          setOverviewIsLoaded(true);

          if (axios.isCancel(error)) {
            //this.logFirer.fireLog('LOG', error);
          } else {
            Modal.error({
              title: t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };

  return (
    <div className="dashboard">
      <Title level={3}>{t('PageContainer.Dashboard.Title')}</Title>
      <Layout>
        <Row gutter={[16, 16]}>
          <Col span={8} xs={24} sm={24} md={12} xl={8}>
            {checkPermission('node_manager') && (
              <Col style={{ paddingTop: 0 }}>
                <Card
                  className="rootNode-card"
                  title={t('Widget.NodeInfo.Title')}
                  extra={
                    <Tooltip title={t('Widget.NodeInfo.Help')}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  }
                >
                  <NodeCard
                    globalSettings={globalSettings}
                    selectedNode={selectedNode}
                    mainColor={mainColor}
                  />
                </Card>
              </Col>
            )}
            {checkPermission('validsign_manager') && (
              <Col>
                <Card
                  className="rootNode-card"
                  title={t('Widget.FoldersCounterInfo.Title')}
                  extra={
                    <Tooltip title={t('Widget.FoldersCounterInfo.Help')}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  }
                >
                  <ValidSignFoldersCounter
                    percentage={percentage}
                    overviewIsLoaded={overviewIsLoaded}
                    foldersStarted={foldersStarted}
                    maxFolders={maxFolders}
                  />
                </Card>
              </Col>
            )}
          </Col>
          {checkPermission('account_manager') && (
            <Col span={8} xs={24} sm={24} md={12} xl={8}>
              <Card
                title={t('Widget.AccountInfo.Title')}
                extra={
                  <Tooltip title={t('Widget.AccountInfo.Help')}>
                    <InfoCircleOutlined />
                  </Tooltip>
                }
              >
                <AccountChart
                  selectedNode={selectedNode}
                  globalSettings={globalSettings}
                  mainColor={mainColor}
                />
              </Card>
            </Col>
          )}
          {checkPermission('validsign_manager') && (
            <Col span={8} xs={24} sm={24} md={12} xl={8}>
              <Card
                title={t('Widget.ValidsignInfo.Title')}
                extra={
                  <Tooltip title={t('Widget.ValidsignInfo.Help')}>
                    <InfoCircleOutlined />
                  </Tooltip>
                }
              >
                <ValidSignChart
                  selectedNode={selectedNode}
                  globalSettings={globalSettings}
                  mainColor={mainColor}
                />
              </Card>
            </Col>
          )}
          {checkPermission('rao_manager') && (
            <Col span={8} xs={24} sm={24} md={12} xl={12}>
              <Card
                title={t('Widget.RAOInfo.Title')}
                extra={
                  <Tooltip title={t('Widget.RAOInfo.Help')}>
                    <InfoCircleOutlined />
                  </Tooltip>
                }
              >
                <RaoChart
                  selectedNode={selectedNode}
                  globalSettings={globalSettings}
                  mainColor={mainColor}
                />
              </Card>
            </Col>
          )}
          {checkPermission('validdriver_manager') && (
            <Col span={8} xs={24} sm={24} md={24} xl={12}>
              <Card
                title={t('Widget.ValidDriverInfo.Title')}
                extra={
                  <Tooltip title={t('Widget.ValidDriverInfo.Title')}>
                    <InfoCircleOutlined />
                  </Tooltip>
                }
              >
                <ValidDriverChart
                  selectedNode={selectedNode}
                  globalSettings={globalSettings}
                  mainColor={mainColor}
                />
              </Card>
            </Col>
          )}
        </Row>
      </Layout>
    </div>
  );
}

export default withTranslation()(Dashboard);
