import { connect } from 'react-redux';
import { setUser } from '../../actions/user-manager';
import Settings from './Settings';

const mapStateToProps = (state) => ({
  globalSettings: state.globalSettingsManager.globalSettings,
  user: state.userManager.user,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (newUserInfo, newRoleList, newAccountInfo) =>
    dispatch(setUser(newUserInfo, newRoleList, newAccountInfo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
