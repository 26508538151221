import axios from 'axios';
import _ from 'lodash';

class HttpServices {
  constructor(envSettings) {
    this.envSettings = _.cloneDeep(envSettings);
    this.source = axios.CancelToken.source();
    this.axiosPostOption = {
      withCredentials: true,
      cancelToken: this.source.token,
    };
  }

  setRequest = (method, envSettings, payload) => {
    const setRequest = {
      method: method,
      id: Math.floor(Date.now() / 1000),
      jsonrpc: envSettings.httpRequest.jsonrpcVersion,
      params: payload,
    };

    return setRequest;
  };

  getUrl = (method, envSettings) =>
    `${envSettings.httpRequest.BaseUrl}${envSettings.httpRequest.Endpoint[method]}`;

  httpServicesPost = (method, envSettings, payload) => {
    return axios.post(
      this.getUrl(method, envSettings),
      this.setRequest(method, envSettings, payload),
      this.axiosPostOption
    );
  };

  httpServicesGet = (method, envSettings) => {
    return axios.get(this.getUrl(method, envSettings), this.axiosPostOption);
  };

  httpServiceCustomGet = (url, requestConfig) => {
    return axios.get(url, requestConfig);
  };
}

export default HttpServices;
