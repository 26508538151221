const breadcrumbStateManager = (
  state = { breadcrumbVisible: false },
  action
) => {
  let nextState = state;

  switch (action.type) {
    case 'CHANGE_BREADCRUMB_STATE':
      nextState = {
        breadcrumbVisible: action.newState,
      };
      return nextState;

    default:
      return nextState;
  }
};

export default breadcrumbStateManager;
