import {
  Avatar,
  Button,
  Col,
  Layout,
  Row,
  Table,
  Tooltip,
  Typography,
  Modal,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Component } from 'react';
import './styles.less';
import AppUtil from '../../../utility/admin.util';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import OAuthServices from '../../../services/oauth.services';
import NodeServices from '../../../services/node.services';
import AddProjectModal from '../../../components/Modals/AddProjectModal';
import EditProjectDrawer from '../../../components/Drawers/EditProjectDrawer';
import { formatData, isNilOrEmpty } from '../../../utility/utils';
import ServiceServices from '../../../services/service.services';
import LogoNotFound from '../../../assets/logo-not-found.png';
import axios from 'axios';

const { Title } = Typography;

class OAuthConsole extends Component {
  assignableRole;
  offline;
  env;
  timer;
  stopStatePropagation;
  accountServices;
  serviceServices;
  nodeServices;
  logFirer;
  isTreeViewAllowed;

  constructor(props) {
    super(props);

    let filteredRoles;
    let rightInfoList;
    let permissionList;
    let oauthRightInfo;

    filteredRoles = this.props.roles.filter((r) => {
      return !_.isUndefined(r.attributes['oauth']);
    });

    permissionList =
      this.props.globalSettings.PagePermissionManager['oauth'] || {};
    rightInfoList = filteredRoles[0]
      ? filteredRoles[0].attributes['oauth'].rightInfo || []
      : [];

    this.assignableRole = filteredRoles[0]
      ? filteredRoles[0].attributes['oauth'].assignableRole || []
      : [];

    if (_.isEmpty(permissionList)) {
      this.props.history.push('/app/fallback');
      oauthRightInfo = {
        addProject: false,
        deleteProject: false,
        updateProject: false,
        uploadExtraOptions: false,
        setAuthPage: false,
        setCredentialsFlow: false,
      };
    } else {
      oauthRightInfo = {
        addProject: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'basic'
        ),
        deleteProject: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'basic'
        ),
        updateProject: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'basic'
        ),
        uploadExtraOptions: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'extra'
        ),
        setAuthPage: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'authPage'
        ),
        setCredentialsFlow: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'admin'
        ),
      };
    }

    this.state = {
      projectsList: [],
      projectsRows: 0,
      loadedList: false,
      page: 1,
      currentProject: {},
      ctxAllowed: null,
      oauthRightInfo: oauthRightInfo,
      isEditProjectDrawerVisible: false,
      isAddProjectModalVisible: false,
      isDrawerRendered: false,
      hasNodeChildren: false,
    };

    if (_.isString(process.env.REACT_APP_ENV)) {
      this.env = process.env.REACT_APP_ENV;
    } else {
      this.env = this.props.globalSettings.DefaultEnvironment;
    }

    this.oAuthServices = new OAuthServices(
      this.props.globalSettings.Environment[this.env]
    );
    this.nodeServices = new NodeServices(
      this.props.globalSettings.Environment[this.env]
    );
    this.serviceServices = new ServiceServices(
      this.props.globalSettings.Environment[this.env]
    );
    this.pageSize = this.props.globalSettings.Environment[
      this.env
    ].envConstants.pageSize;
  }

  getProjects = () => {
    this.oAuthServices
      .listCredentials()
      .then(
        (response) => {
          if (response?.data) {
            if (this.oAuthServices.checkError(response)) {
              const error = this.oAuthServices.getError(response);

              Modal.error({
                title: this.props.t('App.Message.General.ServerError.Title'),
                content: error.message || '',
              });
            } else {
              this.setState({
                projectsList: response.data?.result,
                projectsRows: response.data?.result?.length || 0,
              });
            }
          }
        },
        (error) => {
          Modal.error({
            title: this.props.t('App.Message.General.ServerError.Title'),
            content: error.message,
          });
        }
      )
      .finally(() => {
        this.setState({ loadedList: true });
      });
  };

  onDrawerClose = () => {
    this.setState(
      {
        isEditProjectDrawerVisible: false,
      },
      () => setTimeout(() => this.setState({ isDrawerRendered: false }), 200)
    );
  };

  componentDidMount() {
    this.getNodeChildren();
    this.getNodeService();
    this.getProjects();
  }

  getNodeChildren = () => {
    this.nodeServices.getNodeChildren(this.props.rootNode.nodeId).then(
      (response) => {
        if (response?.data) {
          if (this.nodeServices.checkError(response)) {
            const error = this.nodeServices.getError(response);

            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          } else {
            const result = this.nodeServices.getResult(response);

            this.setState({ hasNodeChildren: result.countRows > 0 });
          }
        }
      },
      (error) => {
        if (axios.isCancel(error)) {
        } else {
          Modal.error({
            title: this.props.t('App.Message.General.ServerError.Title'),
            content: error.message,
          });
        }
      }
    );
  };

  getNodeService = () => {
    this.serviceServices
      .getNodeServices(this.props.rootNode.nodeId)
      .then((response) => {
        if (response?.data && !this.serviceServices.checkError(response)) {
          const result = this.serviceServices.getResult(response);

          if (result?.services?.length > 0) {
            const time4UserService = result.services.find(
              (serv) => serv.name === 'Time4User'
            );

            if (time4UserService) {
              this.getServiceAttributes(time4UserService.sid);
            }
          }
        }
      });
  };

  getServiceAttributes = (time4UserServiceID) => {
    this.serviceServices
      .getServiceAttributes(
        this.props.rootNode.nodeId,
        time4UserServiceID,
        true
      )
      .then((response) => {
        if (response?.data && !this.serviceServices.checkError(response)) {
          const result = this.serviceServices.getResult(response);

          if (result?.countRows > 0) {
            const oauthAttribute = result.attributes.find(
              (el) => el.attributeKey === 'OAUTH2_CLIENT_CRED_CTX_ALLOWED'
            );

            if (oauthAttribute) {
              let contextsAllowed = [];
              const oauthAttributeValue = JSON.parse(
                oauthAttribute.attributeValue
              );

              oauthAttributeValue.forEach((el, i) => {
                contextsAllowed.push({
                  name: el,
                  key: i,
                });
              });
              this.setState({
                ctxAllowed: contextsAllowed,
              });
            }
          }
        }
      });
  };

  render = () => {
    const columns = [
      {
        dataIndex: 'app_logo',
        key: 'app_logo',
        render: (value, record) => {
          let img = value;

          if (!isNilOrEmpty(record.image)) {
            img = record.image;
          }

          if (isNilOrEmpty(value) && isNilOrEmpty(record.image)) {
            img = LogoNotFound;
          }

          return <Avatar shape="square" src={img} size={48} />;
        },
        width: '10%',
      },
      {
        title: this.props.t('PageContainer.OAuthConsole.Table.Headers.Project'),
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: this.props.t(
          'PageContainer.OAuthConsole.Table.Headers.DisplayedName'
        ),
        dataIndex: 'app_name',
        key: 'app_name',
      },
      {
        title: this.props.t(
          'PageContainer.OAuthConsole.Table.Headers.Description'
        ),
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: this.props.t('PageContainer.OAuthConsole.Table.Headers.Access'),
        dataIndex: 'profile',
        key: 'profile',
        ellipsis: true,
        render: (value) =>
          value ? 'Client Credentials' : 'Authorization Code',
      },
      {
        title: this.props.t(
          'PageContainer.OAuthConsole.Table.Headers.LastAccess'
        ),
        dataIndex: 'modify_date',
        key: 'modify_date',
        render: formatData,
      },
    ];

    return (
      <div className="OAuthConsole">
        <Title level={3}>
          {this.props.t('PageContainer.OAuthConsole.Title')}
        </Title>
        <Layout className="OAuthConsole-main">
          <Row justify="space-between" align="middle">
            <Col>
              <strong>{this.state.projectsRows} projects found</strong>
            </Col>
            {this.state.oauthRightInfo.addProject && (
              <Col>
                <Tooltip
                  title={this.props.t(
                    'PageContainer.OAuthConsole.Buttons.NewProject.Tooltip'
                  )}
                >
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<PlusOutlined />}
                    onClick={() =>
                      this.setState({ isAddProjectModalVisible: true })
                    }
                  />
                </Tooltip>
              </Col>
            )}
          </Row>
          <Table
            className="OAuthConsole-table"
            columns={columns}
            loading={!this.state.loadedList}
            dataSource={this.state.projectsList}
            pagination={{
              // current: this.state.page,
              // total: this.state.projectsRows,
              showSizeChanger: false,
              pageSize: this.pageSize,
              position: ['topLeft', 'bottomRight'],
            }}
            onRow={(row) => {
              return {
                onClick: (e) => {
                  this.setState(
                    {
                      currentProject: row,
                    },
                    () =>
                      this.setState({ isDrawerRendered: true }, () =>
                        this.setState({ isEditProjectDrawerVisible: true })
                      )
                  );
                },
              };
            }}
          />
        </Layout>
        <AddProjectModal
          title={this.props.t('Modal.Add.OAuth.Title')}
          handleCancel={() =>
            this.setState({ isAddProjectModalVisible: false })
          }
          hasNodeChildren={this.state.hasNodeChildren}
          isModalVisible={this.state.isAddProjectModalVisible}
          oAuthServices={this.oAuthServices}
          nodeServices={this.nodeServices}
          onConfirm={this.getProjects}
          rootNode={this.props.rootNode}
          oauthRightInfo={this.state.oauthRightInfo}
          ctxAllowed={this.state.ctxAllowed}
        />
        {this.state.isDrawerRendered && (
          <EditProjectDrawer
            oAuthServices={this.oAuthServices}
            hasNodeChildren={this.state.hasNodeChildren}
            onUpdate={this.getProjects}
            onDelete={this.getProjects}
            nodeServices={this.nodeServices}
            rootNode={this.props.rootNode}
            isVisible={this.state.isEditProjectDrawerVisible}
            handleCancel={this.onDrawerClose}
            data={this.state.currentProject}
            oauthRightInfo={this.state.oauthRightInfo}
            ctxAllowed={this.state.ctxAllowed}
          />
        )}
      </div>
    );
  };
}

export default withTranslation()(OAuthConsole);
