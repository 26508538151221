import HttpServices from './http.services';

class ServiceServices {
  envSettings;
  httpService;

  constructor(envSettings) {
    this.envSettings = envSettings;
    this.httpService = new HttpServices(this.envSettings);
  }
  /************** START Response utilities*********************************************/

  checkError = (res) => {
    if (res.data.error) {
      return true;
    } else {
      return false;
    }
  };

  getError = (res) => {
    return {
      code: res.data.error.code,
      message: res.data.error.message,
    };
  };

  getResult = (res) => {
    return res.data.result;
  };

  /************** END Response utilities*********************************************/

  /************** START API services*********************************************/

  getNodeServices = (
    nodeId,
    page = 1,
    name = '',
    size = this.envSettings.envConstants.pageServiceSize
  ) => {
    const params = {
      nodeId: nodeId,
      page: page,
      size: size,
      name: name,
    };
    return this.httpService.httpServicesPost(
      'getNodeServices',
      this.envSettings,
      params
    );
  };

  createService = (nodeId, serviceName, description) => {
    const params = {
      nodeId: nodeId,
      serviceName: serviceName,
      description: description,
    };
    return this.httpService.httpServicesPost(
      'createService',
      this.envSettings,
      params
    );
  };

  deleteService = (
    nodeId,
    serviceId,
    serviceName,
    deleteAttributes = false
  ) => {
    const params = {
      nodeId: nodeId,
      id: serviceId,
      name: serviceName,
      deleteAttributes: deleteAttributes,
    };
    return this.httpService.httpServicesPost(
      'deleteService',
      this.envSettings,
      params
    );
  };

  updateService = (serviceId, description) => {
    const params = {
      id: serviceId,
      description: description,
    };
    return this.httpService.httpServicesPost(
      'updateService',
      this.envSettings,
      params
    );
  };

  getServiceAttributes = (
    nodeId,
    serviceId,
    hereditariness = true,
    name = ''
  ) => {
    const params = {
      nodeId: nodeId,
      serviceId: serviceId,
      hereditariness: hereditariness,
      name: name,
    };
    return this.httpService.httpServicesPost(
      'getServiceAttributes',
      this.envSettings,
      params
    );
  };

  createServiceAttribute = (
    nodeId,
    serviceId,
    attributeName,
    attributeValue
  ) => {
    const params = {
      nodeId: nodeId,
      serviceId: serviceId,
      attributeName: attributeName?.trim(),
      attributeValue: attributeValue?.toString()?.trim(),
    };
    return this.httpService.httpServicesPost(
      'createServiceAttribute',
      this.envSettings,
      params
    );
  };

  updateServiceAttribute = (attributeId, attributeName, attributeValue) => {
    const params = {
      attributeId: attributeId,
      attributeName: attributeName?.trim(),
      attributeValue: attributeValue?.toString()?.trim(),
    };
    return this.httpService.httpServicesPost(
      'updateServiceAttribute',
      this.envSettings,
      params
    );
  };

  deleteServiceAttribute = (nodeId, attributeId) => {
    const params = {
      nodeId: nodeId,
      attributeId: attributeId,
    };
    return this.httpService.httpServicesPost(
      'deleteServiceAttribute',
      this.envSettings,
      params
    );
  };

  createAccountIDNodeLink = (nodeName, accountId) => {
    const params = {
      nodeName,
      accountId,
    };
    return this.httpService.httpServicesPost(
      'createAccountIDNodeLink',
      this.envSettings,
      params
    );
  };

  getAccountIDNodeLink = (nodeId) => {
    const params = {
      nid: nodeId,
    };
    return this.httpService.httpServicesPost(
      'getAccountIDNodeLink',
      this.envSettings,
      params
    );
  };

  /************** END API services*********************************************/
}

export default ServiceServices;
