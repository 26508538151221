import {
  Button,
  Col,
  Input,
  Layout,
  message,
  Modal,
  notification,
  Row,
  Skeleton,
  Space,
  Spin,
  Statistic,
  Switch,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import {
  SubnodeOutlined,
  PlusOutlined,
  GlobalOutlined,
} from '@ant-design/icons';
import './styles.less';
import axios from 'axios';
import _ from 'lodash';
import { Component } from 'react';
import AppUtil from '../../../utility/admin.util';
import NodeServices from '../../../services/node.services';
import LogsFirer from '../../../services/logs-firer.services';
import AddNodeModal from '../../../components/Modals/AddNodeModal';
import { withTranslation } from 'react-i18next';
import AddNodeAttributeModal from '../../../components/Modals/AddNodeAttributeModal';
import EditNodeAttributeDrawer from '../../../components/Drawers/EditNodeAttributeDrawer';
import { isNilOrEmpty } from '../../../utility/utils';

const { Title, Text } = Typography;
const { Search } = Input;

class Nodes extends Component {
  offline;
  env;
  stopStatePropagation;
  nodeServices;
  logFirer;
  isTreeViewAllowed;
  retryableInvervals;

  constructor(props) {
    super(props);
    let filteredRoles;
    let rightInfoList;
    let permissionList;
    let userNodeRightInfo;

    this.isTreeViewAllowed = this.props.roles.some((r) => {
      return Object.entries(r.attributes).some(([_, value]) => {
        return value.rightInfo && value.rightInfo.indexOf('treeView') !== -1
          ? true
          : false;
      });
    });

    filteredRoles = this.props.roles.filter((r) => {
      return !_.isUndefined(r.attributes['node_manager']);
    });

    permissionList =
      this.props.globalSettings.PagePermissionManager['node_manager'] || {};
    rightInfoList = filteredRoles[0]
      ? filteredRoles[0].attributes['node_manager'].rightInfo || []
      : [];

    if (_.isEmpty(permissionList)) {
      this.props.history.push('/app/fallback');
      userNodeRightInfo = {
        createNode: false,
        updateNode: false,
        deleteNode: false,
        addNodeAttribute: false,
        deleteNodeAttribute: false,
        updateNodeAttribute: false,
      };
    } else {
      userNodeRightInfo = {
        createNode: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'createNode'
        ),
        updateNode: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'updateNode'
        ),
        deleteNode: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'deleteNode'
        ),
        addNodeAttribute: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'addNodeAttribute'
        ),
        deleteNodeAttribute: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'deleteNodeAttribute'
        ),
        updateNodeAttribute: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'updateNodeAttribute'
        ),
      };
    }

    this.state = {
      selectedNode: this.props.selectedNode,
      workingNode: {},
      workingAttribute: {},
      isWorkingNodeLoaded: false,
      isParentNodeLoaded: false,
      untouchableAttributeList: [],
      attributeList: [],
      description: '',
      isNodeOpen: true,
      isParentOpen: false,
      attributesRows: 1,
      loadedList: false,
      searchString: '',
      recursion: this.isTreeViewAllowed,
      activeFuzzy: false,
      userNodeRightInfo: userNodeRightInfo,
      isUpdateNodeLoading: false,
      isAddNodeModalVisible: false,
      isAddNodeAttributeModalVisible: false,
      isEditAttributeDrawerVisible: false,
      isDrawerRendered: false,
    };

    if (
      _.isString(process.env.REACT_APP_OFFLINE) &&
      _.isBoolean(JSON.parse(process.env.REACT_APP_OFFLINE))
    ) {
      this.offline = JSON.parse(process.env.REACT_APP_OFFLINE);
    } else {
      this.offline = false;
    }

    if (_.isString(process.env.REACT_APP_ENV)) {
      this.env = process.env.REACT_APP_ENV;
    } else {
      this.env = this.props.globalSettings.DefaultEnvironment;
    }

    this.nodeServices = new NodeServices(
      this.props.globalSettings.Environment[this.env]
    );
    this.logFirer = new LogsFirer(
      this.props.globalSettings.Environment[this.env]
    );
    this.stopStatePropagation = true;

    this.retryableInvervals = this.props.globalSettings.Environment[this.env]
      .envConstants?.retryableIntervals || [8000, 16000, 32000, 64000];
  }

  handleChange = (event) => {
    this.refreshList(
      event.target.value,
      this.state.recursion,
      this.state.activeFuzzy
    );
  };

  refreshList = (searchStr, recursion, activeFuzzy = false) => {
    if (this.props.selectedNode.nodeId) {
      this.stopStatePropagation &&
        this.setState({
          selectedNode: this.props.selectedNode,
          loadedList: false,
          searchString: searchStr,
          recursion: this.isTreeViewAllowed ? recursion : false,
        });

      if (recursion === this.state.recursion) {
        let freshList;

        if (searchStr.length === 0) {
          freshList = _.cloneDeep(this.state.untouchableAttributeList);
        } else {
          let list = this.state.untouchableAttributeList.filter((attribute) => {
            let regexp = new RegExp(
              activeFuzzy
                ? '[' + searchStr.toLowerCase() + ']'
                : searchStr.toLowerCase(),
              'g'
            );
            return regexp.test(attribute.attributeKey.toLowerCase());
          });

          freshList = _.cloneDeep(list);
        }

        this.stopStatePropagation &&
          this.setState({
            attributesRows: freshList.length > 0 ? freshList.length : 1,
            loadedList: true,
            attributeList: freshList,
          });
      } else {
        this.getNodeAttributes(this.props.selectedNode.nodeId, recursion);
      }
    }
  };

  getNodeAttributes = (nodeId, hereditariness = true) => {
    this.nodeServices
      .getNodeAttributes(
        nodeId,
        this.isTreeViewAllowed ? hereditariness : false
      )
      .then(
        (response) => {
          if (response && response.data) {
            if (this.nodeServices.checkError(response)) {
              const error = this.nodeServices.getError(response);

              Modal.error({
                title: this.props.t('App.Message.General.ServerError.Title'),
                content: error.message,
              });

              this.stopStatePropagation &&
                this.setState({
                  workingNode: undefined,
                  attributeList: [],
                  loadedList: true,
                });
            } else {
              const result = this.nodeServices.getResult(response);
              if (result) {
                this.stopStatePropagation &&
                  this.setState({
                    untouchableAttributeList: result.attributes || [],
                    attributeList: result.attributes || [],
                    attributesRows: result.countRows,
                    loadedList: true,
                  });
              }
            }
          } else {
          }
        },
        (error) => {
          if (axios.isCancel(error)) {
            this.logFirer.fireLog('LOG', error);
          } else {
            this.stopStatePropagation &&
              this.setState({
                workingNode: undefined,
                attributeList: [],
                loadedList: true,
              });

            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };

  getNodeIntervalIndex = -1;
  getNode = (nodeId, isParent = false) => {
    this.nodeServices.getNode(nodeId).then(
      (response) => {
        if (response?.data) {
          if (this.nodeServices.checkError(response)) {
            const error = this.nodeServices.getError(response);

            if (error.code === 17155) {
              this.getNodeIntervalIndex = this.getNodeIntervalIndex + 1;

              if (!this.retryableInvervals[this.getNodeIntervalIndex]) {
                Modal.error({
                  title: this.props.t('App.Message.General.ServerError.Title'),
                  content: error.message,
                });
              } else {
                const indexDesc =
                  this.getNodeIntervalIndex <= 2
                    ? this.getNodeIntervalIndex
                    : 2;

                notification.info({
                  message: this.props.t(
                    'Notification.Info.RetryableOperation.Message'
                  ),
                  description: this.props.t(
                    `Notification.Info.RetryableOperation.Description${indexDesc}`
                  ),
                });

                setTimeout(
                  () => this.getNode(nodeId),
                  this.retryableInvervals[this.getNodeIntervalIndex]
                );
              }
            } else {
              Modal.error({
                title: this.props.t('App.Message.General.ServerError.Title'),
                content: error.message,
              });
            }
          } else {
            const result = this.nodeServices.getResult(response);
            if (result) {
              let resNode = {
                access: result.access,
                name: result.name,
                description: result?.description,
                nodeId: result.nodeId,
                parentId: result.parentId,
                children: result.children,
              };

              if (isParent) {
                this.stopStatePropagation &&
                  this.setState({
                    parentNode: resNode,
                    isParentNodeLoaded: true,
                  });
              } else {
                if (
                  this.props.rootNode.nodeId !== resNode.nodeId &&
                  resNode.parentId !== resNode.nodeId
                ) {
                  this.getNode(resNode.parentId, true);
                } else {
                  this.stopStatePropagation &&
                    this.setState({
                      parentNode: undefined,
                      isParentNodeLoaded: true,
                    });
                }
                this.getNodeAttributes(resNode.nodeId, this.state.recursion);
                this.stopStatePropagation &&
                  this.setState({
                    workingNode: resNode,
                    isWorkingNodeLoaded: true,
                  });
              }
            }
          }
        } else {
          this.stopStatePropagation &&
            this.setState({
              workingNode: undefined,
              isWorkingNodeLoaded: true,
              isParentNodeLoaded: true,
            });
        }
      },
      (error) => {
        if (axios.isCancel(error)) {
          this.logFirer.fireLog('LOG', error);
        } else {
          this.stopStatePropagation &&
            this.setState({
              workingNode: undefined,
              isWorkingNodeLoaded: true,
              isParentNodeLoaded: true,
            });

          Modal.error({
            title: this.props.t('App.Message.General.ServerError.Title'),
            content: error.message,
          });
        }
      }
    );
  };

  getChildrenIndex = -1;
  getNodeChildren = (nodeId, nodeName) => {
    this.nodeServices.getNodeChildren(nodeId).then(
      (response) => {
        if (response?.data) {
          if (this.nodeServices.checkError(response)) {
            const error = this.nodeServices.getError(response);

            if (error.code === 17155) {
              this.getChildrenIndex = this.getChildrenIndex + 1;

              if (!this.retryableInvervals[this.getChildrenIndex]) {
                Modal.error({
                  title: this.props.t('App.Message.General.ServerError.Title'),
                  content: error.message,
                });
              } else {
                const indexDesc =
                  this.getChildrenIndex <= 2 ? this.getChildrenIndex : 2;

                notification.info({
                  message: this.props.t(
                    'Notification.Info.RetryableOperation.Message'
                  ),
                  description: this.props.t(
                    `Notification.Info.RetryableOperation.Description${indexDesc}`
                  ),
                });

                setTimeout(
                  () => this.getNodeChildren(nodeId, nodeName),
                  this.retryableInvervals[this.getChildrenIndex]
                );
              }
            } else {
              Modal.error({
                title: this.props.t('App.Message.General.ServerError.Title'),
                content: error.message,
              });
            }
          } else {
            const result = this.nodeServices.getResult(response);
            let newTree = [];

            result.childs.forEach((child) =>
              newTree.push({
                id: child.nodeId,
                pId: child.parentId,
                value: child.name,
                key: child.name,
                title: child.name,
                ...(child.children <= 0 && {
                  isLeaf: true,
                }),
              })
            );

            this.props.setTree(newTree);
            this.props.setSelectedNode({
              nodeId: nodeId,
              nodeName: nodeName,
            });

            this.setState({
              isAddNodeModalVisible: false,
            });
            notification.success({
              message: this.props.t(
                'PageContainer.Nodes.CreateNode.Notification.Success.Message'
              ),
              description: this.props.t(
                'PageContainer.Nodes.CreateNode.Notification.Success.Description',
                {
                  nodeName,
                }
              ),
            });
          }
        }
      },
      (error) => {
        if (axios.isCancel(error)) {
        } else {
          Modal.error({
            title: this.props.t('App.Message.General.ServerError.Title'),
            content: error.message,
          });
        }
      }
    );
  };

  onSubnodeCreate = (values) => {
    const nodeName = values.name.toUpperCase();

    this.nodeServices
      .createNode(this.state.workingNode?.nodeId, nodeName, values?.description)
      .then(
        (response) => {
          if (response?.data) {
            if (this.nodeServices.checkError(response)) {
              const error = this.nodeServices.getError(response);

              Modal.error({
                title: this.props.t('App.Message.General.ServerError.Title'),
                content: error.message,
              });
            } else {
              const result = this.nodeServices.getResult(response);

              if (result) {
                this.getNodeChildren(result.id, nodeName);
              }
            }
          }
        },
        (error) => {
          this.setState({
            isAddNodeModalVisible: false,
          });

          if (axios.isCancel(error)) {
            this.logFirer.fireLog('LOG', error);
          } else {
            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };

  onAttributeCreate = (values) => {
    this.nodeServices
      .createNodeAttribute(
        this.state.workingNode.nodeId,
        values.attributeKey,
        values.attributeValue
      )
      .then(
        (response) => {
          this.setState({
            isAddNodeAttributeModalVisible: false,
          });

          if (response?.data) {
            if (this.nodeServices.checkError(response)) {
              const error = this.nodeServices.getError(response);

              Modal.error({
                title: this.props.t('App.Message.General.ServerError.Title'),
                content: error.message,
              });
            } else {
              message.success(this.props.t('Modal.Add.Attribute.Message'));
              this.getNodeAttributes(
                this.state.workingNode.nodeId,
                this.state.recursion
              );
            }
          } else {
          }
        },
        (error) => {
          this.setState({
            isAddNodeAttributeModalVisible: false,
          });

          if (axios.isCancel(error)) {
            this.logFirer.fireLog('LOG', error);
          } else {
            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };

  onAttributeEdit = (values) => {
    this.nodeServices
      .updateNodeAttribute(
        this.state.workingAttribute.nodeId,
        this.state.workingAttribute.attributeId,
        values.attributeKey,
        values.attributeValue
      )
      .then(
        (response) => {
          this.onDrawerClose();

          if (response?.data) {
            if (this.nodeServices.checkError(response)) {
              const error = this.nodeServices.getError(response);

              Modal.error({
                title: this.props.t('App.Message.General.ServerError.Title'),
                content: error.message,
              });
            } else {
              message.success(this.props.t('Modal.Modify.Attribute.Message'));
              this.getNodeAttributes(
                this.state.workingNode.nodeId,
                this.state.recursion
              );
            }
          }
        },
        (error) => {
          this.onDrawerClose();

          if (axios.isCancel(error)) {
            this.logFirer.fireLog('LOG', error);
          } else {
            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };

  onAttributeDelete = () => {
    this.nodeServices
      .deleteNodeAttribute(
        this.state.workingAttribute.nodeId,
        this.state.workingAttribute.attributeId,
        this.state.workingAttribute.attributeKey
      )
      .then(
        (response) => {
          this.onDrawerClose();
          if (response?.data) {
            if (this.nodeServices.checkError(response)) {
              const error = this.nodeServices.getError(response);

              Modal.error({
                title: this.props.t('App.Message.General.ServerError.Title'),
                content: error.message,
              });
            } else {
              message.success(this.props.t('Modal.Delete.Attribute.Message'));
              this.getNodeAttributes(
                this.state.workingNode.nodeId,
                this.state.recursion
              );
            }
          }
        },
        (error) => {
          this.onDrawerClose();
          if (axios.isCancel(error)) {
            this.logFirer.fireLog('LOG', error);
          } else {
            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };

  onAttributeDisable = () => {
    this.nodeServices
      .resetNodeAttribute(
        this.state.workingNode.nodeId || this.state.workingAttribute.nodeId,
        this.state.workingAttribute.attributeId,
        this.state.workingAttribute.attributeKey
      )
      .then(
        (response) => {
          this.onDrawerClose();

          if (response?.data) {
            if (this.nodeServices.checkError(response)) {
              const error = this.nodeServices.getError(response);

              Modal.error({
                title: this.props.t('App.Message.General.ServerError.Title'),
                content: error.message,
              });
            } else {
              message.success(this.props.t('Modal.Disable.Attribute.Message'));
              this.getNodeAttributes(
                this.state.workingNode.nodeId,
                this.state.recursion
              );
            }
          } else {
          }
        },
        (error) => {
          this.onDrawerClose();

          if (axios.isCancel(error)) {
            this.logFirer.fireLog('LOG', error);
          } else {
            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };

  onDrawerClose = () => {
    this.setState(
      {
        isEditAttributeDrawerVisible: false,
      },
      () => setTimeout(() => this.setState({ isDrawerRendered: false }), 200)
    );
  };

  componentWillMount = () => {
    if (this.props.selectedNode.nodeId) {
      this.getNode(this.props.selectedNode.nodeId);
    }
  };

  componentWillUnmount = () => {
    this.stopStatePropagation = false;
  };

  componentDidUpdate = () => {
    if (
      this.props.selectedNode.nodeId &&
      this.props.selectedNode.nodeId !== this.state.selectedNode.nodeId
    ) {
      this.stopStatePropagation &&
        this.setState({
          selectedNode: this.props.selectedNode,
          workingNode: undefined,
          parentNode: undefined,
          isWorkingNodeLoaded: false,
          isParentNodeLoaded: false,
          loadedList: false,
        });
      this.getNode(this.props.selectedNode.nodeId);
    }
    if (this.props.reloadPage && this.props.selectedNode.nodeId) {
      this.props.stopReloadPage();
      this.stopStatePropagation &&
        this.setState({
          selectedNode: this.props.selectedNode,
          workingNode: undefined,
          parentNode: undefined,
          isWorkingNodeLoaded: false,
          isParentNodeLoaded: false,
          loadedList: false,
        });
      this.getNode(this.props.selectedNode.nodeId);
    }
  };

  onDescriptionChange = (desc) => {
    this.setState({ isUpdateNodeLoading: true });
    this.nodeServices.updateNode(this.state.workingNode.nodeId, desc).then(
      (response) => {
        this.setState({ isUpdateNodeLoading: false });

        if (response?.data) {
          if (this.nodeServices.checkError(response)) {
            const error = this.nodeServices.getError(response);

            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          } else {
            message.success(this.props.t('Modal.Modify.Node.Message'));

            this.setState({
              workingNode: {
                ...this.state.workingNode,
                description: desc,
              },
            });
          }
        }
      },
      (error) => {
        this.setState({ isUpdateNodeLoading: false });
        if (axios.isCancel(error)) {
          this.logFirer.fireLog('LOG', error);
        } else {
          Modal.error({
            title: this.props.t('App.Message.General.ServerError.Title'),
            content: error.message,
          });
        }
      }
    );
  };

  render() {
    const suffix = (
      <GlobalOutlined
        onClick={(e) =>
          this.setState({ activeFuzzy: !this.state.activeFuzzy }, () =>
            this.refreshList(
              this.state.searchString,
              this.state.recursion,
              this.state.activeFuzzy
            )
          )
        }
        style={{
          fontSize: 16,
          color: this.state.activeFuzzy ? this.props.mainColor : '#e6e6e6',
        }}
      />
    );
    const columns = [
      {
        title: this.props.t(
          'PageContainer.Nodes.Attributes.Table.Headers.AttributeId'
        ),
        dataIndex: 'attributeId',
        width: '10%',
        render: (_, record) => (
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <div
              style={{
                width: 8,
                height: 8,
                backgroundColor:
                  this.state.selectedNode?.nodeId === record.nodeId
                    ? 'limegreen'
                    : 'red',
                borderRadius: '100%',
                flex: 'none',
              }}
            />
            {record.attributeId}
          </div>
        ),
      },
      {
        title: this.props.t(
          'PageContainer.Nodes.Attributes.Table.Headers.AttributeKey'
        ),
        dataIndex: 'attributeKey',
        sorter: {
          compare: (a, b) => a.attributeKey.length - b.attributeKey.length,
        },
        width: '35%',
      },
      {
        title: this.props.t(
          'PageContainer.Nodes.Attributes.Table.Headers.AttributeValue'
        ),
        dataIndex: 'attributeValue',
        ellipsis: true,
        width: '35%',
        render: (value) => (
          <Text
            onClick={(e) =>
              e.target.tagName === 'svg' ? e.stopPropagation() : ''
            }
            copyable={!isNilOrEmpty(value)}
          >
            {value}
          </Text>
        ),
      },
      {
        title: this.props.t(
          'PageContainer.Nodes.Attributes.Table.Headers.NodeName'
        ),
        dataIndex: 'nodeName',
        width: '20%',
      },
    ];

    return (
      <div className="nodes">
        <Title level={3}>{this.props.t('PageContainer.Nodes.Title')}</Title>
        <Layout className="nodes-main">
          {!this.state.isWorkingNodeLoaded ? (
            <Skeleton active />
          ) : (
            <Spin spinning={this.state.isUpdateNodeLoading}>
              <Row justify="space-between" align="middle">
                <Col>
                  <Title level={4} style={{ color: this.props.mainColor }}>
                    {this.props.t('PageContainer.Nodes.SelectedNode.Title', {
                      nodeName: this.state.workingNode?.name,
                      nodeId: this.state.workingNode?.nodeId,
                    })}
                  </Title>
                  <Text
                    {...(this.state.userNodeRightInfo.updateNode && {
                      editable: { onChange: this.onDescriptionChange },
                    })}
                  >
                    {this.state.workingNode?.description}
                  </Text>
                </Col>
                <Col>
                  <Statistic
                    title={this.props.t(
                      'PageContainer.Nodes.SelectedNode.Children'
                    )}
                    value={this.state.workingNode.children}
                    valueStyle={{ color: this.props.mainColor }}
                    prefix={<SubnodeOutlined />}
                  />
                </Col>
                {this.state.userNodeRightInfo.createNode && (
                  <Col>
                    <Tooltip
                      title={this.props.t(
                        'PageContainer.Nodes.Buttons.NewNode.Tooltip'
                      )}
                    >
                      <Button
                        type="primary"
                        onClick={() =>
                          this.setState({ isAddNodeModalVisible: true })
                        }
                        shape="circle"
                        icon={<PlusOutlined />}
                      />
                    </Tooltip>
                  </Col>
                )}
              </Row>
            </Spin>
          )}
        </Layout>
        <Layout className="nodes-attributes">
          <Row justify="space-between" align="middle">
            <Col>
              <Title level={5}>
                {this.props.t('PageContainer.Nodes.Attributes.Title')}
              </Title>
            </Col>
            {this.state.userNodeRightInfo.addNodeAttribute && (
              <Col>
                <Tooltip
                  title={this.props.t(
                    'PageContainer.Nodes.Buttons.NewAttribute.Tooltip'
                  )}
                >
                  <Button
                    type="primary"
                    shape="circle"
                    onClick={() =>
                      this.setState({ isAddNodeAttributeModalVisible: true })
                    }
                    icon={<PlusOutlined />}
                  />
                </Tooltip>
              </Col>
            )}
          </Row>
          <Space direction="horizontal" size={16}>
            <Search
              placeholder={this.props.t(
                'PageContainer.Nodes.Attributes.Search.Placeholder'
              )}
              onChange={this.handleChange}
              style={{ width: 300 }}
              suffix={suffix}
            />
            {this.isTreeViewAllowed && (
              <Row gutter={8}>
                <Col>
                  {this.props.t(
                    'PageContainer.Nodes.Attributes.Recursion.Label'
                  )}
                </Col>
                <Col>
                  <Switch
                    defaultChecked
                    onChange={(e) =>
                      this.refreshList(
                        this.state.searchString,
                        e,
                        this.state.activeFuzzy
                      )
                    }
                  />
                </Col>
              </Row>
            )}
          </Space>
          <Table
            className="node-attributes-table"
            columns={columns}
            loading={!this.state.loadedList}
            dataSource={this.state.attributeList}
            pagination={{
              position: ['topLeft', 'bottomRight'],
              showSizeChanger: false,
            }}
            onRow={(row) => {
              return {
                onClick: (e) => {
                  e.preventDefault();
                  this.setState(
                    {
                      workingAttribute: row,
                    },
                    () =>
                      this.setState({ isDrawerRendered: true }, () =>
                        this.setState({ isEditAttributeDrawerVisible: true })
                      )
                  );
                },
              };
            }}
          />
        </Layout>
        <AddNodeModal
          isModalVisible={this.state.isAddNodeModalVisible}
          data={{
            title: this.props.t('Modal.Add.Node.Title', {
              nodeName: this.state.selectedNode?.nodeName,
            }),
          }}
          handleOk={this.onSubnodeCreate}
          handleCancel={() => this.setState({ isAddNodeModalVisible: false })}
        />
        <AddNodeAttributeModal
          isModalVisible={this.state.isAddNodeAttributeModalVisible}
          data={{
            title: this.props.t('Modal.Add.Attribute.Title', {
              nodeName: this.state.selectedNode?.nodeName,
            }),
          }}
          handleOk={this.onAttributeCreate}
          handleCancel={() =>
            this.setState({ isAddNodeAttributeModalVisible: false })
          }
        />
        {this.state.isDrawerRendered && (
          <EditNodeAttributeDrawer
            data={{
              title: this.props.t('Modal.Modify.Attribute.Title', {
                nodeName: this.state.workingAttribute?.nodeName,
              }),
              attributeKey: this.state.workingAttribute?.attributeKey,
              attributeValue: this.state.workingAttribute?.attributeValue,
              nodeName: this.state.workingNode?.name,
            }}
            canUpdate={this.state.userNodeRightInfo.updateNodeAttribute}
            canDelete={this.state.userNodeRightInfo.deleteNodeAttribute}
            canDisable={
              this.state.userNodeRightInfo.updateNodeAttribute &&
              !_.isEmpty(this.state.workingAttribute.attributeValue)
            }
            isVisible={this.state.isEditAttributeDrawerVisible}
            onUpdate={this.onAttributeEdit}
            onDelete={this.onAttributeDelete}
            onDisable={this.onAttributeDisable}
            handleCancel={this.onDrawerClose}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(Nodes);
