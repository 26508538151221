import {
  Avatar,
  Button,
  Col,
  Layout,
  Row,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Component } from 'react';
import './styles.less';
import AppUtil from '../../../utility/admin.util';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import OAuthServices from '../../../services/oauth.services';
import NodeServices from '../../../services/node.services';
import AddProjectModal from '../../../components/Modals/AddProjectModal';
import Modal from 'antd/lib/modal/Modal';
import EditProjectDrawer from '../../../components/Drawers/EditProjectDrawer';

const { Title } = Typography;

class T4IDAdminServices extends Component {
  assignableRole;
  offline;
  env;
  timer;
  stopStatePropagation;
  accountServices;
  nodeServices;
  logFirer;
  isTreeViewAllowed;

  constructor(props) {
    super(props);

    let filteredRoles;
    let rightInfoList;
    let permissionList;
    let userAccountRightInfo;

    filteredRoles = this.props.roles.filter((r) => {
      return !_.isUndefined(r.attributes['account_manager']);
    });

    permissionList =
      this.props.globalSettings.PagePermissionManager['account_manager'] || {};
    rightInfoList = filteredRoles[0]
      ? filteredRoles[0].attributes['account_manager'].rightInfo || []
      : [];

    this.assignableRole = filteredRoles[0]
      ? filteredRoles[0].attributes['account_manager'].assignableRole || []
      : [];

    if (_.isEmpty(permissionList)) {
      this.props.history.push('/app/fallback');
      userAccountRightInfo = {
        listAccounts: false,
        inquiryAccounts: false,
        createAccount: false,
        updateUserData: false,
        deleteAccount: false,
        changeAccountNode: false,
        updateAccountStatus: false,
      };
    } else {
      userAccountRightInfo = {
        listAccounts: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'listAccounts'
        ),
        inquiryAccounts: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'inquiryAccounts'
        ),
        createAccount: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'createAccount'
        ),
        updateUserData: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'updateUserData'
        ),
        deleteAccount: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'deleteAccount'
        ),
        changeAccountNode: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'changeAccountNode'
        ),
        updateAccountStatus: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'updateAccountStatus'
        ),
      };
    }

    this.state = {
      projectsList: [],
      projectsRows: 0,
      loadedList: false,
      page: 1,
      currentProject: {},
      userAccountRightInfo: userAccountRightInfo,
      isEditProjectDrawerVisible: false,
      isAddProjectModalVisible: false,
      isDrawerRendered: false,
    };

    if (_.isString(process.env.REACT_APP_ENV)) {
      this.env = process.env.REACT_APP_ENV;
    } else {
      this.env = this.props.globalSettings.DefaultEnvironment;
    }

    this.oAuthServices = new OAuthServices(
      this.props.globalSettings.Environment[this.env]
    );
    this.nodeServices = new NodeServices(
      this.props.globalSettings.Environment[this.env]
    );
    this.pageSize = this.props.globalSettings.Environment[
      this.env
    ].envConstants.pageSize;
  }

  getProjects = () => {
    this.oAuthServices
      .listCredentials()
      .then(
        (response) => {
          if (response?.data) {
            if (this.oAuthServices.checkError(response)) {
              const error = this.serviceServices.getError(response);

              Modal.error({
                title: this.props.t('App.Message.General.ServerError.Title'),
                content: error.message,
              });
            } else {
              this.setState({
                projectsList: response.data?.result,
                projectsRows: response.data?.result?.length || 0,
              });
            }
          }
        },
        (error) => {
          Modal.error({
            title: this.props.t('App.Message.General.ServerError.Title'),
            content: error.message,
          });
        }
      )
      .finally(() => {
        this.setState({ loadedList: true });
      });
  };

  onDrawerClose = () => {
    this.setState(
      {
        isEditProjectDrawerVisible: false,
      },
      () => setTimeout(() => this.setState({ isDrawerRendered: false }), 200)
    );
  };

  componentDidMount() {
    this.getProjects();
  }

  render = () => {
    const columns = [
      {
        dataIndex: 'app_logo',
        key: 'app_logo',
        render: (value) => <Avatar shape="square" src={value} size={48} />,
        width: '10%',
      },
      {
        title: this.props.t('PageContainer.OAuthConsole.Table.Headers.Project'),
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: this.props.t(
          'PageContainer.OAuthConsole.Table.Headers.DisplayedName'
        ),
        dataIndex: 'app_name',
        key: 'app_name',
      },
      {
        title: this.props.t(
          'PageContainer.OAuthConsole.Table.Headers.Description'
        ),
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: this.props.t(
          'PageContainer.OAuthConsole.Table.Headers.LastAccess'
        ),
        dataIndex: 'modify_date',
        key: 'modify_date',
        render: (value) => new Intl.DateTimeFormat('it-IT').format(value),
      },
    ];

    return (
      <div className="OAuthConsole">
        <Title level={3}>
          {this.props.t('PageContainer.OAuthConsole.Title')}
        </Title>
        <Layout className="OAuthConsole-main">
          <Row justify="space-between" align="middle">
            <Col></Col>
            {this.state.userAccountRightInfo.createAccount && (
              <Col>
                <Tooltip
                  title={this.props.t(
                    'PageContainer.OAuthConsole.Buttons.NewProject.Tooltip'
                  )}
                >
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<PlusOutlined />}
                    onClick={() =>
                      this.setState({ isAddProjectModalVisible: true })
                    }
                  />
                </Tooltip>
              </Col>
            )}
          </Row>
          <Table
            className="OAuthConsole-table"
            columns={columns}
            loading={!this.state.loadedList}
            dataSource={this.state.projectsList}
            pagination={{
              current: this.state.page,
              total: this.state.projectsRows,
              showSizeChanger: false,
              pageSize: this.pageSize,
              position: ['topLeft', 'bottomRight'],
            }}
            onRow={(row) => {
              return {
                onClick: (e) => {
                  this.setState(
                    {
                      currentProject: row,
                    },
                    () =>
                      this.setState({ isDrawerRendered: true }, () =>
                        this.setState({ isEditProjectDrawerVisible: true })
                      )
                  );
                },
              };
            }}
          />
        </Layout>
        <AddProjectModal
          title={this.props.t('Modal.Add.OAuth.Title')}
          handleCancel={() =>
            this.setState({ isAddProjectModalVisible: false })
          }
          isModalVisible={this.state.isAddProjectModalVisible}
          oAuthServices={this.oAuthServices}
          nodeServices={this.nodeServices}
          onConfirm={this.getProjects}
          rootNode={this.props.rootNode}
        />
        {this.state.isDrawerRendered && (
          <EditProjectDrawer
            oAuthServices={this.oAuthServices}
            onUpdate={this.getProjects}
            onDelete={this.getProjects}
            isVisible={this.state.isEditProjectDrawerVisible}
            handleCancel={this.onDrawerClose}
            data={this.state.currentProject}
          />
        )}
      </div>
    );
  };
}

export default withTranslation()(T4IDAdminServices);
