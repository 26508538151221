import { Form, Input, Modal, Spin } from 'antd';
import { useState } from 'react';
import { withTranslation } from 'react-i18next';

const { TextArea } = Input;

function AddServiceAttributeModal({
  title,
  isModalVisible,
  handleOk,
  handleCancel,
  t,
}) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const formLayout = {
    labelCol: {
      span: 3,
    },
    wrapperCol: {
      span: 24,
    },
  };

  return (
    <Modal
      className="addServiceAttributeModal"
      title={title}
      visible={isModalVisible}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            setIsLoading(true);
            handleOk(values);
          })
          .catch((info) => {});
      }}
      onCancel={handleCancel}
      afterClose={() => {
        setIsLoading(false);
        form.resetFields();
      }}
      okButtonProps={{
        loading: isLoading,
      }}
      cancelButtonProps={{
        disabled: isLoading,
      }}
      closable={!isLoading}
    >
      <Spin spinning={isLoading}>
        <Form {...formLayout} form={form} requiredMark="optional">
          <Form.Item
            name="attributeKey"
            label={t('Modal.Add.Serviceattribute.Form.Key.Label')}
            rules={[
              {
                required: true,
                message: t('General.MandatoryField.Text'),
              },
            ]}
          >
            <Input
              placeholder={t('Modal.Add.Serviceattribute.Form.Key.Placeholder')}
            />
          </Form.Item>
          <Form.Item
            name="attributeValue"
            label={t('Modal.Add.Serviceattribute.Form.Value.Label')}
            rules={[
              {
                required: true,
                message: t('General.MandatoryField.Text'),
              },
            ]}
          >
            <TextArea
              rows={4}
              placeholder={t(
                'Modal.Add.Serviceattribute.Form.Value.Placeholder'
              )}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}

export default withTranslation()(AddServiceAttributeModal);
