import HttpServices from './http.services';

class AccountServices {
  envSettings;
  httpService;
  constructor(envSettings) {
    this.envSettings = envSettings;
    this.httpService = new HttpServices(this.envSettings);
  }

  /************** START Response utilities*********************************************/

  checkError = (res) => {
    if (res.data.error) {
      return true;
    } else {
      return false;
    }
  };

  getError = (res) => {
    return {
      code: res.data.error.code,
      message: res.data.error.message,
    };
  };

  getResult = (res) => {
    return res.data.result;
  };

  /************** END Response utilities*********************************************/

  /************** START API services*********************************************/

  addAuthorizedRole = (login, role) => {
    const param = {
      login: login,
      roleAdd: role,
    };
    return this.httpService.httpServicesPost(
      'addAuthorizedRole',
      this.envSettings,
      param
    );
  };

  changeAccountNode = (login, toNode, toNodeId = '') => {
    const param = {
      login: login,
      toNode: toNode,
      toNodeId: toNodeId,
    };
    return this.httpService.httpServicesPost(
      'changeAccountNode',
      this.envSettings,
      param
    );
  };

  createAccount = (
    nodeId,
    login,
    userData,
    language = this.envSettings.envConstants.defLanguage,
    forceSendingActivationEmail = this.envSettings.envConstants
      .forceSendingActivationEmail
  ) => {
    const generateFakePassword = this.envSettings.envConstants
      .generateFakePassword;
    const createAccountAlreadyActive = this.envSettings.envConstants
      .createAccountAlreadyActive;
    const accountStatus = this.envSettings.envConstants.accountStatus;
    const authMode = this.envSettings.envConstants.authMode;
    const param = {
      nodeId: nodeId,
      login: login,
      language: language,
      generateFakePassword: generateFakePassword,
      createAccountAlreadyActive: createAccountAlreadyActive,
      forceSendingActivationEmail: forceSendingActivationEmail,
      userData: {
        ...userData,
        mobileNumber: userData?.mobileNumber?.replaceAll('+', '')?.replace(/^/, '+')
      },
      accountData: {
        login: login,
        authMode: authMode,
        accountStatus: accountStatus,
      },
    };
    return this.httpService.httpServicesPost(
      'createAccount',
      this.envSettings,
      param
    );
  };

  deleteAccount = (login) => {
    const param = {
      login: login,
    };
    return this.httpService.httpServicesPost(
      'deleteAccount',
      this.envSettings,
      param
    );
  };

  resendActivationEmail = (login) => {
    const param = {
      username: login,
    };
    return this.httpService.httpServicesPost(
      'resendActivationEmail',
      this.envSettings,
      param
    );
  };

  deleteAuthorizedRole = (login, role) => {
    const param = {
      login: login,
      roleDelete: role,
    };
    return this.httpService.httpServicesPost(
      'deleteAuthorizedRole',
      this.envSettings,
      param
    );
  };

  getAccountInfoEx = (login) => {
    const param = {
      login: login,
    };
    return this.httpService.httpServicesPost(
      'getAccountInfoEx',
      this.envSettings,
      param
    );
  };

  getServiceProfile = (nodeId, service) => {
    const param = {
      nodeId: nodeId,
      service: service,
    };
    return this.httpService.httpServicesPost(
      'getServiceProfile',
      this.envSettings,
      param
    );
  };

  inquiryAccounts = (
    nodeId,
    ANDFilter,
    orderFilter,
    page,
    recursive = this.envSettings.envConstants.recursiveSearch,
    size = this.envSettings.envConstants.pageSize
  ) => {
    const param = {
      ANDFilter: ANDFilter,
      orderFilter: orderFilter,
      nodeId: nodeId,
      recursive: recursive,
      page: page,
      size: size,
    };
    return this.httpService.httpServicesPost(
      'inquiryAccounts',
      this.envSettings,
      param
    );
  };

  listAccounts = (
    nodeId,
    ANDFilter,
    orderFilter,
    page,
    recursive,
    size = this.envSettings.envConstants.pageSize
  ) => {
    const param = {
      ANDFilter: ANDFilter,
      orderFilter: orderFilter,
      nodeId: nodeId,
      recursive: recursive,
      page: page,
      size: size,
    };
    return this.httpService.httpServicesPost(
      'listAccounts',
      this.envSettings,
      param
    );
  };

  updateAccountData = (
    login,
    language,
    userData,
    confirmationEmail = this.envSettings.envConstants.confirmationEmail
  ) => {
    const param = {
      login: login,
      language: language,
      confirmationEmail: confirmationEmail,
      userData: {
        ...userData,
        mobileNumber: userData?.mobileNumber?.replaceAll('+', '')?.replace(/^/, '+')
      },
    };
    return this.httpService.httpServicesPost(
      'updateUserData',
      this.envSettings,
      param
    );
  };

  updateAccountStatus = (login, operation) => {
    const param = {
      login: login,
      operation,
      ...(operation === 'REVOKE' && { forceRevocation: true }),
      sendNotification: false,
    };
    return this.httpService.httpServicesPost(
      'updateAccountStatus',
      this.envSettings,
      param
    );
  };

  unsuscribe = (componentUnmountedName = 'GenericComponent') => {
    this.httpService.httpUnsuscribe(componentUnmountedName, 'Accounts');
  };

  /************** END API services*********************************************/
}

export default AccountServices;
