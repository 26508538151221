import {
  Col,
  Descriptions,
  Layout,
  List,
  Modal,
  Row,
  Spin,
  Switch,
  Typography,
} from 'antd';
import './styles.less';
import { useEffect, useRef, useState } from 'react';
import ServiceServices from '../../../services/service.services';
import _, { isNumber } from 'lodash';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import NodeServices from '../../../services/node.services';
import Paragraph from 'antd/lib/typography/Paragraph';
import Text from 'antd/lib/typography/Text';
import iconParent from '../../../assets/icons/ico-tree.svg';

const { Title } = Typography;

function Configuration({
  t,
  selectedNode,
  globalSettings,
  mainColor,
}) {
  const [overviewIsLoaded, setOverviewIsLoaded] = useState(false);
  const [dataAttributes, setDataAttributes] = useState();
  const [enablePage, setEnablePage] = useState(false);
  const [contextChildren, setContextChildren] = useState([]);
  const [serviceId, setServiceId] = useState();
  const currentEditField = useRef();
  
  let env;
  if (_.isString(process.env.REACT_APP_ENV)) {
    env = process.env.REACT_APP_ENV;
  } else {
    env = globalSettings.DefaultEnvironment;
  }
  const serviceServices = new ServiceServices(globalSettings.Environment[env]);

  const getNode = (env) => {
    const nodeServices = new NodeServices(globalSettings.Environment[env]);
    setOverviewIsLoaded(false);

    nodeServices.getNode(selectedNode?.nodeId).then(
      (response) => {

        if (response?.data) {
          if (nodeServices.checkError(response)) {
            const error = nodeServices.getError(response);

            Modal.error({
              title: t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          } else {
            const result = nodeServices.getResult(response);

            if (result) {
              const serviceServices = new ServiceServices(
                globalSettings.Environment[env]
              );

              serviceServices.getNodeServices(selectedNode?.nodeId).then(
                (response) => {
                  if (response?.data) {
                    if (serviceServices.checkError(response)) {
                      const error = serviceServices.getError(response);

                      Modal.error({
                        title: t('App.Message.General.ServerError.Title'),
                        content: error.message,
                      });
                    } else {
                      const result = serviceServices.getResult(response);

                      if (result) {
                        if (result.services.length > 0) {
                          const validSignServiceID = result.services.find(
                            (serv) => serv.name === 'ValidSign'
                          );

                          if (validSignServiceID) {
                            setEnablePage(true);
                            setServiceId(validSignServiceID.sid);
                            getServiceAttributes(validSignServiceID.sid);
                          } else {
                            setEnablePage(false);
                            setOverviewIsLoaded(true);
                          }
                        } else {
                          setEnablePage(false);
                          setOverviewIsLoaded(true);
                        }
                      } else {
                        setEnablePage(false);
                        setOverviewIsLoaded(true);
                      }
                    }
                  }
                },
                (error) => {
                  setOverviewIsLoaded(true);
                  if (axios.isCancel(error)) {
                  } else {
                    Modal.error({
                      title: t('App.Message.General.ServerError.Title'),
                      content: error.message,
                    });
                  }
                }
              );
            }
          }
        } else {
          setOverviewIsLoaded(true);
        }
      },
      (error) => {
        setOverviewIsLoaded(true);

        if (axios.isCancel(error)) {
          //this.logFirer.fireLog('LOG', error);
        } else {
          Modal.error({
            title: t('App.Message.General.ServerError.Title'),
            content: error.message,
          });
        }
      }
    );

    nodeServices.getNodeChildren(selectedNode?.nodeId).then(
      (response) => {
        if (response?.data) {
          const result = nodeServices.getResult(response);
          setContextChildren(result.childs.map(item => item.name));
        }
      }
    );
  };


  const getServiceAttributes = (validSignServiceID) => {
    serviceServices
      .getServiceAttributes(selectedNode?.nodeId, validSignServiceID, true)
      .then(
        (response) => {
          if (response?.data) {
            if (serviceServices.checkError(response)) {
              const error = serviceServices.getError(response);

              Modal.error({
                title: t('App.Message.General.ServerError.Title'),
                content: error.message,
              });

            } else {
              const result = serviceServices.getResult(response);
              const attributes = result.attributes;
              if (result?.countRows) {
                const baseUrl = attributes.find(
                  (attr) => attr.attributeKey === 'BASE_URL'
                ) || {};

                const maxFolders = attributes.find(
                  (attr) => attr.attributeKey === 'MAX_FOLDER_AVAILABLE'
                ) || {};

                const enableCompanyOnCloud = attributes.find(
                  (attr) => attr.attributeKey === 'ENABLER_CONTEXT'
                ) || {};
                
                const enableSPIDFlow = attributes.find(
                  (attr) => attr.attributeKey === 'ENABLE_SPID_FLOW'
                ) || {};

                const spidFlow = attributes.find(
                  (attr) => attr.attributeKey === 'SPID_FLOW'
                ) || {};

                const maxSignature = attributes.find(
                  (attr) => attr.attributeKey === 'MAX_SPID_SIGN_AVAILABLE'
                ) || {};
                
                const smsProvider = attributes.find(
                  (attr) => attr.attributeKey === 'AUTH_TYPE_SMS_PROVIDER'
                ) || {};

                const enableTimeStamp = attributes.find(
                  (attr) => attr.attributeKey === 'PKBOX_ENVIRONMENT'
                ) || {};

                const counterNotification = attributes.find(
                  (attr) => attr.attributeKey === 'COUNTERS_NOTIFICATION_CONFIGURATION'
                ) || {};

                const adminVisibleContext = attributes.find(
                  (attr) => attr.attributeKey === 'ADMIN_VISIBLE_CONTEXT'
                ) || {};
                
                setDataAttributes({
                  baseUrl, maxFolders, maxSignature, enableCompanyOnCloud, enableSPIDFlow, spidFlow, smsProvider, enableTimeStamp, counterNotification, adminVisibleContext
                });

              }
            }
          }
          setOverviewIsLoaded(true);
        },
        (error) => {
          setOverviewIsLoaded(true);
          if (axios.isCancel(error)) {
          } else {
            Modal.error({
              title: t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };

  const arrToString = (arr) => {
    if (!arr) return arr;
    return JSON.parse(arr).join(",");
  }

  const isHeritaded = (attributeNodeId) => {
    return !attributeNodeId ? false : attributeNodeId !== selectedNode?.nodeId;
  }

  useEffect(() => {
    let env;

    if (!selectedNode.nodeId) {
      return;
    }

    if (_.isString(process.env.REACT_APP_ENV)) {
      env = process.env.REACT_APP_ENV;
    } else {
      env = globalSettings.DefaultEnvironment;
    }
    getNode(env);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode]);

  const updateServiceAttribute = async (obj, key, value, onSuccess) => {
    if (isHeritaded(obj?.nodeId) || obj?.nodeId === undefined || obj?.attributeId === undefined) {
      // if heritaded or doesen't exists -> create new attribute
      if (selectedNode?.nodeId && key && value && value !== "") {
        // create service attribute
        try {
          const response = await serviceServices.createServiceAttribute(selectedNode?.nodeId, serviceId, key, value);
          if (response?.data) {
            if (serviceServices.checkError(response)) {
              const error = serviceServices.getError(response);
              throw(error);
            } else {
              // onSuccess(response?.data.result?.attributeId);
              // API doesn't work and attributeId returned is always "null"
              getServiceAttributes(serviceId);
            }
          }
        } catch (error) {
          Modal.error({
            title: t('App.Message.General.ServerError.Title'),
            content: error.message,
          });
        }
      }
    } else {
      // update service attribute
      if (obj?.attributeId && key && value && value !== "") {
        try {
          const response = await serviceServices.updateServiceAttribute( obj?.attributeId, key, value );
          if (response?.data) {
            if (serviceServices.checkError(response)) {
              const error = serviceServices.getError(response);
              throw(error);
            }
          }
          onSuccess();
        } catch (error) {
          Modal.error({
            title: t('App.Message.General.ServerError.Title'),
            content: error.message,
          });
        }
      }
    }
  };

  const onUpdateBaseUrl = (e) => {
    updateServiceAttribute(
      dataAttributes.baseUrl, 
      "BASE_URL", 
      e, 
      () => setDataAttributes({...dataAttributes, baseUrl: {...dataAttributes.baseUrl, nodeId: selectedNode?.nodeId, attributeValue: e}})
    );
  };

  const onUpdateCompanyOnCloud = (e) => {
    updateServiceAttribute(
      dataAttributes.enableCompanyOnCloud, 
      "ENABLER_CONTEXT", 
      e ? 'validsign_cloud' : 'validsign', 
      () => setDataAttributes({...dataAttributes, enableCompanyOnCloud: {...dataAttributes.enableCompanyOnCloud, nodeId: selectedNode?.nodeId, attributeValue: e ? 'validsign_cloud' : 'validsign'} })
    );
  };

  const onUpdateSPIDFlow = (e) => {
    updateServiceAttribute(
      dataAttributes.enableSPIDFlow, 
      "ENABLE_SPID_FLOW", 
      e ? "true" : "false",
      () => setDataAttributes(dataAttributes => {
        // enable also timestamp
        // if (e && 
        //     dataAttributes.enableTimeStamp?.attributeId && 
        //     dataAttributes?.enableTimeStamp?.attributeValue && 
        //     dataAttributes?.enableTimeStamp?.attributeValue?.toUpperCase() !== "TRUE"
        // ) {
        //   updateServiceAttribute(dataAttributes.enableTimeStamp, "PKBOX_ENVIRONMENT", e ? 'TIMESTAMP' : 'NO_DOMAIN', () => {})
        //   // update Spid Flow and timestamp state
        //   return {...dataAttributes,
        //     enableSPIDFlow: {...dataAttributes.enableSPIDFlow, attributeValue: e ? "true" : "false"},
        //     enableTimeStamp: {...dataAttributes.enableTimeStamp, attributeValue: "true"} 
        //   }
        // }
        // update Spid Flow state
        return {...dataAttributes, enableSPIDFlow: {...dataAttributes.enableSPIDFlow, nodeId: selectedNode?.nodeId, attributeValue: e ? "true" : "false"} }
      })
    );
  };

  const onUpdateRegistrationOffice = (e) => { 
    const data = dataAttributes.spidFlow?.attributeValue;
    const val = JSON.parse(data ? data : `{"requestLogin":"ValidSignRAO", "productType":10, "providerName":"intesiQCShortTermSpid"}`);
    val.registrationOffice = e;
    updateServiceAttribute(
      dataAttributes.spidFlow, 
      "SPID_FLOW", 
      JSON.stringify(val), 
      () => setDataAttributes({...dataAttributes, spidFlow: {...dataAttributes.spidFlow, nodeId: selectedNode?.nodeId, attributeValue: JSON.stringify(val)} })
    );
  };

  const onUpdateSMSProvider = (e) => {
    updateServiceAttribute(
      dataAttributes.smsProvider, 
      "AUT_TYPE_SMS_PROVIDER", 
      e,
      () => setDataAttributes({...dataAttributes, smsProvider: {...dataAttributes.smsProvider, nodeId: selectedNode?.nodeId, attributeValue: e} })
    );
  };

  const onUpdateMaxFolders = (e) => {
    if (!isNaN(e) && e !== "") {
      // numbers only
        updateServiceAttribute(
        dataAttributes.maxFolders, 
        "MAX_FOLDER_AVAILABLE", 
        e,
        () => setDataAttributes({...dataAttributes, maxFolders: {...dataAttributes.maxFolders, nodeId: selectedNode?.nodeId, attributeValue: e} })
      );
    }
  };

  const onUpdateMaxSignature = (e) => {
    if (!isNaN(e) && e !== "") {
      // numbers only
      updateServiceAttribute(
        dataAttributes.maxSignature, 
        "MAX_SPID_SIGN_AVAILABLE", 
        e,
        () => setDataAttributes({...dataAttributes, maxSignature: {...dataAttributes.maxSignature, nodeId: selectedNode?.nodeId, attributeValue: e} })
      );
    }
  };
  
  const onUpdateEnableTimestamp = (e) => {
    updateServiceAttribute(
      dataAttributes.enableTimeStamp, 
      "PKBOX_ENVIRONMENT", 
      e ? 'TIMESTAMP' : 'NO_DOMAIN',
      () => setDataAttributes({...dataAttributes, enableTimeStamp: {...dataAttributes.enableTimeStamp, nodeId: selectedNode?.nodeId, attributeValue: e ? "TIMESTAMP" : "NO_DOMAIN"} })
    );
  };

  const onUpdateAdminVisibleContext = (e) => {
    const val = e.replace(/\s/g, "").split(",");
    // check if all context items exist
    let theyExist = true;
    val.forEach(item => {
      if (contextChildren && contextChildren?.indexOf(item) === -1)
      theyExist = false;
    });
    if (theyExist || e === "") {
      const arrVal = (e === "") ? "[]" : JSON.stringify(val);
      const strVal = e;
      // update if all items exist in context
      updateServiceAttribute(
        dataAttributes.adminVisibleContext || {}, 
        "ADMIN_VISIBLE_CONTEXT", 
        arrVal,
        () => setDataAttributes({...dataAttributes, adminVisibleContext: {...dataAttributes.adminVisibleContext, nodeId: selectedNode?.nodeId, attributeValue: strVal} })
      );
    } else {
      Modal.error({
        title: t('App.Message.General.ServerError.Title'),
        content: t('App.Message.General.ServerError.ContextNotExists'),
      });
    }
  };
  
  const onUpdateFolderRetention = (e) => {
    const data = dataAttributes.counterNotification?.attributeValue;
    const val = JSON.parse(data ? data : "{}");
    val.folder_retention = JSON.parse(`[${e}]`);
    updateServiceAttribute(
      dataAttributes.counterNotification, 
      "COUNTERS_NOTIFICATION_CONFIGURATION", 
      JSON.stringify(val),
      () => setDataAttributes({...dataAttributes, counterNotification: {...dataAttributes.counterNotification, nodeId: selectedNode?.nodeId, attributeValue: JSON.stringify(val)} })
    );
  };

  const onUpdateNotificationTo = (e) => {
    const data = dataAttributes.counterNotification?.attributeValue;
    const val = JSON.parse(data ? data : "{}");
    val.to = e.replace(/\s/g, "").split(",");
    updateServiceAttribute(
      dataAttributes.counterNotification, 
      "COUNTERS_NOTIFICATION_CONFIGURATION", 
      JSON.stringify(val),
      () => setDataAttributes({...dataAttributes, counterNotification: {...dataAttributes.counterNotification, nodeId: selectedNode?.nodeId, attributeValue: JSON.stringify(val)} })
    );
  };

  const onUpdateNotificationCC = (e) => {
    const data = dataAttributes.counterNotification?.attributeValue;
    const val = JSON.parse(data ? data : "{}");
    val.cc = e.replace(/\s/g, "").split(",");
    updateServiceAttribute(
      dataAttributes.counterNotification, 
      "COUNTERS_NOTIFICATION_CONFIGURATION", 
      JSON.stringify(val),
      () => setDataAttributes({...dataAttributes, counterNotification: {...dataAttributes.counterNotification, nodeId: selectedNode?.nodeId, attributeValue: JSON.stringify(val)} })
    );
  };

  return (
    <div className="configuration">
      <Title level={3}>{t('PageContainer.Validsign.Configuration.Title')}</Title>
      {/* <div>dataAttributes: </div>
      <pre>{JSON.stringify(dataAttributes)}</pre> */}
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Layout className="configuration-node">
              <div
                className="configuration-node-title"
                style={{ color: mainColor }}
              >
                {t('PageContainer.Validsign.Configuration.General')}
              </div>
              {
                !overviewIsLoaded ? 
                  <Spin size='large' />
                :
                <Descriptions column={1}>
                  <Descriptions.Item label={t('PageContainer.Validsign.Configuration.BaseUrl')}
                  >
                    <Paragraph 
                      disabled={!enablePage} 
                      style={{width: '100%'}}
                      editable={!enablePage ? false : { autoSize: true, maxLength: 50, onChange: (e) => currentEditField.current = e, onEnd: () => onUpdateBaseUrl(currentEditField.current) }}
                    >{isHeritaded(dataAttributes?.baseUrl?.nodeId) ? "" : dataAttributes?.baseUrl?.attributeValue}</Paragraph>
                  </Descriptions.Item>
                  { enablePage && isHeritaded(dataAttributes?.baseUrl?.nodeId) && 
                    <div className="inheritNodeData">
                      <img src={iconParent} alt="parent" width="16" /> <Text type="secondary">{dataAttributes?.baseUrl?.nodeName}: {dataAttributes?.baseUrl?.attributeValue}</Text>
                    </div>
                  }

                  <Descriptions.Item label={t('PageContainer.Validsign.Configuration.EnableCompanyOnCloud')}>
                    <Switch disabled={!enablePage} key={Math.random()} onChange={onUpdateCompanyOnCloud} defaultChecked={dataAttributes?.enableCompanyOnCloud && dataAttributes?.enableCompanyOnCloud?.attributeValue?.toUpperCase() === "VALIDSIGN_CLOUD"} style={{marginBottom: '1em'}} />
                  </Descriptions.Item>
                  { enablePage && isHeritaded(dataAttributes?.enableCompanyOnCloud?.nodeId) && 
                    <div className="inheritNodeData">
                      <img src={iconParent} alt="parent" width="16" /> <Text type="secondary">{dataAttributes?.enableCompanyOnCloud?.nodeName}: {dataAttributes?.enableCompanyOnCloud?.attributeValue}</Text>
                    </div>
                  }

                  <Descriptions.Item label={t('PageContainer.Validsign.Configuration.SMSProvider')}>
                    <Paragraph 
                      disabled={!enablePage} 
                      style={{width: '100%'}} 
                      editable={!enablePage ? false : { autoSize: true, maxLength: 50, onChange: (e) => currentEditField.current = e, onEnd: () => onUpdateSMSProvider(currentEditField.current) }}
                    >{isHeritaded(dataAttributes?.smsProvider?.nodeId) ? "" : dataAttributes?.smsProvider?.attributeValue}</Paragraph>
                  </Descriptions.Item>
                  { enablePage && isHeritaded(dataAttributes?.smsProvider?.nodeId) &&
                    <div className="inheritNodeData">
                      <img src={iconParent} alt="parent" width="16" /> <Text type="secondary">{dataAttributes?.smsProvider?.nodeName}: {dataAttributes?.smsProvider?.attributeValue}</Text>
                    </div>
                  }
                  
                  <Descriptions.Item label={t('PageContainer.Validsign.Configuration.MaxFolders')}>
                    <Paragraph 
                      disabled={!enablePage} 
                      style={{width: '100%'}} 
                      editable={!enablePage ? false : { autoSize: true, maxLength: 10, onChange: (e) => currentEditField.current = e, onEnd: () => onUpdateMaxFolders(currentEditField.current) }}
                    >{isHeritaded(dataAttributes?.maxFolders?.nodeId) ? "" : dataAttributes?.maxFolders?.attributeValue}</Paragraph>
                  </Descriptions.Item>
                  { enablePage && isHeritaded(dataAttributes?.maxFolders?.nodeId) &&
                    <div className="inheritNodeData">
                      <img src={iconParent} alt="parent" width="16" /> <Text type="secondary">{dataAttributes?.maxFolders?.nodeName}: {dataAttributes?.maxFolders?.attributeValue}</Text>
                    </div>
                  }
                </Descriptions>
              }
            </Layout>
          </Col>
          <Col xs={24} md={12}>
            <Layout className="configuration-node">
              <div
                className="configuration-node-title"
                style={{ color: mainColor }}
              >
                {t('PageContainer.Validsign.Configuration.Communications')}
              </div>
              {
                !overviewIsLoaded ? 
                  <Spin size='large' />
                :
                <Descriptions column={1}>
                  <Descriptions.Item label={t('PageContainer.Validsign.Configuration.FolderRetention')}>
                    <Paragraph
                      disabled={!enablePage} 
                      style={{width: '100%'}} 
                      editable={!enablePage ? false : { autoSize: true, maxLength: 50, onChange: (e) => currentEditField.current = e, onEnd: () => onUpdateFolderRetention(currentEditField.current) }}
                    >{isHeritaded(dataAttributes?.counterNotification?.nodeId) ? "" : dataAttributes?.counterNotification?.attributeValue ? JSON.parse(dataAttributes?.counterNotification?.attributeValue).folder_retention?.toString() : ""}</Paragraph>
                  </Descriptions.Item>
                  { enablePage && isHeritaded(dataAttributes?.counterNotification?.nodeId) &&
                    <div className="inheritNodeData">
                      <img src={iconParent} alt="parent" width="16" /> <Text type="secondary">{dataAttributes?.counterNotification?.nodeName}: {dataAttributes?.counterNotification?.attributeValue ? JSON.parse(dataAttributes?.counterNotification?.attributeValue).folder_retention?.toString() : ""}</Text>
                    </div>
                  }

                  <Descriptions.Item label={t('PageContainer.Validsign.Configuration.NotificationTo')}>
                    <Paragraph
                      disabled={!enablePage} 
                      style={{width: '100%'}} 
                      editable={!enablePage ? false : { autoSize: true, onChange: (e) => currentEditField.current = e, onEnd: () => onUpdateNotificationTo(currentEditField.current) }}
                    >{isHeritaded(dataAttributes?.counterNotification?.nodeId) ? "" : dataAttributes?.counterNotification?.attributeValue ? JSON.parse(dataAttributes?.counterNotification?.attributeValue).to?.toString() : ""}</Paragraph>
                  </Descriptions.Item>
                  { enablePage && isHeritaded(dataAttributes?.counterNotification?.nodeId) &&
                    <div className="inheritNodeData">
                      <img src={iconParent} alt="parent" width="16" /> <Text type="secondary">{dataAttributes?.counterNotification?.nodeName}: {dataAttributes?.counterNotification?.attributeValue ? JSON.parse(dataAttributes?.counterNotification?.attributeValue).to?.toString() : ""}</Text>
                    </div>
                  }

                  <Descriptions.Item label={t('PageContainer.Validsign.Configuration.NotificationCC')}>
                    <Paragraph
                      disabled={!enablePage} 
                      style={{width: '100%'}} 
                      editable={!enablePage ? false : { autoSize: true, onChange: (e) => currentEditField.current = e, onEnd: () => onUpdateNotificationCC(currentEditField.current) }}
                    >{isHeritaded(dataAttributes?.counterNotification?.nodeId) ? "" : dataAttributes?.counterNotification?.attributeValue ? JSON.parse(dataAttributes?.counterNotification?.attributeValue).cc?.toString() : ""}</Paragraph>
                  </Descriptions.Item>
                  { enablePage && isHeritaded(dataAttributes?.counterNotification?.nodeId) &&
                    <div className="inheritNodeData">
                      <img src={iconParent} alt="parent" width="16" /> <Text type="secondary">{dataAttributes?.counterNotification?.nodeName}: {dataAttributes?.counterNotification?.attributeValue ? JSON.parse(dataAttributes?.counterNotification?.attributeValue).cc?.toString() : ""}</Text>
                    </div>
                  }
                </Descriptions>
              }
            </Layout>
          </Col>
          <Col xs={24} md={12}>
            <Layout className="configuration-node">
              <div
                className="configuration-node-title"
                style={{ color: mainColor }}
              >
                {t('PageContainer.Validsign.Configuration.SpidFlow')}
              </div>
              {
                !overviewIsLoaded ? 
                  <Spin size='large' />
                :
                <Descriptions column={1}>
                  <Descriptions.Item label={t('PageContainer.Validsign.Configuration.EnableSPIDFlow')}>
                    <Switch disabled={!enablePage} key={Math.random()} onChange={onUpdateSPIDFlow} defaultChecked={dataAttributes?.enableSPIDFlow?.attributeValue && dataAttributes?.enableSPIDFlow?.attributeValue?.toUpperCase() === "TRUE"} style={{marginBottom: '1em'}} />
                  </Descriptions.Item>
                  { enablePage && isHeritaded(dataAttributes?.enableSPIDFlow?.nodeId) && 
                    <div className="inheritNodeData">
                      <img src={iconParent} alt="parent" width="16" /> <Text type="secondary">{dataAttributes?.enableSPIDFlow?.nodeName}: {dataAttributes?.enableSPIDFlow?.attributeValue}</Text>
                    </div>
                  }

                  <Descriptions.Item label={t('PageContainer.Validsign.Configuration.RegistrationOffice')}>
                    <Paragraph
                      disabled={!enablePage ? true : (dataAttributes?.enableSPIDFlow?.attributeValue && dataAttributes?.enableSPIDFlow?.attributeValue?.toUpperCase() !== "TRUE")}
                      style={{width: '100%'}}
                      editable={!enablePage ? false : (dataAttributes?.enableSPIDFlow?.attributeValue && dataAttributes?.enableSPIDFlow?.attributeValue?.toUpperCase() === "TRUE") ? { autoSize: true, maxLength: 50, onChange: (e) => currentEditField.current = e, onEnd: () => onUpdateRegistrationOffice(currentEditField.current) } : false}
                    >{isHeritaded(dataAttributes?.spidFlow?.nodeId) ? "" : dataAttributes?.spidFlow?.attributeValue  ? JSON.parse(dataAttributes?.spidFlow?.attributeValue).registrationOffice : ""}</Paragraph>
                  </Descriptions.Item>
                  { enablePage && isHeritaded(dataAttributes?.spidFlow?.nodeId) &&
                    <div className="inheritNodeData">
                      <img src={iconParent} alt="parent" width="16" /> <Text type="secondary">{dataAttributes?.spidFlow?.nodeName}: {dataAttributes?.spidFlow?.attributeValue ? JSON.parse(dataAttributes?.spidFlow?.attributeValue).registrationOffice : ""}</Text>
                    </div>                      
                  }

                  <Descriptions.Item label={t('PageContainer.Validsign.Configuration.MaxNumberOfSignature')}>
                    <Paragraph
                      disabled={!enablePage ? true : (dataAttributes?.enableSPIDFlow?.attributeValue && dataAttributes?.enableSPIDFlow?.attributeValue?.toUpperCase() !== "TRUE")}
                      style={{width: '100%'}}
                      editable={!enablePage ? false : (dataAttributes?.enableSPIDFlow?.attributeValue && dataAttributes?.enableSPIDFlow?.attributeValue?.toUpperCase() === "TRUE") ? { autoSize: true, maxLength: 10, onChange: (e) => currentEditField.current = e, onEnd: () => onUpdateMaxSignature(currentEditField.current) } : false}
                    >{isHeritaded(dataAttributes?.maxSignature?.nodeId) ? "" : dataAttributes?.maxSignature?.attributeValue}</Paragraph>
                  </Descriptions.Item>
                  { enablePage && isHeritaded(dataAttributes?.maxSignature?.nodeId) &&
                    <div className="inheritNodeData">
                      <img src={iconParent} alt="parent" width="16" /> <Text type="secondary">{dataAttributes?.maxSignature?.nodeName}: {dataAttributes?.maxSignature?.attributeValue}</Text>
                    </div>                      
                  }
                </Descriptions>
              }
            </Layout>
          </Col>
          <Col xs={24} md={12}>
            <Layout className="configuration-node">
              <div
                className="configuration-node-title"
                style={{ color: mainColor }}
              >
                {t('PageContainer.Validsign.Configuration.AdminVisibleContext')}
              </div>
              {
                !overviewIsLoaded ? 
                  <Spin size='large' />
                :
                <>
                  <Descriptions column={1}>
                    <Descriptions.Item label={t('PageContainer.Validsign.Configuration.EnableVisibleContext')}>
                      <Paragraph
                        disabled={!enablePage}
                        style={{width: '100%'}}
                        editable={!enablePage ? false : { autoSize: true, onChange: (e) => currentEditField.current = e, onEnd: () => onUpdateAdminVisibleContext(currentEditField.current) }}
                      >{isHeritaded(dataAttributes?.adminVisibleContext?.nodeId) ? "" : arrToString(dataAttributes?.adminVisibleContext?.attributeValue)}</Paragraph>
                    </Descriptions.Item>
                    { enablePage && isHeritaded(dataAttributes?.adminVisibleContext?.nodeId) &&
                        <div className="inheritNodeData">
                          <img src={iconParent} alt="parent" width="16" /> <Text type="secondary">{dataAttributes?.adminVisibleContext?.nodeName}: {arrToString(dataAttributes?.adminVisibleContext?.attributeValue)}</Text>
                        </div>                      
                      }
                  </Descriptions>
                  <div className='contextList'>
                    <div className='contextListTitle'>{t('PageContainer.Validsign.Configuration.ContextList')}</div>
                    {/* <ul>
                      {
                        contextChildren.map(item => (<li>{item}</li>))
                      }
                    </ul> */}
                    <List
                      grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 2,
                        md: 3,
                        lg: 4,
                        xl: 4,
                        xxl: 5,
                      }}
                      dataSource={contextChildren.map(el => ({title: el}))}
                      renderItem={(item) => (
                        <List.Item>
                          {item.title}
                        </List.Item>
                      )}
                    />
                  </div>
                </>
              }
            </Layout>
          </Col>
          <Col xs={24} md={12}>
            <Layout className="configuration-node">
              <div
                className="configuration-node-title"
                style={{ color: mainColor }}
              >
                {t('PageContainer.Validsign.Configuration.Timestamp')}
              </div>
              {
                !overviewIsLoaded ? 
                  <Spin size='large' />
                :
                <Descriptions column={1}>
                  <Descriptions.Item label={t('PageContainer.Validsign.Configuration.EnableTimestamp')}>
                    <Switch disabled={!enablePage} key={Math.random()} onChange={onUpdateEnableTimestamp} defaultChecked={dataAttributes?.enableTimeStamp?.attributeValue?.toUpperCase() === "TIMESTAMP"} style={{marginBottom: '1em'}} />
                  </Descriptions.Item>
                  { enablePage && isHeritaded(dataAttributes?.enableTimeStamp?.nodeId) && 
                    <div className="inheritNodeData">
                      <img src={iconParent} alt="parent" width="16" /> <Text type="secondary">{dataAttributes?.enableTimeStamp?.nodeName}: {dataAttributes?.enableTimeStamp?.attributeValue}</Text>
                    </div>
                  }
                </Descriptions>
              }
            </Layout>
          </Col>

        </Row>
    </div>
  );
}

export default withTranslation()(Configuration);
