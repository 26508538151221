class LogsFirer {
  envSettings;
  constructor(envSettings) {
    this.envSettings = envSettings;
  }

  fireLog(type, object, label) {
    if (this.envSettings.logsFirerSettings.fireLogs) {
      switch (type) {
        case 'INFO':
          if (this.envSettings.logsFirerSettings.logsType[type]) {
            label ? console.info(label, object) : console.info(object);
          }
          break;
        case 'LOG':
          if (this.envSettings.logsFirerSettings.logsType[type]) {
            label ? console.log(label, object) : console.log(object);
          }
          break;
        case 'WARN':
          if (this.envSettings.logsFirerSettings.logsType[type]) {
            label ? console.warn(label, object) : console.warn(object);
          }
          break;
        case 'ERROR':
          if (this.envSettings.logsFirerSettings.logsType[type]) {
            label ? console.error(label, object) : console.error(object);
          }
          break;
        default:
          break;
      }
    }
  }
}

export default LogsFirer;
