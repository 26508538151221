import {
  Alert,
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Modal,
  Row,
  Spin,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useState } from 'react';
import './styles.less';
import { withTranslation } from 'react-i18next';

const { TextArea } = Input;

function EditNodeAttributeDrawer({
  data = {},
  handleCancel,
  t,
  isVisible,
  onUpdate,
  onDelete,
  onDisable,
  canUpdate = true,
  canDelete = true,
  canDisable = true,
}) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { title, attributeKey, attributeValue, nodeName } = data;
  const formLayout = {
    labelCol: {
      span: 3,
    },
    wrapperCol: {
      span: 24,
    },
  };
  const onOperationClick = (option) => {
    switch (option) {
      case 'update':
        setIsLoading(true);

        form
          .validateFields()
          .then((values) => {
            onUpdate(values);
          })
          .catch((_) => {
            setIsLoading(false);
          });
        break;

      case 'disable':
        Modal.confirm({
          title: t('Modal.Disable.Attribute.DisableMessage.Warning'),
          okText: t('Modal.Disable.Attribute.Buttons.Disable'),
          okType: 'danger',
          okButtonProps: {
            type: 'primary',
          },
          cancelText: t('Modal.Disable.Attribute.Buttons.Close'),
          autoFocusButton: null,
          closable: true,
          content: (
            <>
              <Alert
                style={{ marginBottom: 16 }}
                message={t('Modal.Disable.Attribute.DisableMessage.Info')}
                type="warning"
              />
              <span>
                {t('Modal.Disable.Attribute.DisableMessage.Question', {
                  attributeName: attributeKey,
                  nodeName,
                })}
              </span>
            </>
          ),
          onOk: () => {
            setIsLoading(true);
            onDisable();
          },
        });
        break;

      case 'delete':
        Modal.confirm({
          title: t('Modal.Delete.Attribute.DeleteMessage.Warning'),
          okText: t('Modal.Delete.Attribute.Buttons.Delete'),
          okType: 'danger',
          okButtonProps: {
            type: 'primary',
          },
          cancelText: t('Modal.Delete.Attribute.Buttons.Close'),
          autoFocusButton: null,
          closable: true,
          content: (
            <>
              <Alert
                style={{ marginBottom: 16 }}
                message={t('Modal.Delete.Attribute.DeleteMessage.Info')}
                type="warning"
              />
              <span>
                {t('Modal.Delete.Attribute.DeleteMessage.Question', {
                  attributeName: attributeKey,
                  nodeName,
                })}
              </span>
            </>
          ),
          onOk: () => {
            setIsLoading(true);
            onDelete();
          },
        });
        break;

      default:
        break;
    }
  };

  return (
    <Drawer
      title={title}
      className="editNodeAttributeDrawer"
      placement="right"
      width={480}
      visible={isVisible}
      onClose={() => {
        form.resetFields();
        setIsLoading(false);
        handleCancel();
      }}
      footer={
        <Row align="middle" justify="space-between">
          <Col>
            <Button onClick={handleCancel}>{t('Drawer.Buttons.Close')}</Button>
          </Col>
          <Col>
            <Row>
              {canDelete && (
                <Col>
                  <Button
                    danger
                    onClick={() => onOperationClick('delete')}
                    icon={<DeleteOutlined />}
                    type="primary"
                  >
                    {t('Modal.Delete.Attribute.Buttons.Delete')}
                  </Button>
                </Col>
              )}
              {canDisable && (
                <Col>
                  <Button
                    className="btn-warn"
                    onClick={() => onOperationClick('disable')}
                    type="text"
                  >
                    {t('Modal.Disable.Attribute.Buttons.Disable')}
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
          {canUpdate && (
            <Col>
              <Button onClick={() => onOperationClick('update')} type="primary">
                {t('Modal.Modify.Node.Buttons.Modify')}
              </Button>
            </Col>
          )}
        </Row>
      }
    >
      <Spin spinning={isLoading}>
        <Form
          {...formLayout}
          form={form}
          requiredMark="optional"
          initialValues={{
            attributeKey,
            attributeValue,
          }}
        >
          <Form.Item
            name="attributeKey"
            style={{ width: '100%' }}
            label={t('Modal.Modify.Attribute.Form.Key.Label')}
            rules={[
              {
                required: true,
                message: t('General.MandatoryField.Text'),
              },
            ]}
          >
            <Input
              placeholder={t('Modal.Modify.Attribute.Form.Key.Placeholder')}
              disabled={!canUpdate}
            />
          </Form.Item>
          <Form.Item
            name="attributeValue"
            style={{ width: '100%' }}
            label={t('Modal.Modify.Attribute.Form.Value.Label')}
            rules={[
              {
                required: true,
                message: t('General.MandatoryField.Text'),
              },
            ]}
          >
            <TextArea
              rows={6}
              placeholder={t('Modal.Modify.Attribute.Form.Value.Placeholder')}
              disabled={!canUpdate}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
}

export default withTranslation()(EditNodeAttributeDrawer);
