import HttpServices from './http.services';

class RoleServices {
  envSettings;
  httpService;

  constructor(envSettings) {
    this.envSettings = envSettings;
    this.httpService = new HttpServices(this.envSettings);
  }

  /************** START Response utilities*********************************************/

  checkError = (res) => {
    if (res.data.error) {
      return true;
    } else {
      return false;
    }
  };

  getError = (res) => {
    return {
      code: res.data.error.code,
      message: res.data.error.message,
    };
  };

  getResult = (res) => {
    return res.data.result;
  };

  /************** END Response utilities*********************************************/

  /************** START API services*********************************************/

  getRoles = (nodeId) => {
    const params = {
      nodeId: nodeId,
    };
    return this.httpService.httpServicesPost(
      'getRoles',
      this.envSettings,
      params
    );
  };

  getRoleContexts = (nodeId, roleId, page = 1, name = '') => {
    const params = {
      nodeId: nodeId,
      roleId: roleId,
      page: page,
      size: this.envSettings.envConstants.contextListSize,
      name: name,
    };
    return this.httpService.httpServicesPost(
      'getRoleContexts',
      this.envSettings,
      params
    );
  };

  unsuscribe = (componentUnmountedName = 'GenericComponent') => {
    this.httpService.httpUnsuscribe(componentUnmountedName, 'Roles');
  };

  /************** END API services*********************************************/
}

export default RoleServices;
