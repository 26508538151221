import React, { Component } from 'react';

// Translation
import { withTranslation } from 'react-i18next';

// Utility
import _ from 'lodash';

// API Service
import Axios from 'axios';
import NodeServices from '../../services/node.services';
import LogsFirer from '../../services/logs-firer.services';
import { Breadcrumb, Modal, Space } from 'antd';
import { SisternodeOutlined } from '@ant-design/icons';

import './styles.less';
import { LOGOUT, SETTINGS, SOFTWARE_INFO } from '../../constants/routes';
import axios from 'axios';

class _Breadcrumb extends Component {
  nodeServices;
  offline;
  env;
  stopStatePropagation;
  logFirer;

  constructor(props) {
    super(props);

    if (
      _.isString(process.env.REACT_APP_OFFLINE) &&
      _.isBoolean(JSON.parse(process.env.REACT_APP_OFFLINE))
    ) {
      this.offline = JSON.parse(process.env.REACT_APP_OFFLINE);
    } else {
      this.offline = false;
    }

    if (_.isString(process.env.REACT_APP_ENV)) {
      this.env = process.env.REACT_APP_ENV;
    } else {
      this.env = this.props.globalSettings.DefaultEnvironment;
    }

    let url;
    let urlArray;
    if (
      this.props.location.pathname.includes('configurations') ||
      this.props.location.pathname.includes('profiles')
    ) {
      urlArray = this.props.location.pathname.split('/');
      url = '/' + urlArray[1] + '/' + urlArray[2] + '/' + urlArray[3] + '/';
    } else {
      url = this.props.defaultUrl;
    }

    this.state = {
      breadcrumb: [],
      selectedNode: {},
      waitResponse: false,
      baseUrl: url,
    };

    this.nodeServices = new NodeServices(
      this.props.globalSettings.Environment[this.env]
    );
    this.logFirer = new LogsFirer(
      this.props.globalSettings.Environment[this.env]
    );
    this.stopStatePropagation = true;
  }

  setBaseUrl = () => {
    let url;
    let urlArray;
    if (
      this.props.location.pathname.includes('configurations') ||
      this.props.location.pathname.includes('profiles')
    ) {
      urlArray = this.props.location.pathname.split('/');
      url = '/' + urlArray[1] + '/' + urlArray[2] + '/' + urlArray[3] + '/';
    } else {
      url = this.props.defaultUrl;
    }
    if (this.state.baseUrl !== url) {
      this.stopStatePropagation &&
        this.setState({
          baseUrl: url,
        });
    }
  };

  setBreadcrumb = () => {
    if (this.props.selectedNode.nodeId !== this.state.selectedNode.nodeId) {
      this.stopStatePropagation &&
        this.setState({
          waitResponse: true,
        });

      this.generateBreadcrumb(this.props.rootNode, this.props.selectedNode);
      this.stopStatePropagation &&
        this.setState({
          selectedNode: this.props.selectedNode,
        });
    }
  };

  componentDidUpdate = () => {
    this.setBaseUrl();

    this.setBreadcrumb();
  };

  componentDidMount = () => {
    this.setBaseUrl();

    this.setBreadcrumb();
  };

  getNode = (nodeId) => {
    return this.nodeServices.getNode(nodeId).then(
      (response) => {
        if (response?.data) {
          if (this.nodeServices.checkError(response)) {
          } else {
            const result = this.nodeServices.getResult(response);
            return result;
          }
        } else {
        }
      },
      (error) => {
        if (Axios.isCancel(error)) {
          this.logFirer.fireLog('LOG', error);
        }
      }
    );
  };

  generateBreadcrumb = async (rootNode, selectedNode) => {
    let breadcrumb = [];
    let checkId = selectedNode.nodeId;

    let Node;

    if (rootNode.nodeId && checkId && rootNode.nodeName) {
      while (rootNode.nodeId !== checkId) {
        Node = await this.getNode(checkId);
        if (Node) {
          breadcrumb.push({
            name: Node.name,
            nodeId: Node.nodeId,
            parentId: Node.parentId,
          });

          checkId = Node.parentId;
        } else {
          break;
        }
      }

      breadcrumb.push({
        name: rootNode.nodeName,
        nodeId: rootNode.nodeId,
      });
    }

    this.stopStatePropagation &&
      this.setState({
        breadcrumb: breadcrumb.reverse(),
        waitResponse: false,
      });
  };

  onBreadcrumbItemClick = (crumb) => {
    this.props.setSelectedNode({
      nodeName: crumb.name,
      nodeId: crumb.nodeId,
    });

    this.nodeServices.getNodeChildren(crumb.nodeId).then(
      (response) => {
        if (response?.data) {
          if (this.nodeServices.checkError(response)) {
            const error = this.nodeServices.getError(response);

            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          } else {
            const result = this.nodeServices.getResult(response);
            let newTree = [];

            result.childs.forEach((child) =>
              newTree.push({
                id: child.nodeId,
                pId: child.parentId,
                value: child.name,
                key: child.name,
                title: child.name,
                ...(child.children <= 0 && {
                  isLeaf: true,
                }),
              })
            );

            this.props.setTree(newTree);
          }
        }
      },
      (error) => {
        if (axios.isCancel(error)) {
          this.logFirer.fireLog('LOG', error);
        } else {
          Modal.error({
            title: this.props.t('App.Message.General.ServerError.Title'),
            content: error.message,
          });
        }
      }
    );
  };

  componentWillUnmount = () => {
    this.stopStatePropagation = false;
    //this.nodeServices.unsuscribe("Breadcrumb");
  };

  render = () => {
    if (
      window.location.pathname === SOFTWARE_INFO ||
      window.location.pathname === SETTINGS ||
      window.location.pathname === LOGOUT
    ) {
      return null;
    } else
      return (
        <Space className="breadcrumbSpace">
          <SisternodeOutlined />
          <Breadcrumb className="breadcrumb">
            {this.state.breadcrumb.map((crumb, i) => (
              <Breadcrumb.Item
                key={i}
                onClick={() => this.onBreadcrumbItemClick(crumb)}
              >
                {crumb.name}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </Space>
      );
  };
}

export default withTranslation()(_Breadcrumb);
