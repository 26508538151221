import HttpServices from './http.services';

class ValidSignServices {
  constructor(envSettings) {
    this.envSettings = envSettings;
    this.httpService = new HttpServices(this.envSettings);
  }

  checkError = (res) => {
    if (res.data.error) {
      return true;
    } else {
      return false;
    }
  };

  getError = (res) => {
    return {
      code: res.data.error.code,
      message: res.data.error.message,
    };
  };

  getResult = (res) => {
    return res.data.result;
  };

  getFoldersStarted = (url, requestConfig) => {
    return this.httpService.httpServiceCustomGet(url, requestConfig);
  };

  getSignatureStarted= (url, requestConfig) => {
    return this.httpService.httpServiceCustomGet(url, requestConfig);
  };
}

export default ValidSignServices;
