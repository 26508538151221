import { combineReducers } from 'redux';
import menuStateManager from './menu-states-manager';
import globalSettingsManager from './global-settings-manager';
import modalManager from './modal-manager';
import userManager from './user-manager';
import selectedNodeManager from './selected-node-manager';
import treeManager from './tree-manager';
import breadcrumbStateManager from './breadcrumb-states-manager';
import reloadPageManager from './reload-page-manager';
import defaultUrlManager from './defaut-url-manager';
import refreshTreeItemManager from './refresh-tree-item-manager';

const reducersApp = combineReducers({
  globalSettingsManager,
  menuStateManager,
  modalManager,
  userManager,
  selectedNodeManager,
  treeManager,
  breadcrumbStateManager,
  reloadPageManager,
  defaultUrlManager,
  refreshTreeItemManager,
});

export default reducersApp;
