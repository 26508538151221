const defaultSettings = {
  DefaultEnvironment: '',
  OflineMode: false,
  Environment: {},
  PagePermissionManager: {},
  MenuManager: [],
};

const globalSettingsManager = (
  state = {
    isValidSettings: true,
    settingsError: false,
    globalSettings: defaultSettings,
  },
  action
) => {
  let nextState = state;

  switch (action.type) {
    case 'SET_GLOBAL_SETTING':
      nextState = {
        isValidSettings: true,
        settingsError: false,
        globalSettings: action.newState,
      };
      return nextState;
    case 'GET_GLOBAL_SETTING_ERROR':
      nextState = {
        isValidSettings: false,
        settingsError: true,
        globalSettings: defaultSettings,
      };
      return nextState;

    default:
      return nextState;
  }
};

export default globalSettingsManager;
