import { connect } from 'react-redux';
import { setSelectedNode } from '../../../actions/selected-node-manager';
import {
  removeLeafProp,
  setNode,
  setTree,
} from '../../../actions/tree-manager';
import Overview from './Overview';

const mapStateToProps = (state) => ({
  globalSettings: state.globalSettingsManager.globalSettings,
  selectedNode: state.selectedNodeManager.node,
  mainColor: state.userManager.mainColor,
  roles: state.userManager.user.roles,
  tree: state.treeManager.tree,
});

const mapDispatchToProps = (dispatch) => ({
  setNode: (newNode) => dispatch(setNode(newNode)),
  setSelectedNode: (node) => dispatch(setSelectedNode(node)),
  removeLeafProp: (node) => dispatch(removeLeafProp(node)),
  setTree: (tree) => dispatch(setTree(tree)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
