const reloadPageManager = (state = { reloadPage: false }, action) => {
  let nextState = state;

  switch (action.type) {
    case 'START_RELOAD_PAGE':
      nextState = {
        reloadPage: true,
      };
      return nextState;

    case 'STOP_RELOAD_PAGE':
      nextState = {
        reloadPage: false,
      };
      return nextState;

    default:
      return nextState;
  }
};

export default reloadPageManager;
