import { connect } from 'react-redux';
import { setDefaultUrl, setCurrentPage } from './actions/default-url-manager';
import {
  getGlobalSettingError,
  setGlobalSetting,
} from './actions/global-setting-manager';
import { setSelectedNode } from './actions/selected-node-manager';
import {
  getUserError,
  setIsLogoLoading,
  setLogo,
  setMainColor,
  setUser,
} from './actions/user-manager';
import App from './App';

const mapStateToProps = (state) => ({
  globalSettings: state.globalSettingsManager.globalSettings,
  isValidSettings: state.globalSettingsManager.isValidSettings,
  settingsError: state.globalSettingsManager.settingsError,
  isValidUser: state.userManager.isValidUser,
  userError: state.userManager.userError,
  user: state.userManager.user,
  selectedNode: state.selectedNodeManager.node,
  roleList: state.userManager.user.roles,
  rootNode: state.userManager.user.accountInfo
    ? state.userManager.user.accountInfo.nodeData
    : state.selectedNodeManager.node,
});

const mapDispatchToProps = (dispatch) => ({
  setGlobalSettings: (settings) => dispatch(setGlobalSetting(settings)),
  getGlobalSettingError: () => dispatch(getGlobalSettingError()),
  setUser: (newUserInfo, newRoleList, newAccountInfo) =>
    dispatch(setUser(newUserInfo, newRoleList, newAccountInfo)),
  getUserError: () => dispatch(getUserError()),
  setCurrentPage: (payload) => dispatch(setCurrentPage(payload)),
  setSelectedNode: (selectedNode) => dispatch(setSelectedNode(selectedNode)),
  setDefaultUrl: (url) => dispatch(setDefaultUrl(url)),
  setMainColor: (color) => dispatch(setMainColor(color)),
  setLogo: (logo) => dispatch(setLogo(logo)),
  setIsLogoLoading: (isLogoLoading) =>
    dispatch(setIsLogoLoading(isLogoLoading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
