import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TreeManager from './TreeManager';
import { openModalInfo } from '../../actions/modal-manager';
import { setTree } from '../../actions/tree-manager';
import { setSelectedNode } from '../../actions/selected-node-manager';

const mapStateToProps = (state) => ({
  globalSettings: state.globalSettingsManager.globalSettings,
  selectedNode: state.selectedNodeManager.node,
  tree: state.treeManager.tree,
  defaultUrl: state.defaultUrlManager.url,
  mainColor: state.userManager.mainColor,
  rootNode: state.userManager.user.accountInfo
    ? state.userManager.user.accountInfo.nodeData
    : { nodeId: 1, nodeName: 'Base' },
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (isOpen, title, message) =>
    dispatch(openModalInfo(isOpen, title, message)),
  setTree: (newTree) => dispatch(setTree(newTree)),
  setSelectedNode: (selectedNode) => dispatch(setSelectedNode(selectedNode)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TreeManager)
);
