import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Layout,
  message,
  Modal,
  notification,
  Row,
  Skeleton,
  Spin,
  Switch,
  Typography,
} from 'antd';
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import './styles.less';
import { useEffect, useState } from 'react';
import EditCompanyModal from '../../../components/Modals/EditCompanyModal';
import { VALIDSIGN_USER_MANAGEMENT } from '../../../constants/routes';
import { useHistory } from 'react-router-dom';
import ServiceServices from '../../../services/service.services';
import _ from 'lodash';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import ValidSignServices from '../../../services/validsign.services';
import NodeServices from '../../../services/node.services';
import AppUtil from '../../../utility/admin.util';
import ValidSignFoldersCounter from './components/ValidSignFoldersCounter';
import ValidSignSignatureCounter from './components/ValidSignSignatureCounter';

const { Title } = Typography;

function Overview({
  t,
  selectedNode,
  globalSettings,
  mainColor,
  roles,
  setTree,
  tree,
  setSelectedNode,
  removeLeafProp,
}) {
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [maxFolders, setMaxFolders] = useState(0);
  const [foldersStarted, setFoldersStarted] = useState(0);
  const [maxSignature, setMaxSignature] = useState(0);
  const [signatureStarted, setSignatureStarted] = useState(0);
  const [workingNode, setWorkingNode] = useState({});
  const [overviewIsLoaded, setOverviewIsLoaded] = useState(false);
  const [foldersError, setFoldersError] = useState(false);
  const [signatureError, setSignatureError] = useState(false);
  const [isModalRendered, setIsModalRendered] = useState(false);
  const [maxFoldersAttribute, setMaxFoldersAttribute] = useState();
  const [maxSignaturesAttribute, setMaxSignaturesAttribute] = useState();
  const [showSignature, setShowSignature] = useState(false);
  const percentage = foldersStarted / maxFolders;
  const signaturePercentage = signatureStarted / maxSignature;

  const showModal = () => {
    setIsModalRendered(true);
    setIsModalVisible(true);
  };

  const onSuccessNodeUpdated = (env) => {
    message.success(
      t('PageContainer.Validsign.Overview.EditCompany.Notification.Success')
    );
    setIsModalRendered(false);
    setIsModalVisible(false);
    getNode(env);
  };

  let env;
  if (_.isString(process.env.REACT_APP_ENV)) {
    env = process.env.REACT_APP_ENV;
  } else {
    env = globalSettings.DefaultEnvironment;
  }
  const nodeServices = new NodeServices(globalSettings.Environment[env]);
  const serviceServices = new ServiceServices(globalSettings.Environment[env]);
  const retryableInvervals = globalSettings.Environment[env].envConstants
    ?.retryableIntervals || [8000, 16000, 32000, 64000];

  const handleOk = (data) => {

    if (!selectedNode.nodeId) {
      return;
    }

    nodeServices.updateNode(selectedNode.nodeId, data.companyName).then(
      (response) => {
        if (response?.data) {
          if (nodeServices.checkError(response)) {
            const error = nodeServices.getError(response);

            Modal.error({
              title: t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          } else {
            // if (canEditMaxFolders) {
            //   serviceServices
            //     .updateServiceAttribute(
            //       maxFoldersAttribute?.attributeId,
            //       maxFoldersAttribute?.attributeKey,
            //       data.maxFolders
            //     )
            //     .then(
            //       (response) => {
            //         if (response?.data) {
            //           if (serviceServices.checkError(response)) {
            //             const error = serviceServices.getError(response);

            //             Modal.error({
            //               title: t('App.Message.General.ServerError.Title'),
            //               content: error.message,
            //             });
            //           } else {
            //             setMaxFolders(data.maxFolders);
            //             onSuccessNodeUpdated(env);
            //           }
            //         }
            //       },
            //       (error) => {
            //         if (axios.isCancel(error)) {
            //           //this.logFirer.fireLog('LOG', error);
            //         } else {
            //           Modal.error({
            //             title: t('App.Message.General.ServerError.Title'),
            //             content: error.message,
            //           });
            //         }
            //       }
            //     );
            // } else {
              onSuccessNodeUpdated(env);
            // }
          }
        }
      },
      (error) => {
        if (axios.isCancel(error)) {
          //this.logFirer.fireLog('LOG', error);
        } else {
          Modal.error({
            title: t('App.Message.General.ServerError.Title'),
            content: error.message,
          });
        }
      }
    );
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setIsModalRendered(false);
  };

  // IG SIGN manager
  const filteredRoles = roles.filter((r) => {
    return !_.isUndefined(r.attributes['validsign_manager']);
  });
  const permissionList =
    globalSettings.PagePermissionManager['validsign_manager'] || {};
  const rightInfoList = filteredRoles[0]
    ? filteredRoles[0].attributes['validsign_manager'].rightInfo || []
    : [];

  // service manager
  const filteredServiceRoles = roles.filter((r) => {
    return !_.isUndefined(r.attributes['service_manager']);
  });
  let serviceRightInfoList = filteredServiceRoles[0]
    ? filteredServiceRoles[0].attributes['service_manager'].rightInfo || []
    : [];
  const servicePermissionList =
    globalSettings.PagePermissionManager['service_manager'] || {};
  serviceRightInfoList = filteredServiceRoles[0]
    ? filteredRoles[0].attributes['service_manager'].rightInfo || []
    : [];

  let validsignRightInfo;

  if (_.isEmpty(permissionList)) {
    history.push('/app/fallback');
    validsignRightInfo = {
      createNewCompany: false,
      createNewCompanyCloud: false,
      updateServiceAttribute: false,
    };
  } else {
    validsignRightInfo = {
      createNewCompany: AppUtil.getPermission(
        permissionList.pagePermission,
        permissionList.allPagePesmissionAllowed,
        rightInfoList,
        'createNewCompany'
      ),
      createNewCompanyCloud: AppUtil.getPermission(
        permissionList.pagePermission,
        permissionList.allPagePesmissionAllowed,
        rightInfoList,
        'createNewCompanyCloud'
      ),
      updateServiceAttribute: AppUtil.getPermission(
        servicePermissionList.pagePermission,
        servicePermissionList.allPagePesmissionAllowed,
        serviceRightInfoList,
        'updateServiceAttribute'
      ),
      createAccount: AppUtil.getPermission(
        permissionList.pagePermission,
        permissionList.allPagePesmissionAllowed,
        rightInfoList,
        'createAccount'
      ),
    };
  }

  // FORM
  const [form] = Form.useForm();
  const [companyId, setCompanyId] = useState();
  const [isNewCompanyLoading, setIsNewCompanyLoading] = useState(false);
  const onCompanyIdChange = (e) => {
    if (!companyId?.length && /\d/.test(e.target.value)) {
      setCompanyId('');
      form.resetFields(['companyId']);
    } else {
      setCompanyId(e.target.value.toUpperCase().replace(/ /g, ''));
    }
  };

  let createNodeIntervalIndex = -1;
  const onFinish = (values) => {
    setIsNewCompanyLoading(true);

    const { companyName } = values;
    const _companyId = values?.companyId.toUpperCase().replace(/ /g, '');

    nodeServices
      .createNode(selectedNode?.nodeId, _companyId, companyName.trim())
      .then(
        (response) => {
          if (response?.data) {
            if (nodeServices.checkError(response)) {
              const error = nodeServices.getError(response);

              if (error.code === 17155) {
                createNodeIntervalIndex = createNodeIntervalIndex + 1;

                if (!retryableInvervals[createNodeIntervalIndex]) {
                  Modal.error({
                    title: t('App.Message.General.ServerError.Title'),
                    content: error.message,
                  });

                  setIsNewCompanyLoading(false);
                } else {
                  const indexDesc =
                    createNodeIntervalIndex <= 2 ? createNodeIntervalIndex : 2;

                  notification.info({
                    message: t('Notification.Info.RetryableOperation.Message'),
                    description: t(
                      `Notification.Info.RetryableOperation.Description${indexDesc}`
                    ),
                  });

                  setTimeout(
                    () => onFinish(values),
                    retryableInvervals[createNodeIntervalIndex]
                  );
                }
              } else {
                Modal.error({
                  title: t('App.Message.General.ServerError.Title'),
                  content: error.message,
                });

                setIsNewCompanyLoading(false);
              }
            } else {
              const result = nodeServices.getResult(response);

              if (result) {
                createService(_companyId, result.id);
              }
            }
          }
        },
        (error) => {
          setIsNewCompanyLoading(false);

          if (axios.isCancel(error)) {
          } else {
            Modal.error({
              title: t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };

  let createEnablerContextIndex = -1;
  const createEnablerContext = (nodeId, sid) => {
    serviceServices
      .createServiceAttribute(nodeId, sid, 'ENABLER_CONTEXT', 'validsign_cloud')
      .then(
        (response) => {
          if (response?.data) {
            if (serviceServices.checkError(response)) {
              const error = serviceServices.getError(response);

              if (error.code === 17155) {
                createEnablerContextIndex = createEnablerContextIndex + 1;

                if (!retryableInvervals[createEnablerContextIndex]) {
                  Modal.error({
                    title: t('App.Message.General.ServerError.Title'),
                    content: error.message,
                  });
                } else {
                  const indexDesc =
                    createEnablerContextIndex <= 2
                      ? createEnablerContextIndex
                      : 2;

                  notification.info({
                    message: t('Notification.Info.RetryableOperation.Message'),
                    description: t(
                      `Notification.Info.RetryableOperation.Description${indexDesc}`
                    ),
                  });

                  setTimeout(
                    () => createEnablerContext(nodeId, sid),
                    retryableInvervals[createEnablerContextIndex]
                  );
                }
              } else {
                Modal.error({
                  title: t('App.Message.General.ServerError.Title'),
                  content: error.message,
                });
              }
            } else {
              const result = serviceServices.getResult(response);

              if (result) {
                setCurrentNode(nodeId);
              }
            }
          }
        },
        (error) => {
          if (axios.isCancel(error)) {
          } else {
            Modal.error({
              title: t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };

  let createServiceIndex = -1;
  const createService = (companyId, nodeId) => {
    serviceServices
      .createService(nodeId, 'ValidSign', `ValidSign for customer ${companyId}`)
      .then(
        (response) => {
          if (response?.data) {
            if (serviceServices.checkError(response)) {
              const error = serviceServices.getError(response);

              if (error.code === 17155) {
                createServiceIndex = createServiceIndex + 1;

                if (!retryableInvervals[createServiceIndex]) {
                  Modal.error({
                    title: t('App.Message.General.ServerError.Title'),
                    content: error.message,
                  });

                  setIsNewCompanyLoading(false);
                } else {
                  const indexDesc =
                    createServiceIndex <= 2 ? createServiceIndex : 2;

                  notification.info({
                    message: t('Notification.Info.RetryableOperation.Message'),
                    description: t(
                      `Notification.Info.RetryableOperation.Description${indexDesc}`
                    ),
                  });

                  setTimeout(
                    () => createService(companyId, nodeId),
                    retryableInvervals[createServiceIndex]
                  );
                }
              } else {
                Modal.error({
                  title: t('App.Message.General.ServerError.Title'),
                  content: error.message,
                });

                setIsNewCompanyLoading(false);
              }
            } 
            
            
            else {
              /*
              const result = serviceServices.getResult(response);

              if (result) {
                createServiceAttribute(nodeId, result.id, maxFolders);
              }
              */
              setTimeout(
                () => setCurrentNode(nodeId), 10000);
            }
          }
        },
        (error) => {
          setIsNewCompanyLoading(false);

          if (axios.isCancel(error)) {
            //this.logFirer.fireLog('LOG', error);
          } else {
            Modal.error({
              title: t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };

  let createServiceAttributeIndex = -1;
/*
  const createServiceAttribute = (nodeId, sid, maxFoldersAvailable) => {
    serviceServices
      .createServiceAttribute(
        nodeId,
        sid,
        'MAX_FOLDER_AVAILABLE',
        parseInt(maxFoldersAvailable, 10)
      )
      .then(
        (response) => {
          if (response?.data) {
            if (serviceServices.checkError(response)) {
              const error = serviceServices.getError(response);

              if (error.code === 17155) {
                createServiceAttributeIndex = createServiceAttributeIndex + 1;

                if (!retryableInvervals[createServiceAttributeIndex]) {
                  Modal.error({
                    title: t('App.Message.General.ServerError.Title'),
                    content: error.message,
                  });

                  setIsNewCompanyLoading(false);
                } else {
                  const indexDesc =
                    createServiceAttributeIndex <= 2
                      ? createServiceAttributeIndex
                      : 2;

                  notification.info({
                    message: t('Notification.Info.RetryableOperation.Message'),
                    description: t(
                      `Notification.Info.RetryableOperation.Description${indexDesc}`
                    ),
                  });

                  setTimeout(
                    () =>
                      createServiceAttribute(nodeId, sid, maxFoldersAvailable),
                    retryableInvervals[createServiceAttributeIndex]
                  );
                }
              } else {
                Modal.error({
                  title: t('App.Message.General.ServerError.Title'),
                  content: error.message,
                });

                setIsNewCompanyLoading(false);
              }
            } else {
              const result = serviceServices.getResult(response);

              if (result) {
                // if (form.getFieldValue('newCompanyCloudToggle')) {
                //   createEnablerContext(nodeId, sid);
                // } else {
                  setCurrentNode(nodeId);
                // }
              }
            }
          }
        },
        (error) => {
          setIsNewCompanyLoading(false);

          if (axios.isCancel(error)) {
          } else {
            Modal.error({
              title: t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };
*/

  const setCurrentNode = (nodeId) => {
    const isSelectedNodeLeaf = tree.find(
      (node) => node.id === selectedNode.nodeId
    )?.isLeaf;

    if (isSelectedNodeLeaf) {
      removeLeafProp(selectedNode.nodeId);
    } /* else {
      if(tree.length > 1) {
        const newNode = {
          id: nodeId,
          pId: selectedNode?.nodeId,
          value: companyId,
          title: companyId.trim(),
          isLeaf: true,
        };

        setNode(newNode);
      }
    }*/

    nodeServices.getNodeChildren(nodeId).then(
      (response) => {
        if (response?.data) {
          if (nodeServices.checkError(response)) {
            const error = nodeServices.getError(response);

            Modal.error({
              title: t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          } else {
            const result = nodeServices.getResult(response);
            let newTree = [];

            result.childs.forEach((child) =>
              newTree.push({
                id: child.nodeId,
                pId: child.parentId,
                value: child.name,
                key: child.name,
                title: child.name,
                ...(child.children <= 0 && {
                  isLeaf: true,
                }),
              })
            );

            setTree(newTree);
            setSelectedNode({
              nodeId: nodeId,
              nodeName: companyId?.replace(/ /g, ''),
            });
          }
        }
      },
      (error) => {
        if (axios.isCancel(error)) {
        } else {
          Modal.error({
            title: t('App.Message.General.ServerError.Title'),
            content: error.message,
          });
        }
      }
    );

    form.resetFields();

    const notificationKey = `open${Date.now()}`;

    notification.open({
      message: t(
        'PageContainer.Validsign.Overview.CreateNewCompany.Notification.Success.Message'
      ),
      key: notificationKey,
      description: t(
        'PageContainer.Validsign.Overview.CreateNewCompany.Notification.Success.Description',
        { companyId }
      ),
      ...(validsignRightInfo.createAccount && {
        btn: (
          <Button
            type="primary"
            size="small"
            onClick={() => {
              notification.close(notificationKey);
              history.push(VALIDSIGN_USER_MANAGEMENT);
            }}
          >
            {t(
              'PageContainer.Validsign.Overview.CreateNewCompany.Notification.Success.Button'
            )}
          </Button>
        ),
      }),
    });

    setIsNewCompanyLoading(false);
  };

  const getNode = (env) => {
    const nodeServices = new NodeServices(globalSettings.Environment[env]);

    nodeServices.getNode(selectedNode?.nodeId).then(
      (response) => {
        if (response?.data) {
          if (nodeServices.checkError(response)) {
            const error = nodeServices.getError(response);

            Modal.error({
              title: t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          } else {
            const result = nodeServices.getResult(response);

            if (result) {
              setWorkingNode(result);

              const serviceServices = new ServiceServices(
                globalSettings.Environment[env]
              );

              serviceServices.getNodeServices(selectedNode?.nodeId).then(
                (response) => {
                  if (response?.data) {
                    if (serviceServices.checkError(response)) {
                      const error = serviceServices.getError(response);

                      Modal.error({
                        title: t('App.Message.General.ServerError.Title'),
                        content: error.message,
                      });
                    } else {
                      const result = serviceServices.getResult(response);

                      if (result) {
                        if (result.services.length > 0) {
                          const validSignServiceID = result.services.find(
                            (serv) => serv.name === 'ValidSign'
                          );

                          if (validSignServiceID) {
                            getServiceAttributes(validSignServiceID.sid);
                          } else {
                            setOverviewIsLoaded(true);
                            setFoldersError(true);
                          }
                        } else {
                          setOverviewIsLoaded(true);
                          setFoldersError(true);
                        }
                      } else {
                        setOverviewIsLoaded(true);
                        setFoldersError(true);
                      }
                    }
                  }
                },
                (error) => {
                  setOverviewIsLoaded(true);
                  setFoldersError(true);
                  if (axios.isCancel(error)) {
                  } else {
                    Modal.error({
                      title: t('App.Message.General.ServerError.Title'),
                      content: error.message,
                    });
                  }
                }
              );
            }
          }
        } else {
          setOverviewIsLoaded(true);
          setFoldersError(true);
          setWorkingNode({});
        }
      },
      (error) => {
        setOverviewIsLoaded(true);
        setFoldersError(true);
        setWorkingNode({});

        if (axios.isCancel(error)) {
          //this.logFirer.fireLog('LOG', error);
        } else {
          Modal.error({
            title: t('App.Message.General.ServerError.Title'),
            content: error.message,
          });
        }
      }
    );
  };

  const getServiceAttributes = (validSignServiceID) => {
    serviceServices
      .getServiceAttributes(selectedNode?.nodeId, validSignServiceID, true)
      .then(
        (response) => {
          if (response?.data) {
            if (serviceServices.checkError(response)) {
              const error = serviceServices.getError(response);

              Modal.error({
                title: t('App.Message.General.ServerError.Title'),
                content: error.message,
              });

              setOverviewIsLoaded(true);

              /*this.setState({
                attributeList: [],
                loadedAttributeList: true,
              });*/
            } else {
              const result = serviceServices.getResult(response);
              const attributes = result.attributes;

              if (result?.countRows) {
                // get max folder
                const maxFolders = attributes.find(
                  (attr) => attr.attributeKey === 'MAX_FOLDER_AVAILABLE'
                )?.attributeValue;
                setMaxFoldersAttribute(
                  attributes.find(
                    (attr) => attr.attributeKey === 'MAX_FOLDER_AVAILABLE'
                  )
                );
                const baseUrl = attributes.find(
                  (attr) => attr.attributeKey === 'BASE_URL'
                )?.attributeValue;
                const jwtToken = attributes.find(
                  (attr) => attr.attributeKey === 'JWT_TOKEN'
                )?.attributeValue;

                const enableSPIDFlow = attributes.find(
                  (attr) => attr.attributeKey === 'ENABLE_SPID_FLOW'
                )?.attributeValue;
                setShowSignature(enableSPIDFlow);
                
                // get max spid signature
                const maxSignature = attributes.find(
                  (attr) => attr.attributeKey === 'MAX_SPID_SIGN_AVAILABLE'
                )?.attributeValue;
                setMaxSignaturesAttribute(
                  attributes.find(
                    (attr) => attr.attributeKey === 'MAX_SPID_SIGN_AVAILABLE'
                  )
                );

                if (baseUrl && jwtToken) {
                  const validSignServices = new ValidSignServices();

                  // folders started
                  validSignServices
                    .getFoldersStarted(
                      // `${baseUrl}/be/foldersApi?node_context=${selectedNode?.nodeName}`,
                      `${baseUrl}/be/counter?type=folder&node_context=${selectedNode?.nodeName}`,
                      {
                        headers: {
                          authorization: `Bearer ${jwtToken}`,
                        },
                      }
                    )
                    .then((response) => {
                      if (response?.data) {
                        const counter = response.data[0];

                        setFoldersStarted(counter.counter);
                        setFoldersError(false);
                      }
                    })
                    .catch((err) => {
                      setFoldersError(true);
                    });


                  // signature started TODO: set right API url (se l'url ritorna un errore mettere NA anziché )
                  if (enableSPIDFlow) {
                    validSignServices
                      .getSignatureStarted(
                        // `${baseUrl}/be/counter?node_context=${selectedNode?.nodeName}&type=SPID_SIGN`,
                        `${baseUrl}/be/counter?type=spid_sign&node_context=${selectedNode?.nodeName}`,
                        {
                          headers: {
                            authorization: `Bearer ${jwtToken}`,
                          },
                        }
                      )
                      .then((response) => {
                        if (response?.data) {
                          const counter = response.data[0];

                          setSignatureStarted(counter.counter);
                          setSignatureError(false);
                        }

                      })
                      .catch((err) => {
                        setSignatureError(true);
                      });
                  }
                } else {
                  setFoldersError(true);
                  setSignatureError(true);
                }

                setOverviewIsLoaded(true);

                if (maxFolders) {
                  setMaxFolders(parseInt(maxFolders, 10));
                }

                if (maxSignature) {
                  setMaxSignature(parseInt(maxSignature, 10));
                }
              } else {
                setOverviewIsLoaded(true);
              }
            }
          }
        },
        (error) => {
          setOverviewIsLoaded(true);

          if (axios.isCancel(error)) {
            //this.logFirer.fireLog('LOG', error);
          } else {
            setFoldersError(true);
            setSignatureError(true);
            Modal.error({
              title: t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };

  useEffect(() => {
    let env;

    if (!selectedNode.nodeId) {
      return;
    }

    if (_.isString(process.env.REACT_APP_ENV)) {
      env = process.env.REACT_APP_ENV;
    } else {
      env = globalSettings.DefaultEnvironment;
    }

    setMaxFolders(0);
    setMaxSignature(0);
    setFoldersStarted(0);
    setSignatureStarted(0);
    setFoldersError(false);
    setSignatureError(false);
    setOverviewIsLoaded(false);

    getNode(env);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode]);

  return (
    <div className="overview">
      <Title level={3}>{t('PageContainer.Validsign.Overview.Title')}</Title>
      <Row gutter={16}>
        <Col xs={24} lg={12}>
          <Layout className="overview-node">
            <Row justify="space-between" align="middle">
              <Col>
                <span
                  className="overview-node-title"
                  style={{ color: mainColor }}
                >
                  {workingNode.name || <Skeleton active />}
                </span>
              </Col>
              <Col>
                <Button
                  type="primary"
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={showModal}
                />
              </Col>
            </Row>
            <p>{workingNode.description || <Skeleton active />}</p>
            <ValidSignFoldersCounter
              percentage={percentage}
              overviewIsLoaded={overviewIsLoaded}
              foldersStarted={foldersStarted}
              maxFolders={maxFolders}
            />
            { showSignature && (
              <ValidSignSignatureCounter
                percentage={signaturePercentage} // % value
                overviewIsLoaded={overviewIsLoaded}
                signatureStarted={signatureStarted} // started value
                maxSignature={maxSignature} // max value
              />
            )}
          </Layout>
        </Col>
        <Col xs={24} lg={12}>
          {validsignRightInfo.createNewCompany ||
          validsignRightInfo.createNewCompanyCloud ? (
            <Layout className="overview-newCompany">
              <Spin spinning={isNewCompanyLoading}>
                <span
                  className="overview-newCompany-title"
                  style={{ color: mainColor }}
                >
                  {t('PageContainer.Validsign.Overview.CreateNewCompany.Title')}
                </span>
                <Form
                  layout="vertical"
                  form={form}
                  requiredMark="optional"
                  onFinish={onFinish}
                >
                  <Row gutter={16}>
                    <Col xs={24}>
                      <Form.Item
                        label={t(
                          'PageContainer.Validsign.Overview.CreateNewCompany.Form.CompanyId.Label'
                        )}
                        name="companyId"
                        rules={[
                          {
                            required: true,
                            message: t('General.MandatoryField.Text'),
                          },
                        ]}
                        tooltip={{
                          title: t(
                            'PageContainer.Validsign.Overview.CreateNewCompany.Form.CompanyId.Tooltip'
                          ),
                          icon: <InfoCircleOutlined />,
                        }}
                        required
                      >
                        <Input
                          value={companyId}
                          style={{
                            textTransform: 'uppercase',
                          }}
                          onChange={onCompanyIdChange}
                          placeholder={t(
                            'PageContainer.Validsign.Overview.CreateNewCompany.Form.CompanyId.Placeholder'
                          )}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <Form.Item
                        label={t(
                          'PageContainer.Validsign.Overview.CreateNewCompany.Form.CompanyName.Label'
                        )}
                        name="companyName"
                        rules={[
                          {
                            required: true,
                            message: t('General.MandatoryField.Text'),
                          },
                        ]}
                        required
                      >
                        <Input
                          placeholder={t(
                            'PageContainer.Validsign.Overview.CreateNewCompany.Form.CompanyName.Placeholder'
                          )}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="form-button" justify="end">
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        {t(
                          'PageContainer.Validsign.Overview.CreateNewCompany.Form.Submit'
                        )}
                      </Button>
                    </Form.Item>
                  </Row>
                </Form>
              </Spin>
            </Layout>
          ) : null}
        </Col>
      </Row>
      {isModalRendered && (
        <EditCompanyModal
          data={{
            foldersStarted,
            companyName: workingNode?.description,
            companyId: workingNode?.name,
          }}
          isModalVisible={isModalVisible}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
      )}
    </div>
  );
}

export default withTranslation()(Overview);
