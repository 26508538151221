import LeftMenu from './LeftMenu';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  state,
  currentPage: state?.defaultUrlManager?.currentPage,
  roleList: state.userManager.user.roles,
  globalSettings: state.globalSettingsManager?.globalSettings,
  logo: state.userManager.logo,
  isLogoLoading: state.userManager.isLogoLoading,
});

export default connect(mapStateToProps, null)(LeftMenu);
