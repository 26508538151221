const menuStateManager = (
  state = { leftMenuActive: true, rightMenuActive: true },
  action
) => {
  let nextState = state;

  switch (action.type) {
    case 'CHANGE_LEFT_MENU_STATE':
      if (window.innerWidth <= 960) {
        nextState = {
          leftMenuActive: action.newState,
          rightMenuActive: false,
        };
      } else {
        nextState = {
          leftMenuActive: action.newState,
          rightMenuActive: state.rightMenuActive,
        };
      }
      return nextState;

    case 'CHANGE_RIGHT_MENU_STATE':
      if (window.innerWidth <= 960) {
        nextState = {
          leftMenuActive: false,
          rightMenuActive: action.newState,
        };
      } else {
        nextState = {
          leftMenuActive: state.leftMenuActive,
          rightMenuActive: action.newState,
        };
      }
      return nextState;

    case 'CHANGE_BACKDROP_MENU_STATE':
      if (window.innerWidth <= 1200) {
        nextState = {
          leftMenuActive: state.leftMenuActive,
          rightMenuActive: false,
        };
      }
      if (window.innerWidth <= 960) {
        nextState = {
          leftMenuActive: false,
          rightMenuActive: false,
        };
      }

      return nextState;

    default:
      return nextState;
  }
};

export default menuStateManager;
