import _, { isEmpty, isNil } from 'lodash';
import {
  ToolOutlined,
  PieChartOutlined,
  FormOutlined,
  KeyOutlined,
  ProfileOutlined,
  SettingOutlined,
} from '@ant-design/icons';

export const getStatus = (value, t) => {
  switch (value) {
    case 0:
      return {
        value: t('PageContainer.Accounts.AccountStatus.STATUS_NOT_ACTIVE'),
        color: 'red',
      };
    case 1:
      return {
        value: t('PageContainer.Accounts.AccountStatus.STATUS_ACTIVE'),
        color: 'green',
      };
    case 2:
      return {
        value: t(
          'PageContainer.Accounts.AccountStatus.STATUS_NOT_ACTIVE_PWD_RESET_PENDING'
        ),
        color: 'rose',
      };
    case 3:
      return {
        value: t(
          'PageContainer.Accounts.AccountStatus.STATUS_ACTIVE_PWD_RESET_PENDING'
        ),
        color: 'coral',
      };
    case 4:
      return {
        value: t('PageContainer.Accounts.AccountStatus.STATUS_DEACTIVATE'),
        color: 'darkcyan',
      };
    case 5:
      return {
        value: t(
          'PageContainer.Accounts.AccountStatus.STATUS_DEACTIVATE_PWD_RESET_PENDING'
        ),
        color: 'darkgrey',
      };
    case 6:
      return {
        value: t('PageContainer.Accounts.AccountStatus.STATUS_SUSPENDED'),
        color: 'darkmagenta',
      };
    case 7:
      return {
        value: t(
          'PageContainer.Accounts.AccountStatus.STATUS_NOT_ACTIVE_CONFIRM_CONTACT_PENDING'
        ),
        color: 'darkslategrey',
      };
    case 8:
      return {
        value: t('PageContainer.Accounts.AccountStatus.STATUS_REVOKED'),
        color: 'darkkhaki',
      };
    case 9:
      return {
        value: t('PageContainer.Accounts.AccountStatus.STATUS_PENDING'),
        color: 'orange',
      };
    default:
      return {
        value: t('PageContainer.Accounts.AccountStatus.STATUS_NOT_MAPPED'),
        color: 'black',
      };
  }
};

export const allowsOnlyNumbers = (e) => {
  const charCode = e.keyCode || e.which;

  if (charCode > 31 && (charCode < 48 || charCode > 57)) e.preventDefault();
};

export const formatData = (data) => {
  const year = data.substring(0, 4);
  const month = data.substring(4, 6);
  const day = data.substring(6, 8);
  const timeh = data.substring(8, 10);
  const timem = data.substring(10, 12);
  const languageUsed = localStorage.getItem('i18nextLng') || 'en';

  if (languageUsed.toLowerCase() === 'it')
    return `${day}/${month}/${year} ${timeh}:${timem}`;
  else if (languageUsed.toLowerCase() === 'en')
    return `${year}/${month}/${day} ${timeh}:${timem}`;
  else return `${day}/${month}/${year} ${timeh}:${timem}`;
};

export const isNilOrEmpty = (val) => isNil(val) || isEmpty(val);

const checkPermission = (item, roleList) => {
  let checked = false;

  if (item.allUserAllowed) {
    checked = true;
  }

  const adminPortalRole = roleList.find((role) =>
    role.attributes.hasOwnProperty('admin_portal')
  );

  roleList.forEach((role) => {
    if (
      item.key === 'DASHBOARD' &&
      !adminPortalRole?.attributes.admin_portal.rightInfo.includes('basic')
    ) {
      return (checked = false);
    }

    if (
      role.name === item.accessPermission.role ||
      item.accessPermission.role === 'ALL'
    ) {
      if (
        !_.isEmpty(role.attributes[item.accessPermission.profile]) &&
        !item?.disableWhenRightInfo?.some((el) =>
          role.attributes[item.accessPermission.profile].rightInfo.includes(el)
        )
      ) {
        return (checked = true);
      }
    }
  });

  return checked;
};
export const getLeftMenuItems = (globalSettings, roleList) => {
  let itemList = [];

  const mapIcon = {
    DASHBOARD: <PieChartOutlined />,
    OAUTH_CONSOLE: <SettingOutlined />,
    CONFIGURATIONS: <ToolOutlined />,
    PROFILES: <ProfileOutlined />,
    T4IDADMIN: <KeyOutlined />,
    VALIDSIGN: <FormOutlined />,
  };

  globalSettings.MenuManager.forEach((configItem) => {
    let newItem;

    if (checkPermission(configItem, roleList)) {
      newItem = {
        isDisabled: configItem.isDisabled,
        isVisible: configItem.isVisible,
        nodeRelated: configItem.nodeRelated,
        url: configItem.url,
        key: configItem.key,
        allUserAllowed: configItem.allUserAllowed,
        accessPermission: configItem.accessPermission,
        icon: mapIcon[configItem.key],
      };
      if (configItem.submenu) {
        newItem.submenu = [];
        configItem.submenu.forEach((item) => {
          if (checkPermission(item, roleList)) {
            if (newItem.submenu) {
              newItem.submenu.push(item);
            }
          }
        });
      }
      itemList.push(newItem);
    }
  });
  return itemList;
};
