import {
  Alert,
  Button,
  Col,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
  Switch,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import {
  PlusOutlined,
  GlobalOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Component } from 'react';
import './styles.less';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import AppUtil from '../../../utility/admin.util';
import _ from 'lodash';
import ServiceServices from '../../../services/service.services';
import LogsFirer from '../../../services/logs-firer.services';
import AddServiceAttributeModal from '../../../components/Modals/AddServiceAttributeModal';
import EditServiceAttributeDrawer from '../../../components/Drawers/EditServiceAttributeDrawer';
import AddServiceModal from '../../../components/Modals/AddServiceModal';
import { isNilOrEmpty } from '../../../utility/utils';

const { Title, Text } = Typography;
const { Search } = Input;
const { Option } = Select;

class Services extends Component {
  offline;
  env;
  stopStatePropagation;
  serviceServices;
  logFirer;
  isTreeViewAllowed;

  constructor(props) {
    super(props);
    let filteredRoles;
    let rightInfoList;
    let permissionList;
    let userServiceRightInfo;

    this.isTreeViewAllowed = this.props.roles.some((r) => {
      return Object.entries(r.attributes).some(([_, value]) => {
        return value.rightInfo && value.rightInfo.indexOf('treeView') !== -1
          ? true
          : false;
      });
    });

    filteredRoles = this.props.roles.filter((r) => {
      return !_.isUndefined(r.attributes['service_manager']);
    });

    permissionList =
      this.props.globalSettings.PagePermissionManager['service_manager'] || {};
    rightInfoList = filteredRoles[0]
      ? filteredRoles[0].attributes['service_manager'].rightInfo || []
      : [];

    if (_.isEmpty(permissionList)) {
      this.props.history.push('/app/fallback');
      userServiceRightInfo = {
        inquiryService: false,
        createService: false,
        updateService: false,
        deleteService: false,
        addServiceAttribute: false,
        deleteServiceAttribute: false,
        updateServiceAttribute: false,
      };
    } else {
      userServiceRightInfo = {
        inquiryService: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'inquiryService'
        ),
        createService: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'createService'
        ),
        updateService: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'updateService'
        ),
        deleteService: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'deleteService'
        ),
        addServiceAttribute: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'addServiceAttribute'
        ),
        deleteServiceAttribute: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'deleteServiceAttribute'
        ),
        updateServiceAttribute: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'updateServiceAttribute'
        ),
      };
    }

    this.state = {
      serviceList: [],
      loadedServiceList: false,
      selectedNode: this.props.selectedNode,
      untouchableAttributeList: [],
      attributeList: [],
      isServiceOpen: true,
      isParentOpen: false,
      attributesRows: 1,
      loadedAttributeList: false,
      page: 1,
      searchString: '',
      order: 'ASC',
      recursion: this.isTreeViewAllowed,
      activeFuzzy: false,
      userServiceRightInfo: userServiceRightInfo,
      isServiceLoading: false,
      isAddServiceAttributeModalVisible: false,
      isEditServiceAttributeDrawerVisible: false,
      isDrawerRendered: false,
      isAddServiceModalVisible: false,
    };

    if (
      _.isString(process.env.REACT_APP_OFFLINE) &&
      _.isBoolean(JSON.parse(process.env.REACT_APP_OFFLINE))
    ) {
      this.offline = JSON.parse(process.env.REACT_APP_OFFLINE);
    } else {
      this.offline = false;
    }

    if (_.isString(process.env.REACT_APP_ENV)) {
      this.env = process.env.REACT_APP_ENV;
    } else {
      this.env = this.props.globalSettings.DefaultEnvironment;
    }

    this.serviceServices = new ServiceServices(
      this.props.globalSettings.Environment[this.env]
    );
    this.logFirer = new LogsFirer(
      this.props.globalSettings.Environment[this.env]
    );
    this.pageSize = this.props.globalSettings.Environment[
      this.env
    ].envConstants.pageSize;

    this.handleChange = this.handleChange.bind(this);
    this.changeSelectedService = this.changeSelectedService.bind(this);

    this.stopStatePropagation = true;
  }

  submit = (e) => {
    e.preventDefault();
  };

  handleChange = (event) => {
    this.refreshList(
      1,
      event.target.value,
      this.state.order,
      this.state.recursion,
      this.state.activeFuzzy
    );
  };

  changeSelectedService = (event) => {
    this.refreshList(
      1,
      '',
      'ASC',
      this.state.recursion,
      this.state.activeFuzzy,
      parseInt(event.target.value, 10)
    );
  };

  /*changePagination = (event) => {
    if (this.state.selectedService && event.target.validity.valid && !_.isNaN(parseInt(event.target.value, 10))) {
      this.refreshList(parseInt(event.target.value, 10), this.state.searchString, this.state.order, this.state.recursion, this.state.activeFuzzy);
    }
  }*/

  /*changeOrder = (order, list) => {
    let sorted;
    if (order !== this.state.order) {
      sorted = _.cloneDeep(list.reverse());
    } else {
      sorted = _.cloneDeep(list);
    }

    this.stopStatePropagation && this.setState({
      attributeList: sorted,
      order: order
    });
  }*/

  refreshList = (
    page,
    searchStr,
    order,
    recursion,
    activeFuzzy = false,
    serviceId
  ) => {
    let newSelectedService;

    if (this.props.selectedNode.nodeId && this.state.selectedService) {
      if (serviceId) {
        newSelectedService = this.state.serviceList.filter((s) => {
          return s?.sid === serviceId;
        })[0];
      } else {
        newSelectedService = _.cloneDeep(this.state.selectedService);
      }

      this.stopStatePropagation &&
        this.setState({
          selectedService: newSelectedService,
          loadedAttributeList: false,
          page: page,
          searchString: searchStr,
          order: order,
          recursion: this.isTreeViewAllowed ? recursion : false,
        });

      if (
        recursion === this.state.recursion &&
        newSelectedService.sid === this.state.selectedService?.sid
      ) {
        let freshList;

        if (searchStr.length === 0) {
          freshList = _.cloneDeep(this.state.untouchableAttributeList);
        } else {
          let list = this.state.untouchableAttributeList.filter(
            (serviceAttribute) => {
              let regexp = new RegExp(
                activeFuzzy
                  ? '[' + searchStr.toLowerCase() + ']'
                  : searchStr.toLowerCase(),
                'g'
              );
              return regexp.test(serviceAttribute.attributeKey.toLowerCase());
            }
          );

          freshList = _.cloneDeep(list);
        }

        setTimeout(() => {
          this.stopStatePropagation &&
            this.setState({
              attributesRows: freshList.length > 0 ? freshList.length : 1,
              loadedAttributeList: true,
              attributeList: freshList,
            });
        }, 200);
      } else {
        this.getServiceAttributes(
          this.props.selectedNode.nodeId,
          newSelectedService?.sid,
          this.isTreeViewAllowed ? recursion : false
        );
      }
    }
  };

  getServiceAttributes = (nodeId, serviceId, hereditariness = true) => {
    this.setState({ loadedAttributeList: false });
    this.serviceServices
      .getServiceAttributes(
        nodeId,
        serviceId,
        this.isTreeViewAllowed ? hereditariness : false
      )
      .then(
        (response) => {
          if (response?.data) {
            if (this.serviceServices.checkError(response)) {
              const error = this.serviceServices.getError(response);

              Modal.error({
                title: this.props.t('App.Message.General.ServerError.Title'),
                content: error.message,
              });

              this.stopStatePropagation &&
                this.setState({
                  attributeList: [],
                  loadedAttributeList: true,
                });
            } else {
              const result = this.serviceServices.getResult(response);
              if (result) {
                this.stopStatePropagation &&
                  this.setState({
                    untouchableAttributeList: result.attributes || [],
                    attributeList: result.attributes || [],
                    attributesRows: result.countRows,
                    loadedAttributeList: true,
                  });
              }
            }
          } else {
          }
        },
        (error) => {
          if (axios.isCancel(error)) {
            this.logFirer.fireLog('LOG', error);
          } else {
            this.stopStatePropagation &&
              this.setState({
                attributeList: [],
                loadedAttributeList: true,
              });
            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };

  getNodeServices = (nodeId, serviceId) => {
    this.serviceServices.getNodeServices(nodeId).then(
      (response) => {
        if (response?.data) {
          if (this.serviceServices.checkError(response)) {
            const error = this.serviceServices.getError(response);

            this.stopStatePropagation &&
              this.setState({
                serviceList: [],
                selectedService: undefined,
                attributeList: [],
                loadedServiceList: true,
                loadedAttributeList: true,
              });

            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          } else {
            const result = this.serviceServices.getResult(response);
            if (result) {
              let newSelectedService;
              if (result.services.length > 0) {
                if (serviceId) {
                  newSelectedService = result.services.filter((s) => {
                    return s?.sid === serviceId;
                  })[0];

                  if (!newSelectedService) {
                    newSelectedService = _.cloneDeep(result.services[0]);
                  }
                } else {
                  newSelectedService = _.cloneDeep(result.services[0]);
                }

                this.getServiceAttributes(nodeId, newSelectedService?.sid);
                this.stopStatePropagation &&
                  this.setState({
                    serviceList: result.services,
                    selectedService: newSelectedService,
                    loadedServiceList: true,
                  });
              } else {
                this.stopStatePropagation &&
                  this.setState({
                    serviceList: [],
                    selectedService: undefined,
                    attributeList: [],
                    attributesRows: 0,
                    loadedServiceList: true,
                    loadedAttributeList: true,
                  });
              }
            }
          }
        } else {
          this.stopStatePropagation &&
            this.setState({
              serviceList: [],
              selectedService: undefined,
              attributeList: [],
              loadedServiceList: true,
              loadedAttributeList: true,
            });
        }
      },
      (error) => {
        if (axios.isCancel(error)) {
          this.logFirer.fireLog('LOG', error);
        } else {
          Modal.error({
            title: this.props.t('App.Message.General.ServerError.Title'),
            content: error.message,
          });
          this.stopStatePropagation &&
            this.setState({
              serviceList: [],
              selectedService: undefined,
              attributeList: [],
              loadedServiceList: true,
              loadedAttributeList: true,
            });
        }
      }
    );
  };

  modifyService = (newDescription) => {
    this.setState({ isServiceLoading: true });

    this.serviceServices
      .updateService(this.state.selectedService?.sid, newDescription)
      .then(
        (response) => {
          this.setState({ isServiceLoading: false });

          if (response?.data) {
            if (this.serviceServices.checkError(response)) {
              const error = this.serviceServices.getError(response);

              Modal.error({
                title: this.props.t('App.Message.General.ServerError.Title'),
                content: error.message,
              });
            } else {
              message.success(this.props.t('Modal.Modify.Service.Message'));
              this.getNodeServices(
                this.props.selectedNode.nodeId,
                this.state.selectedService?.sid
              );
            }
          }
        },
        (error) => {
          this.setState({ isServiceLoading: false });

          if (axios.isCancel(error)) {
            this.logFirer.fireLog('LOG', error);
          } else {
            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };

  createService = () => {
    this.setState({ loadedServiceList: false });
    this.getNodeServices(this.props.selectedNode.nodeId);
    this.setState({ isAddServiceModalVisible: false });
  };

  deleteService = () => {
    const that = this;

    Modal.confirm({
      title: this.props.t('Modal.Delete.Service.Title', {
        nodeName: that.state.selectedNode.nodeName,
      }),
      okText: this.props.t('Modal.Delete.Service.Buttons.Delete'),
      okType: 'danger',
      okButtonProps: {
        type: 'primary',
      },
      cancelText: this.props.t('Modal.Delete.Service.Buttons.Close'),
      autoFocusButton: null,
      closable: true,
      content: (
        <>
          <Alert
            style={{ marginBottom: 16 }}
            message={this.props.t('Modal.Delete.Service.DeleteMessage.Warning')}
            type="warning"
          />
          <span>
            {this.props.t('Modal.Delete.Service.DeleteMessage.Question', {
              serviceName: this.state.selectedService?.name,
              nodeName: this.state.selectedNode.nodeName,
            })}
          </span>
        </>
      ),
      onOk: () => {
        this.setState({ isServiceLoading: true });

        this.serviceServices
          .deleteService(
            this.state.selectedService?.nid,
            this.state.selectedService?.sid,
            this.state.selectedService?.name
          )
          .then(
            (response) => {
              this.setState({ isServiceLoading: false });

              if (response?.data) {
                if (this.serviceServices.checkError(response)) {
                  const error = this.serviceServices.getError(response);

                  Modal.error({
                    title: this.props.t(
                      'App.Message.General.ServerError.Title'
                    ),
                    content: error.message,
                  });
                } else {
                  this.getNodeServices(this.props.selectedNode.nodeId);
                }
              }
            },
            (error) => {
              this.setState({ isServiceLoading: false });

              if (axios.isCancel(error)) {
                this.logFirer.fireLog('LOG', error);
              } else {
                Modal.error({
                  title: this.props.t('App.Message.General.ServerError.Title'),
                  content: error.message,
                });
              }
            }
          );
      },
    });
  };

  createNodeAttribute = (data) => {
    this.serviceServices
      .createServiceAttribute(
        this.props.selectedNode.nodeId,
        this.state.selectedService?.sid,
        data.attributeKey,
        data.attributeValue
      )
      .then(
        (response) => {
          this.setState({ isAddServiceAttributeModalVisible: false });

          if (response?.data) {
            if (this.serviceServices.checkError(response)) {
              const error = this.serviceServices.getError(response);

              Modal.error({
                title: this.props.t('App.Message.General.ServerError.Title'),
                content: error.message,
              });
            } else {
              message.success(
                this.props.t('Modal.Add.Serviceattribute.Message')
              );
              this.getServiceAttributes(
                this.props.selectedNode.nodeId,
                this.state.selectedService?.sid,
                this.state.recursion
              );
            }
          }
        },
        (error) => {
          this.setState({ isAddServiceAttributeModalVisible: false });

          if (axios.isCancel(error)) {
            this.logFirer.fireLog('LOG', error);
          } else {
            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };

  componentWillMount = () => {
    if (this.props.selectedNode.nodeId) {
      this.getNodeServices(this.props.selectedNode.nodeId);
    }
  };

  componentWillUnmount = () => {
    this.stopStatePropagation = false;
  };

  componentDidUpdate = () => {
    if (
      this.props.selectedNode.nodeId &&
      this.props.selectedNode.nodeId !== this.state.selectedNode.nodeId
    ) {
      this.stopStatePropagation &&
        this.setState({
          selectedNode: this.props.selectedNode,
          loadedServiceList: false,
          loadedAttributeList: false,
        });
      this.getNodeServices(this.props.selectedNode.nodeId);
    }
    if (this.props.reloadPage && this.props.selectedNode.nodeId) {
      this.props.stopReloadPage();
      this.stopStatePropagation &&
        this.setState({
          selectedNode: this.props.selectedNode,
          loadedServiceList: false,
          loadedAttributeList: false,
        });
      this.getNodeServices(
        this.props.selectedNode.nodeId,
        this.state.selectedService ? this.state.selectedService.sid : undefined
      );
    }
  };

  onServiceSelect = (option) => {
    const selectedService = this.state.serviceList.find(
      (service) => service?.sid === option
    );

    if (selectedService) {
      this.setState(
        {
          selectedService,
        },
        () =>
          this.getServiceAttributes(
            this.props.selectedNode.nodeId,
            option,
            this.state.recursion
          )
      );
    }
  };

  onDrawerClose = () => {
    this.setState(
      {
        isEditServiceAttributeDrawerVisible: false,
      },
      () => setTimeout(() => this.setState({ isDrawerRendered: false }), 200)
    );
  };

  updateServiceAttribute = (data) => {
    this.serviceServices
      .updateServiceAttribute(
        this.state.workingServiceAttribute.attributeId,
        data.attributeKey,
        data.attributeValue
      )
      .then(
        (response) => {
          this.onDrawerClose();

          if (response?.data) {
            if (this.serviceServices.checkError(response)) {
              const error = this.serviceServices.getError(response);

              Modal.error({
                title: this.props.t('App.Message.General.ServerError.Title'),
                content: error.message,
              });
            } else {
              message.success(
                this.props.t('Modal.Modify.Serviceattribute.Message')
              );
              this.getServiceAttributes(
                this.props.selectedNode.nodeId,
                this.state.selectedService?.sid,
                this.state.recursion
              );
            }
          }
        },
        (error) => {
          this.onDrawerClose();

          if (axios.isCancel(error)) {
            this.logFirer.fireLog('LOG', error);
          } else {
            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };

  deleteServiceAttribute = () => {
    this.serviceServices
      .deleteServiceAttribute(
        this.state.workingServiceAttribute.nodeId,
        this.state.workingServiceAttribute.attributeId
      )
      .then(
        (response) => {
          this.onDrawerClose();

          if (response?.data) {
            if (this.serviceServices.checkError(response)) {
              const error = this.serviceServices.getError(response);

              Modal.error({
                title: this.props.t('App.Message.General.ServerError.Title'),
                content: error.message,
              });
            } else {
              message.success(
                this.props.t('Modal.Delete.Serviceattribute.Message')
              );
              this.getServiceAttributes(
                this.state.workingServiceAttribute.nodeId,
                this.state.selectedService?.sid,
                this.state.recursion
              );
            }
          }
        },
        (error) => {
          this.onDrawerClose();

          if (axios.isCancel(error)) {
            this.logFirer.fireLog('LOG', error);
          } else {
            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };

  disableServiceAttribute = () => {
    this.serviceServices
      .createServiceAttribute(
        this.state.workingServiceAttribute.nodeId,
        this.state.workingServiceAttribute.attributeId,
        this.state.workingServiceAttribute.attributeKey,
        ''
      )
      .then(
        (response) => {
          this.onDrawerClose();

          if (response?.data) {
            if (this.serviceServices.checkError(response)) {
              const error = this.serviceServices.getError(response);

              Modal.error({
                title: this.props.t('App.Message.General.ServerError.Title'),
                content: error.message,
              });
            } else {
              message.success(
                this.props.t('Modal.Disable.Serviceattribute.Message')
              );
              this.getServiceAttributes(
                this.state.workingServiceAttribute.nodeId,
                this.state.selectedService?.sid,
                this.state.recursion
              );
            }
          }
        },
        (error) => {
          this.onDrawerClose();

          if (axios.isCancel(error)) {
            this.logFirer.fireLog('LOG', error);
          } else {
            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };

  render = () => {
    const suffix = (
      <GlobalOutlined
        onClick={(e) =>
          this.setState({ activeFuzzy: !this.state.activeFuzzy }, () =>
            this.refreshList(
              this.state.page,
              this.state.searchString,
              this.state.order,
              this.state.recursion,
              this.state.activeFuzzy
            )
          )
        }
        style={{
          fontSize: 16,
          color: this.state.activeFuzzy ? this.props.mainColor : '#e6e6e6',
        }}
      />
    );
    const columns = [
      {
        title: this.props.t(
          'PageContainer.Services.Attributes.Table.Headers.AttributeId'
        ),
        dataIndex: 'attributeId',
        width: '10%',
        render: (_, record) => (
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <div
              style={{
                width: 8,
                height: 8,
                backgroundColor:
                  this.state.selectedNode?.nodeId === record.nodeId
                    ? 'limegreen'
                    : 'red',
                borderRadius: '100%',
                flex: 'none',
              }}
            />
            {record.attributeId}
          </div>
        ),
      },
      {
        title: this.props.t(
          'PageContainer.Services.Attributes.Table.Headers.AttributeKey'
        ),
        dataIndex: 'attributeKey',
        width: '35%',
        sorter: {
          compare: (a, b) => a.attributeKey.length - b.attributeKey.length,
        },
      },
      {
        title: this.props.t(
          'PageContainer.Services.Attributes.Table.Headers.AttributeValue'
        ),
        dataIndex: 'attributeValue',
        ellipsis: true,
        width: '35%',
        render: (value) => (
          <Text
            onClick={(e) =>
              e.target.tagName === 'svg' ? e.stopPropagation() : ''
            }
            copyable={!isNilOrEmpty(value)}
          >
            {value}
          </Text>
        ),
      },
      {
        title: this.props.t(
          'PageContainer.Services.Attributes.Table.Headers.NodeName'
        ),
        dataIndex: 'nodeName',
        width: '20%',
      },
    ];

    return (
      <div className="services">
        <Title level={3}>{this.props.t('PageContainer.Services.Title')}</Title>
        <Layout className="services-main">
          {!this.state.loadedServiceList ? (
            <Skeleton active />
          ) : (
            <Spin spinning={this.state.isServiceLoading}>
              <Row justify="space-between" align="middle">
                <Col>
                  <div>
                    <Select
                      showSearch
                      className="services-select"
                      style={{ width: 400 }}
                      placeholder="Select service"
                      optionFilterProp="children"
                      defaultActiveFirstOption
                      value={this.state.selectedService?.sid}
                      onSelect={this.onServiceSelect}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.serviceList.map((service, i) => (
                        <Option value={service?.sid} key={i}>
                          {service.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  {this.state.serviceList.length ? (
                    <Text
                      {...(this.state.userServiceRightInfo.updateService && {
                        editable: { onChange: this.modifyService },
                      })}
                    >
                      {this.state.selectedService?.description}
                    </Text>
                  ) : null}
                </Col>
                <Col>
                  <Space size={16}>
                    {this.state.serviceList.length &&
                    this.state.userServiceRightInfo.deleteService ? (
                      <Tooltip
                        title={this.props.t('Modal.Delete.Service.Title', {
                          nodeName: this.state.selectedNode.nodeName,
                        })}
                      >
                        <Button
                          onClick={this.deleteService}
                          shape="circle"
                          icon={<DeleteOutlined />}
                          danger
                        />
                      </Tooltip>
                    ) : null}
                    {this.state.userServiceRightInfo.createService && (
                      <Tooltip
                        title={this.props.t('Modal.Add.Service.Title', {
                          nodeName: this.props.selectedNode?.nodeName,
                        })}
                      >
                        <Button
                          type="primary"
                          shape="circle"
                          icon={<PlusOutlined />}
                          onClick={() =>
                            this.setState({ isAddServiceModalVisible: true })
                          }
                        />
                      </Tooltip>
                    )}
                  </Space>
                </Col>
              </Row>
            </Spin>
          )}
        </Layout>
        <Layout className="services-attributes">
          <Row justify="space-between" align="middle">
            <Col>
              <Title level={5} style={{ color: this.props.mainColor }}>
                {this.props.t('PageContainer.Services.Attributes.Title')}
              </Title>
            </Col>
            {this.state.serviceList.length &&
            this.state.userServiceRightInfo.addServiceAttribute ? (
              <Col>
                <Tooltip
                  title={this.props.t('Modal.Add.Serviceattribute.Title', {
                    nodeName: this.props.selectedNode.nodeName,
                  })}
                >
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<PlusOutlined />}
                    onClick={() =>
                      this.setState({ isAddServiceAttributeModalVisible: true })
                    }
                  />
                </Tooltip>
              </Col>
            ) : null}
          </Row>
          <Space direction="horizontal" size={16}>
            <Search
              placeholder={this.props.t(
                'PageContainer.Services.Attributes.Search.Placeholder'
              )}
              onChange={this.handleChange}
              style={{ width: 300 }}
              suffix={suffix}
            />
            {this.isTreeViewAllowed && (
              <Row gutter={8}>
                <Col>
                  {this.props.t(
                    'PageContainer.Services.Attributes.Recursion.Label'
                  )}
                </Col>
                <Col>
                  <Switch
                    defaultChecked
                    onChange={(e) =>
                      this.refreshList(
                        this.state.page,
                        this.state.searchString,
                        this.state.order,
                        e,
                        this.state.activeFuzzy
                      )
                    }
                  />
                </Col>
              </Row>
            )}
          </Space>
          <Table
            className="service-attributes-table"
            columns={columns}
            dataSource={this.state.attributeList}
            loading={!this.state.loadedAttributeList}
            pagination={{
              showSizeChanger: false,
              position: ['topLeft', 'bottomRight'],
            }}
            onRow={(row) => {
              return {
                onClick: (e) => {
                  e.preventDefault();
                  this.setState(
                    {
                      workingServiceAttribute: row,
                    },
                    () =>
                      this.setState({ isDrawerRendered: true }, () =>
                        this.setState({
                          isEditServiceAttributeDrawerVisible: true,
                        })
                      )
                  );
                },
              };
            }}
          />
        </Layout>
        <AddServiceAttributeModal
          isModalVisible={this.state.isAddServiceAttributeModalVisible}
          title={this.props.t('Modal.Add.Serviceattribute.Title', {
            nodeName: this.props.selectedNode.nodeName,
          })}
          handleOk={this.createNodeAttribute}
          handleCancel={() =>
            this.setState({ isAddServiceAttributeModalVisible: false })
          }
        />
        {this.state.isDrawerRendered && (
          <EditServiceAttributeDrawer
            data={{
              title: this.props.t('Modal.Modify.Serviceattribute.Title', {
                nodeName: this.state.workingServiceAttribute?.nodeName,
              }),
              attributeKey: this.state.workingServiceAttribute?.attributeKey,
              attributeValue: this.state.workingServiceAttribute
                ?.attributeValue,
              nodeName: this.props.selectedNode?.name,
            }}
            isVisible={this.state.isEditServiceAttributeDrawerVisible}
            onUpdate={this.updateServiceAttribute}
            onDelete={this.deleteServiceAttribute}
            onDisable={this.disableServiceAttribute}
            handleCancel={this.onDrawerClose}
            canUpdate={this.state.userServiceRightInfo.updateServiceAttribute}
            canDelete={this.state.userServiceRightInfo.deleteServiceAttribute}
          />
        )}
        <AddServiceModal
          title={this.props.t('Modal.Add.Service.Title', {
            nodeName: this.props.selectedNode?.nodeName,
          })}
          nodeId={this.props.selectedNode?.nodeId}
          suggestedServicesList={
            this.props.globalSettings.Environment[this.env].envConstants
              .suggestedServiceList
          }
          isModalVisible={this.state.isAddServiceModalVisible}
          onConfirm={this.createService}
          handleCancel={() =>
            this.setState({ isAddServiceModalVisible: false })
          }
          serviceServices={this.serviceServices}
        />
      </div>
    );
  };
}

export default withTranslation()(Services);
