import {
  ACCOUNTS,
  DASHBOARD,
  NODES,
  ROLES,
  SERVICES,
  VALIDSIGN_OVERVIEW,
  VALIDSIGN_CONFIGURATION,
  VALIDSIGN_USER_MANAGEMENT,
  VALIDDRIVER,
  RAO,
  SETTINGS,
  SOFTWARE_INFO,
  LOGOUT,
  IDP_PROVIDERS,
  OAUTH_CONSOLE,
  T4IDADMIN_SERVICES,
} from '../constants/routes';
import Dashboard from './Dashboard';
import Nodes from './Configurations/Nodes';
import Overview from './ValidSign/Overview';
import Configuration from './ValidSign/Configuration';
import UserManagement from './ValidSign/UserManagement';
import Accounts from './Configurations/Accounts';
import Services from './Configurations/Services';
import Roles from './Configurations/Roles';
import IdpProviders from './Configurations/IdpProviders';
import ValidDriver from './Profiles/ValidDriver';
import Rao from './Profiles/RAO';
import Settings from './Settings';
import SoftwareInfo from './SoftwareInfo';
import Logout from './Logout';
import OAuthConsole from './Configurations/OAuthConsole';
import T4IDAdminServices from './T4IDAdmin/T4IDAdminServices';

export const routes = [
  {
    name: 'DASHBOARD',
    exact: true,
    path: DASHBOARD,
    component: Dashboard,
  },
  {
    name: 'NODES',
    exact: true,
    path: NODES,
    component: Nodes,
  },
  {
    name: 'ACCOUNTS',
    exact: true,
    path: ACCOUNTS,
    component: Accounts,
  },
  {
    name: 'SERVICES',
    exact: true,
    path: SERVICES,
    component: Services,
  },
  {
    name: 'ROLES',
    exact: true,
    path: ROLES,
    component: Roles,
  },
  {
    name: 'IDP_PROVIDERS',
    exact: true,
    path: IDP_PROVIDERS,
    component: IdpProviders,
  },
  {
    name: 'OAUTH_CONSOLE',
    exact: true,
    path: OAUTH_CONSOLE,
    component: OAuthConsole,
  },
  {
    name: 'T4IDADMIN_SERVICES',
    exact: true,
    path: T4IDADMIN_SERVICES,
    component: T4IDAdminServices,
  },
  {
    name: 'VALIDDRIVER',
    exact: true,
    path: VALIDDRIVER,
    component: ValidDriver,
  },
  {
    name: 'RAO',
    exact: true,
    path: RAO,
    component: Rao,
  },
  {
    name: 'VALIDSIGN_OVERVIEW',
    exact: true,
    path: VALIDSIGN_OVERVIEW,
    component: Overview,
  },
  {
    name: 'VALIDSIGN_CONFIGURATION',
    exact: true,
    path: VALIDSIGN_CONFIGURATION,
    component: Configuration,
  },
  {
    name: 'VALIDSIGN_USER_MANAGEMENT',
    exact: true,
    path: VALIDSIGN_USER_MANAGEMENT,
    component: UserManagement,
  },
  {
    name: 'SETTINGS',
    exact: true,
    path: SETTINGS,
    component: Settings,
  },
  {
    name: 'SOFTWARE_INFO',
    exact: true,
    path: SOFTWARE_INFO,
    component: SoftwareInfo,
  },
  {
    name: 'LOGOUT',
    exact: true,
    path: LOGOUT,
    component: Logout,
  },
];
