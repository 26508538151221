import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './styles.less';

function PhoneInputWrapper({ value, onChange, disabled = false }) {
  return (
    <PhoneInput
      country="it"
      value={value}
      onChange={onChange}
      inputClass="phoneInputWrapper"
      disabled={disabled}
    />
  );
}

export default PhoneInputWrapper;
