export default class AppUtil {
  static getPermission = (
    rightInfo,
    allPagePesmissionAllowed,
    rightInfoList,
    action
  ) => {
    if (allPagePesmissionAllowed) {
      return true;
    } else {
      for (const property in rightInfo) {
        if (
          rightInfoList.indexOf(property) > -1 &&
          rightInfo[property].indexOf(action) > -1
        ) {
          return true;
        }
      }
      return false;
    }
  };
}
