import { Empty, Skeleton } from 'antd';
import { Column } from '@ant-design/charts';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import AccountServices from '../../../../services/account.services';
import _ from 'lodash';

function ValidSignChart({ globalSettings, selectedNode, t, mainColor }) {
  const [validSignData, setValidSignData] = useState([]);
  const getValidSignCountData = (accountServices, role) => {
    return accountServices
      .listAccounts(
        selectedNode.nodeId,
        { ...(role ? { roles: role } : { login: '' }) },
        { login: 'ASC' },
        1,
        false,
        200
      )
      .then(
        (response) => {
          if (response?.data) {
            if (accountServices.checkError(response)) {
              return 0;
            } else {
              const result = accountServices.getResult(response);

              return result.accountsRows || 0;
            }
          }
        },
        (error) => {
          if (axios.isCancel(error)) {
            //this.logFirer.fireLog('LOG', error);
          } else {
            return 0;
          }
        }
      );
  };
  const getValidSignData = (accountServices) => {
    setIsValidSignDataLoaded(false);

    Promise.all([
      getValidSignCountData(accountServices),
      getValidSignCountData(accountServices, 'validsign_user'),
      getValidSignCountData(accountServices, 'validsign_administrator'),
    ])
      .then((response) => {
        if (response) {
          setValidSignData([
            {
              type: t('PageContainer.Dashboard.Widget.ValidSign.BasicUser'),
              value: response[0],
            },
            {
              type: t('PageContainer.Dashboard.Widget.ValidSign.ValidSignUser'),
              value: response[1],
            },
            {
              type: t(
                'PageContainer.Dashboard.Widget.ValidSign.ValidSignAdministrator'
              ),
              value: response[2],
            },
          ]);
          setIsValidSignDataLoaded(true);
        }
      })
      .catch((_) => {
        setIsValidSignDataLoaded(true);
      });
  };

  const validSignChartConfig = {
    data: validSignData,
    xField: 'type',
    yField: 'value',
    seriesField: 'type',
    color: [mainColor, '#01CFCB', '#525566'],
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
  };
  const [isValidSignDataLoaded, setIsValidSignDataLoaded] = useState(false);

  useEffect(() => {
    let env;

    if (_.isString(process.env.REACT_APP_ENV)) {
      env = process.env.REACT_APP_ENV;
    } else {
      env = globalSettings.DefaultEnvironment;
    }

    const accountServices = new AccountServices(
      globalSettings.Environment[env]
    );

    getValidSignData(accountServices);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode]);

  const checkDataEmpty = () => validSignData.filter((data) => data.value > 0);

  return isValidSignDataLoaded ? (
    checkDataEmpty().length ? (
      <Column {...validSignChartConfig} />
    ) : (
      <Empty />
    )
  ) : (
    <Skeleton active />
  );
}

export default withTranslation()(ValidSignChart);
