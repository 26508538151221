import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Breadcrumb from './Breadcrumb';
import { openModalInfo } from '../../actions/modal-manager';
import { setSelectedNode } from '../../actions/selected-node-manager';
import { setTree } from '../../actions/tree-manager';

const mapStateToProps = (state) => ({
  rootNode: state.userManager.user.accountInfo
    ? state.userManager.user.accountInfo.nodeData
    : state.selectedNodeManager.node,
  selectedNode: state.selectedNodeManager.node,
  globalSettings: state.globalSettingsManager.globalSettings,
  breadcrumbVisible: state.breadcrumbStateManager.breadcrumbVisible,
  defaultUrl: state.defaultUrlManager.url,
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (isOpen, title, message) =>
    dispatch(openModalInfo(isOpen, title, message)),
  setSelectedNode: (selectedNode) => dispatch(setSelectedNode(selectedNode)),
  setTree: (newTree) => dispatch(setTree(newTree)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Breadcrumb)
);
