import HttpServices from './http.services';

class NodeServices {
  constructor(envSettings) {
    this.envSettings = envSettings;
    this.httpService = new HttpServices(this.envSettings);
  }

  /************** START Response utilities*********************************************/

  checkError = (res) => {
    if (res.data.error) {
      return true;
    } else {
      return false;
    }
  };

  getError = (res) => {
    return {
      code: res.data.error.code,
      message: res.data.error.message,
    };
  };

  getResult = (res) => {
    return res.data.result;
  };

  /************** END Response utilities*********************************************/

  /************** START API services*********************************************/

  createNode = (nodeId, name, description) => {
    const params = {
      parentNodeId: nodeId,
      name: name,
      description: description,
    };
    return this.httpService.httpServicesPost(
      'createNode',
      this.envSettings,
      params
    );
  };

  getNode = (nodeId) => {
    const params = {
      nodeId: nodeId,
    };
    return this.httpService.httpServicesPost(
      'getNode',
      this.envSettings,
      params
    );
  };

  searchNode = (nodeId, name = '') => {
    const params = {
      nodeId: nodeId,
      name: name,
    };
    return this.httpService.httpServicesPost(
      'searchNode',
      this.envSettings,
      params
    );
  };

  getNodeChildren = (nodeId, page = 1, name = '') => {
    const params = {
      nodeId: nodeId,
      page: page,
      size: this.envSettings.envConstants.treeViewSubnodeSize,
      name: name,
    };
    return this.httpService.httpServicesPost(
      'getNodeChildren',
      this.envSettings,
      params
    );
  };

  getNodeAttributes = (nodeId, hereditariness = true, name = '') => {
    const params = {
      nodeId: nodeId,
      hereditariness: hereditariness,
      name: name,
    };
    return this.httpService.httpServicesPost(
      'getNodeAttributes',
      this.envSettings,
      params
    );
  };

  updateNode = (nodeId, description) => {
    const params = {
      nodeId: nodeId,
      description: description,
    };
    return this.httpService.httpServicesPost(
      'updateNode',
      this.envSettings,
      params
    );
  };

  createNodeAttribute = (nodeId, attributeName, attributeValue) => {
    const params = {
      nodeId: nodeId,
      attributeName: attributeName.trim(),
      attributeValue: attributeValue.trim(),
    };
    return this.httpService.httpServicesPost(
      'createNodeAttribute',
      this.envSettings,
      params
    );
  };

  resetNodeAttribute = (nodeId, attributeId, attributeName) => {
    const params = {
      nodeId: nodeId,
      attributeId: attributeId,
      attributeName: attributeName,
    };
    return this.httpService.httpServicesPost(
      'resetNodeAttribute',
      this.envSettings,
      params
    );
  };

  updateNodeAttribute = (
    nodeId,
    attributeId,
    attributeName,
    attributeValue
  ) => {
    const params = {
      nodeId: nodeId,
      attributeId: attributeId,
      attributeName: attributeName.trim(),
      attributeValue: attributeValue.trim(),
    };
    return this.httpService.httpServicesPost(
      'updateNodeAttribute',
      this.envSettings,
      params
    );
  };

  deleteNodeAttribute = (nodeId, attributeId, attributeName) => {
    const params = {
      nodeId: nodeId,
      attributeId: attributeId,
      attributeName: attributeName,
    };
    return this.httpService.httpServicesPost(
      'deleteNodeAttribute',
      this.envSettings,
      params
    );
  };

  /************** END API services*********************************************/
}

export default NodeServices;
