export const setTree = (newTree) => ({
  type: 'SET_TREE',
  newTree,
});

export const setNode = (newNode) => ({
  type: 'SET_NODE',
  newNode,
});

export const removeLeafProp = (nodeId) => ({
  type: 'REMOVE_LEAF_PROP',
  nodeId,
});
