import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { stopReloadPage } from '../../../actions/reload-page-manager';
import T4IDAdminServices from './T4IDAdminServices';

const mapStateToProps = (state) => ({
  globalSettings: state.globalSettingsManager.globalSettings,
  reloadPage: state.reloadPageManager.reloadPage,
  roles: state.userManager.user.roles,
  mainColor: state.userManager.mainColor,
  rootNode: state.userManager.user.accountInfo
    ? state.userManager.user.accountInfo.nodeData
    : state.selectedNodeManager.node,
});

const mapDispatchToProps = (dispatch) => ({
  stopReloadPage: () => dispatch(stopReloadPage()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(T4IDAdminServices)
);
