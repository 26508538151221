import { Col, Layout, Result, Row, Skeleton, Typography } from 'antd';
import { withTranslation } from 'react-i18next';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { routes } from './routes';
import LeftMenu from './components/LeftMenu';
import TreeManager from './components/TreeManager';
import ProfileManager from './components/ProfileManager';
import getGlobalSettings from './services/global-settings.services';
import _ from 'lodash';
import { Component } from 'react';
import { compose } from 'redux';
import UserServices from './services/user.services';
import LogsFirer from './services/logs-firer.services';
import ProbeServices from './services/probe.services';
import Breadcrumb from './components/Breadcrumb';
import ServiceServices from './services/service.services';
import { LOGOUT, OAUTH_CONSOLE } from './constants/routes';
import NodeServices from './services/node.services';

const { Content, Footer, Header } = Layout;
const { Text } = Typography;

let userServices;
let serviceServices;
let probeServices;
let logLogsFirer;
let nodeServices;

class App extends Component {
  env;

  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
    };

    if (_.isString(process.env.REACT_APP_ENV)) {
      this.env = process.env.REACT_APP_ENV;
    } else {
      this.env = this.props.globalSettings.DefaultEnvironment;
    }
  }

  componentDidMount = () => {
    const {
      setCurrentPage,
      setGlobalSettings,
      history,
      getGlobalSettingError,
    } = this.props;
    const currentPage =
      window.location.pathname === '/'
        ? 'DASHBOARD'
        : routes.find(
            (route) =>
              route.path !== '/' &&
              window.location.pathname.includes(route.path.split(':')[0])
          )?.name;

    setCurrentPage(currentPage);

    history.listen((newRoute) => {
      const currentPage =
        newRoute.pathname === '/'
          ? 'DASHBOARD'
          : routes.find(
              (route) =>
                route.path !== '/' &&
                newRoute.pathname.includes(route.path.split(':')[0])
            )?.name;

      setCurrentPage(currentPage);
    });

    getGlobalSettings().then(
      (res) => {
        setGlobalSettings(res.data.globalSettings);
        userServices = new UserServices(
          res.data.globalSettings.Environment[this.env]
        );
        probeServices = new ProbeServices(
          res.data.globalSettings.Environment[this.env]
        );
        logLogsFirer = new LogsFirer(
          res.data.globalSettings.Environment[this.env]
        );
        serviceServices = new ServiceServices(
          res.data.globalSettings.Environment[this.env]
        );
        nodeServices = new NodeServices(
          res.data.globalSettings.Environment[this.env]
        );
        this.probe();
      },
      (error) => {
        getGlobalSettingError();
      }
    );

    this.collapseLeftMenu();

    window.addEventListener('resize', () => this.collapseLeftMenu());
  };

  collapseLeftMenu = () => {
    if (window.innerWidth <= 1200) {
      this.setState({ collapsed: true });
    }
  };

  checkPermission = (item, roleList) => {
    let checked = false;

    roleList.some((role) => {
      if (
        role.name === item.accessPermission.role ||
        item.accessPermission.role === 'ALL'
      ) {
        if (!_.isEmpty(role.attributes[item.accessPermission.profile])) {
          checked = true;
        }
      }
      return checked;
    });
    return checked;
  };

  getFirstNodeRelatedUrl = (menuManager, roles) => {
    let url = '';

    menuManager.some((configItem) => {
      if (configItem.nodeRelated && configItem.isVisible) {
        if (this.checkPermission(configItem, roles)) {
          url = configItem.url;
        }
      } else {
        if (configItem.submenu) {
          configItem.submenu.some((item) => {
            if (item.nodeRelated && item.isVisible) {
              if (this.checkPermission(item, roles)) {
                url = item.url;
              }
            }
            return url !== '';
          });
        }
      }
      return url !== '';
    });

    return url;
  };

  getServiceAttributes = (validSignServiceID) => {
    serviceServices
      .getServiceAttributes(
        this.props.selectedNode.nodeId,
        validSignServiceID,
        true
      )
      .then(
        (response) => {
          if (response?.data) {
            if (serviceServices.checkError(response)) {
              this.props.setIsLogoLoading(false);
            } else {
              const result = serviceServices.getResult(response);

              if (result) {
                const adminBundle = result.attributes.filter(
                  (attr) =>
                    attr.attributeKey === 'ADMIN_BUNDLE' &&
                    !_.isNil(attr.attributeValue)
                );

                if (adminBundle?.length) {
                  const baseUrl =
                    process.env.REACT_APP_ENV === 'local'
                      ? 'https://admin.int4mind.com'
                      : window.location.origin;
                  const logoUrl = `${baseUrl}/external/${adminBundle[0]?.attributeValue}/logo.png`;
                  const mainColorUrl = `${baseUrl}/external/${adminBundle[0]?.attributeValue}/style.json`;

                  fetch(logoUrl)
                    .then((response) => {
                      if (response.status === 200) {
                        this.props.setLogo(logoUrl);
                        this.props.setIsLogoLoading(false);
                      } else {
                        this.props.setIsLogoLoading(false);
                      }
                    })
                    .catch((err) => this.props.setIsLogoLoading(false));

                  fetch(mainColorUrl)
                    .then((response) => {
                      return response.json();
                    })
                    .then((response) => {
                      if (response.mainColor) {
                        let mainColor = response.mainColor;
                        mainColor =
                          mainColor.indexOf('#') < 0
                            ? `#${mainColor}`
                            : mainColor;

                        this.props.setMainColor(mainColor);
                        window.less.modifyVars({
                          '@primary-color': mainColor,
                          '@modal-heading-color': mainColor,
                        });
                        window.less.refreshStyles();
                      }
                    })
                    .catch((err) => {});
                } else {
                  this.props.setIsLogoLoading(false);
                }
              } else {
                this.props.setIsLogoLoading(false);
              }
            }
          } else {
            this.props.setIsLogoLoading(false);
          }
        },
        (error) => {
          this.props.setIsLogoLoading(false);
        }
      )
      .catch((err) => this.props.setIsLogoLoading(false));
  };

  getUser = () => {
    userServices.getAccountInfoEx().then(
      (response) => {
        if (response?.data) {
          if (userServices.checkError(response)) {
            const error = userServices.getError(response);

            if (logLogsFirer) {
              logLogsFirer.fireLog(
                'LOG',
                error.message,
                this.props.t('App.Message.General.ServerError.Title') +
                  ' ' +
                  error.code
              );
            }

            this.props.getUserError();
          } else {
            const result = userServices.getResult(response);

            if (result) {
              const {
                userData: {
                  firstname,
                  lastname,
                  fiscalCode,
                  email,
                  mobileNumber,
                  sex = undefined,
                  nickname = undefined,
                },
                accountStatus,
                loginLevel,
                login,
                language = 'EN',
                nodeData,
                tokenInfo = undefined,
              } = result;
              const newUserInfo = {
                firstname,
                lastname,
                fiscalCode,
                email,
                mobileNumber,
                sex,
                nickname,
              };
              const newAccountInfo = {
                accountStatus,
                loginLevel,
                login,
                language,
                nodeData,
                tokenInfo,
              };
              const newRoleList = [];

              if (result.roles) {
                result.roles.forEach((role) => {
                  const { name, description, attributes } = role;

                  newRoleList.push({
                    name,
                    description,
                    attributes,
                  });
                });
              }

              this.props.setUser(newUserInfo, newRoleList, newAccountInfo);
              this.props.setSelectedNode(result.nodeData);
              this.props.setDefaultUrl(
                this.getFirstNodeRelatedUrl(
                  this.props.globalSettings.MenuManager,
                  newRoleList
                )
              );

              const userLanguage = newAccountInfo.language.toLowerCase();
              this.props.i18n.changeLanguage(userLanguage);

              nodeServices
                .getNodeAttributes(this.props.rootNode.nodeId, false)
                .then(
                  (response) => {
                    if (response?.data) {
                      if (nodeServices.checkError(response)) {
                      } else {
                        const result = nodeServices.getResult(response);

                        if (result) {
                          if (result.attributes?.length > 0) {
                            const adminBundle = result.attributes.filter(
                              (attr) =>
                                attr.attributeKey === 'ADMIN_BUNDLE' &&
                                !_.isNil(attr.attributeValue)
                            );

                            if (adminBundle?.length) {
                              const baseUrl =
                                process.env.REACT_APP_ENV === 'local'
                                  ? 'https://admin.int4mind.com'
                                  : window.location.origin;
                              const logoUrl = `${baseUrl}/external/${adminBundle[0]?.attributeValue}/logo.png`;
                              const mainColorUrl = `${baseUrl}/external/${adminBundle[0]?.attributeValue}/style.json`;

                              fetch(logoUrl)
                                .then((response) => {
                                  if (response.status === 200) {
                                    this.props.setLogo(logoUrl);
                                    this.props.setIsLogoLoading(false);
                                  } else {
                                    this.props.setIsLogoLoading(false);
                                  }
                                })
                                .catch((err) =>
                                  this.props.setIsLogoLoading(false)
                                );

                              fetch(mainColorUrl)
                                .then((response) => {
                                  return response.json();
                                })
                                .then((response) => {
                                  if (response.mainColor) {
                                    let mainColor = response.mainColor;
                                    mainColor =
                                      mainColor.indexOf('#') < 0
                                        ? `#${mainColor}`
                                        : mainColor;

                                    this.props.setMainColor(mainColor);
                                    window.less.modifyVars({
                                      '@primary-color': mainColor,
                                      '@modal-heading-color': mainColor,
                                    });
                                    window.less.refreshStyles();
                                  }
                                })
                                .catch((err) => {});
                            } else {
                              this.props.setIsLogoLoading(false);
                            }
                          } else {
                            this.props.setIsLogoLoading(false);
                          }
                        } else {
                          this.props.setIsLogoLoading(false);
                        }
                      }
                    } else {
                      this.props.setIsLogoLoading(false);
                    }
                  },
                  (error) => {
                    this.props.setIsLogoLoading(false);
                  }
                );
            } else {
              this.props.getUserError();
              this.props.setIsLogoLoading(false);
            }
          }
        } else {
          this.props.getUserError();
          this.props.setIsLogoLoading(false);

          window.location.href = LOGOUT;
        }
      },
      (error) => {
        this.props.setIsLogoLoading(false);

        if (logLogsFirer) {
          logLogsFirer.fireLog(
            'LOG',
            error.message,
            this.props.t('App.Message.General.ServerError.Title')
          );
        }
        window.location.href = LOGOUT;
        this.props.getUserError();
      }
    );
  };

  probe = () => {
    if (this.env === 'local') {
      this.getUser();
    } else {
      probeServices.probe().then(
        (res) => {
          this.getUser();
        },
        (error) => {}
      );
    }
  };

  isTreeViewAllowed = () => {
    return this.props.roleList.some((r) => {
      return Object.entries(r.attributes).some(([_, value]) => {
        return value.rightInfo && value.rightInfo.indexOf('treeView') !== -1
          ? true
          : false;
      });
    });
  };

  getFirstAvailableRoute = () => {
    const adminPortalRole = this.props.roleList.find((role) =>
      role.attributes.hasOwnProperty('admin_portal')
    );
    const firstAvailableRoute = routes.find((route) =>
      adminPortalRole?.attributes.admin_portal.rightInfo.includes(
        route.name.toLocaleLowerCase()
      )
    );

    return firstAvailableRoute?.path;
  };

  getComponentByRoute = (routeName) => {
    return routes.find((route) => route.name === routeName)?.component;
  };

  render() {
    const { collapsed } = this.state;
    const {
      isValidUser,
      isValidSettings,
      settingsError,
      userError,
    } = this.props;
    const isLoading = !isValidUser;

    return (
      <div className="app">
        <Layout style={{ minHeight: '100vh' }}>
          <LeftMenu
            collapsed={collapsed}
            onCollapse={(collapsed) => this.setState({ collapsed })}
            isLoading={isLoading}
          />
          <Layout
            className="app-site-layout"
            style={{
              width: `calc(100% - ${collapsed ? 80 : 200}px)`,
              marginLeft: collapsed ? 80 : 200,
            }}
          >
            <Header
              className={`app-header ${
                !this.isTreeViewAllowed() ? 'app-header--noTreeViewAllowed' : ''
              }`}
            >
              {isLoading ? (
                <>
                  <div className="header-skeletonLeft">
                    <Skeleton.Button active />
                    <Skeleton.Button active />
                    <Skeleton.Button active />
                  </div>
                  <div className="header-skeletonRight">
                    <Skeleton.Avatar active shape="circle" />
                  </div>
                </>
              ) : (
                <>
                  {this.isTreeViewAllowed() && <TreeManager />}
                  <ProfileManager />
                </>
              )}
            </Header>
            <Content className="app-site-layout-content">
              {isValidUser && isValidSettings ? (
                <>
                  {this.isTreeViewAllowed() &&
                    window.location.pathname !== OAUTH_CONSOLE && (
                      <Breadcrumb />
                    )}
                  <Switch>
                    {routes.map((route, i) => {
                      const adminPortalRole = this.props.roleList.find((role) =>
                        role.attributes.hasOwnProperty('admin_portal')
                      );

                      if (
                        route.name === 'DASHBOARD' &&
                        !adminPortalRole?.attributes.admin_portal.rightInfo.includes(
                          'basic'
                        )
                      ) {
                        return false;
                      }

                      return (
                        <Route
                          key={i}
                          exact={route.exact}
                          path={route.path}
                          component={route.component}
                        />
                      );
                    })}

                    <Redirect from="*" to={this.getFirstAvailableRoute()} />
                  </Switch>
                </>
              ) : !isValidSettings && settingsError ? (
                <Result
                  status="500"
                  title="500"
                  subTitle="Sorry, something went wrong."
                />
              ) : !isValidUser && userError ? (
                <Result
                  status="403"
                  title="401"
                  subTitle="Sorry, you are not authorized to access this page."
                />
              ) : (
                <Layout className="dashboard-skeleton">
                  <Skeleton active />
                  <Row gutter={[16, 16]}>
                    <Col span={8} xs={24} sm={24} md={12} xl={8}>
                      <Skeleton.Avatar active shape="square" />
                    </Col>
                    <Col span={8} xs={24} sm={24} md={12} xl={8}>
                      <Skeleton.Avatar active shape="square" />
                    </Col>
                    <Col span={8} xs={24} sm={24} md={12} xl={8}>
                      <Skeleton.Avatar active shape="square" />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={8} xs={24} sm={24} md={12} xl={8}>
                      <Skeleton.Avatar active shape="square" />
                    </Col>
                    <Col span={8} xs={24} sm={24} md={12} xl={8}>
                      <Skeleton.Avatar active shape="square" />
                    </Col>
                    <Col span={8} xs={24} sm={24} md={12} xl={8}>
                      <Skeleton.Avatar active shape="square" />
                    </Col>
                  </Row>
                </Layout>
              )}
            </Content>
            <Footer className="app-site-layout-footer">
              <Text>Intesi Group S.p.A. ©{new Date().getFullYear()}</Text>
            </Footer>
          </Layout>
        </Layout>
      </div>
    );
  }
}

export default compose(withTranslation(), withRouter)(App);
