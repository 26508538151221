import './styles.less';
import { Layout, Menu, Skeleton } from 'antd';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import IgIco from '../../assets/icons/ico-ig-white.svg';
import IgLogo from '../../assets/icons/logo-ig-white.svg';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { getLeftMenuItems } from '../../utility/utils';
import { routes } from '../../routes';

const { Sider } = Layout;
const { SubMenu } = Menu;

function LeftMenu({
  collapsed,
  onCollapse,
  currentPage,
  globalSettings,
  roleList,
  isLoading,
  isLogoLoading,
  t,
  logo,
}) {
  const history = useHistory();
  const onMenuItemClick = (item) => {
    import('../../constants/routes').then((e) => history.push(e[item.key]));
  };
  const rootSubmenuKeys = [
    'DASHBOARD',
    'OAUTH_CONSOLE',
    'CONFIGURATIONS',
    'PROFILES',
    'T4IDADMIN',
    'VALIDSIGN',
  ];
  const [openKeys, setOpenKeys] = useState(['DASHBOARD']);
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const getDefaultSelectedKey = () => {
    const adminPortalRole = roleList.find((role) =>
      role.attributes.hasOwnProperty('admin_portal')
    );
    const firstAvailableRoute = routes.find((route) =>
      adminPortalRole?.attributes.admin_portal.rightInfo.includes(
        route.name.toLocaleLowerCase()
      )
    );

    return firstAvailableRoute?.name;
  };

  const getLogo = () => {
    if (logo) {
      return `url(${logo})`;
    } else {
      return `url(${collapsed ? IgIco : IgLogo})`;
    }
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      className="leftMenu"
    >
      {isLogoLoading ? (
        <div className="leftMenu-logoSkeleton">
          <Skeleton.Button size="large" active />
        </div>
      ) : (
        <div
          className="leftMenu-logo"
          style={{
            textAlign: collapsed ? 'center' : 'left',
            backgroundImage: getLogo(),
            backgroundSize: logo ? 'contain' : 'cover',
          }}
        />
      )}

      <Menu
        theme="dark"
        defaultSelectedKeys={[getDefaultSelectedKey()]}
        selectedKeys={[currentPage]}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        mode="inline"
        onClick={onMenuItemClick}
      >
        {isLoading ? (
          <div className="menu-skeleton">
            <Skeleton.Button active size="default" />
            <Skeleton.Button active size="default" />
            <Skeleton.Button active size="default" />
            <Skeleton.Button active size="default" />
          </div>
        ) : (
          getLeftMenuItems(globalSettings, roleList).map((item) => {
            if (item.submenu) {
              if (!_.isEmpty(item.submenu)) {
                return (
                  <SubMenu
                    key={item.key}
                    icon={item.icon}
                    title={t(`LeftMenu.${item.key}.label`)}
                  >
                    {item.submenu.map(
                      (sub) =>
                        sub.isVisible &&
                        !sub.disabled && (
                          <Menu.Item key={sub.key}>
                            {t(`LeftMenu.${sub.key}.label`)}
                          </Menu.Item>
                        )
                    )}
                  </SubMenu>
                );
              }
              return null;
            } else {
              return (
                item.isVisible &&
                !item.disabled && (
                  <Menu.Item key={item.key} icon={item.icon}>
                    {t(`LeftMenu.${item.key}.label`)}
                  </Menu.Item>
                )
              );
            }
          })
        )}
      </Menu>
      <span className="leftMenu-version">
        {isLoading ? (
          <Skeleton.Button size="small" active />
        ) : (
          process.env.REACT_APP_VERSION
        )}
      </span>
    </Sider>
  );
}

export default withTranslation()(LeftMenu);
