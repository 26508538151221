export const DASHBOARD = '/';
export const FALLBACK = '/app/fallback';
export const SETTINGS = '/app/settings';
export const SOFTWARE_INFO = '/app/software-info';
export const LOGOUT = '/logout';
export const NODES = '/app/configurations/nodes';
export const ACCOUNTS = '/app/configurations/accounts';
export const SERVICES = '/app/configurations/services';
export const PROVIDERS = '/app/configurations/providers';
export const GROUPS = '/app/configurations/groups';
export const ROLES = '/app/configurations/roles';
export const IDP_PROVIDERS = '/app/configurations/idp-providers';
export const OAUTH_CONSOLE = '/app/oauth-console';
export const VALIDDRIVER = '/app/profiles/validdriver';
export const RAO = '/app/profiles/rao';
export const T4IDADMIN_SERVICES = '/app/t4idadmin/services';
export const VALIDSIGN_OVERVIEW = '/app/validsign/overview';
export const VALIDSIGN_CONFIGURATION = '/app/validsign/configuration';
export const VALIDSIGN_USER_MANAGEMENT = '/app/validsign/user-management';
