import { Empty, Skeleton } from 'antd';
import { Pie } from '@ant-design/charts';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import AccountServices from '../../../../services/account.services';
import _ from 'lodash';

function AccountChart({ globalSettings, selectedNode, t }) {
  // ACCOUNTS
  const [accountsData, setAccountsData] = useState();
  const accountsChartConfig = {
    data: accountsData,
    colorField: 'type',
    angleField: 'value',
    interactions: [{ type: 'element-active' }],
  };
  const getAccountsData = (accountServices) => {
    setIsAccountsDataLoaded(false);

    accountServices
      .listAccounts(
        selectedNode.nodeId,
        { login: '' },
        { login: 'ASC' },
        1,
        false,
        200
      )
      .then(
        (response) => {
          if (response?.data) {
            if (accountServices.checkError(response)) {
              setAccountsData([]);
              setIsAccountsDataLoaded(true);
            } else {
              const result = accountServices.getResult(response);
              let accountsDataChart = [
                {
                  type: t(
                    'PageContainer.Accounts.AccountStatus.STATUS_NOT_ACTIVE'
                  ),
                  value: 0,
                  key: 0,
                },
                {
                  type: t('PageContainer.Accounts.AccountStatus.STATUS_ACTIVE'),
                  value: 0,
                  key: 1,
                },
                {
                  type: t(
                    'PageContainer.Accounts.AccountStatus.STATUS_NOT_ACTIVE_PWD_RESET_PENDING'
                  ),
                  value: 0,
                  key: 2,
                },
                {
                  type: t(
                    'PageContainer.Accounts.AccountStatus.STATUS_ACTIVE_PWD_RESET_PENDING'
                  ),
                  value: 0,
                  key: 3,
                },
                {
                  type: t(
                    'PageContainer.Accounts.AccountStatus.STATUS_DEACTIVATE'
                  ),
                  value: 0,
                  key: 4,
                },
                {
                  type: t(
                    'PageContainer.Accounts.AccountStatus.STATUS_DEACTIVATE_PWD_RESET_PENDING'
                  ),
                  value: 0,
                  key: 5,
                },
                {
                  type: t(
                    'PageContainer.Accounts.AccountStatus.STATUS_SUSPENDED'
                  ),
                  value: 0,
                  key: 6,
                },
                {
                  type: t(
                    'PageContainer.Accounts.AccountStatus.STATUS_NOT_ACTIVE_CONFIRM_CONTACT_PENDING'
                  ),
                  value: 0,
                  key: 7,
                },
                {
                  type: t(
                    'PageContainer.Accounts.AccountStatus.STATUS_REVOKED'
                  ),
                  value: 0,
                  key: 8,
                },
                {
                  type: t(
                    'PageContainer.Accounts.AccountStatus.STATUS_PENDING'
                  ),
                  value: 0,
                  key: 9,
                },
              ];

              if (result?.accountsInfoEx?.length) {
                accountsDataChart.forEach((acc) => {
                  result.accountsInfoEx.forEach((el) => {
                    if (acc.key === el.accountStatus) {
                      acc.value = result.accountsInfoEx.filter(
                        (ex) => ex.accountStatus === acc.key
                      )?.length;
                    }
                  });
                });

                setAccountsData(accountsDataChart.filter((el) => el.value > 0));
                setIsAccountsDataLoaded(true);
              } else {
                setAccountsData([]);
                setIsAccountsDataLoaded(true);
              }
            }
          } else {
            setAccountsData([]);
            setIsAccountsDataLoaded(true);
          }
        },
        (error) => {
          if (axios.isCancel(error)) {
            //this.logFirer.fireLog('LOG', error);
          } else {
            setAccountsData([]);
            setIsAccountsDataLoaded(true);
          }
        }
      );
  };
  const [isAccountsDataLoaded, setIsAccountsDataLoaded] = useState(false);

  useEffect(() => {
    let env;

    if (_.isString(process.env.REACT_APP_ENV)) {
      env = process.env.REACT_APP_ENV;
    } else {
      env = globalSettings.DefaultEnvironment;
    }

    const accountServices = new AccountServices(
      globalSettings.Environment[env]
    );

    getAccountsData(accountServices);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode]);

  return isAccountsDataLoaded ? (
    _.isEmpty(accountsData) ? (
      <Empty />
    ) : (
      <Pie {...accountsChartConfig} />
    )
  ) : (
    <Skeleton active />
  );
}

export default withTranslation()(AccountChart);
