import { Col, Row, Skeleton, Statistic } from 'antd';
import { SubnodeOutlined, UserOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import NodeServices from '../../../../services/node.services';
import AccountServices from '../../../../services/account.services';

function NodeCard({ globalSettings, selectedNode, t, mainColor }) {
  const [isNodeDataLoaded, setIsNodeDataLoaded] = useState(false);
  const [nodeData, setNodeData] = useState();
  const [accountData, setAccountData] = useState(0);

  const getAccountData = (accountServices) => {
    return accountServices
      .listAccounts(
        selectedNode.nodeId,
        { login: '' },
        { login: 'ASC' },
        1,
        false,
        200
      )
      .then(
        (response) => {
          if (response?.data) {
            if (accountServices.checkError(response)) {
              return 0;
            } else {
              const result = accountServices.getResult(response);

              return result.accountsRows || 0;
            }
          }
        },
        (error) => {
          if (axios.isCancel(error)) {
            //this.logFirer.fireLog('LOG', error);
          } else {
            return 0;
          }
        }
      );
  };

  const getNodeData = (nodeServices) => {
    setIsNodeDataLoaded(false);

    return nodeServices.getNode(selectedNode.nodeId).then(
      (response) => {
        if (response?.data) {
          if (nodeServices.checkError(response)) {
            return {};
          } else {
            const result = nodeServices.getResult(response);

            if (result) {
              return result;
            }
          }
        } else {
          return {};
        }
      },
      (error) => {
        if (axios.isCancel(error)) {
          //this.logFirer.fireLog('LOG', error);
        } else {
          return {};
        }
      }
    );
  };
  const getData = async (nodeServices, accountServices) => {
    const _nodeData = await getNodeData(nodeServices);
    const _accountData = await getAccountData(accountServices);

    setNodeData(_nodeData);
    setAccountData(_accountData);

    setTimeout(() => setIsNodeDataLoaded(true), 500);
  };

  useEffect(() => {
    let env;

    if (_.isString(process.env.REACT_APP_ENV)) {
      env = process.env.REACT_APP_ENV;
    } else {
      env = globalSettings.DefaultEnvironment;
    }

    const nodeServices = new NodeServices(globalSettings.Environment[env]);
    const accountServices = new AccountServices(
      globalSettings.Environment[env]
    );

    getData(nodeServices, accountServices);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode]);

  return isNodeDataLoaded ? (
    <>
      <span className="rootNode-card-title" style={{ color: mainColor }}>
        {nodeData.name}
      </span>
      <span className="rootNode-card-description">{nodeData.description}</span>
      <Row gutter={16} style={{ marginTop: 24 }}>
        <Col xs={12}>
          <Statistic
            title={t('Widget.NodeInfo.Footer.Subnodes')}
            value={nodeData.children}
            valueStyle={{ color: '#525566' }}
            prefix={<SubnodeOutlined />}
          />
        </Col>
        <Col xs={12}>
          <Statistic
            title={t('Widget.NodeInfo.Footer.Accounts')}
            value={accountData}
            valueStyle={{ color: '#01CFCB' }}
            prefix={<UserOutlined />}
          />
        </Col>
      </Row>
    </>
  ) : (
    <Skeleton active />
  );
}

export default withTranslation()(NodeCard);
