export const setUser = (newUserInfo, newRoleList, newAccountInfo) => ({
  type: 'SET_USER',
  newUserInfo,
  newRoleList,
  newAccountInfo,
});

export const setMainColor = (mainColor) => ({
  type: 'SET_MAIN_COLOR',
  mainColor,
});

export const setLogo = (logo) => ({
  type: 'SET_LOGO',
  logo,
});

export const setIsLogoLoading = (isLogoLoading) => ({
  type: 'SET_IS_LOGO_LOADING',
  isLogoLoading,
});

export const clearUser = () => ({
  type: 'CLEAN_USER',
});

export const getUserError = () => ({
  type: 'GET_USER_ERROR',
});
