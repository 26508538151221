import { Form, Input, Modal, notification, Result, Select, Steps } from 'antd';
import './styles.less';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import { useState } from 'react';

const { Option } = Select;
const { Step } = Steps;

function ActivateIdpProviderModal({
  globalSettings,
  isModalVisible,
  servicesList,
  suggestedServicesList,
  handleCancel,
  t,
  customerNode,
  nodeServices,
  serviceServices,
  presettedCustomerName = null,
  rootNode,
  selectedNode,
  handleFinishActivation,
  setSelectedNode,
}) {
  const env = process.env.REACT_APP_ENV || globalSettings.DefaultEnvironment;
  const retryableInvervals = globalSettings.Environment[env].envConstants
    ?.retryableIntervals || [8000, 16000, 32000, 64000];
  const isUpdate = presettedCustomerName?.length;
  const [currentStep, setCurrentStep] = useState(0);
  const [newCustomerNode, setNewCustomerNode] = useState({});
  const [customerName, setCustomerName] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [serviceForm] = Form.useForm();
  const [form] = Form.useForm();
  const [
    bankIdSwedenServiceOnBaseNode,
    setBankIdSwedenServiceOnBaseNode,
  ] = useState(null);
  const [baseNode, setBaseNode] = useState(null);

  const filterServicesList = (service) => {
    if (isUpdate) {
      return (
        (service.name === 'BankIDNorway' ||
          service.name === 'BankIDSweden' ||
          service.name === 'FTN' ||
          service.name === 'MitID' ||
          service.name === 'NemID' ||
          service.name === 'iDIN' ||
          service.name === 'Spid') &&
        !servicesList.find((s) => s.name === service.name)
      );
    } else {
      return (
        service.name === 'BankIDNorway' ||
        service.name === 'BankIDSweden' ||
        service.name === 'FTN' ||
        service.name === 'MitID' ||
        service.name === 'NemID' ||
        service.name === 'iDIN' ||
        service.name === 'Spid'
      );
    }
  };

  const formLayout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 24,
    },
  };
  const footerConfig = {
    okButton: {
      text:
        currentStep === 2
          ? t('Modal.IDPActivation.Footer.OkText.Activate')
          : t('Modal.IDPActivation.Footer.OkText.Next'),
      props: {
        hidden: currentStep === 3,
        loading: isLoading,
      },
    },
    cancelButton: {
      text: t('Modal.IDPActivation.Footer.CancelText'),
      props: {
        hidden: currentStep < 3,
      },
    },
  };

  const createNode = (customerName) => {
    const _companyId = customerName.toUpperCase().replace(/ /g, '');
    const companyDescription = `Authentication node for ${customerName}`;

    nodeServices
      .createNode(customerNode.nodeId, _companyId, companyDescription)
      .then(
        (response) => {
          if (response?.data) {
            if (nodeServices.checkError(response)) {
              const error = nodeServices.getError(response);

              Modal.error({
                title: t('App.Message.General.ServerError.Title'),
                content: error.message,
              });

              setIsLoading(false);
            } else {
              const result = nodeServices.getResult(response);

              if (result) {
                // node created!
                // go to next step
                notification.success({
                  message: t(
                    'Modal.IDPActivation.Notification.Success.CreateNode.Message'
                  ),
                  description: t(
                    'Modal.IDPActivation.Notification.Success.CreateNode.Description',
                    { companyId: _companyId }
                  ),
                });

                setNewCustomerNode({
                  nodeId: result.id,
                  name: _companyId,
                  companyDescription,
                  parentId: customerNode.nodeId,
                });
                setIsLoading(false);
                setCurrentStep(currentStep + 1);
              }
            }
          }
        },
        (error) => {
          setIsLoading(false);

          if (axios.isCancel(error)) {
          } else {
            Modal.error({
              title: t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };

  const createService = (services) => {
    let _services = [];

    if (!services) {
      setCurrentStep(currentStep + 1);
      return;
    }

    setIsLoading(true);

    services.forEach((service) => {
      const serv = suggestedServicesList.find((s) => s.name === service);

      if (serv) {
        _services.push(serv);
      }
    });

    if (_services.length) {
      // for each service do createService
      _services.forEach((service, idx) =>
        createServiceRequest(service, idx, _services.length)
      );
    }
  };

  const createServiceRequest = (service, idx, servicesLength) => {
    let createServiceIntervalIndex = -1;
    const serviceFromGlobal = globalSettings.IDPProviders.services.find(
      (s) => s.serviceName === service.name
    );
    const description = serviceFromGlobal?.serviceDescription || null;

    if (description) {
      createServiceRequest_Promise(
        service,
        description,
        createServiceIntervalIndex,
        idx,
        servicesLength,
        serviceFromGlobal
      );
    }
  };
  const createServiceRequest_Promise = (
    service,
    description,
    createServiceIntervalIndex,
    idx,
    servicesLength,
    serviceFromGlobal
  ) => {
    serviceServices
      .createService(
        isUpdate ? selectedNode.nodeId : newCustomerNode.nodeId,
        service.name,
        description.replace(
          /<CUSTOMER_NAME>/g,
          isUpdate ? presettedCustomerName : newCustomerNode.name
        )
      )
      .then((response) => {
        if (response?.data) {
          if (serviceServices.checkError(response)) {
            const error = serviceServices.getError(response);

            if (error.code === 17155) {
              createServiceIntervalIndex = createServiceIntervalIndex + 1;

              if (!retryableInvervals[createServiceIntervalIndex]) {
                throw new Error();
              } else {
                const indexDesc =
                  createServiceIntervalIndex <= 2
                    ? createServiceIntervalIndex
                    : 2;

                notification.info({
                  message: t('Notification.Info.RetryableOperation.Message'),
                  description: t(
                    `Notification.Info.RetryableOperation.IDP.Description${indexDesc}`,
                    {
                      service: service.name,
                    }
                  ),
                });

                setTimeout(
                  () =>
                    createServiceRequest_Promise(
                      service,
                      description,
                      createServiceIntervalIndex,
                      idx,
                      servicesLength,
                      serviceFromGlobal
                    ),
                  retryableInvervals[createServiceIntervalIndex]
                );
              }
            } else {
              throw new Error();
            }
          } else {
            createServiceSuccess(
              response.data?.result?.id,
              service.name,
              serviceFromGlobal.serviceAttributes
            );
          }
        }
      })
      .catch(() => createServiceError(service.name))
      .finally(() => {
        if (idx === servicesLength - 1) {
          setCurrentStep(currentStep + 1);
          setIsLoading(false);

          if (!isUpdate) {
            setTimeout(() => {
              setSelectedNode({
                nodeId: newCustomerNode.nodeId,
                nodeName: newCustomerNode.name,
              });
            }, 1000);
          }
        }
      });
  };

  let createAccountIDNodeLinkIntervalIndex = -1;
  const createAccountIDNodeLinkRequest = () => {
    if ((isUpdate && form.getFieldValue('accountId')) || !isUpdate) {
      setIsLoading(true);

      const nodeName = isUpdate ? presettedCustomerName : newCustomerNode.name;
      const accountId = form.getFieldValue('accountId');

      serviceServices
        .createAccountIDNodeLink(nodeName, accountId)
        .then((response) => {
          if (serviceServices.checkError(response)) {
            const error = serviceServices.getError(response);

            if (error.code === 1101) {
              const companyHasAccountId = error.message.match(
                /(?<=\[)[^\][]*(?=])/g
              )[1];

              notification.error({
                message: t(
                  'Modal.IDPActivation.Notification.Error.AccountLinkNode.Message'
                ),
                description: t(
                  'Modal.IDPActivation.Notification.Error.AccountLinkNode.DescriptionAlreadyExists',
                  {
                    accountId,
                    companyName: companyHasAccountId,
                  }
                ),
              });

              setIsLoading(false);
              createAccountIDNodeLinkIntervalIndex = -1;
            } else if (error.code === 17155) {
              createAccountIDNodeLinkIntervalIndex =
                createAccountIDNodeLinkIntervalIndex + 1;

              if (!retryableInvervals[createAccountIDNodeLinkIntervalIndex]) {
                throw new Error();
              } else {
                const indexDesc =
                  createAccountIDNodeLinkIntervalIndex <= 2
                    ? createAccountIDNodeLinkIntervalIndex
                    : 2;

                notification.info({
                  message: t('Notification.Info.RetryableOperation.Message'),
                  description: t(
                    `Notification.Info.RetryableOperation.Description${indexDesc}`
                  ),
                });

                setTimeout(
                  createAccountIDNodeLinkRequest,
                  retryableInvervals[createAccountIDNodeLinkIntervalIndex]
                );
              }
            } else {
              throw new Error();
            }
          } else {
            notification.success({
              message: t(
                'Modal.IDPActivation.Notification.Success.AccountLinkNode.Message'
              ),
              description: t(
                'Modal.IDPActivation.Notification.Success.AccountLinkNode.Description',
                {
                  accountId,
                  nodeName,
                }
              ),
            });

            setIsLoading(false);
            setCurrentStep(currentStep + 1);
          }
        })
        .catch(() => {
          notification.error({
            message: t(
              'Modal.IDPActivation.Notification.Error.AccountLinkNode.Message'
            ),
            description: t(
              'Modal.IDPActivation.Notification.Error.AccountLinkNode.Description'
            ),
          });

          setIsLoading(false);
          createAccountIDNodeLinkIntervalIndex = -1;
        });
    } else {
      setIsLoading(false);
      setCurrentStep(currentStep + 1);
    }
  };

  const createServiceSuccess = (sid, serviceName, serviceAttributes) => {
    handleFinishActivation();

    notification.success({
      message: t(
        'Modal.IDPActivation.Notification.Success.CreateService.Message'
      ),
      description: t(
        'Modal.IDPActivation.Notification.Success.CreateService.Description',
        {
          serviceName,
          nodeName: isUpdate ? presettedCustomerName : newCustomerNode.name,
        }
      ),
    });

    setTimeout(() => {
      // timeout to create suspense
      serviceAttributes.forEach((attr) => {
        const attrKey = attr.attributeKey.replace(
          /<CUSTOMER_NAME>/g,
          isUpdate ? presettedCustomerName : newCustomerNode.name
        );
        const attrValue = attr.attributeValue.replace(
          /<CUSTOMER_NAME>/g,
          isUpdate ? presettedCustomerName : newCustomerNode.name
        );

        let nodeId = isUpdate ? selectedNode.nodeId : newCustomerNode.nodeId;
        let _sid = sid;

        if (
          attr.attributeKey ===
          'BANKID_SE_CONFIGURATION_IGCERTIFICATE4<CUSTOMER_NAME>'
        ) {
          nodeId = baseNode;
          _sid = bankIdSwedenServiceOnBaseNode?.sid;
        }

        serviceServices
          .createServiceAttribute(nodeId, _sid, attrKey, attrValue)
          .then(
            (response) => {
              if (response?.data) {
                if (serviceServices.checkError(response)) {
                  createServiceAttributeError(attrKey, serviceName);
                } else {
                  createServiceAttributeSuccess(attrKey, serviceName);
                }
              }
            },
            () => createServiceAttributeError(attrKey, serviceName)
          );
      });
    }, 1000);
  };

  const createServiceError = (serviceName) => {
    notification.error({
      message: t(
        'Modal.IDPActivation.Notification.Error.CreateService.Message'
      ),
      description: t(
        'Modal.IDPActivation.Notification.Error.CreateService.Description',
        {
          serviceName,
          nodeName: isUpdate ? presettedCustomerName : newCustomerNode.name,
        }
      ),
    });
  };

  const createServiceAttributeSuccess = (attributeKey, serviceName) => {
    notification.success({
      message: t(
        'Modal.IDPActivation.Notification.Success.CreateServiceAttribute.Message'
      ),
      description: t(
        'Modal.IDPActivation.Notification.Success.CreateServiceAttribute.Description',
        {
          attributeKey,
          serviceName,
        }
      ),
    });
  };

  const createServiceAttributeError = (attributeKey, serviceName) => {
    notification.error({
      message: t(
        'Modal.IDPActivation.Notification.Error.CreateServiceAttribute.Message'
      ),
      description: t(
        'Modal.IDPActivation.Notification.Error.CreateServiceAttribute.Description',
        {
          attributeKey,
          serviceName,
        }
      ),
    });
  };

  const onCustomerNameChange = (e) => {
    if (!customerName?.length && /\d/.test(e.target.value)) {
      setCustomerName('');
      form.resetFields(['customerName']);
    } else {
      setCustomerName(e.target.value.toUpperCase().replace(/ /g, ''));
    }
  };

  const preventBaseNodeException = (option) => {
    if (option === 'BankIDSweden') {
      nodeServices.searchNode(rootNode.nodeId, 'Base').then(
        (response) => {
          if (response?.data) {
            if (nodeServices.checkError(response)) {
              baseNodeNotExistingError();
            } else {
              const result = nodeServices.getResult(response);

              if (result.childs?.length) {
                const baseNodeId = result.childs.find(
                  (el) => el.name === 'Base'
                )?.nodeId;

                if (baseNodeId) {
                  setBaseNode(baseNodeId);

                  serviceServices.getNodeServices(baseNodeId).then(
                    (response) => {
                      if (response?.data) {
                        if (serviceServices.checkError(response)) {
                          const error = serviceServices.getError(response);

                          Modal.error({
                            title: t('App.Message.General.ServerError.Title'),
                            content: error.message,
                          });
                        } else {
                          const result = serviceServices.getResult(response);

                          if (result?.countRows) {
                            const bankIdSweden = result.services.find(
                              (el) => el.name === 'BankIDSweden'
                            );

                            if (bankIdSweden) {
                              setBankIdSwedenServiceOnBaseNode(bankIdSweden);
                            }
                          }
                        }
                      }
                    },
                    (error) => {
                      if (axios.isCancel(error)) {
                      } else {
                        Modal.error({
                          title: t('App.Message.General.ServerError.Title'),
                          content: error.message,
                        });
                      }
                    }
                  );
                } else {
                  baseNodeNotExistingError();
                }
              } else {
                baseNodeNotExistingError();
              }
            }
          }
        },
        () => {
          baseNodeNotExistingError();
        }
      );
    }
  };

  const baseNodeNotExistingError = () => {
    const services = serviceForm.getFieldValue('services');

    serviceForm.setFieldsValue({
      services: services.slice(0, -1),
    });

    Modal.error({
      title: t('Modal.IDPActivation.Steps.Third.Error.BaseNodeNotExists.Title'),
      content: t(
        'Modal.IDPActivation.Steps.Third.Error.BaseNodeNotExists.Message'
      ),
    });
  };

  return (
    <Modal
      className="activateIdpProviderModal"
      title={t(
        `Modal.IDPActivation.Title.${isUpdate ? 'Service' : 'Customer'}`
      )}
      closable
      maskClosable={false}
      visible={isModalVisible}
      okText={footerConfig.okButton.text}
      okButtonProps={footerConfig.okButton.props}
      cancelButtonProps={footerConfig.cancelButton.props}
      cancelText={footerConfig.cancelButton.text}
      afterClose={() => {
        setIsLoading(false);
        setCurrentStep(0);
        setNewCustomerNode({});
        form.resetFields();
        serviceForm.resetFields();
      }}
      onOk={() => {
        switch (currentStep) {
          case 0:
            form
              .validateFields()
              .then((values) => {
                const { customerName } = values;

                if (isUpdate) {
                  setCurrentStep(currentStep + 1);
                } else {
                  setIsLoading(true);

                  // search if exists customerName
                  nodeServices.searchNode(rootNode.nodeId, customerName).then(
                    (response) => {
                      if (response?.data) {
                        if (nodeServices.checkError(response)) {
                          setIsLoading(false);
                        } else {
                          const result = nodeServices.getResult(response);

                          if (result.childs?.length) {
                            // node already exists
                            // go to next step
                            notification.warning({
                              message: t(
                                'Modal.IDPActivation.Notification.Warning.ExistingNode.Message'
                              ),
                              description: t(
                                'Modal.IDPActivation.Notification.Warning.ExistingNode.Description',
                                { customerName }
                              ),
                            });

                            setIsLoading(false);
                            setNewCustomerNode(result.childs[0]);
                            setCurrentStep(currentStep + 1);
                          } else {
                            // node not exists
                            // create it
                            createNode(customerName);
                          }
                        }
                      }
                    },
                    (error) => {
                      setIsLoading(false);

                      if (axios.isCancel(error)) {
                      } else {
                        Modal.error({
                          title: t('App.Message.General.ServerError.Title'),
                          content: error.message,
                        });
                      }
                    }
                  );
                }
              })
              .catch((info) => {});
            break;

          case 1:
            createAccountIDNodeLinkRequest();
            break;

          case 2:
            serviceForm
              .validateFields()
              .then((values) => {
                const { services } = values;

                createService(services);
              })
              .catch((info) => {});
            break;

          default:
            return;
        }
      }}
      onCancel={handleCancel}
    >
      <Steps current={currentStep}>
        <Step key={0} title={t('Modal.IDPActivation.Steps.First.Title')} />
        <Step key={1} title={t('Modal.IDPActivation.Steps.Second.Title')} />
        <Step key={2} title={t('Modal.IDPActivation.Steps.Third.Title')} />
        <Step key={2} title={t('Modal.IDPActivation.Steps.Fourth.Title')} />
      </Steps>
      <div className="activateIdpProviderModal-currentStep">
        {currentStep === 0 && (
          <Form
            {...formLayout}
            form={form}
            initialValues={{
              customerName: isUpdate ? presettedCustomerName : null,
            }}
          >
            <Form.Item
              name="customerName"
              label={t(
                'Modal.IDPActivation.Steps.First.Form.CustomerName.Label'
              )}
              rules={[
                {
                  required: true,
                  message: t('General.MandatoryField.Text'),
                },
              ]}
            >
              <Input
                value={customerName}
                style={{
                  textTransform: 'uppercase',
                }}
                disabled={presettedCustomerName?.length}
                placeholder={t(
                  'Modal.IDPActivation.Steps.First.Form.CustomerName.Placeholder'
                )}
                onChange={onCustomerNameChange}
              />
            </Form.Item>
          </Form>
        )}
        {currentStep === 1 && (
          <Form {...formLayout} form={form}>
            <Form.Item
              name="accountId"
              label={t('Modal.IDPActivation.Steps.Second.Form.AccountId.Label')}
              rules={[
                {
                  required: !isUpdate,
                  message: t('General.MandatoryField.Text'),
                },
              ]}
            >
              <Input
                placeholder={t(
                  `Modal.IDPActivation.Steps.Second.Form.AccountId.${
                    isUpdate ? 'Service' : 'Customer'
                  }.Placeholder`
                )}
              />
            </Form.Item>
          </Form>
        )}
        {currentStep === 2 && (
          <Form form={serviceForm}>
            <Form.Item
              name="services"
              label={t('Modal.IDPActivation.Steps.Third.Form.Service.Label')}
              rules={[
                {
                  required: isUpdate ? false : true,
                  message: t('General.MandatoryField.Text'),
                },
              ]}
            >
              <Select
                showSearch
                mode="multiple"
                className="services-select"
                placeholder={t(
                  'Modal.IDPActivation.Steps.Third.Form.Service.Placeholder'
                )}
                onSelect={preventBaseNodeException}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {suggestedServicesList
                  .filter(filterServicesList)
                  .map((service, i) => (
                    <Option value={service?.name} key={i}>
                      {service?.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Form>
        )}
        {currentStep === 3 && (
          <Result
            status="success"
            title={t('Modal.IDPActivation.Steps.Fourth.Result.Title')}
            subTitle={t('Modal.IDPActivation.Steps.Fourth.Result.SubTitle')}
          />
        )}
      </div>
    </Modal>
  );
}

export default withTranslation()(ActivateIdpProviderModal);
