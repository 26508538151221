import HttpServices from './http.services';

class ProbeService {
  envSettings;
  httpService;
  constructor(envSettings) {
    this.envSettings = envSettings;
    this.httpService = new HttpServices(this.envSettings);
  }

  probe = () => {
    return this.httpService.httpServicesGet('probe', this.envSettings);
  };
}

export default ProbeService;
