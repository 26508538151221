import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  notification,
  Popconfirm,
  Row,
  Select,
  Spin,
  Switch,
  Tooltip,
  Upload,
  Modal,
  Space,
  message,
} from 'antd';
import {
  DeleteOutlined,
  DownloadOutlined,
  LoadingOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  UploadOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import './styles.less';
import { withTranslation } from 'react-i18next';
import ImgCrop from 'antd-img-crop';
import TextArea from 'antd/lib/input/TextArea';
import { isNilOrEmpty } from '../../../utility/utils';

const { Option } = Select;

function EditProjectDrawer({
  oAuthServices,
  onUpdate,
  onDelete,
  isVisible,
  handleCancel,
  oauthRightInfo,
  data,
  nodeServices,
  hasNodeChildren,
  rootNode,
  ctxAllowed,
  t,
}) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [jsonValue, setJsonValue] = useState();
  const [untouchableExtraOptions, setUntouchableExtraOptions] = useState();
  const [searchString, setSearchString] = useState();
  const [nodes, setNodes] = useState([]);
  const [isContextAllowedEnabled, setIsContextAllowedEnabled] = useState(false);
  const [isExtraOptionsChanged, setIsExtraOptionsChanged] = useState(false);
  const [logo_url, setLogoUrl] = useState();

  useEffect(() => {
    if (oauthRightInfo.uploadExtraOptions) {
      setUntouchableExtraOptions(data.extra_options);

      form.setFieldsValue({
        extraOptions: JSON.stringify(JSON.parse(data.extra_options), null, 2),
      });
    }

    setLogoUrl(data.app_logo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isNilOrEmpty(data.image)) {
      setImageUrl(data.image);
    }

    if (!isNilOrEmpty(ctxAllowed)) {
      setNodes(ctxAllowed);
      setIsContextAllowedEnabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctxAllowed]);

  const uploadButton = (
    <div>
      {isLoadingImage ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const downloadClientJson = () => {
    const jsonData = {
      client_id: data.client_id,
      client_secret: data.client_secret,
    };
    const a = document.createElement('a');
    const file = new Blob([JSON.stringify(jsonData)], {
      type: 'application/json',
    });
    a.href = URL.createObjectURL(file);
    a.download = data.name;
    a.click();
  };

  const beforeUpload = (file) => {
    const reader = new FileReader();
    const isLt1M = file.size / 1024 / 1024 < 1;

    if (!isLt1M) {
      message.error('Image must be smaller than 1MB!');
    } else {
      reader.onload = (event) => {
        if (typeof event.target?.result === 'string') {
          const base64string = event.target.result;
          if (base64string) {
            form.setFieldsValue({
              logoURL: null,
            });

            setLogoUrl(null);
            setImageUrl(base64string);
          }
        }
      };
      reader.onerror = (event) => {};
      reader.readAsDataURL(file);
    }

    // Prevent upload
    return false;
  };

  const onOperationClick = (operation) => {
    switch (operation) {
      case 'delete':
        setIsLoading(true);

        oAuthServices
          .removeCredential(data.client_id, data.node_id)
          .then(
            (response) => {
              if (response?.data) {
                if (oAuthServices.checkError(response)) {
                  const error = oAuthServices.getError(response);

                  Modal.error({
                    title: t('App.Message.General.ServerError.Title'),
                    content: error.message,
                  });
                } else {
                  handleCancel();
                  onDelete();

                  notification.success({
                    message: t(
                      'Drawer.Delete.Notification.Success.OAuth.Message'
                    ),
                    description: t(
                      'Drawer.Delete.Notification.Success.OAuth.Description'
                    ),
                  });
                }
              }
            },
            (error) => {
              Modal.error({
                title: t('App.Message.General.ServerError.Title'),
                content: error.message,
              });
            }
          )
          .finally(() => {
            setIsLoading(false);
          });
        break;

      case 'update':
        form.validateFields().then((values) => {
          setIsLoading(true);

          oAuthServices
            .updateCredential(
              imageUrl,
              values.project,
              values.displayedName,
              values.description,
              values.logoURL,
              values.allowedURIs,
              data.profile,
              data.client_id,
              values.nodeId,
              values.showAuthorizationPage,
              values.extraOptions || null
            )
            .then(
              (response) => {
                if (response?.data) {
                  if (oAuthServices.checkError(response)) {
                    const error = oAuthServices.getError(response);

                    Modal.error({
                      title: t('App.Message.General.ServerError.Title'),
                      content: error.message,
                    });
                  } else {
                    handleCancel();
                    onUpdate();

                    notification.success({
                      message: t(
                        'Modal.Modify.OAuth.Notification.Success.Message'
                      ),
                      description: t(
                        'Modal.Modify.OAuth.Notification.Success.Description'
                      ),
                    });
                  }
                }
              },
              (error) => {
                Modal.error({
                  title: t('App.Message.General.ServerError.Title'),
                  content: error.message,
                });
              }
            )
            .finally(() => {
              setIsLoading(false);
            });
        });
        break;

      default:
        break;
    }
  };

  let timeout;
  const getNodes = (value, callback) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    function fake() {
      nodeServices.searchNode(rootNode.nodeId, value).then(
        (response) => {
          if (response?.data) {
            if (nodeServices.checkError(response)) {
              const error = nodeServices.getError(response);

              Modal.error({
                title: t('App.Message.General.ServerError.Title'),
                content: error.message,
              });
            } else {
              const result = nodeServices.getResult(response);

              callback(result.childs);
            }
          }
        },
        (error) => {
          Modal.error({
            title: t('App.Message.General.ServerError.Title'),
            content: error.message,
          });
        }
      );
    }

    timeout = setTimeout(fake, 300);
  };

  const handleNodeSearch = (value) => {
    if (value) {
      getNodes(value, setNodes);
    } else {
      setNodes([]);
    }
  };
  const handleNodeChange = (value) => {
    setSearchString(value);
  };

  const beforeCrop = (file) => {
    if (file?.type === "image/svg+xml") {
      beforeUpload(file);
    } else {
      return true;
    }
  }

  return (
    <Drawer
      title="Edit project"
      className="editProjectDrawer"
      placement="right"
      width={560}
      visible={isVisible}
      onClose={() => {
        form.resetFields();
        setIsLoading(false);
        handleCancel();
      }}
      footer={
        <Row align="middle" justify="space-between">
          <Col>
            <Button onClick={handleCancel}>{t('Drawer.Buttons.Close')}</Button>
          </Col>
          <Col>
            <Row>
              {oauthRightInfo.deleteProject && (
                <Col style={{ marginRight: 8 }}>
                  <Popconfirm
                    title={t('Drawer.Delete.Confirm.OAuth')}
                    onConfirm={() => onOperationClick('delete')}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button danger icon={<DeleteOutlined />} type="primary">
                      {t('Drawer.Buttons.Delete')}
                    </Button>
                  </Popconfirm>
                </Col>
              )}
              {oauthRightInfo.updateProject && (
                <Col>
                  <Button
                    onClick={() => onOperationClick('update')}
                    type="primary"
                  >
                    {t('Drawer.Buttons.Update')}
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      }
    >
      <Spin spinning={isLoading}>
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            allowedURIs: data.allowed_URIs,
            logoURL: data.app_logo,
            extraOptions: data.extra_options,
            displayedName: data.app_name,
            description: data.description,
            project: data.name,
            clientCredentialsFlow: data.profile,
            nodeId: data.node_id,
            showAuthorizationPage: data.show_authorization_page,
          }}
        >
          <Row style={{ marginBottom: 24 }}>
            <Col xs={10}>
              <Row>
                <strong>{t('Modal.Modify.OAuth.Access.Label')}</strong>
              </Row>
              <Row>
                {t(
                  `Modal.Modify.OAuth.Access.Options.${
                    data.profile ? 'ClientCredentialsFlow' : 'Basic'
                  }`
                )}
              </Row>
            </Col>
            <Col xs={14}>
              {data.profile === 1 &&
                (isContextAllowedEnabled || hasNodeChildren) && (
                  <Form.Item
                    name="nodeId"
                    rules={[
                      {
                        required: oauthRightInfo.setCredentialsFlow,
                        message: t('General.MandatoryField.Text'),
                      },
                    ]}
                  >
                    <Select
                      showSearch={!isContextAllowedEnabled}
                      value={searchString}
                      placeholder={t(
                        'Modal.Shift.Account.Form.SearcNode.Placeholder'
                      )}
                      disabled={!oauthRightInfo.setCredentialsFlow}
                      defaultActiveFirstOption={false}
                      filterOption={false}
                      onSearch={handleNodeSearch}
                      onChange={handleNodeChange}
                      notFoundContent={null}
                    >
                      {nodes.map((node) => (
                        <Option key={node.name}>{node.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
            </Col>
          </Row>
          <Row>
            <Col xs={20}>
              <Row>
                <strong>{t('Modal.Modify.OAuth.Client.ID')}:&nbsp;</strong>
                {data.client_id}
              </Row>
              <Row style={{ wordBreak: 'break-all', marginTop: 8 }}>
                <strong>{t('Modal.Modify.OAuth.Client.Secret')}:&nbsp;</strong>
                <span>{data.client_secret}</span>
              </Row>
            </Col>
            <Col xs={4}>
              <Row>
                <Tooltip title={t('Modal.Modify.OAuth.Client.Download')}>
                  <Button
                    type="link"
                    onClick={downloadClientJson}
                    icon={<DownloadOutlined />}
                  />
                </Tooltip>
              </Row>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col xs={5}>
              <ImgCrop rotate beforeCrop={beforeCrop}>
                <Upload listType="picture-card" beforeUpload={beforeUpload}>
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{ width: '100%' }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </ImgCrop>
            </Col>
            <Col xs={19}>
              <Form.Item
                name="project"
                rules={[
                  {
                    required: true,
                    message: t('General.MandatoryField.Text'),
                  },
                ]}
              >
                <Input
                  placeholder={t('Modal.Modify.OAuth.Form.Project.Placeholder')}
                />
              </Form.Item>
              <Form.Item
                name="displayedName"
                rules={[
                  {
                    required: true,
                    message: t('General.MandatoryField.Text'),
                  },
                ]}
              >
                <Input
                  placeholder={t(
                    'Modal.Modify.OAuth.Form.DisplayedName.Placeholder'
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                  message: t('General.MandatoryField.Text'),
                },
              ]}
              style={{ width: '100%' }}
            >
              <Input
                placeholder={t(
                  'Modal.Modify.OAuth.Form.Description.Placeholder'
                )}
              />
            </Form.Item>
          </Row>
          <Row>
            <Col xs={12}>
              <Tooltip title={logo_url}>
                <Form.Item
                  name="logoURL"
                  rules={[
                    {
                      pattern: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi,
                      message: 'Logo URL must a valid http/https url',
                    },
                    {
                      max: 2048,
                      message: 'Logo URL cannot be longer than 2048 characters',
                    },
                  ]}
                >
                  <Input
                    placeholder={t(
                      'Modal.Modify.OAuth.Form.LogoURL.Placeholder'
                    )}
                    onChange={() => {
                      setImageUrl(null);
                      setLogoUrl(form.getFieldValue('logoURL'));
                    }}
                  />
                </Form.Item>
              </Tooltip>
            </Col>
            {!isNilOrEmpty(logo_url) && (
              <Col xs={12}>
                <center>
                  <img src={logo_url} alt={logo_url} style={{ width: 150 }} />
                </center>
              </Col>
            )}
          </Row>
          <Divider />
          {oauthRightInfo.uploadExtraOptions && (
            <Col>
              <Space>
                <strong>Extra options</strong>
                <Row>
                  <Col>
                    <Upload
                      fileList={null}
                      accept=".json"
                      beforeUpload={(file, fileList) => {
                        if (fileList[0].type !== 'application/json') {
                          return notification.error({
                            message: t(
                              'Notification.Error.WrongFileType.Message'
                            ),
                            description: t(
                              'Notification.Error.WrongFileType.Description',
                              { fileType: 'json' }
                            ),
                          });
                        }

                        const reader = new FileReader();

                        reader.onload = (e) => {
                          form.setFieldsValue({
                            extraOptions: e.target.result,
                          });

                          setJsonValue(e.target.result);
                        };
                        reader.readAsText(file);

                        return false;
                      }}
                      showUploadList={false}
                      maxCount={1}
                      style={{
                        marginRight: 0,
                      }}
                    >
                      <Button type="link" icon={<UploadOutlined />} />
                    </Upload>
                  </Col>
                  <Col>
                    {(!isNilOrEmpty(jsonValue) || isExtraOptionsChanged) && (
                      <Button
                        type="text"
                        danger
                        onClick={() => {
                          form.setFieldsValue({
                            extraOptions: untouchableExtraOptions,
                          });
                          setIsExtraOptionsChanged(false);
                          setJsonValue(null);
                        }}
                        icon={<UndoOutlined />}
                      />
                    )}
                  </Col>
                </Row>
              </Space>
              {(!isNilOrEmpty(data.extra_options) ||
                !isNilOrEmpty(jsonValue)) && (
                <Form.Item style={{ width: '100%' }} name="extraOptions">
                  <TextArea
                    style={{ height: 150 }}
                    onChange={() => setIsExtraOptionsChanged(true)}
                  />
                </Form.Item>
              )}
              <Divider />
            </Col>
          )}
          <Row>
            <strong style={{ marginBottom: 8 }}>Allowed Redirect Uri</strong>
            <Form.List name="allowedURIs">
              {(fields, { add, remove }) => (
                <Col xs={24}>
                  {fields.map((field) => (
                    <Form.Item required={false} key={field.key}>
                      <Form.Item
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            message: 'Please input URL or delete this field.',
                          },
                        ]}
                        noStyle
                      >
                        <Input
                          placeholder={t(
                            'Modal.Modify.OAuth.Form.URL.Placeholder'
                          )}
                          style={{ width: '90%' }}
                        />
                      </Form.Item>
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                        disabled={fields.length <= 1}
                        style={{
                          pointerEvents: fields.length <= 1 ? 'none' : 'auto',
                        }}
                      />
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={{ width: '100%' }}
                      icon={<PlusOutlined />}
                    >
                      {t('Modal.Modify.OAuth.Form.AddURL.Label')}
                    </Button>
                  </Form.Item>
                </Col>
              )}
            </Form.List>
          </Row>
          {oauthRightInfo.setAuthPage && (
            <>
              <Divider />
              <Row style={{ alignItems: 'center' }} gutter={12}>
                <Col>
                  <Form.Item
                    name="showAuthorizationPage"
                    valuePropName="checked"
                    style={{ marginBottom: 0 }}
                  >
                    <Switch />
                  </Form.Item>
                </Col>
                <Col>Show authorization page</Col>
              </Row>
            </>
          )}
        </Form>
      </Spin>
    </Drawer>
  );
}

export default withTranslation()(EditProjectDrawer);
