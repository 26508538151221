const refreshTreeItemManager = (state = { refreshTreeItem: false }, action) => {
  let nextState = state;

  switch (action.type) {
    case 'START_REFRESH_TREE_ITEM':
      nextState = {
        refreshTreeItem: true,
      };
      return nextState;

    case 'STOP_REFRESH_TREE_ITEM':
      nextState = {
        refreshTreeItem: false,
      };
      return nextState;

    default:
      return nextState;
  }
};

export default refreshTreeItemManager;
