import { Col, Form, Input, InputNumber, Modal, Row, Spin } from 'antd';
import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import './styles.less';

function EditCompanyModal({
  data,
  isModalVisible,
  handleOk,
  handleCancel,
  t,
}) {
  const [form] = Form.useForm();
  const { companyName, companyId } = data;
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal
      className="editCompanyModal"
      title={t('Modal.Modify.Company.Title', { nodeName: companyId })}
      visible={isModalVisible}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            setIsLoading(true);
            handleOk(values);
          })
          .catch((info) => {});
      }}
      onCancel={handleCancel}
      afterClose={() => {
        setIsLoading(false);
        form.resetFields();
      }}
      okButtonProps={{
        loading: isLoading,
      }}
      ov
      okText={t('Modal.Modify.Company.Buttons.Confirm')}
      cancelButtonProps={{
        disabled: isLoading,
      }}
      cancelText={t('Modal.Modify.Company.Buttons.Close')}
      closable={!isLoading}
    >
      <Spin spinning={isLoading}>
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            companyId,
            companyName,
          }}
        >
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item
                name="companyId"
                label={t('Modal.Modify.Company.Form.CompanyId.Label')}
                rules={[
                  {
                    required: true,
                    message: t('General.MandatoryField.Text'),
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            {/* <Col md={12}>
              <Form.Item
                name="maxFolders"
                label={t('Modal.Modify.Company.Form.MaxFolders.Label')}
              >
                <InputNumber
                  disabled={!canEditMaxFolders}
                  min={0}
                  type="number"
                  placeholder={t(
                    'Modal.Modify.Company.Form.MaxFolders.Placeholder'
                  )}
                />
              </Form.Item>
            </Col> */}
          </Row>
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item
                name="companyName"
                label={t('Modal.Modify.Company.Form.CompanyName.Label')}
                rules={[
                  {
                    required: true,
                    message: t('General.MandatoryField.Text'),
                  },
                ]}
              >
                <Input
                  placeholder={t(
                    'Modal.Modify.Company.Form.CompanyName.Placeholder'
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

export default withTranslation()(EditCompanyModal);
