import { Progress } from '@ant-design/charts';
import { Col, Row, Skeleton, Typography } from 'antd';
import { withTranslation } from 'react-i18next';
import './styles.less';

const { Text } = Typography;

function ValidSignFoldersCounter({
  t,
  percentage,
  overviewIsLoaded,
  foldersStarted,
  maxFolders,
}) {
  const getRangeColor = () => {
    const GREEN = '#52C41A';
    const ORANGE = '#FAAD14';
    const RED = '#FF4D4F';

    if (percentage > 0 && percentage <= 0.4) return GREEN;
    else if (percentage > 0.4 && percentage <= 0.7) return ORANGE;
    else return RED;
  };

  const progressConfig = {
    height: 50,
    width: 300,
    autoFit: false,
    barWidthRatio: 0.5,
    percent: percentage,
    color: [getRangeColor(), '#E8EDF3'],
  };

  return (
    <div className="validSignFoldersCounter">
      {overviewIsLoaded ? (
        foldersStarted === 0 && maxFolders === 0 ? (
          <Row align="middle" gutter={32}>
            <Col>
              <Text type="warning">
                {t('PageContainer.Validsign.Overview.NoFoldersAvailable')}
              </Text>
            </Col>
          </Row>
        ) : (
          <>
            <Row align="middle" gutter={32}>
              <Col>
                <span className="validSignFoldersCounter-value">
                  {foldersStarted || 'N/D'}{' '}
                </span>
                <span style={{ color: getRangeColor() }}>
                  {t(
                    'PageContainer.Validsign.Overview.CompanyInfo.FoldersStarted'
                  )}
                </span>
              </Col>
              <Col>
                <span className="validSignFoldersCounter-value">
                  {maxFolders || 'N/D'}{' '}
                </span>
                <span style={{ color: '#b7b7b7' }}>
                  {t(
                    'PageContainer.Validsign.Overview.CompanyInfo.MaxNumberOfFolders'
                  )}
                </span>
              </Col>
            </Row>
            <Progress {...progressConfig} />
          </>
        )
      ) : (
        <Skeleton active />
      )}
    </div>
  );
}

export default withTranslation()(ValidSignFoldersCounter);
