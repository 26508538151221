import React, { Component } from 'react';
import _ from 'lodash';
import { DASHBOARD, LOGOUT } from '../../constants/routes';
import { Layout } from 'antd';

class Logout extends Component {
  env;

  constructor(props) {
    super(props);
    this.state = {};
    if (_.isString(process.env.REACT_APP_ENV)) {
      this.env = process.env.REACT_APP_ENV;
    } else {
      this.env = this.props.globalSettings.DefaultEnvironment;
    }
  }

  componentWillMount = () => {
    if (this.env === 'local') {
      window.location.href = DASHBOARD;
    } else {
      window.location.href = LOGOUT;
    }
  };

  render = () => {
    return (
      <Layout>
        <div className="logout" />
      </Layout>
    );
  };
}

export default Logout;
