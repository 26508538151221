export const openModalInfo = (isOpen, title, message) => ({
  type: 'OPEN_MODAL_INFO',
  isOpen,
  title,
  message,
});

export const openModalChangeItemLinkStatus = (
  isOpen,
  title,
  nodeId,
  nodeName,
  section,
  payload,
  message
) => ({
  type: 'OPEN_MODAL_CHANGE_ITEM_LINK_STATUS',
  isOpen,
  title,
  nodeId,
  nodeName,
  section,
  payload,
  message,
});

export const openModalAdd = (
  isOpen,
  title,
  nodeId,
  nodeName,
  section,
  message,
  options
) => ({
  type: 'OPEN_MODAL_ADD',
  isOpen,
  title,
  nodeId,
  nodeName,
  section,
  message,
  options,
});

export const openModalModify = (
  isOpen,
  title,
  nodeId,
  nodeName,
  section,
  payload,
  message
) => ({
  type: 'OPEN_MODAL_MODIFY',
  isOpen,
  title,
  nodeId,
  nodeName,
  section,
  payload,
  message,
});

export const openModalDelete = (
  isOpen,
  title,
  nodeId,
  nodeName,
  section,
  payload,
  message
) => ({
  type: 'OPEN_MODAL_DELETE',
  isOpen,
  title,
  nodeId,
  nodeName,
  section,
  payload,
  message,
});

export const openModalDisable = (
  isOpen,
  title,
  nodeId,
  nodeName,
  section,
  payload,
  message
) => ({
  type: 'OPEN_MODAL_DISABLE',
  isOpen,
  title,
  nodeId,
  nodeName,
  section,
  payload,
  message,
});

export const openModalShiftNode = (
  isOpen,
  title,
  nodeId,
  nodeName,
  section,
  payload,
  message
) => ({
  type: 'OPEN_MODAL_SHIFT_NODE',
  isOpen,
  title,
  nodeId,
  nodeName,
  section,
  payload,
  message,
});

export const openModalActivationEmail = (
  isOpen,
  title,
  section,
  payload,
  message
) => ({
  type: 'OPEN_MODAL_ACTIVTION_EMAIL',
  isOpen,
  title,
  section,
  payload,
  message,
});

export const openModalError = (isOpen, title, message) => ({
  type: 'OPEN_MODAL_ERROR',
  isOpen,
  title,
  message,
});

export const closeModal = () => ({
  type: 'CLOSE_MODAL',
});
