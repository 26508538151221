import { connect } from 'react-redux';
import Roles from './Roles';
import { stopReloadPage } from '../../../actions/reload-page-manager';

const mapStateToProps = (state) => ({
  globalSettings: state.globalSettingsManager.globalSettings,
  selectedNode: state.selectedNodeManager.node,
  reloadPage: state.reloadPageManager.reloadPage,
  roles: state.userManager.user.roles,
  rootNode: state.userManager.user.accountInfo
    ? state.userManager.user.accountInfo.nodeData
    : {},
  mainColor: state.userManager.mainColor,
});

const mapDispatchToProps = (dispatch) => ({
  stopReloadPage: () => dispatch(stopReloadPage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
