import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import './i18n.js';
import App from './app.connector';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider, Spin } from 'antd';
import { Provider } from 'react-redux';
import reducers from './reducers';
import { createStore } from 'redux';

const store = createStore(
  reducers,
  typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
    ? // Call the brower extension function to create the enhancer.
      window.__REDUX_DEVTOOLS_EXTENSION__()
    : // Else we return a no-op function.
      (f) => f
);

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <ConfigProvider componentSize="large">
        <Suspense fallback={<Spin />}>
          <App />
        </Suspense>
      </ConfigProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
