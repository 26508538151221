import { Modal, Select, TreeSelect } from 'antd';
import _ from 'lodash';
import NodeServices from '../../services/node.services';
import LogsFirer from '../../services/logs-firer.services';
import { Component } from 'react';
import './styles.less';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import { OAUTH_CONSOLE } from '../../constants/routes';

const { Option } = Select;

class TreeView extends Component {
  nodeServices;
  logFirer;
  env;
  timeout;

  constructor(props) {
    super(props);

    if (_.isString(process.env.REACT_APP_ENV)) {
      this.env = process.env.REACT_APP_ENV;
    } else {
      this.env = this.props.globalSettings.DefaultEnvironment;
    }

    this.state = {
      initTree: false,
      searchString: null,
      nodes: [],
    };

    this.nodeServices = new NodeServices(
      this.props.globalSettings.Environment[this.env]
    );
    this.logFirer = new LogsFirer(
      this.props.globalSettings.Environment[this.env]
    );

    window.less.refreshStyles();
  }

  onChange = async (value) => {
    const { tree, setSelectedNode, setTree } = this.props;
    const nodeId = tree.find((node) => node.value === value)?.id;

    if (!nodeId) return;

    this.nodeServices.getNodeChildren(nodeId).then(
      (response) => {
        if (response?.data) {
          if (this.nodeServices.checkError(response)) {
            const error = this.nodeServices.getError(response);

            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          } else {
            const result = this.nodeServices.getResult(response);
            let newTree = [];

            result.childs.forEach((child) =>
              newTree.push({
                id: child.nodeId,
                pId: child.parentId,
                value: child.name,
                key: child.name,
                title: child.name,
                ...(child.children <= 0 && {
                  isLeaf: true,
                }),
              })
            );

            setTree(newTree);

            this.setState({
              searchString: null,
              nodes: [],
            });

            setSelectedNode({
              nodeId,
              nodeName: value,
            });
          }
        }
      },
      (error) => {
        if (axios.isCancel(error)) {
          this.logFirer.fireLog('LOG', error);
        } else {
          Modal.error({
            title: this.props.t('App.Message.General.ServerError.Title'),
            content: error.message,
          });
        }
      }
    );
  };

  initTree = (nodeId) => {
    this.setState({
      searchString: null,
      nodes: [],
    });
    this.nodeServices.getNodeChildren(nodeId).then(
      (response) => {
        if (response?.data) {
          if (this.nodeServices.checkError(response)) {
            const error = this.nodeServices.getError(response);

            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          } else {
            const result = this.nodeServices.getResult(response);
            let newTree = [];

            result.childs.forEach((child) =>
              newTree.push({
                id: child.nodeId,
                pId: child.parentId,
                value: child.name,
                key: child.name,
                title: child.name,
                ...(child.children <= 0 && {
                  isLeaf: true,
                }),
              })
            );

            this.props.setTree(newTree);
          }
        }
      },
      (error) => {
        if (axios.isCancel(error)) {
          this.logFirer.fireLog('LOG', error);
        } else {
          Modal.error({
            title: this.props.t('App.Message.General.ServerError.Title'),
            content: error.message,
          });
        }
      }
    );
  };

  UNSAFE_componentWillReceiveProps = () => {
    if (
      this.props.selectedNode.nodeId &&
      !_.isNaN(this.props.selectedNode.nodeId) &&
      _.isEmpty(this.props.tree) &&
      !this.state.initTree
    ) {
      this.setState({
        initTree: true,
      });
      this.initTree(this.props.selectedNode.nodeId);
    }
  };

  onLoadData = (tree) => {
    return new Promise((resolve) => {
      this.nodeServices.getNodeChildren(tree.id).then(
        (response) => {
          if (response?.data) {
            if (this.nodeServices.checkError(response)) {
              const error = this.nodeServices.getError(response);

              Modal.error({
                title: this.props.t('App.Message.General.ServerError.Title'),
                content: error.message,
              });
            } else {
              const result = this.nodeServices.getResult(response);

              const { tree, setTree } = this.props;
              let newTree = [...tree];

              result.childs.forEach((child) =>
                newTree.push({
                  id: child.nodeId,
                  pId: child.parentId,
                  value: child.name,
                  key: child.name,
                  title: child.name,
                  ...(child.children <= 0 && {
                    isLeaf: true,
                  }),
                })
              );

              setTree(newTree);
            }
          }
          resolve();
        },
        (error) => {
          if (axios.isCancel(error)) {
            this.logFirer.fireLog('LOG', error);
          } else {
            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
          resolve();
        }
      );
    });
  };

  getNodes = (value, callback) => {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    const that = this;

    function fake() {
      if (!that.props.rootNode?.nodeId) {
        return;
      }

      that.nodeServices.searchNode(that.props.rootNode.nodeId, value).then(
        (response) => {
          if (response?.data) {
            if (that.nodeServices.checkError(response)) {
            } else {
              const result = that.nodeServices.getResult(response);

              callback(result.childs);
            }
          }
        },
        (error) => {
          if (axios.isCancel(error)) {
            that.logFirer.fireLog('LOG', error);
          } else {
            Modal.error({
              title: that.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
    }

    this.timeout = setTimeout(fake, 300);
  };

  handleSearch = (value) => {
    if (value) {
      this.getNodes(value, (data) => this.setState({ nodes: data }));
    } else {
      this.setState({ nodes: [] });
    }
  };

  handleChange = (value) => {
    const nodeId = parseInt(value, 10);

    this.nodeServices.getNodeChildren(nodeId).then(
      (response) => {
        if (response?.data) {
          if (this.nodeServices.checkError(response)) {
            const error = this.nodeServices.getError(response);

            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          } else {
            const result = this.nodeServices.getResult(response);
            let newTree = [];

            result.childs.forEach((child) =>
              newTree.push({
                id: child.nodeId,
                pId: child.parentId,
                value: child.name,
                key: child.name,
                title: child.name,
                ...(child.children <= 0 && {
                  isLeaf: true,
                }),
              })
            );

            this.props.setTree(newTree);

            this.props.setSelectedNode({
              nodeId,
              nodeName: this.state.nodes.find((node) => node.nodeId === nodeId)
                ?.name,
            });

            this.setState({
              searchString: null,
              nodes: [],
            });
          }
        }
      },
      (error) => {
        if (axios.isCancel(error)) {
          this.logFirer.fireLog('LOG', error);
        } else {
          Modal.error({
            title: this.props.t('App.Message.General.ServerError.Title'),
            content: error.message,
          });
        }
      }
    );
  };

  render = () => {
    const {
      tree,
      selectedNode: { nodeName },
      mainColor,
    } = this.props;

    return (
      <section className="treeManager">
        {window.location.pathname !== OAUTH_CONSOLE && (
          <>
            <span style={{ color: mainColor }}>
              {this.props.t('TopBar.TreeManager.Title')}
            </span>
            <TreeSelect
              className="treeManager-select"
              treeDataSimpleMode
              value={nodeName}
              treeNodeFilterProp="title"
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              onChange={this.onChange}
              loadData={this.onLoadData}
              treeData={tree}
              style={{
                background: mainColor,
                borderColor: mainColor,
              }}
            />
            <Select
              showSearch
              className="treeManager-search"
              value={this.state.searchString}
              placeholder={this.props.t('GlobalNodeSearch.Search.Placeholder')}
              defaultActiveFirstOption={false}
              suffixIcon={<SearchOutlined />}
              filterOption={false}
              onSearch={this.handleSearch}
              onChange={this.handleChange}
              notFoundContent={null}
            >
              {this.state.nodes.map((node) => (
                <Option key={node.nodeId}>{node.name}</Option>
              ))}
            </Select>
          </>
        )}
      </section>
    );
  };
}

export default withTranslation()(TreeView);
