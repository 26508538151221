import {
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
} from 'antd';
import './styles.less';
import { ReactComponent as ItalyIco } from '../../../assets/icons/ico-flag-it.svg';
import { ReactComponent as UkIco } from '../../../assets/icons/ico-flag-uk.svg';
import { ReactComponent as EsIco } from '../../../assets/icons/ico-flag-es.svg';
import { ReactComponent as FrIco } from '../../../assets/icons/ico-flag-fr.svg';
import { withTranslation } from 'react-i18next';
import { useState } from 'react';
import PhoneInputWrapper from '../../PhoneInputWrapper';

const { Option } = Select;

function AddAccountModal({
  context = 'ACCOUNT',
  title,
  assignableRole,
  isModalVisible,
  onConfirm,
  handleCancel,
  t,
}) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const langOptionStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  };

  return (
    <Modal
      className="addAccountModal"
      title={title}
      visible={isModalVisible}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            setIsLoading(true);
            onConfirm(values);
          })
          .catch((info) => {});
      }}
      onCancel={handleCancel}
      afterClose={() => {
        setIsLoading(false);
        form.resetFields();
      }}
      okButtonProps={{
        loading: isLoading,
      }}
      cancelButtonProps={{
        disabled: isLoading,
      }}
      closable={!isLoading}
    >
      <Spin spinning={isLoading}>
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            language: 'EN',
            prefix: '+39',
            sendActivationEmail: false,
          }}
        >
          <Row gutter={16}>
            <Col xs={16}>
              <Form.Item
                name="login"
                rules={[
                  {
                    required: true,
                    message: t('General.MandatoryField.Text'),
                  },
                ]}
              >
                <Input
                  placeholder={t('Modal.Add.Account.Form.Login.Placeholder')}
                />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item name="language">
                <Select>
                  <Option value="EN">
                    <div style={langOptionStyle}>
                      <UkIco
                        style={{ width: 20, height: 20, marginRight: 8 }}
                      />
                      <span>{t('App.Languages.ENGLISH')}</span>
                    </div>
                  </Option>
                  <Option value="IT">
                    <div style={langOptionStyle}>
                      <ItalyIco
                        style={{ width: 20, height: 20, marginRight: 8 }}
                      />
                      <span>{t('App.Languages.ITALIAN')}</span>
                    </div>
                  </Option>
                  <Option value="ES">
                    <div style={langOptionStyle}>
                      <EsIco
                        style={{ width: 20, height: 20, marginRight: 8 }}
                      />
                      <span>{t('App.Languages.SPANISH')}</span>
                    </div>
                  </Option>
                  <Option value="FR">
                    <div style={langOptionStyle}>
                      <FrIco
                        style={{ width: 20, height: 20, marginRight: 8 }}
                      />
                      <span>{t('App.Languages.FRENCH')}</span>
                    </div>
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {context === 'VALIDDRIVER' && (
            <Row gutter={16}>
              <Col xs={24}>
                <Form.Item
                  name="selectedRole"
                  rules={[
                    {
                      required: true,
                      message: t('General.MandatoryField.Text'),
                    },
                  ]}
                >
                  <Select
                    style={{ width: '100%' }}
                    placeholder={t(
                      'Modal.Add.Validdriver.Form.selectedRole.Label'
                    )}
                  >
                    {assignableRole.map((role, key) => {
                      return (
                        <Option key={key} value={role}>
                          {t('App.AssignableRole.Role.' + role)}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'Please input a valid e-mail!',
                  },
                  {
                    required: true,
                    message: t('General.MandatoryField.Text'),
                  },
                ]}
              >
                <Input
                  placeholder={t('Modal.Add.Account.Form.Email.Placeholder')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={12}>
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: t('General.MandatoryField.Text'),
                  },
                ]}
              >
                <Input
                  placeholder={t('Modal.Add.Account.Form.Name.Placeholder')}
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: t('General.MandatoryField.Text'),
                  },
                ]}
              >
                <Input
                  placeholder={t('Modal.Add.Account.Form.Surname.Placeholder')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item name="phone">
                <PhoneInputWrapper
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Form.Item name="sendActivationEmail" valuePropName="checked">
            <Checkbox>
              {t('Modal.Add.Account.Form.ForceSendingActivationEmail.Label')}
            </Checkbox>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}

export default withTranslation()(AddAccountModal);
