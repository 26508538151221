import { connect } from 'react-redux';
import { setSelectedNode } from '../../../actions/selected-node-manager';
import ActivateIdpProviderModal from './ActivateIdpProviderModal';

const mapStateToProps = (state) => ({
  globalSettings: state.globalSettingsManager?.globalSettings,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedNode: (selectedNode) => dispatch(setSelectedNode(selectedNode)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivateIdpProviderModal);
