import {
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
  Tooltip,
} from 'antd';
import './styles.less';
import { ReactComponent as ItalyIco } from '../../../assets/icons/ico-flag-it.svg';
import { ReactComponent as UkIco } from '../../../assets/icons/ico-flag-uk.svg';
import { ReactComponent as EsIco } from '../../../assets/icons/ico-flag-es.svg';
import { ReactComponent as FrIco } from '../../../assets/icons/ico-flag-fr.svg';
import { useState } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import PhoneInputWrapper from '../../PhoneInputWrapper';

const { Option } = Select;

function ValidSignAccountModal({
  isModalVisible,
  onConfirm,
  handleCancel,
  assignableRole,
  title,
  t,
}) {
  const [form] = Form.useForm();
  const [phoneNumber, setPhoneNumber] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [userType, setUserType] = useState();
  const langOptionStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  };
  const extTooltip = (title) => (
    <Tooltip title={title}>
      <QuestionCircleOutlined />
    </Tooltip>
  );

  const onUserTypeChange = (userType) => {
    setUserType(userType);
  };

  return (
    <Modal
      className="validSignAccountModal"
      title={title}
      visible={isModalVisible}
      onOk={() => {
        form.validateFields().then((values) => {
          setIsLoading(true);
          onConfirm(values);
        });
      }}
      onCancel={handleCancel}
      afterClose={() => {
        form.resetFields();
        setUserType();
        setIsLoading(false);
      }}
      okButtonProps={{
        loading: isLoading,
      }}
      okText={t('Modal.Add.Validsign.Buttons.Add')}
      cancelText={t('Modal.Add.Validsign.Buttons.Close')}
      cancelButtonProps={{
        disabled: isLoading,
      }}
      closable={!isLoading}
    >
      <Spin spinning={isLoading}>
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            language: 'EN',
            prefix: '+39',
            forceSendingActivationEmail: true,
          }}
        >
          <Row gutter={16}>
            <Col xs={16}>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'Please input a valid e-mail!',
                  },
                  {
                    required: true,
                    message: t('General.MandatoryField.Text'),
                  },
                ]}
              >
                <Input
                  placeholder={t('Modal.Add.Validsign.Form.Email.Label')}
                />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item name="language">
                <Select>
                  <Option value="EN">
                    <div style={langOptionStyle}>
                      <UkIco
                        style={{ width: 20, height: 20, marginRight: 8 }}
                      />
                      <span>{t('App.Languages.ENGLISH')}</span>
                    </div>
                  </Option>
                  <Option value="IT">
                    <div style={langOptionStyle}>
                      <ItalyIco
                        style={{ width: 20, height: 20, marginRight: 8 }}
                      />
                      <span>{t('App.Languages.ITALIAN')}</span>
                    </div>
                  </Option>
                  <Option value="ES">
                    <div style={langOptionStyle}>
                      <EsIco
                        style={{ width: 20, height: 20, marginRight: 8 }}
                      />
                      <span>{t('App.Languages.SPANISH')}</span>
                    </div>
                  </Option>
                  <Option value="FR">
                    <div style={langOptionStyle}>
                      <FrIco
                        style={{ width: 20, height: 20, marginRight: 8 }}
                      />
                      <span>{t('App.Languages.FRENCH')}</span>
                    </div>
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={12}>
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: t('General.MandatoryField.Text'),
                  },
                ]}
              >
                <Input placeholder={t('Modal.Add.Validsign.Form.Name.Label')} />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: t('General.MandatoryField.Text'),
                  },
                ]}
              >
                <Input
                  placeholder={t('Modal.Add.Validsign.Form.Surname.Label')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: t('General.MandatoryField.Text'),
                  },
                ]}
              >
                <PhoneInputWrapper
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <div className="userType-section">
            <Form.Item
              name="userType"
              label={t('Modal.Add.Validsign.Form.UserType.Label')}
              className="userType-section-inputSelect"
              rules={[
                {
                  required: true,
                  message: t('General.MandatoryField.Text'),
                },
              ]}
            >
              <Select
                placeholder={t('App.AssignableRole.Label')}
                onChange={onUserTypeChange}
              >
                {assignableRole.map((role) => {
                  if (
                    role === 'validsign_user' ||
                    role === 'vs_admin' ||
                    role === 'vs_admin_ext' ||
                    role === 'vs_admin_adv' ||
                    role === 'vs_group_admin'
                  ) {
                    return (
                      <Option value={role}>
                        {t(`App.AssignableRole.Role.${role}`)}
                      </Option>
                    );
                  } else return null;
                })}
              </Select>
            </Form.Item>
            {userType === 'validsign_user' ? (
              <>
                <Row className="roles-switch" gutter={[8, 8]}>
                  <Col>
                    <Form.Item name="validsign_basic">
                      <Switch defaultChecked disabled />
                    </Form.Item>
                  </Col>
                  <Col>
                    {t('Modal.Add.Validsign.UserTypes.OnlyQuickSign.Label')}{' '}
                    {extTooltip(
                      t('Modal.Add.Validsign.UserTypes.OnlyQuickSign.Tooltip')
                    )}
                  </Col>
                </Row>
                {assignableRole.indexOf('validsign_user') > -1 && (
                  <Row className="roles-switch" gutter={[8, 8]}>
                    <Col>
                      <Form.Item name="validsign_user">
                        <Switch />
                      </Form.Item>
                    </Col>
                    <Col>
                      {t('Modal.Add.Validsign.UserTypes.FolderCreator.Label')}{' '}
                      {extTooltip(
                        t('Modal.Add.Validsign.UserTypes.FolderCreator.Tooltip')
                      )}
                    </Col>
                  </Row>
                )}
                {assignableRole.indexOf('validsign_basic_cloud') > -1 && (
                  <Row className="roles-switch" gutter={[8, 8]}>
                    <Col>
                      <Form.Item name="validsign_basic_cloud">
                        <Switch />
                      </Form.Item>
                    </Col>
                    <Col>
                      {t(
                        'Modal.Add.Validsign.UserTypes.OnlyQuickSignCloud.Label'
                      )}{' '}
                      {extTooltip(
                        t(
                          'Modal.Add.Validsign.UserTypes.OnlyQuickSignCloud.Tooltip'
                        )
                      )}
                    </Col>
                  </Row>
                )}
                {assignableRole.indexOf('validsign_user_cloud') > -1 && (
                  <Row className="roles-switch" gutter={[8, 8]}>
                    <Col>
                      <Form.Item name="validsign_user_cloud">
                        <Switch />
                      </Form.Item>
                    </Col>
                    <Col>
                      {t(
                        'Modal.Add.Validsign.UserTypes.FolderCreatorCloud.Label'
                      )}{' '}
                      {extTooltip(
                        t(
                          'Modal.Add.Validsign.UserTypes.FolderCreatorCloud.Tooltip'
                        )
                      )}
                    </Col>
                  </Row>
                )}
              </>
            ) : userType === 'vs_admin' ||
              userType === 'vs_admin_ext' ||
              userType === 'vs_admin_adv' ||
              userType === 'vs_group_admin' ? (
              <>
                {assignableRole.indexOf('validsign_user') > -1 && (
                  <Row className="roles-switch" gutter={[8, 8]}>
                    <Col>
                      <Form.Item name="validsign_user">
                        <Switch />
                      </Form.Item>
                    </Col>
                    <Col>
                      {t('Modal.Add.Validsign.UserTypes.FolderCreator.Label')}{' '}
                      {extTooltip(
                        t('Modal.Add.Validsign.UserTypes.FolderCreator.Tooltip')
                      )}
                    </Col>
                  </Row>
                )}
                {assignableRole.indexOf('validsign_user_cloud') > -1 && (
                  <Row className="roles-switch" gutter={[8, 8]}>
                    <Col>
                      <Form.Item name="validsign_user_cloud">
                        <Switch />
                      </Form.Item>
                    </Col>
                    <Col>
                      {t(
                        'Modal.Add.Validsign.UserTypes.FolderCreatorCloud.Label'
                      )}{' '}
                      {extTooltip(
                        t(
                          'Modal.Add.Validsign.UserTypes.FolderCreatorCloud.Tooltip'
                        )
                      )}
                    </Col>
                  </Row>
                )}
                {/* {assignableRole.indexOf('vs_admin_adv') > -1 && (
                  <Row className="roles-switch" gutter={[8, 8]}>
                    <Col>
                      <Form.Item name="vs_admin_adv">
                        <Switch />
                      </Form.Item>
                    </Col>
                    <Col>
                      {t('Modal.Add.Validsign.UserTypes.WithSettings.Label')}{' '}
                      {extTooltip(
                        t('Modal.Add.Validsign.UserTypes.WithSettings.Tooltip')
                      )}
                    </Col>
                  </Row>
                )} */}
                {/* {assignableRole.indexOf('vs_admin_ext') > -1 && (
                  <Row className="roles-switch" gutter={[8, 8]}>
                    <Col>
                      <Form.Item name="vs_admin_ext">
                        <Switch />
                      </Form.Item>
                    </Col>
                    <Col>
                      {t('Modal.Add.Validsign.UserTypes.WithSubc.Label')}{' '}
                      {extTooltip(
                        t('Modal.Add.Validsign.UserTypes.WithSubc.Tooltip')
                      )}
                    </Col>
                  </Row>
                )} */}
                {/* {assignableRole.indexOf('validsign_administrator_cloud') >
                  -1 && (
                  <Row className="roles-switch" gutter={[8, 8]}>
                    <Col>
                      <Form.Item name="validsign_administrator_cloud">
                        <Switch />
                      </Form.Item>
                    </Col>
                    <Col>
                      {t('Modal.Add.Validsign.UserTypes.WithIGCloud.Label')}{' '}
                      {extTooltip(
                        t('Modal.Add.Validsign.UserTypes.WithIGCloud.Tooltip')
                      )}
                    </Col>
                  </Row>
                )} */}
              </>
            ) : null}
          </div>
          <Divider />
          <Form.Item name="forceSendingActivationEmail" valuePropName="checked">
            <Checkbox>
              {t('Modal.Add.Account.Form.ForceSendingActivationEmail.Label')}
            </Checkbox>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}

export default withTranslation()(ValidSignAccountModal);
