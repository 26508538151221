import {
  Button,
  Col,
  Input,
  Layout,
  message,
  Modal,
  notification,
  Popover,
  Row,
  Space,
  Switch,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { PlusOutlined, GlobalOutlined } from '@ant-design/icons';
import { Component } from 'react';
import './styles.less';
import axios from 'axios';
import LogsFirer from '../../../services/logs-firer.services';
import AppUtil from '../../../utility/admin.util';
import _ from 'lodash';
import AccountServices from '../../../services/account.services';
import { withTranslation } from 'react-i18next';
import AddAccountModal from '../../../components/Modals/AddAccountModal';
import EditAccountDrawer from '../../../components/Drawers/EditAccountDrawer';
import NodeServices from '../../../services/node.services';
import { getStatus, isNilOrEmpty } from '../../../utility/utils';

const { Title } = Typography;
const { Search } = Input;

class Accounts extends Component {
  assignableRole;
  offline;
  env;
  timer;
  stopStatePropagation;
  accountServices;
  nodeServices;
  logFirer;
  isTreeViewAllowed;

  constructor(props) {
    super(props);

    let filteredRoles;
    let filteredRolesRoleManager;
    let rightInfoList;
    let rightInfoListRoleManager;
    let permissionListRoleManager;
    let permissionList;
    let userAccountRightInfo;

    this.isTreeViewAllowed = this.props.roles.some((r) => {
      return Object.entries(r.attributes).some(([_, value]) => {
        return value.rightInfo && value.rightInfo.indexOf('treeView') !== -1
          ? true
          : false;
      });
    });

    filteredRoles = this.props.roles.filter((r) => {
      return !_.isUndefined(r.attributes['account_manager']);
    });

    filteredRolesRoleManager = this.props.roles.filter((r) => {
      return !_.isUndefined(r.attributes['role_manager']);
    });

    permissionList =
      this.props.globalSettings.PagePermissionManager['account_manager'] || {};
    permissionListRoleManager =
      this.props.globalSettings.PagePermissionManager['role_manager'] || {};

    rightInfoList = filteredRoles[0]
      ? filteredRoles[0].attributes['account_manager'].rightInfo || []
      : [];

    rightInfoListRoleManager = filteredRolesRoleManager[0]
      ? filteredRolesRoleManager[0].attributes['role_manager'].rightInfo || []
      : [];

    this.assignableRole = filteredRoles[0]
      ? filteredRoles[0].attributes['account_manager'].assignableRole || []
      : [];

    if (_.isEmpty(permissionList)) {
      this.props.history.push('/app/fallback');
      userAccountRightInfo = {
        listAccounts: false,
        inquiryAccounts: false,
        createAccount: false,
        updateUserData: false,
        deleteAccount: false,
        changeAccountNode: false,
        updateAccountStatus: false,
        deleteRole: false,
      };
    } else {
      userAccountRightInfo = {
        listAccounts: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'listAccounts'
        ),
        inquiryAccounts: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'inquiryAccounts'
        ),
        createAccount: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'createAccount'
        ),
        updateUserData: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'updateUserData'
        ),
        deleteAccount: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'deleteAccount'
        ),
        changeAccountNode: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'changeAccountNode'
        ),
        updateAccountStatus: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'updateAccountStatus'
        ),
        deleteRole: AppUtil.getPermission(
          permissionListRoleManager.pagePermission,
          permissionListRoleManager.allPagePesmissionAllowed,
          rightInfoListRoleManager,
          'deleteRole'
        ),
      };
    }

    this.state = {
      accountList: [],
      accountsRows: 1,
      selectedNode: this.props.selectedNode,
      loadedList: false,
      page: 1,
      searchString: '',
      order: 'ASC',
      recursion: false,
      activeFuzzy: false,
      userAccountRightInfo: userAccountRightInfo,
      isAddAccountModalVisible: false,
      isEditAccountDrawerVisible: false,
      isDrawerRendered: false,
    };

    if (
      _.isString(process.env.REACT_APP_OFFLINE) &&
      _.isBoolean(JSON.parse(process.env.REACT_APP_OFFLINE))
    ) {
      this.offline = JSON.parse(process.env.REACT_APP_OFFLINE);
    } else {
      this.offline = false;
    }

    if (_.isString(process.env.REACT_APP_ENV)) {
      this.env = process.env.REACT_APP_ENV;
    } else {
      this.env = this.props.globalSettings.DefaultEnvironment;
    }

    this.accountServices = new AccountServices(
      this.props.globalSettings.Environment[this.env]
    );
    this.nodeServices = new NodeServices(
      this.props.globalSettings.Environment[this.env]
    );
    this.logFirer = new LogsFirer(
      this.props.globalSettings.Environment[this.env]
    );
    this.handleChange = this.handleChange.bind(this);
    this.changePagination = this.changePagination.bind(this);
    this.stopStatePropagation = true;
    this.pageSize = this.props.globalSettings.Environment[
      this.env
    ].envConstants.pageSize;
  }

  handleChange = (event) => {
    clearTimeout(this.timer);

    this.setState({
      searchString: event.target.value,
      recursion: true
    });

    this.timer = setTimeout(
      event.target.value.length > 0 && event.target.value.length < 3 ?
        null
      : this.search
    , 1500);
  };

  search = () => {
    this.refreshList(
      this.state.page,
      this.state.searchString,
      this.state.order,
      this.state.recursion,
      this.state.activeFuzzy
    );
  };

  changePagination = (pagination) => {
    this.refreshList(
      pagination.current,
      this.state.searchString,
      this.state.order,
      this.state.recursion,
      this.state.activeFuzzy
    );
  };

  refreshList = (
    page,
    searchStr = '',
    order,
    recursion = false,
    activeFuzzy = false
  ) => {
    let str = searchStr;
    if (this.props.selectedNode.nodeId) {
      this.stopStatePropagation &&
        this.setState({
          selectedNode: this.props.selectedNode,
          loadedList: false,
          page,
          searchString: searchStr,
          order,
          recursion
        });

      if (activeFuzzy && searchStr?.length) {
        str = searchStr.split('').join('%');
      }

      this.getAccountList(
        this.props.selectedNode.nodeId,
        {
          login: str,
        },
        {
          login: order,
        },
        page,
        recursion
      );
    }
  };

  getAccountList = (nodeId, ANDFilter, orderFilter, page, recursion = false, size) => {
    this.accountServices
      .listAccounts(
        nodeId,
        ANDFilter,
        orderFilter,
        page,
        recursion,
        size
      )
      .then(
        (response) => {
          if (response?.data) {
            if (this.accountServices.checkError(response)) {
              const error = this.accountServices.getError(response);
              this.stopStatePropagation &&
                this.setState({
                  accountList: [],
                  loadedList: true,
                });

              Modal.error({
                title: this.props.t('App.Message.General.ServerError.Title'),
                content: error.message,
              });
            } else {
              const result = this.accountServices.getResult(response);
              if (result.accountsRows) {
                this.stopStatePropagation &&
                  this.setState({
                    accountList: result.accountsInfoEx || [],
                    accountsRows: result.accountsRows,
                    loadedList: true,
                  });
              } else {
                if (result.accountsInfoEx && result.accountsInfoEx.length > 0) {
                  this.stopStatePropagation &&
                    this.setState({
                      accountList: result.accountsInfoEx || [],
                      loadedList: true,
                    });
                } else {
                  this.stopStatePropagation &&
                    this.setState({
                      accountList: result.accountsInfoEx || [],
                      accountsRows: 1,
                      loadedList: true,
                    });
                }
              }
            }
          } else {
            this.stopStatePropagation &&
              this.setState({
                accountList: [],
                loadedList: true,
              });
          }
        },
        (error) => {
          if (axios.isCancel(error)) {
            this.logFirer.fireLog('LOG', error);
          } else {
            this.stopStatePropagation &&
              this.setState({
                accountList: [],
                loadedList: true,
              });

            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };

  createAccount = (values) => {
    const userData = {
      firstname: values.firstName,
      lastname: values.lastName,
      email: values.email,
      ...(!isNilOrEmpty(values.phone) && {
        mobileNumber: `+${values.phone}`,
      }),
    };

    this.accountServices
      .createAccount(
        this.props.selectedNode.nodeId,
        values.login,
        userData,
        values.language,
        values.sendActivationEmail
      )
      .then(
        (response) => {
          this.setState({ isAddAccountModalVisible: false });

          if (response?.data) {
            if (this.accountServices.checkError(response)) {
              const error = this.accountServices.getError(response);

              Modal.error({
                title: this.props.t('App.Message.General.ServerError.Title'),
                content: error.message,
              });
            } else {
              notification.success({
                message: this.props.t('Modal.Add.Account.Message'),
              });
              this.refreshList(
                this.state.page,
                this.state.searchString,
                this.state.order,
                this.state.recursion,
                this.state.activeFuzzy
              );
            }
          }
        },
        (error) => {
          this.setState({ isAddAccountModalVisible: false });

          if (axios.isCancel(error)) {
            this.logFirer.fireLog('LOG', error);
          } else {
            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };

  updateAccount = (values) => {
    let userData = {
      firstname: values.firstName,
      lastname: values.lastName,
      email: values.email,
      ...(!isNilOrEmpty(values.phone) && {
        mobileNumber: `+${values.phone}`,
      }),
    };

    this.accountServices
      .updateAccountData(
        values.login,
        values.language,
        userData,
        values.sendActivationEmail
      )
      .then(
        (response) => {
          this.onDrawerClose();

          if (response?.data) {
            if (this.accountServices.checkError(response)) {
              const error = this.accountServices.getError(response);

              Modal.error({
                title: this.props.t('App.Message.General.ServerError.Title'),
                content: error.message,
              });
            } else {
              message.success(this.props.t('Modal.Modify.Account.Message'));
              this.refreshList(
                this.state.page,
                this.state.searchString,
                this.state.order,
                this.state.recursion,
                this.state.activeFuzzy
              );
            }
          }
        },
        (error) => {
          this.onDrawerClose();

          if (axios.isCancel(error)) {
            this.logFirer.fireLog('LOG', error);
          } else {
            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };

  deleteAccount = () => {
    this.accountServices.deleteAccount(this.state.workingAccount.login).then(
      (response) => {
        this.onDrawerClose();

        if (response?.data) {
          if (this.accountServices.checkError(response)) {
            const error = this.accountServices.getError(response);

            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          } else {
            message.success(this.props.t('Modal.Delete.Account.Message'));
            this.refreshList(
              this.state.page,
              this.state.searchString,
              this.state.order,
              this.state.recursion,
              this.state.activeFuzzy
            );
          }
        }
      },
      (error) => {
        this.onDrawerClose();

        if (axios.isCancel(error)) {
          this.logFirer.fireLog('LOG', error);
        } else {
          Modal.error({
            title: this.props.t('App.Message.General.ServerError.Title'),
            content: error.message,
          });
        }
      }
    );
  };

  changeNode = (nodeName) => {
    this.accountServices
      .changeAccountNode(this.state.workingAccount.login, nodeName)
      .then(
        (response) => {
          this.onDrawerClose();

          if (response?.data) {
            if (this.accountServices.checkError(response)) {
              const error = this.accountServices.getError(response);

              Modal.error({
                title: this.props.t('App.Message.General.ServerError.Title'),
                content: error.message,
              });
            } else {
              message.success(
                this.props.t('Modal.Shift.Account.Message', {
                  login: this.state.workingAccount.login,
                })
              );
              this.refreshList(
                this.state.page,
                this.state.searchString,
                this.state.order,
                this.state.recursion,
                this.state.activeFuzzy
              );
            }
          }
        },
        (error) => {
          this.onDrawerClose();

          if (axios.isCancel(error)) {
            this.logFirer.fireLog('LOG', error);
          } else {
            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };

  resendEmail = () => {
    this.accountServices
      .resendActivationEmail(this.state.workingAccount.login)
      .then(
        (response) => {
          this.onDrawerClose();

          if (response?.data) {
            if (this.accountServices.checkError(response)) {
              const error = this.accountServices.getError(response);

              Modal.error({
                title: this.props.t('App.Message.General.ServerError.Title'),
                content: error.message,
              });
            } else {
              message.success('The email is correctly sent!');
            }
          }
        },
        (error) => {
          this.onDrawerClose();

          if (axios.isCancel(error)) {
            this.logFirer.fireLog('LOG', error);
          } else {
            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };

  changeStatus = (feedback) => {
    this.onDrawerClose();

    if (feedback === 'success') {
      notification.success({
        message: `${this.state.workingAccount.login} is correctly updated!`,
      });
      this.refreshList(
        this.state.page,
        this.state.searchString,
        this.state.order,
        this.state.recursion,
        this.state.activeFuzzy
      );
    }
  };

  onDrawerClose = () => {
    this.setState(
      {
        isEditAccountDrawerVisible: false,
      },
      () => setTimeout(() => this.setState({ isDrawerRendered: false }), 200)
    );
  };

  componentWillMount = () => {
    this.timer = null;
    if (this.props.selectedNode.nodeId) {
      this.getAccountList(
        this.props.selectedNode.nodeId,
        {
          login: '',
        },
        {
          login: this.state.order,
        },
        1
      );
    }
  };

  componentDidUpdate = () => {
    if (
      this.props.selectedNode.nodeId &&
      this.props.selectedNode.nodeId !== this.state.selectedNode.nodeId
    ) {
      this.stopStatePropagation &&
        this.setState({
          selectedNode: this.props.selectedNode,
          loadedList: false,
          page: 1,
          searchString: '',
          order: 'ASC',
          recursion: false,
        });
      this.getAccountList(
        this.props.selectedNode.nodeId,
        {
          login: '',
        },
        {
          login: 'ASC',
        },
        1,
        false
      );
    }
    if (this.props.reloadPage && this.props.selectedNode.nodeId) {
      this.props.stopReloadPage();
      this.stopStatePropagation &&
        this.setState({
          selectedNode: this.props.selectedNode,
          loadedList: false,
          searchString: '',
        });
      this.getAccountList(
        this.props.selectedNode.nodeId,
        {
          login: '',
        },
        {
          login: this.state.order,
        },
        this.state.page,
        this.state.recursion
      );
    }
  };

  componentWillUnmount = () => {
    this.stopStatePropagation = false;
    clearTimeout(this.timer);
  };

  render = () => {
    const suffix = (
      <GlobalOutlined
        onClick={() =>
          this.setState({ activeFuzzy: !this.state.activeFuzzy }, () =>
            this.refreshList(
              this.state.page,
              this.state.searchString,
              this.state.order,
              this.state.recursion,
              this.state.activeFuzzy
            )
          )
        }
        style={{
          fontSize: 16,
          color: this.state.activeFuzzy ? this.props.mainColor : '#e6e6e6',
        }}
      />
    );
    const columns = [
      {
        title: this.props.t('PageContainer.Accounts.Table.Headers.Username'),
        dataIndex: 'login',
        key: 'login',
        ellipsis: true,
        sorter: (a, b) => a.login.length - b.login.length,
        render: (value, record) => (
          <Popover
            content={record.roles.map((row, i) => (
              <div key={i} style={{ fontSize: 14 }}>
                {row.name}
              </div>
            ))}
            title={this.props.t(
              'PageContainer.CoreComponent.TableItem.Account.Detail.Section.Roles.Title'
            )}
            placement="topLeft"
          >
            {value}
          </Popover>
        ),
      },
      {
        title: this.props.t('PageContainer.Accounts.Table.Headers.Name'),
        dataIndex: ['userData', 'firstname'],
        key: 'firstname',
      },
      {
        title: this.props.t('PageContainer.Accounts.Table.Headers.Surname'),
        dataIndex: ['userData', 'lastname'],
        key: 'lastname',
      },
      {
        title: this.props.t('PageContainer.Accounts.Table.Headers.Node'),
        dataIndex: ['nodeData', 'nodeName'],
        key: 'nodeName',
      },
      {
        title: this.props.t('PageContainer.Accounts.Table.Headers.Status'),
        dataIndex: 'accountStatus',
        key: 'accountStatus',
        render: (value) => (
          <Tag color={getStatus(value, this.props.t).color}>
            {getStatus(value, this.props.t).value}
          </Tag>
        ),
      },
    ];

    return (
      <div className="accounts">
        <Title level={3}>{this.props.t('PageContainer.Accounts.Title')}</Title>
        <Layout className="accounts-main">
          <Row justify="space-between" align="middle">
            <Col>
              <Space direction="horizontal" size={16}>
                <Search
                  placeholder={this.props.t(
                    'PageContainer.Accounts.Search.Placeholder'
                  )}
                  onChange={this.handleChange}
                  disabled={!this.state.loadedList}
                  style={{ width: 300 }}
                  suffix={suffix}
                />
                {this.isTreeViewAllowed && (
                  <Row gutter={8}>
                    <Col>
                      {this.props.t('PageContainer.Accounts.Recursion.Label')}
                    </Col>
                    <Col>
                      <Switch
                        checked={this.state.recursion}
                        disabled={this.state.searchString?.length}
                        onChange={(e) =>
                          this.refreshList(
                            1,
                            this.state.searchString,
                            this.state.order,
                            e,
                            this.state.activeFuzzy
                          )
                        }
                      />
                    </Col>
                  </Row>
                )}
              </Space>
            </Col>
            {this.state.userAccountRightInfo.createAccount && (
              <Col>
                <Tooltip
                  title={this.props.t(
                    'PageContainer.Accounts.Buttons.NewAccount.Tooltip'
                  )}
                >
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<PlusOutlined />}
                    onClick={() =>
                      this.setState({ isAddAccountModalVisible: true })
                    }
                  />
                </Tooltip>
              </Col>
            )}
          </Row>
          <Table
            className="accounts-table"
            columns={columns}
            loading={!this.state.loadedList}
            dataSource={this.state.accountList}
            pagination={{
              current: this.state.page,
              total: this.state.accountsRows,
              showSizeChanger: false,
              pageSize: this.pageSize,
              position: ['topLeft', 'bottomRight'],
            }}
            onChange={this.changePagination}
            onRow={(row) => {
              return {
                onClick: (e) => {
                  this.setState(
                    {
                      workingAccount: row,
                    },
                    () =>
                      this.setState({ isDrawerRendered: true }, () =>
                        this.setState({ isEditAccountDrawerVisible: true })
                      )
                  );
                },
              };
            }}
          />
        </Layout>
        <AddAccountModal
          title={this.props.t('Modal.Add.Account.Title', {
            nodeName: this.props.selectedNode.nodeName,
          })}
          isModalVisible={this.state.isAddAccountModalVisible}
          onConfirm={this.createAccount}
          handleCancel={() =>
            this.setState({ isAddAccountModalVisible: false })
          }
        />
        {this.state.isDrawerRendered && (
          <EditAccountDrawer
            title={this.props.t('Modal.Modify.Account.Title', {
              nodeName: this.state.workingAccount.nodeData.nodeName,
            })}
            accountRoles={this.state.workingAccount.roles}
            onRemoveAccountRole={() => {
              this.refreshList(
                this.state.page,
                this.state.searchString,
                this.state.order,
                this.state.recursion,
                this.state.activeFuzzy
              );
            }}
            canRemoveRoles={this.state.userAccountRightInfo.deleteRole}
            accountServices={this.accountServices}
            nodeId={this.state.selectedNode.nodeId}
            isVisible={this.state.isEditAccountDrawerVisible}
            data={this.state.workingAccount}
            nodeServices={this.nodeServices}
            onUpdate={this.updateAccount}
            onDelete={this.deleteAccount}
            onChangeNode={this.changeNode}
            onSendActivationEmail={this.resendEmail}
            onChangeStatus={this.changeStatus}
            handleCancel={this.onDrawerClose}
            canUpdate={
              this.state.userAccountRightInfo.updateUserData &&
              this.state.workingAccount.isUpdatable
            }
            canDelete={
              this.state.userAccountRightInfo.deleteAccount &&
              (this.state.workingAccount.accountStatus === 0 ||
                this.state.workingAccount.accountStatus === 9)
            }
            canChangeNode={this.state.userAccountRightInfo.changeAccountNode}
            canResendEmail={this.state.workingAccount.accountStatus === 9}
            canChangeStatus={
              this.state.userAccountRightInfo.updateAccountStatus
            }
          />
        )}
      </div>
    );
  };
}

export default withTranslation()(Accounts);
