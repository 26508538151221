import { GlobalOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Input,
  Layout,
  message,
  Modal,
  notification,
  Popover,
  Row,
  Select,
  Switch,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import './styles.less';
import { Component } from 'react';
import LogsFirer from '../../../services/logs-firer.services';
import AppUtil from '../../../utility/admin.util';
import _ from 'lodash';
import AccountServices from '../../../services/account.services';
import EditAccountDrawer from '../../../components/Drawers/EditAccountDrawer';
import NodeServices from '../../../services/node.services';
import AddAccountModal from '../../../components/Modals/AddAccountModal';
import { getStatus, isNilOrEmpty } from '../../../utility/utils';

const { Title } = Typography;
const { Search } = Input;
const { Option } = Select;

class ValidDriver extends Component {
  assignableRole;
  env;
  timer;
  stopStatePropagation;
  accountServices;
  nodeServices;
  logFirer;
  isTreeViewAllowed;

  constructor(props) {
    super(props);

    let filteredRoles;
    let filteredRolesRoleManager;
    let rightInfoList;
    let rightInfoListRoleManager;
    let permissionList;
    let permissionListRoleManager;
    let validDriverRightInfo;

    this.isTreeViewAllowed = this.props.roles.some((r) => {
      return Object.entries(r.attributes).some(([_, value]) => {
        return value.rightInfo && value.rightInfo.indexOf('treeView') !== -1
          ? true
          : false;
      });
    });

    filteredRoles = this.props.roles.filter((r) => {
      return !_.isUndefined(r.attributes['validdriver_manager']);
    });
    filteredRolesRoleManager = this.props.roles.filter((r) => {
      return !_.isUndefined(r.attributes['role_manager']);
    });

    permissionList =
      this.props.globalSettings.PagePermissionManager['validdriver_manager'] ||
      {};
    permissionListRoleManager =
      this.props.globalSettings.PagePermissionManager['role_manager'] || {};

    rightInfoList = filteredRoles[0]
      ? filteredRoles[0].attributes['validdriver_manager'].rightInfo || []
      : [];
    rightInfoListRoleManager = filteredRolesRoleManager[0]
      ? filteredRolesRoleManager[0].attributes['role_manager'].rightInfo || []
      : [];
    this.assignableRole = filteredRoles[0]
      ? filteredRoles[0].attributes['validdriver_manager'].assignableRole || []
      : [];

    if (_.isEmpty(permissionList)) {
      this.props.history.push('/app/fallback');
      validDriverRightInfo = {
        listAccounts: false,
        inquiryAccounts: false,
        createAccount: false,
        updateUserData: false,
        deleteAccount: false,
        addAuthorizedRole: false,
        deleteRole: false,
        updateAccountStatus: false,
      };
    } else {
      validDriverRightInfo = {
        listAccounts: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'listAccounts'
        ),
        inquiryAccounts: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'inquiryAccounts'
        ),
        createAccount: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'createAccount'
        ),
        updateUserData: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'updateUserData'
        ),
        deleteAccount: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'deleteAccount'
        ),
        addAuthorizedRole: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'addAuthorizedRole'
        ),
        deleteRole: AppUtil.getPermission(
          permissionListRoleManager.pagePermission,
          permissionListRoleManager.allPagePesmissionAllowed,
          rightInfoListRoleManager,
          'deleteRole'
        ),
        updateAccountStatus: AppUtil.getPermission(
          permissionList.pagePermission,
          permissionList.allPagePesmissionAllowed,
          rightInfoList,
          'updateAccountStatus'
        ),
      };
    }

    this.state = {
      accountList: [],
      accountsRows: 1,
      selectedNode: this.props.selectedNode,
      workingAccount: {},
      isDrawerRendered: false,
      isEditAccountDrawerVisible: false,
      loadedList: false,
      assignableRoleRow: {},
      page: 1,
      selectedRole: '',
      order: 'ASC',
      recursion: false,
      activeFuzzy: false,
      RightInfo: validDriverRightInfo,
      isAddAccountModalVisible: false,
    };

    if (_.isString(process.env.REACT_APP_ENV)) {
      this.env = process.env.REACT_APP_ENV;
    } else {
      this.env = this.props.globalSettings.DefaultEnvironment;
    }

    this.accountServices = new AccountServices(
      this.props.globalSettings.Environment[this.env]
    );
    this.logFirer = new LogsFirer(
      this.props.globalSettings.Environment[this.env]
    );
    this.nodeServices = new NodeServices(
      this.props.globalSettings.Environment[this.env]
    );
    this.handleChange = this.handleChange.bind(this);
    this.changeSelectedRole = this.changeSelectedRole.bind(this);
    this.changePagination = this.changePagination.bind(this);
    this.stopStatePropagation = true;
    this.pageSize = this.props.globalSettings.Environment[
      this.env
    ].envConstants.pageSize;
  }

  createAccount = async (values) => {
    const userData = {
      firstname: values.firstName,
      lastname: values.lastName,
      email: values.email,
      ...(!isNilOrEmpty(values.phone) && {
        mobileNumber: `+${values.phone}`,
      }),
    };

    this.accountServices
      .createAccount(
        this.props.selectedNode.nodeId,
        values.login,
        userData,
        values.language,
        values.sendActivationEmail
      )
      .then(
        (response) => {
          this.setState({ isAddAccountModalVisible: false });

          if (response?.data) {
            if (this.accountServices.checkError(response)) {
              const error = this.accountServices.getError(response);

              Modal.error({
                title: this.props.t('App.Message.General.ServerError.Title'),
                content: error.message,
              });
            } else {
              this.addAuthorizedRole(values.login, values.selectedRole);
            }
          }
        },
        (error) => {
          this.setState({ isAddAccountModalVisible: false });

          if (axios.isCancel(error)) {
            this.logFirer.fireLog('LOG', error);
          } else {
            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };

  addAuthorizedRole = (login, role) => {
    this.accountServices.addAuthorizedRole(login, role).then(
      (response) => {
        this.onDrawerClose();

        if (response?.data) {
          if (this.accountServices.checkError(response)) {
            const error = this.accountServices.getError(response);

            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          } else {
            message.success('Account correctly updated!');
            this.refreshList(
              this.state.page,
              this.state.searchString,
              this.state.order,
              this.state.recursion,
              this.state.activeFuzzy
            );
          }
        }
      },
      (error) => {
        this.onDrawerClose();
        if (axios.isCancel(error)) {
          this.logFirer.fireLog('LOG', error);
        } else {
          Modal.error({
            title: this.props.t('App.Message.General.ServerError.Title'),
            content: error.message,
          });
        }
      }
    );
  };

  deleteAuthorizedRole = (login, role) => {
    this.accountServices.deleteAuthorizedRole(login, role).then(
      (response) => {
        this.onDrawerClose();

        if (response?.data) {
          if (this.accountServices.checkError(response)) {
            const error = this.accountServices.getError(response);

            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        } else {
          message.success('Account correctly updated!');
          this.refreshList(
            this.state.page,
            this.state.searchString,
            this.state.order,
            this.state.recursion,
            this.state.activeFuzzy
          );
        }
      },
      (error) => {
        this.onDrawerClose();

        if (axios.isCancel(error)) {
          this.logFirer.fireLog('LOG', error);
        } else {
          Modal.error({
            title: this.props.t('App.Message.General.ServerError.Title'),
            content: error.message,
          });
        }
      }
    );
  };

  updateAccount = async (values) => {
    let userData = {
      firstname: values.firstName,
      lastname: values.lastName,
      email: values.email,
      ...(!isNilOrEmpty(values.phone) && {
        mobileNumber: `+${values.phone}`,
      }),
    };

    this.accountServices
      .updateAccountData(
        values.login,
        values.language,
        userData,
        values.sendActivationEmail
      )
      .then(
        (response) => {
          if (response?.data) {
            if (this.accountServices.checkError(response)) {
              const error = this.accountServices.getError(response);

              Modal.error({
                title: this.props.t('App.Message.General.ServerError.Title'),
                content: error.message,
              });
            } else {
              for (let role of this.assignableRole) {
                if (values.selectedRole === role) {
                  this.addAuthorizedRole(this.state.workingAccount.login, role);
                } else {
                  this.deleteAuthorizedRole(
                    this.state.workingAccount.login,
                    role
                  );
                }
              }
              message.success(this.props.t('Modal.Modify.Account.Message'));
              this.refreshList(
                this.state.page,
                this.state.searchString,
                this.state.order,
                this.state.recursion,
                this.state.activeFuzzy
              );
            }
          }
        },
        (error) => {
          this.onDrawerClose();

          if (axios.isCancel(error)) {
            this.logFirer.fireLog('LOG', error);
          } else {
            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };

  deleteAccount = () => {
    this.accountServices.deleteAccount(this.state.workingAccount.login).then(
      (response) => {
        this.onDrawerClose();

        if (response?.data) {
          if (this.accountServices.checkError(response)) {
            const error = this.accountServices.getError(response);

            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          } else {
            message.success('Account correctly deleted!');
            this.refreshList(
              this.state.page,
              this.state.searchString,
              this.state.order,
              this.state.recursion,
              this.state.activeFuzzy
            );
          }
        }
      },
      (error) => {
        this.onDrawerClose();

        if (axios.isCancel(error)) {
          this.logFirer.fireLog('LOG', error);
        } else {
          Modal.error({
            title: this.props.t('App.Message.General.ServerError.Title'),
            content: error.message,
          });
        }
      }
    );
  };

  onDrawerClose = () => {
    this.setState(
      {
        isEditAccountDrawerVisible: false,
      },
      () => setTimeout(() => this.setState({ isDrawerRendered: false }), 200)
    );
  };

  changeNode = (nodeName) => {
    this.accountServices
      .changeAccountNode(this.state.workingAccount.login, nodeName)
      .then(
        (response) => {
          this.onDrawerClose();

          if (response?.data) {
            if (this.accountServices.checkError(response)) {
              const error = this.accountServices.getError(response);

              Modal.error({
                title: this.props.t('App.Message.General.ServerError.Title'),
                content: error.message,
              });
            } else {
              message.success('Node correctly changed!');
              this.refreshList(
                this.state.page,
                this.state.searchString,
                this.state.order,
                this.state.recursion,
                this.state.activeFuzzy
              );
            }
          }
        },
        (error) => {
          this.onDrawerClose();

          if (axios.isCancel(error)) {
            this.logFirer.fireLog('LOG', error);
          } else {
            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };

  resendEmail = () => {
    this.accountServices
      .resendActivationEmail(this.state.workingAccount.login)
      .then(
        (response) => {
          this.onDrawerClose();

          if (response?.data) {
            if (this.accountServices.checkError(response)) {
              const error = this.accountServices.getError(response);

              Modal.error({
                title: this.props.t('App.Message.General.ServerError.Title'),
                content: error.message,
              });
            } else {
              message.success('Email is correctly sent!');
            }
          }
        },
        (error) => {
          this.onDrawerClose();

          if (axios.isCancel(error)) {
            this.logFirer.fireLog('LOG', error);
          } else {
            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };

  changeSelectedRole = (value) => {
    this.refreshList(
      1,
      this.state.searchString,
      this.state.order,
      this.state.recursion,
      this.state.activeFuzzy,
      value
    );
  };

  handleChange = (event) => {
    clearTimeout(this.timer);

    this.setState({
      searchString: event.target.value,
      recursion: true
    });

    this.timer = setTimeout(
      event.target.value.length > 0 && event.target.value.length < 3 ?
        null
      : this.search
    , 1500);
  };

  search = () => {
    this.refreshList(
      1,
      this.state.searchString,
      this.state.order,
      this.state.recursion,
      this.state.activeFuzzy,
      this.state.selectedRole
    );
  };

  changePagination = (pagination) => {
    if (pagination.current === this.state.page) return;

    this.refreshList(
      pagination.current,
      this.state.searchString,
      this.state.order,
      this.state.recursion,
      this.state.activeFuzzy,
      this.state.selectedRole
    );
  };

  refreshList = (
    page,
    searchStr,
    order,
    recursion = false,
    activeFuzzy = false,
    selectedRole = ''
  ) => {
    let str = searchStr;
    if (this.props.selectedNode.nodeId) {
      this.stopStatePropagation &&
        this.setState((prevState, props) => ({
          selectedNode: props.selectedNode,
          loadedList: false,
          page: page,
          searchString: searchStr,
          selectedRole: selectedRole,
          order: order,
          recursion
        }));

      if (activeFuzzy) {
        str = searchStr.split('').join('%');
      }

      this.getAccountList(
        this.props.selectedNode.nodeId,
        {
          login: str,
          roles: selectedRole,
        },
        {
          login: order,
        },
        page,
        recursion
      );
    }
  };

  getAccountList = (nodeId, ANDFilter, orderFilter, page, recursion = false, size) => {
    this.accountServices
      .listAccounts(
        nodeId,
        ANDFilter,
        orderFilter,
        page,
        recursion,
        size
      )
      .then(
        (response) => {
          if (response?.data) {
            if (this.accountServices.checkError(response)) {
              const error = this.accountServices.getError(response);
              this.stopStatePropagation &&
                this.setState({
                  accountList: [],
                  loadedList: true,
                });
              Modal.error({
                title: this.props.t('App.Message.General.ServerError.Title'),
                content: error.message,
              });
            } else {
              const result = this.accountServices.getResult(response);

              if (result.accountsRows) {
                this.stopStatePropagation &&
                  this.setState({
                    accountList: result.accountsInfoEx || [],
                    accountsRows: result.accountsRows,
                    loadedList: true,
                  });
              } else {
                if (result.accountsInfoEx && result.accountsInfoEx.length > 0) {
                  this.stopStatePropagation &&
                    this.setState({
                      accountList: result.accountsInfoEx || [],
                      loadedList: true,
                    });
                } else {
                  this.stopStatePropagation &&
                    this.setState({
                      accountList: result.accountsInfoEx || [],
                      accountsRows: 1,
                      loadedList: true,
                    });
                }
              }
            }
          }
        },
        (error) => {
          if (axios.isCancel(error)) {
            this.logFirer.fireLog('LOG', error);
          } else {
            this.stopStatePropagation &&
              this.setState({
                accountList: [],
                loadedList: true,
              });
            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
  };

  changeStatus = (feedback) => {
    this.onDrawerClose();

    if (feedback === 'success') {
      notification.success({
        message: `${this.state.workingAccount.login} is correctly updated!`,
      });
      this.refreshList(
        this.state.page,
        this.state.searchString,
        this.state.order,
        this.state.recursion,
        this.state.activeFuzzy
      );
    }
  };

  componentDidMount = () => {
    this.timer = null;
    if (this.props.selectedNode.nodeId) {
      this.getAccountList(
        this.props.selectedNode.nodeId,
        {
          login: '',
          roles: '',
        },
        {
          login: this.state.order,
        },
        1
      );
    }
  };

  componentDidUpdate = () => {
    if (
      this.props.selectedNode.nodeId &&
      this.props.selectedNode.nodeId !== this.state.selectedNode.nodeId
    ) {
      this.stopStatePropagation &&
        this.setState((prevState, props) => ({
          selectedNode: props.selectedNode,
          loadedList: false,
          assignableRoleRow: {},
          page: 1,
          searchString: '',
          selectedRole: '',
          order: 'ASC',
          recursion: false,
        }));

      this.getAccountList(
        this.props.selectedNode.nodeId,
        {
          login: '',
          roles: '',
        },
        {
          login: 'ASC',
        },
        1,
        false
      );
    }
    if (this.props.reloadPage && this.props.selectedNode.nodeId) {
      this.props.stopReloadPage();
      this.stopStatePropagation &&
        this.setState((prevState, props) => ({
          selectedNode: props.selectedNode,
          loadedList: false,
          searchString: '',
        }));
      this.getAccountList(
        this.props.selectedNode.nodeId,
        {
          login: '',
          roles: this.state.selectedRole,
        },
        {
          login: this.state.order,
        },
        this.state.page,
        this.state.recursion
      );
    }
  };

  componentWillUnmount = () => {
    this.stopStatePropagation = false;
    clearTimeout(this.timer);
  };

  render() {
    const suffix = (
      <GlobalOutlined
        onClick={() =>
          this.setState({ activeFuzzy: !this.state.activeFuzzy }, () =>
            this.refreshList(
              1,
              this.state.searchString,
              this.state.order,
              this.state.recursion,
              this.state.activeFuzzy
            )
          )
        }
        style={{
          fontSize: 16,
          color: this.state.activeFuzzy ? this.props.mainColor : '#e6e6e6',
        }}
      />
    );
    const columns = [
      {
        title: this.props.t('PageContainer.ValidDriver.Table.Headers.Username'),
        dataIndex: 'login',
        key: 'login',
        ellipsis: true,
        sorter: (a, b) => a.login.localeCompare(b.login.length),
        render: (value, record) => (
          <Popover
            content={record.roles.map((row) => (
              <div style={{ fontSize: 14 }}>{row.name}</div>
            ))}
            title="Roles"
            placement="topLeft"
          >
            {value}
          </Popover>
        ),
      },
      {
        title: this.props.t('PageContainer.ValidDriver.Table.Headers.Name'),
        dataIndex: ['userData', 'firstname'],
        key: 'firstname',
      },
      {
        title: this.props.t('PageContainer.ValidDriver.Table.Headers.Surname'),
        dataIndex: ['userData', 'lastname'],
        key: 'lastname',
      },
      {
        title: this.props.t('PageContainer.ValidDriver.Table.Headers.Node'),
        dataIndex: ['nodeData', 'nodeName'],
        key: 'nodeName',
      },
      {
        title: this.props.t('PageContainer.ValidDriver.Table.Headers.Status'),
        dataIndex: 'accountStatus',
        key: 'accountStatus',
        render: (value) => (
          <Tag color={getStatus(value, this.props.t).color}>
            {getStatus(value, this.props.t).value}
          </Tag>
        ),
      },
    ];

    return (
      <div className="validDriver">
        <Title level={3}>
          {this.props.t('PageContainer.ValidDriver.Title')}
        </Title>
        <Layout className="validDriver-filters">
          <Row gutter={16} align="middle">
            <Col xs={8}>
              <Search
                value={this.state.searchString}
                placeholder={this.props.t(
                  'PageContainer.ValidDriver.Search.Placeholder'
                )}
                disabled={!this.state.loadedList}
                suffix={suffix}
                onChange={this.handleChange}
              />
            </Col>
            <Col xs={8}>
              <Select
                style={{ width: '100%' }}
                value={this.state.selectedRole}
                onChange={this.changeSelectedRole}
              >
                <Option value="">
                  {this.props.t('App.AssignableRole.Role.all_users')}
                </Option>
                {this.assignableRole
                  ?.sort((a, b) => a.localeCompare(b))
                  ?.map((role, key) => {
                    //@Todo eliminare ruolo basic quando implementato
                    return (
                      <Option key={key} value={role}>
                        {this.props.t('App.AssignableRole.Role.' + role)}
                      </Option>
                    );
                  })}
              </Select>
            </Col>
            {this.isTreeViewAllowed && (
              <Col xs={8}>
                <Row
                  gutter={8}
                  justify="end"
                  align="middle"
                  className="validDriver-filters-includeSubCompanies"
                >
                  <Col>
                    {this.props.t('PageContainer.ValidDriver.Recursion.Label')}
                  </Col>
                  <Col>
                    <Switch
                      checked={this.state.recursion}
                      disabled={this.state.searchString?.length}
                      onChange={(e) =>
                        this.refreshList(
                          1,
                          this.state.serachString,
                          this.state.order,
                          e,
                          this.state.activeFuzzy,
                          this.state.selectedRole
                        )
                      }
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Layout>
        <Layout className="validDriver-main">
          {this.state.RightInfo.createAccount && (
            <Row justify="end" align="middle">
              <Col>
                <Tooltip title="Add Valid Driver">
                  <Button
                    onClick={() =>
                      this.setState({ isAddAccountModalVisible: true })
                    }
                    type="primary"
                    shape="circle"
                    icon={<PlusOutlined />}
                  />
                </Tooltip>
              </Col>
            </Row>
          )}
          <Table
            className="validDriver-main-table"
            columns={columns}
            loading={!this.state.loadedList}
            dataSource={this.state.accountList}
            pagination={{
              current: this.state.page,
              total: this.state.accountsRows,
              showSizeChanger: false,
              pageSize: this.pageSize,
              position: ['topLeft', 'bottomRight'],
            }}
            onChange={this.changePagination}
            onRow={(row) => {
              return {
                onClick: (e) => {
                  this.setState(
                    {
                      workingAccount: row,
                    },
                    () =>
                      this.setState({ isDrawerRendered: true }, () =>
                        this.setState({ isEditAccountDrawerVisible: true })
                      )
                  );
                },
              };
            }}
          />
        </Layout>
        <AddAccountModal
          title={this.props.t('Modal.Add.Validdriver.Title', {
            nodeName: this.props.selectedNode.nodeName,
          })}
          context="VALIDDRIVER"
          isModalVisible={this.state.isAddAccountModalVisible}
          onConfirm={this.createAccount}
          handleCancel={() =>
            this.setState({ isAddAccountModalVisible: false })
          }
          assignableRole={this.assignableRole}
        />
        {this.state.isDrawerRendered && (
          <EditAccountDrawer
            title={this.props.t('Modal.Modify.Validdriver.Title', {
              nodeName: this.state.workingAccount.nodeData.nodeName,
            })}
            context="VALIDDRIVER"
            accountRoles={this.state.workingAccount.roles}
            accountServices={this.accountServices}
            onRemoveAccountRole={() => {
              this.refreshList(
                this.state.page,
                this.state.searchString,
                this.state.order,
                this.state.recursion,
                this.state.activeFuzzy
              );
            }}
            canRemoveRoles={this.state.RightInfo.deleteRole}
            nodeId={this.state.selectedNode.nodeId}
            isVisible={this.state.isEditAccountDrawerVisible}
            data={this.state.workingAccount}
            nodeServices={this.nodeServices}
            onUpdate={this.updateAccount}
            onDelete={this.deleteAccount}
            onChangeNode={this.changeNode}
            onChangeStatus={this.changeStatus}
            onSendActivationEmail={this.resendEmail}
            handleCancel={this.onDrawerClose}
            assignableRole={this.assignableRole}
            canUpdate={
              this.state.RightInfo.updateUserData &&
              this.state.workingAccount.isUpdatable
            }
            canDelete={
              this.state.RightInfo.deleteAccount &&
              (this.state.workingAccount.accountStatus === 0 ||
                this.state.workingAccount.accountStatus === 9)
            }
            canResendEmail={this.state.workingAccount.accountStatus === 9}
            canChangeStatus={this.state.RightInfo.updateAccountStatus}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(ValidDriver);
