import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Upload,
} from 'antd';
import ImgCrop from 'antd-img-crop';
import { withTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  LoadingOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  UploadOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import './styles.less';
import { Option } from 'antd/lib/mentions';
import { isNilOrEmpty } from '../../../utility/utils';

const { TextArea } = Input;

function AddProjectModal({
  title,
  isModalVisible,
  onConfirm,
  handleCancel,
  oAuthServices,
  nodeServices,
  rootNode,
  oauthRightInfo,
  ctxAllowed,
  hasNodeChildren,
  t,
}) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [ccFlow, setCcFlow] = useState(false);
  const [jsonFile, setJsonFile] = useState();
  const [searchString, setSearchString] = useState();
  const [nodes, setNodes] = useState([]);
  const [isContextAllowedEnabled, setIsContextAllowedEnabled] = useState(false);
  const [isExtraOptionsChanged, setIsExtraOptionsChanged] = useState(false);
  const [logo_url, setLogoUrl] = useState();

  useEffect(() => {
    if (!isNilOrEmpty(ctxAllowed)) {
      setNodes(ctxAllowed);
      setIsContextAllowedEnabled(true);
    }
  }, [ctxAllowed]);

  let timeout;

  const getNodes = (value, callback) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    function fake() {
      nodeServices.searchNode(rootNode.nodeId, value).then(
        (response) => {
          if (response?.data) {
            if (nodeServices.checkError(response)) {
              const error = nodeServices.getError(response);

              Modal.error({
                title: t('App.Message.General.ServerError.Title'),
                content: error.message,
              });
            } else {
              const result = nodeServices.getResult(response);
              
              if ( rootNode.nodeName.toLowerCase().includes(value?.toLowerCase()) 
                && result.childs.filter((el) => el.name === rootNode.nodeName).length === 0
              ) {
                result.childs = [{name: rootNode.nodeName, nodeId: rootNode.nodeId}, ...result.childs];
              }

              callback(result.childs);
            }
          }
        },
        (error) => {
          Modal.error({
            title: t('App.Message.General.ServerError.Title'),
            content: error.message,
          });
        }
      );
    }

    timeout = setTimeout(fake, 300);
  };

  const handleNodeSearch = (value) => {
    if (value) {
      getNodes(value, setNodes);
    } else {
      setNodes([]);
    }
  };
  const handleNodeChange = (value) => {
    setSearchString(value);
  };

  const beforeUpload = (file) => {
    const reader = new FileReader();
    const isLt1M = file.size / 1024 / 1024 < 1;

    if (!isLt1M) {
      message.error('Image must be smaller than 1MB!');
    } else {
      reader.onload = (event) => {
        if (typeof event.target?.result === 'string') {
          const base64string = event.target.result;
          if (base64string) {
            form.setFieldsValue({
              logoURL: null,
            });
            setLogoUrl(null);
            setImageUrl(base64string);
          }
        }
      };
      reader.onerror = (event) => {};
      reader.readAsDataURL(file);
    }

    // Prevent upload
    return false;
  };

  const uploadButton = (
    <div>
      {isLoadingImage ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const onJSONChange = (info) => {
    let fileList = [...info.fileList];

    // 2. Read from response and show file link
    fileList = fileList.map((file) => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });

    setJsonFile(fileList);
  };

  const onSubmit = (values) => {
    const {
      project,
      displayedName,
      description,
      logoURL,
      allowedURIs,
      clientCredentialsFlow,
      nodeId,
      showAuthorizationPage,
      extraOptions,
    } = values;

    setIsLoading(true);

    oAuthServices
      .addCredential(
        imageUrl,
        project,
        displayedName,
        description,
        logoURL,
        allowedURIs,
        clientCredentialsFlow === true ? 1 : 0,
        nodeId,
        showAuthorizationPage,
        extraOptions || null
      )
      .then(
        (response) => {
          if (response?.data) {
            if (oAuthServices.checkError(response)) {
              const error = oAuthServices.getError(response);

              Modal.error({
                title: t('App.Message.General.ServerError.Title'),
                content: error.message,
              });
            } else {
              handleCancel();
              onConfirm();

              notification.success({
                message: t('Modal.Add.OAuth.Notification.Success.Message'),
                description: t(
                  'Modal.Add.OAuth.Notification.Success.Description'
                ),
              });
            }
          }
        },
        (error) => {
          Modal.error({
            title: t('App.Message.General.ServerError.Title'),
            content: error.message,
          });
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  const beforeCrop = (file) => {
    if (file?.type === "image/svg+xml") {
      beforeUpload(file);
    } else {
      return true;
    }
  }

  return (
    <Modal
      className="addProjectModal"
      title={title}
      visible={isModalVisible}
      onOk={() => {
        form.validateFields().then((values) => {
          setIsLoading(true);
          onSubmit(values);
        });
      }}
      onCancel={handleCancel}
      afterClose={() => {
        setIsLoading(false);
        form.resetFields();
        setCcFlow(false);
        setImageUrl(null);
        setLogoUrl(null);
        setIsExtraOptionsChanged(false);
      }}
      okButtonProps={{
        loading: isLoading,
      }}
      cancelButtonProps={{
        disabled: isLoading,
      }}
      closable={!isLoading}
    >
      <Spin spinning={isLoading}>
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            allowedURIs: [''],
            clientCredentialsFlow: false,
            showAuthorizationPage: false,
          }}
        >
          <Row>
            <Col xs={5}>
              <ImgCrop rotate beforeCrop={beforeCrop}>
                <Upload listType="picture-card" beforeUpload={beforeUpload}>
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{ width: '100%' }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </ImgCrop>
            </Col>
            <Col xs={19}>
              <Form.Item
                name="project"
                rules={[
                  {
                    required: true,
                    message: t('General.MandatoryField.Text'),
                  },
                ]}
              >
                <Input
                  placeholder={t('Modal.Add.OAuth.Form.Project.Placeholder')}
                />
              </Form.Item>
              <Form.Item
                name="displayedName"
                rules={[
                  {
                    required: true,
                    message: t('General.MandatoryField.Text'),
                  },
                ]}
              >
                <Input
                  placeholder={t(
                    'Modal.Add.OAuth.Form.DisplayedName.Placeholder'
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                  message: t('General.MandatoryField.Text'),
                },
              ]}
              style={{ width: '100%' }}
            >
              <Input
                placeholder={t('Modal.Add.OAuth.Form.Description.Placeholder')}
              />
            </Form.Item>
          </Row>
          <Row>
            <Col xs={12}>
              <Form.Item
                name="logoURL"
                rules={[
                  {
                    pattern: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi,
                    message: 'Logo URL must a valid http/https url',
                  },
                  {
                    max: 2048,
                    message: 'Logo URL cannot be longer than 2048 characters',
                  },
                ]}
              >
                <Input
                  onChange={() => {
                    setImageUrl(null);
                    setLogoUrl(form.getFieldValue('logoURL'));
                  }}
                  placeholder={t('Modal.Add.OAuth.Form.LogoURL.Placeholder')}
                />
              </Form.Item>
            </Col>
            {!isNilOrEmpty(logo_url) && (
              <Col xs={12}>
                <center>
                  <img src={logo_url} alt={logo_url} style={{ width: 150 }} />
                </center>
              </Col>
            )}
          </Row>
          <Divider />
          <Row>
            <strong style={{ marginBottom: 8 }}>Allowed Redirect Uri</strong>
            <Form.List name="allowedURIs">
              {(fields, { add, remove }) => (
                <Col xs={24}>
                  {fields.map((field) => (
                    <Form.Item required={false} key={field.key}>
                      <Form.Item
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            message: 'Please input URL or delete this field.',
                          },
                        ]}
                        noStyle
                      >
                        <Input
                          placeholder={t(
                            'Modal.Add.OAuth.Form.URL.Placeholder'
                          )}
                          style={{ width: '90%' }}
                        />
                      </Form.Item>
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                        disabled={fields.length <= 1}
                        style={{
                          pointerEvents: fields.length <= 1 ? 'none' : 'auto',
                        }}
                      />
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={{ width: '100%' }}
                      icon={<PlusOutlined />}
                    >
                      {t('Modal.Add.OAuth.Form.AddURL.Label')}
                    </Button>
                  </Form.Item>
                </Col>
              )}
            </Form.List>
          </Row>
          {oauthRightInfo.uploadExtraOptions && (
            <>
              <Divider />
              <Space>
                <strong>Extra options</strong>
                <Row>
                  <Col>
                    <Upload
                      fileList={jsonFile}
                      accept=".json"
                      beforeUpload={(file, fileList) => {
                        if (fileList[0].type !== 'application/json') {
                          return notification.error({
                            message: t(
                              'Notification.Error.WrongFileType.Message'
                            ),
                            description: t(
                              'Notification.Error.WrongFileType.Description',
                              { fileType: 'json' }
                            ),
                          });
                        }

                        const reader = new FileReader();

                        reader.onload = (e) => {
                          setIsExtraOptionsChanged(true);

                          form.setFieldsValue({
                            extraOptions: e.target.result,
                          });
                        };
                        reader.readAsText(file);

                        return false;
                      }}
                      onChange={onJSONChange}
                      showUploadList={false}
                      maxCount={1}
                      style={{
                        flexDirection: 'row',
                      }}
                    >
                      <Button type="link" icon={<UploadOutlined />} />
                    </Upload>
                  </Col>
                  <Col>
                    {isExtraOptionsChanged && (
                      <Button
                        type="text"
                        danger
                        onClick={() => {
                          form.setFieldsValue({
                            extraOptions: null,
                          });
                          setIsExtraOptionsChanged(false);
                        }}
                        icon={<UndoOutlined />}
                      />
                    )}
                  </Col>
                </Row>
              </Space>
              <Form.Item style={{ width: '100%' }} name="extraOptions">
                <TextArea onChange={() => setIsExtraOptionsChanged(true)} />
              </Form.Item>
            </>
          )}
          {oauthRightInfo.setCredentialsFlow && (
            <>
              <Divider />
              <Row style={{ marginTop: 24 }}>
                <Col xs={12}>
                  <Row gutter={12} style={{ alignItems: 'center' }}>
                    <Col>
                      <Form.Item
                        name="clientCredentialsFlow"
                        valuePropName="checked"
                        style={{ marginBottom: 0 }}
                      >
                        <Switch
                          onChange={(value) => {
                            setCcFlow(value);

                            if (!value) {
                              form.setFieldsValue({
                                nodeId: null,
                              });
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col>Client Credentials Flow</Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  {ccFlow && (isContextAllowedEnabled || hasNodeChildren) && (
                    <Form.Item
                      name="nodeId"
                      rules={[
                        {
                          required: oauthRightInfo.setCredentialsFlow,
                          message: t('General.MandatoryField.Text'),
                        },
                      ]}
                    >
                      <Select
                        showSearch={!isContextAllowedEnabled}
                        value={searchString}
                        placeholder={t(
                          'Modal.Shift.Account.Form.SearcNode.Placeholder'
                        )}
                        disabled={!oauthRightInfo.setCredentialsFlow}
                        defaultActiveFirstOption={false}
                        filterOption={false}
                        onSearch={handleNodeSearch}
                        onChange={handleNodeChange}
                        notFoundContent={null}
                      >
                        { (!nodes || nodes?.length === 0) && <Option key={rootNode.nodeName}>{rootNode.nodeName}</Option> }
                        {nodes.map((node) => (
                          <Option key={node.name}>{node.name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Col>
              </Row>
            </>
          )}
          {oauthRightInfo.setAuthPage && (
            <>
              <Divider />
              <Row style={{ alignItems: 'center' }} gutter={12}>
                <Col>
                  <Form.Item
                    name="showAuthorizationPage"
                    valuePropName="checked"
                    style={{ marginBottom: 0 }}
                  >
                    <Switch />
                  </Form.Item>
                </Col>
                <Col>Show authorization page</Col>
              </Row>
            </>
          )}
        </Form>
      </Spin>
    </Modal>
  );
}

export default withTranslation()(AddProjectModal);
