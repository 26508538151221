import { Col, Form, Input, Modal, Row, Select, Spin } from 'antd';
import './styles.less';
import { withTranslation } from 'react-i18next';
import { useState } from 'react';
import axios from 'axios';

const { Option } = Select;

function AddServiceModal({
  title,
  suggestedServicesList,
  nodeId,
  isModalVisible,
  onConfirm,
  handleCancel,
  t,
  serviceServices,
}) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (value) => {
    form.setFieldsValue({
      serviceDescription: t(
        `App.SuggestedServices.${
          suggestedServicesList.find((service) => service.name === value)?.name
        }`
      ),
    });
  };

  const createService = (values) => {
    serviceServices
      .createService(nodeId, values.serviceName, values.serviceDescription)
      .then(
        (response) => {
          if (response?.data) {
            if (serviceServices.checkError(response)) {
              const error = serviceServices.getError(response);

              Modal.error({
                title: t('App.Message.General.ServerError.Title'),
                content: error.message,
              });
              setIsLoading(false);
            } else {
              onConfirm();
            }
          }
        },
        (error) => {
          if (axios.isCancel(error)) {
            //this.logFirer.fireLog('LOG', error);
          } else {
            Modal.error({
              title: this.props.t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
            setIsLoading(false);
          }
        }
      );
  };

  return (
    <Modal
      className="addServiceModal"
      title={title}
      visible={isModalVisible}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            setIsLoading(true);
            createService(values);
          })
          .catch((info) => {});
      }}
      onCancel={handleCancel}
      afterClose={() => {
        setIsLoading(false);
        form.resetFields();
      }}
      okButtonProps={{
        loading: isLoading,
      }}
      cancelButtonProps={{
        disabled: isLoading,
      }}
      closable={!isLoading}
    >
      <Spin spinning={isLoading}>
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            serviceName: suggestedServicesList[0].name,
            serviceDescription: t(
              `App.SuggestedServices.${suggestedServicesList[0].name}`
            ),
          }}
        >
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item
                name="serviceName"
                label={t('Modal.Add.Service.Form.Suggestion.Label')}
                rules={[
                  {
                    required: true,
                    message: t('General.MandatoryField.Text'),
                  },
                ]}
              >
                <Select onChange={onChange}>
                  {suggestedServicesList.map((service) => (
                    <Option key={service.name} value={service.name}>
                      {service.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item
                label={t('Modal.Add.Service.Form.Description.Label')}
                name="serviceDescription"
              >
                <Input
                  placeholder={t(
                    'Modal.Add.Service.Form.Description.Placeholder'
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

export default withTranslation()(AddServiceModal);
