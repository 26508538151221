import { Col, Form, Input, Modal, Row, Spin } from 'antd';
import { useState } from 'react';
import { withTranslation } from 'react-i18next';

function AddNodeModal({ data, isModalVisible, handleOk, handleCancel, t }) {
  const [form] = Form.useForm();
  const { title } = data;
  const [isLoading, setIsLoading] = useState(false);
  const [nodeName, setNodeName] = useState();

  const onNameChange = (e) => {
    if (!nodeName?.length && /\d/.test(e.target.value)) {
      setNodeName('');
      form.resetFields(['name']);
    } else {
      setNodeName(e.target.value);
    }
  };

  return (
    <Modal
      className="addNodeModal"
      title={title}
      visible={isModalVisible}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            setIsLoading(true);
            handleOk({
              name: values.name.trim().toUpperCase().replace(/ /g, ''),
              description: values.description.trim(),
            });
            handleCancel();
          })
          .catch((info) => {});
      }}
      onCancel={handleCancel}
      afterClose={() => {
        setIsLoading(false);
        form.resetFields();
      }}
      okText={t('Modal.Add.Node.Buttons.Add')}
      cancelText={t('Modal.Add.Node.Buttons.Close')}
      okButtonProps={{
        loading: isLoading,
      }}
      cancelButtonProps={{
        disabled: isLoading,
      }}
      closable={!isLoading}
    >
      <Spin spinning={isLoading}>
        <Form layout="vertical" form={form} requiredMark="optional">
          <Row gutter={16}>
            <Col xs={12}>
              <Form.Item
                name="name"
                label={t('Modal.Add.Node.Form.Name.Label')}
                rules={[
                  {
                    required: true,
                    message: t('General.MandatoryField.Text'),
                  },
                ]}
              >
                <Input
                  style={{
                    textTransform: 'uppercase',
                  }}
                  value={nodeName}
                  onChange={onNameChange}
                  placeholder={t('Modal.Add.Node.Form.Name.Placeholder')}
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name="description"
                label={t('Modal.Add.Node.Form.Description.Label')}
                rules={[
                  {
                    required: true,
                    message: t('General.MandatoryField.Text'),
                  },
                ]}
              >
                <Input
                  placeholder={t('Modal.Add.Node.Form.Description.Placeholder')}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

export default withTranslation()(AddNodeModal);
