import { Col, Form, Modal, Row, Select } from 'antd';
import './styles.less';
import { withTranslation } from 'react-i18next';
import { useState } from 'react';
import axios from 'axios';

const { Option } = Select;

function ShiftAccountNodeModal({
  title,
  isModalVisible,
  onConfirm,
  handleCancel,
  t,
  nodeServices,
  nodeId,
}) {
  const [form] = Form.useForm();
  const [searchString, setSearchString] = useState();
  const [nodes, setNodes] = useState([]);

  let timeout;

  const getNodes = (value, callback) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    function fake() {
      nodeServices.searchNode(nodeId, value).then(
        (response) => {
          if (response?.data) {
            if (nodeServices.checkError(response)) {
              const error = nodeServices.getError(response);

              this.logFirer.fireLog('LOG', error);
              Modal.error({
                title: t('App.Message.General.ServerError.Title'),
                content: error.message,
              });
            } else {
              const result = nodeServices.getResult(response);

              callback(result.childs);
            }
          }
        },
        (error) => {
          if (axios.isCancel(error)) {
            this.logFirer.fireLog('LOG', error);
          } else {
            Modal.error({
              title: t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          }
        }
      );
    }

    timeout = setTimeout(fake, 300);
  };
  const handleSearch = (value) => {
    if (value) {
      getNodes(value, (data) => setNodes(data));
    } else {
      setNodes([]);
    }
  };
  const handleChange = (value) => {
    setSearchString(value);
  };

  return (
    <Modal
      className="shiftAccountNodeModal"
      title={title}
      visible={isModalVisible}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onConfirm(values);
          })
          .catch((info) => {});
      }}
      onCancel={handleCancel}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item
              name="searchString"
              rules={[
                {
                  required: true,
                  message: t('General.MandatoryField.Text'),
                },
              ]}
            >
              <Select
                showSearch
                value={searchString}
                placeholder={t(
                  'Modal.Shift.Account.Form.SearcNode.Placeholder'
                )}
                defaultActiveFirstOption={false}
                filterOption={false}
                onSearch={handleSearch}
                onChange={handleChange}
                notFoundContent={null}
              >
                {nodes.map((node) => (
                  <Option key={node.nodeId}>{node.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default withTranslation()(ShiftAccountNodeModal);
