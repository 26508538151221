import {
  Alert,
  Button,
  Checkbox,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Modal,
  notification,
  Popconfirm,
  Row,
  Select,
  Spin,
  Tag,
} from 'antd';
import { DeleteOutlined, LinkOutlined } from '@ant-design/icons';
import { ReactComponent as ItalyIco } from '../../../assets/icons/ico-flag-it.svg';
import { ReactComponent as UkIco } from '../../../assets/icons/ico-flag-uk.svg';
import { ReactComponent as EsIco } from '../../../assets/icons/ico-flag-es.svg';
import { ReactComponent as FrIco } from '../../../assets/icons/ico-flag-fr.svg';
import { useState } from 'react';
import './styles.less';
import { withTranslation } from 'react-i18next';
import ShiftAccountNodeModal from '../../Modals/ShiftAccountNodeModal';
import axios from 'axios';
import PhoneInputWrapper from '../../PhoneInputWrapper';
import { getStatus, isNilOrEmpty } from '../../../utility/utils';

const { Option } = Select;

function EditAccountDrawer({
  context = 'ACCOUNT',
  assignableRole,
  title,
  data = {},
  accountRoles,
  onRemoveAccountRole,
  accountServices,
  nodeId,
  canRemoveRoles,
  t,
  isVisible,
  nodeServices,
  handleCancel,
  onUpdate,
  onDelete,
  onChangeNode,
  onSendActivationEmail,
  onChangeStatus,
  canUpdate = true,
  canDelete = true,
  canChangeNode = true,
  canResendEmail = true,
  canChangeStatus = true,
}) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [editableAccountRoles, setEditableAccountRoles] = useState(
    accountRoles.sort((x, y) =>
      x.name === 'basic_user' ? -1 : y.name === 'basic_user' ? 1 : 0
    )
  );

  const [
    isShiftAccountNodeModalVisible,
    setIsShiftAccountNodeModalVisible,
  ] = useState(false);
  const {
    login,
    language,
    userData: { email, firstname, lastname, mobileNumber },
    sendActivationEmail = true,
  } = data;
  const [phoneNumber, setPhoneNumber] = useState(mobileNumber);

  const onOperationClick = (option, extraParam) => {
    switch (option) {
      case 'update':
        setIsLoading(true);

        form
          .validateFields()
          .then((values) => {
            onUpdate(values);
          })
          .catch((_) => {
            setIsLoading(false);
          });
        break;

      case 'change_node':
        setIsShiftAccountNodeModalVisible(true);
        break;

      case 'change_status':
        Modal.confirm({
          title: t(`Modal.ChangeStatus.Account.${extraParam}.Title`, { login }),
          okText: t(`Modal.ChangeStatus.Account.${extraParam}.Confirm`),
          okButtonProps: {
            type: extraParam === 'REVOKE' ? 'primary' : 'default',
            danger: extraParam === 'REVOKE' || extraParam === 'SUSPEND',
          },
          cancelText: 'Cancel',
          autoFocusButton: null,
          closable: true,
          content: (
            <>
              <Alert
                style={{ marginBottom: 16 }}
                message={t(
                  `Modal.ChangeStatus.Account.${extraParam}.Description`,
                  { login }
                )}
                type={extraParam === 'RESUME' ? 'info' : 'warning'}
              />
              {extraParam === 'REVOKE' ? (
                <span>
                  {t(`Modal.ChangeStatus.Account.${extraParam}.Extra`, {
                    login,
                  })}
                </span>
              ) : null}
            </>
          ),
          onOk: () => {
            setIsLoading(true);
            changeStatus(extraParam);
          },
        });
        break;

      case 'resend_email':
        Modal.confirm({
          title: t(
            'Modal.ActivationEmail.Account.ActivationEmailMessage.Warning'
          ),
          okText: 'Send',
          okButtonProps: {
            type: 'primary',
          },
          cancelText: 'Cancel',
          autoFocusButton: null,
          closable: true,
          content: (
            <>
              <Alert
                style={{ marginBottom: 16 }}
                message={t(
                  'Modal.ActivationEmail.Account.ActivationEmailMessage.Info'
                )}
                type="info"
              />
              <span>
                {t(
                  'Modal.ActivationEmail.Account.ActivationEmailMessage.Question',
                  {
                    email,
                  }
                )}
              </span>
            </>
          ),
          onOk: () => {
            setIsLoading(true);
            onSendActivationEmail();
          },
        });
        break;

      case 'delete':
        Modal.confirm({
          title: t('Modal.Delete.Account.DeleteMessage.Warning'),
          okText: t('Modal.Delete.Account.Buttons.Delete'),
          okType: 'danger',
          okButtonProps: {
            type: 'primary',
          },
          cancelText: t('Modal.Delete.Account.Buttons.Close'),
          autoFocusButton: null,
          closable: true,
          content: (
            <>
              <Alert
                style={{ marginBottom: 16 }}
                message={t('Modal.Delete.Account.DeleteMessage.Info')}
                type="warning"
              />
              <span>
                {t('Modal.Delete.Account.DeleteMessage.Question', {
                  login,
                  nodeName: data?.nodeData?.nodeName,
                })}
              </span>
            </>
          ),
          onOk: () => {
            setIsLoading(true);
            onDelete();
          },
        });
        break;

      default:
        break;
    }
  };
  const langOptionStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  };

  const onNewNodeConfirm = (values) => {
    setIsShiftAccountNodeModalVisible(false);
    setIsLoading(true);
    onChangeNode(values.searchString);
  };

  const getRole = () => {
    let _role = '';

    assignableRole.forEach((assRole) => {
      if (_role.length) {
        return;
      }
      data.roles.forEach((role) => {
        if (role.name === assRole) {
          _role = role.name;
          return;
        }
      });
    });

    return _role;
  };

  const changeStatus = (newStatus) => {
    accountServices.updateAccountStatus(login, newStatus).then(
      (response) => {
        onChangeStatus('error');

        setIsLoading(false);

        if (response?.data) {
          if (accountServices.checkError(response)) {
            const error = accountServices.getError(response);

            Modal.error({
              title: t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          } else {
            onChangeStatus('success');
          }
        }
      },
      (error) => {
        onChangeStatus('error');
        setIsLoading(false);

        if (axios.isCancel(error)) {
        } else {
          Modal.error({
            title: this.props.t('App.Message.General.ServerError.Title'),
            content: error.message,
          });
        }
      }
    );
  };

  const removeRoleFromAccount = (roleName) => {
    accountServices.deleteAuthorizedRole(data.login, roleName).then(
      (response) => {
        if (response?.data) {
          if (accountServices.checkError(response)) {
            const error = accountServices.getError(response);

            Modal.error({
              title: t('App.Message.General.ServerError.Title'),
              content: error.message,
            });
          } else {
            notification.success({
              description: t(
                'Modal.ChangeItemLinkStatus.Role_account.Message',
                { action: 'removed' }
              ),
            });
            setEditableAccountRoles(
              editableAccountRoles.filter(function (obj) {
                return obj.name !== roleName;
              })
            );
            handleCancel();
            onRemoveAccountRole();
          }
        }
      },
      (error) => {
        Modal.error({
          title: t('App.Message.General.ServerError.Title'),
          content: error.message,
        });
      }
    );
  };

  return (
    <Drawer
      title={title}
      className="editAccountDrawer"
      placement="right"
      width={480}
      visible={isVisible}
      onClose={() => {
        form.resetFields();
        setIsLoading(false);
        handleCancel();
      }}
      footer={
        <Row align="middle" justify="space-between">
          <Col>
            <Button onClick={handleCancel}>{t('Drawer.Buttons.Close')}</Button>
          </Col>
          <Col>
            <Row>
              {canDelete && (
                <Col>
                  <Button
                    danger
                    onClick={() => onOperationClick('delete')}
                    icon={<DeleteOutlined />}
                    type="primary"
                  >
                    {t('PageContainer.CoreComponent.TableItem.Actions.Delete')}
                  </Button>
                </Col>
              )}
              {canChangeNode && context !== 'VALIDDRIVER' && context !== 'RAO' && (
                <Col>
                  <Button
                    className="btn-warn"
                    onClick={() => onOperationClick('change_node')}
                    type="text"
                  >
                    {t(
                      'PageContainer.CoreComponent.TableItem.Actions.ChangeNode'
                    )}
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
          {canUpdate && (
            <Col>
              <Button onClick={() => onOperationClick('update')} type="primary">
                {t('PageContainer.CoreComponent.TableItem.Actions.Modify')}
              </Button>
            </Col>
          )}
        </Row>
      }
    >
      <Spin spinning={isLoading}>
        {canChangeStatus && (
          <>
            <Row justify="space-between" align="middle">
              <Row align="middle">
                <Row>{t('Modal.ChangeStatus.Account.Status')}:</Row>
                <Row>
                  <Tag color={getStatus(data?.accountStatus, t).color}>
                    {getStatus(data?.accountStatus, t).value}
                  </Tag>
                </Row>
              </Row>
              <Row className="btnStatusContainer">
                {data?.accountStatus > 0 && data?.accountStatus !== 8 && (
                  <>
                    <Row>
                      <Button
                        type="primary"
                        danger
                        size="small"
                        onClick={() =>
                          onOperationClick('change_status', 'REVOKE')
                        }
                      >
                        {t('Modal.ChangeStatus.Account.REVOKE.Confirm')}
                      </Button>
                    </Row>
                  </>
                )}
                {data?.accountStatus === 1 && (
                  <Row>
                    <Button
                      danger
                      size="small"
                      onClick={() =>
                        onOperationClick('change_status', 'SUSPEND')
                      }
                    >
                      {t('Modal.ChangeStatus.Account.SUSPEND.Confirm')}
                    </Button>
                  </Row>
                )}
                {data?.accountStatus === 6 && (
                  <Row>
                    <Button
                      size="small"
                      onClick={() =>
                        onOperationClick('change_status', 'RESUME')
                      }
                    >
                      {t('Modal.ChangeStatus.Account.RESUME.Confirm')}
                    </Button>
                  </Row>
                )}
                {data.accountStatus === 9 ? (
                  <Row>
                    <Button
                      size="small"
                      onClick={() => onOperationClick('resend_email')}
                    >
                      {t(
                        'PageContainer.CoreComponent.TableItem.Actions.ActivationEmail'
                      )}
                    </Button>
                  </Row>
                ) : null}
              </Row>
            </Row>
            <Divider />
          </>
        )}
        <Form
          layout="vertical"
          form={form}
          requiredMark="optional"
          initialValues={{
            login,
            language,
            email,
            firstName: firstname,
            lastName: lastname,
            phone: mobileNumber,
            sendActivationEmail,
            ...(context === 'VALIDDRIVER' && {
              selectedRole:
                data.roles.find(
                  (role) => role.name.indexOf('valid_driver') > -1
                )?.name || '',
            }),
            ...(context === 'RAO' && {
              selectedRole: getRole(),
            }),
          }}
        >
          <Row gutter={16}>
            <Col xs={16}>
              <Form.Item name="login">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item name="language">
                <Select disabled={!canUpdate}>
                  <Option value="EN">
                    <div style={langOptionStyle}>
                      <UkIco
                        style={{ width: 20, height: 20, marginRight: 8 }}
                      />
                      <span>{t('App.Languages.ENGLISH')}</span>
                    </div>
                  </Option>
                  <Option value="IT">
                    <div style={langOptionStyle}>
                      <ItalyIco
                        style={{ width: 20, height: 20, marginRight: 8 }}
                      />
                      <span>{t('App.Languages.ITALIAN')}</span>
                    </div>
                  </Option>
                  <Option value="ES">
                    <div style={langOptionStyle}>
                      <EsIco
                        style={{ width: 20, height: 20, marginRight: 8 }}
                      />
                      <span>{t('App.Languages.SPANISH')}</span>
                    </div>
                  </Option>
                  <Option value="FR">
                    <div style={langOptionStyle}>
                      <FrIco
                        style={{ width: 20, height: 20, marginRight: 8 }}
                      />
                      <span>{t('App.Languages.FRENCH')}</span>
                    </div>
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {(context === 'VALIDDRIVER' || context === 'RAO') && (
            <Row gutter={16}>
              <Col xs={24}>
                <Form.Item name="selectedRole">
                  <Select
                    style={{ width: '100%' }}
                    disabled={!canUpdate}
                    placeholder={t(
                      `Modal.Modify.${
                        context === 'VALIDDRIVER' ? 'Validdriver' : 'Rao'
                      }.Form.selectedRole.Label`
                    )}
                  >
                    <Option value="">
                      {t(
                        `App.AssignableRole.Role.no_role_${
                          context === 'VALIDDRIVER' ? 'ValidDriver' : 'RAO'
                        }`
                      )}
                    </Option>
                    {assignableRole.map((role, key) => {
                      //@Todo eliminare ruolo basic quando implementato
                      return (
                        <Option key={key} value={role}>
                          {t('App.AssignableRole.Role.' + role)}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'Please input a valid e-mail!',
                  },
                  {
                    required: true,
                    message: t('General.MandatoryField.Text'),
                  },
                ]}
              >
                <Input
                  disabled={!canUpdate}
                  placeholder={t('Modal.Modify.Account.Form.Email.Placeholder')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={12}>
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: t('General.MandatoryField.Text'),
                  },
                ]}
              >
                <Input
                  disabled={!canUpdate}
                  placeholder={t('Modal.Modify.Account.Form.Name.Placeholder')}
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: t('General.MandatoryField.Text'),
                  },
                ]}
              >
                <Input
                  placeholder={t(
                    'Modal.Modify.Account.Form.Surname.Placeholder'
                  )}
                  disabled={!canUpdate}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item name="phone">
                <PhoneInputWrapper
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  disabled={!canUpdate}
                />
              </Form.Item>
            </Col>
          </Row>
          {canResendEmail && (
            <>
              <Divider />
              <Row align="middle" justify="space-between">
                <Col>
                  <Form.Item
                    name="sendActivationEmail"
                    valuePropName="checked"
                    style={{ marginBottom: 0 }}
                  >
                    <Checkbox>
                      {t('Modal.Modify.Account.Form.ConfirmationEmail.Label')}
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form>
        {!isNilOrEmpty(editableAccountRoles) && (
          <>
            <Divider style={{ marginBottom: 8 }} orientation="left">
              {t('PageContainer.Roles.Title')}
            </Divider>
            {editableAccountRoles.map((role, i) => (
              <div
                className="customTag"
                style={{
                  display: 'inline-flex',
                  background: '#fafafa',
                  border: '1px solid #d9d9d9',
                  padding: '8px 12px',
                  boxSizing: 'border-box',
                  alignItems: 'center',
                  margin: '8px 8px 0 0',
                }}
              >
                <span>{role.name}</span>
                {canRemoveRoles && role.name !== 'basic_user' && (
                  <Popconfirm
                    title="Are you sure to remove this role from this account?"
                    onConfirm={() => removeRoleFromAccount(role.name)}
                    okText="Yes"
                    cancelText="No"
                    key={i}
                  >
                    <LinkOutlined
                      style={{
                        marginLeft: 8,
                        cursor: 'pointer',
                        color: '#ff4d4f',
                      }}
                    />
                  </Popconfirm>
                )}
              </div>
            ))}
          </>
        )}
      </Spin>
      <ShiftAccountNodeModal
        title={t('Modal.Shift.Account.Title', {
          nodeName: data?.nodeData?.nodeName,
        })}
        nodeId={nodeId}
        isModalVisible={isShiftAccountNodeModalVisible}
        onConfirm={onNewNodeConfirm}
        handleCancel={() => setIsShiftAccountNodeModalVisible(false)}
        nodeServices={nodeServices}
      />
    </Drawer>
  );
}

export default withTranslation()(EditAccountDrawer);
