import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Typography,
} from 'antd';
import './styles.less';
import { ReactComponent as ItalyIco } from '../../assets/icons/ico-flag-it.svg';
import { ReactComponent as UkIco } from '../../assets/icons/ico-flag-uk.svg';
import { ReactComponent as EsIco } from '../../assets/icons/ico-flag-es.svg';
import { ReactComponent as FrIco } from '../../assets/icons/ico-flag-fr.svg';
import { withTranslation } from 'react-i18next';
import { useState } from 'react';
import UserServices from '../../services/user.services';
import axios from 'axios';
import _ from 'lodash';
import PhoneInputWrapper from '../../components/PhoneInputWrapper';
import { isNilOrEmpty } from '../../utility/utils';

const { Option } = Select;
const { Title } = Typography;

function Settings({ t, globalSettings, user, setUser, i18n }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(user?.userInfo?.mobileNumber);
  const langOptionStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  };

  const onSubmit = (values) => {
    let env;

    if (_.isString(process.env.REACT_APP_ENV)) {
      env = process.env.REACT_APP_ENV;
    } else {
      env = globalSettings.DefaultEnvironment;
    }

    const userServices = new UserServices(globalSettings.Environment[env]);

    const userData = {
      firstname: values.firstname,
      lastname: values.lastname,
      ...(!isNilOrEmpty(phoneNumber) && {
        mobileNumber: `+${phoneNumber}`,
      }),
      email: values.email,
    };

    setIsLoading(true);

    userServices.updateUserData(values.language, userData).then(
      (response) => {
        if (response?.data) {
          if (userServices.checkError(response)) {
            const error = userServices.getError(response);

            Modal.error({
              title: t('App.Message.General.ServerError.Title'),
              content: error.message,
            });

            setIsLoading(false);
          } else {
            setUser(userData, user.roles, {
              ...user.accountInfo,
              language: values.language,
            });
            i18n.changeLanguage(values.language.toLowerCase());
            message.success('User correctly updated!');
            setIsLoading(false);
          }
        }
      },
      (error) => {
        if (axios.isCancel(error)) {
        } else {
          Modal.error({
            title: t('App.Message.General.ServerError.Title'),
            content: error.message,
          });
        }

        setIsLoading(false);
      }
    );
  };

  return (
    <div className="settings">
      <Title level={3}>{t('PageContainer.Settings.Title')}</Title>
      <Layout className="settings-main">
        <Spin spinning={isLoading}>
          <Form
            layout="vertical"
            form={form}
            requiredMark="optional"
            onFinish={onSubmit}
            initialValues={{
              firstname: user?.userInfo?.firstname,
              lastname: user?.userInfo?.lastname,
              email: user?.userInfo?.email,
              language: user?.accountInfo?.language,
            }}
          >
            <Row gutter={16}>
              <Col xs={24}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      message: 'Please input a valid e-mail!',
                    },
                    {
                      required: true,
                      message: t('General.MandatoryField.Text'),
                    },
                  ]}
                >
                  <Input
                    placeholder={t(
                      'PageContainer.Settings.UserDetail.Form.email.Label'
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={12}>
                <Form.Item
                  name="firstname"
                  rules={[
                    {
                      required: true,
                      message: t('General.MandatoryField.Text'),
                    },
                  ]}
                >
                  <Input
                    placeholder={t(
                      'PageContainer.Settings.UserDetail.Form.name.Label'
                    )}
                  />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  name="lastname"
                  rules={[
                    {
                      required: true,
                      message: t('General.MandatoryField.Text'),
                    },
                  ]}
                >
                  <Input
                    placeholder={t(
                      'PageContainer.Settings.UserDetail.Form.surname.Label'
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={12}>
                <Form.Item>
                  <PhoneInputWrapper
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                  />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item name="language">
                  <Select
                    placeholder={t(
                      'PageContainer.Settings.UserDetail.Form.language.Label'
                    )}
                  >
                    <Option value="EN">
                      <div style={langOptionStyle}>
                        <UkIco
                          style={{ width: 20, height: 20, marginRight: 8 }}
                        />
                        <span>{t('App.Languages.ENGLISH')}</span>
                      </div>
                    </Option>
                    <Option value="IT">
                      <div style={langOptionStyle}>
                        <ItalyIco
                          style={{ width: 20, height: 20, marginRight: 8 }}
                        />
                        <span>{t('App.Languages.ITALIAN')}</span>
                      </div>
                    </Option>
                    <Option value="ES">
                      <div style={langOptionStyle}>
                        <EsIco
                          style={{ width: 20, height: 20, marginRight: 8 }}
                        />
                        <span>{t('App.Languages.SPANISH')}</span>
                      </div>
                    </Option>
                    <Option value="FR">
                      <div style={langOptionStyle}>
                        <FrIco
                          style={{ width: 20, height: 20, marginRight: 8 }}
                        />
                        <span>{t('App.Languages.FRENCH')}</span>
                      </div>
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Button type="primary" htmlType="submit">
              {t('PageContainer.Settings.UserDetail.Button.Update')}
            </Button>
          </Form>
        </Spin>
      </Layout>
    </div>
  );
}

export default withTranslation()(Settings);
