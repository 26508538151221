import { Avatar, Col, Dropdown, Menu, Row } from 'antd';
import {
  SettingOutlined,
  LogoutOutlined,
  InfoCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import './styles.less';
import { useHistory } from 'react-router';
import { LOGOUT, SETTINGS, SOFTWARE_INFO } from '../../constants/routes';
import { withTranslation } from 'react-i18next';

function ProfileManager({ userManager, t }) {
  const history = useHistory();

  const onMenuOptionClick = (option) => {
    switch (option.key) {
      case 'settings':
        history.push(SETTINGS);
        break;

      case 'about':
        history.push(SOFTWARE_INFO);
        break;

      case 'logout':
        history.push(LOGOUT);
        break;

      default:
        return;
    }
  };
  const menu = (
    <Menu onClick={onMenuOptionClick}>
      {userManager?.isValidUser && (
        <>
          <Menu.Item key="settings" icon={<SettingOutlined />}>
            {t('TopBar.AvatarMenu.Settings')}
          </Menu.Item>
          <Menu.Item key="about" icon={<InfoCircleOutlined />}>
            {t('TopBar.AvatarMenu.SoftwareInfo')}
          </Menu.Item>
          <Menu.Divider />
        </>
      )}
      <Menu.Item key="logout" icon={<LogoutOutlined />}>
        {t('TopBar.AvatarMenu.Logout')}
      </Menu.Item>
    </Menu>
  );

  return (
    <Row align="middle" className="profileManager" gutter={16}>
      {userManager?.isValidUser ? (
        <>
          <Col className="profileManager-left">
            <div>
              {userManager.user.userInfo.firstname}{' '}
              {userManager.user.userInfo.lastname}
            </div>
            <div>{userManager.user.userInfo.email}</div>
          </Col>
          <Col>
            <Dropdown overlay={menu} trigger={['click']}>
              <Avatar className="profileManager-avatar" size="large">
                {userManager.user.userInfo.firstname.substring(0, 1)}
                {userManager.user.userInfo.lastname.substring(0, 1)}
              </Avatar>
            </Dropdown>
          </Col>
        </>
      ) : (
        <Col>
          <Dropdown overlay={menu} trigger={['click']}>
            <Avatar
              className="profileManager-avatar"
              size="large"
              icon={<UserOutlined />}
            />
          </Dropdown>
        </Col>
      )}
    </Row>
  );
}

export default withTranslation()(ProfileManager);
